import { useState, useContext, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import {
    useQuery,
    useMutation,
} from '@tanstack/react-query'
import _ from 'lodash'
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import PageWrapper from '../../components/share/PageWrapper';
import Typography from '../../components/share/Typography';
import Button from '../../components/share/Button';
import Select from '../../components/share/Select';
import GreyDivider from '../../components/GreyDivider';
import TextField from '../../components/share/TextField';
import Form from '../../components/share/form/Form';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import { createStock } from '../../apis/stock';
import { Routes, Route, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Fields } from './formFields'
import { userContext } from '../../context/UserProvider';
import { getPermission } from '../../utils'

const SaveButton = styled(Button)`
    && {
        align-self: end; 
        width: 80px;
        margin: 10px 20px 14px 20px;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
    height: 100%;
    overflow: auto;
`

const margin = 10;

const schema = Joi.object({
    category: Joi.string().required(),
    internalCode: Joi.string().required(),
    availableQty: Joi.number().required(),
    productDescription: Joi.string().empty(""),
}).messages({
    "any.required": "Required",
    "object.base": "Required"
});

function StockAddPage(props) {

    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [user, setUser] = useContext(userContext);
    const productType = searchParams.get('type');

    const { canView, canModify } = getPermission(user?.permissions?.stock)

    const { control, handleSubmit, watch, formState: { errors }, reset } = useForm({
        resolver: joiResolver(schema),
    });

    useEffect(() => {
        if (!productType) return
        reset({
            category: productType
        })
    }, [productType])

    const mutation = useMutation({
        mutationFn: (payload) => createStock(payload),
        onSuccess: (data) => {
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
            navigate(`/stock?tab=${data.category}`)
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const handleOnSubmit = (formValue) => {
        const payload = {
            internalCode: formValue.internalCode,
            productDescription: formValue.productDescription,
            availableQty: formValue.availableQty,
            category: formValue.category
        };
        console.log("payload", payload)
        mutation.mutate(payload)
    }

    console.log("errors", errors)

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Stock > Add New`}</Typography>
            </>
        )
    }

    const CardHeader = () => {
        return (
            <SaveButton loading={mutation.isLoading} disabled={!canModify} onClick={() => handleSubmit(handleOnSubmit)()}>Save</SaveButton>
        )
    }

    return (
        <PageWrapper
            loading={mutation.isLoading}
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            renderCardHeader={CardHeader}
        >
            <Content>
                <Form fields={Fields({ control, errors })} p={`14px 0px`} />
            </Content>
        </PageWrapper>
    )
}

export default StockAddPage