import { useState } from 'react'
import styled from 'styled-components'
import { styled as styledMui } from '@mui/material/styles';
import CollpaseContainer from '../share/CollapseContainer'
import { Tabs } from '../../pages/Product/add'
import { Grid } from '@mui/material';
import Typography from '../share/Typography';
import {
    Step1Fields, FlipCardColorFields,
    OptionFields,
    VasFields,
    TncFields,
    RecommendFields
} from '../../pages/Product/formFields';
import Form from '../share/form/Form'
import ProductDetail from './ProductDetail';
import ControlComponent from './Control'

const Content = styledMui('div')(
    ({ theme, selected, error }) => `
    overflow: auto;
    padding: 20px;

    && {
        > *:not(:last-child) {
            border-bottom: 1px solid ${theme.palette.secondary.main};
        }
    }
  `,
);

const Indicator = styledMui(Typography)(
    ({ theme, selected, error }) => `
        height: 25px;
        width: 25px;
        border-radius: 50%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        background-color: ${theme.palette.primary.light};
  `,
);

const Header = ({ index, title }) => {
    return (
        <Grid container direction='row' alignItems='center' style={{ marginRight: 8 }}>
            <Grid item>
                <Indicator>{index}</Indicator>
            </Grid>
            <Grid item>
                <Typography variant="header3" >{title}</Typography>
            </Grid>
        </Grid>
    )
}

function Confirmation({ control, reset, getValues }) {


    const renderContent = (position) => {
        switch (position) {
            case 0: {
                return (
                    <Form fields={Step1Fields({ name: "step1", control, readOnly: true })} p={`14px 0px`} />
                )
            }

            case 1: {
                return (
                    <ProductDetail
                        control={control}
                        name="step2"
                        readOnly
                        getValues={getValues}
                    />
                )
            }

            case 2: {
                return (
                    <Form fields={TncFields({ control, name: "step3", readOnly: true })} p={`14px 0px`} />
                )
            }

            case 3: {
                return (
                    <Form fields={VasFields({ control, name: "step4", readOnly: true })} p={`14px 0px`} />
                )
            }

            case 4: {
                return <Form fields={RecommendFields({ control, name: "step5", readOnly: true })} p={`14px 0px`} />
            }

            case 5: {
                return (
                    <ControlComponent control={control} reset={reset} getValues={getValues} readOnly={true} />
                )
            }
        }
    }

    return (
        <Content>
            {
                Tabs.map((item, position) => {
                    return (
                        <CollpaseContainer
                            renderTitle={() => <Header title={item.name} index={position + 1} />}
                            arrowColor={"#CACACA"}
                        >
                            <div style={{ overflow: "auto" }}>
                                {renderContent && renderContent(position)}
                            </div>
                        </CollpaseContainer>
                    )
                })
            }
        </Content>
    )
}

export default Confirmation