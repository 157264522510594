import Grid from '@mui/material/Grid';
import GreyDivider from '../../GreyDivider'

function Form({ fields, p = 20 }) {
    return (
        <Grid container columns={2} spacing={2} style={{ padding: p }}>
            {
                fields.map((item, position) => {
                    if (!item?.id) return null
                    if (item.id === "spacing") return <Grid item xs={1} key={`${item.id}-${position}`} />
                    if (item.id === "xs" || item.xs) return <Grid item xs={2} key={`${item.id}-${position}`}>{item.component}</Grid>
                    if (item.id === 'hr') return (
                        <Grid item xs={2}>
                            <GreyDivider />
                        </Grid>
                    )
                    if (!item?.component) return null
                    return (
                        <Grid item xs={item.xs || item.id === "dynamicList" ? 2 : 1} key={`${item.id}-${position}`}>
                            {item.component}
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

export default Form