import { useState, useContext, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import _, { isBoolean, isUndefined } from 'lodash'
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
    PageWrapper,
    Typography,
    Button,
    Form,
    Progressbar,
    PositionedMenu,
    PublishPeriodDialog
} from '../../components/share';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import { companiesContext } from '../../context/CompaniesProvider';
import { vasContext } from '../../context/VasProvider';
import { tncContext } from '../../context/TncProvider';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import {
    Step1Fields,
    Step2Fields,
    VasFields,
    TncFields,
    RecommendFields
} from './formFields';
import ControlComponent from '../../components/mobile/Control'
import Summary from '../../components/mobile/Confirmation';
import { fetchPlan, updatePlan } from '../../apis/plan';
import { DefFrontCardFields, Tabs, schema } from './add';
import { getDirtyValues, removeEmptyElements, removeId, toIds, isDirtyArray, getPermission } from '../../utils';
import { userContext } from '../../context/UserProvider';
import constants from '../../constants'


const SaveButton = styled(Button)`
    && {
        align-self: end; 
        min-width: 80px;
        margin: 10px 8px;
        height: 30px;
    }
`
const RowDirectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => props.showConfirmation ? "space-between" : "flex-end"};
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: auto;
`

const formatItemsWithImageKey = (items) => {
    return items.map((item, index) => {
        const { imageUrl, ...rest } = item;
        return {
            ...rest,
            image: item?.image ? {
                fileKey: item?.image || undefined,
                previewUrl: item?.imageUrl,
            } : undefined,
        }
    });
};

const getResetData = ({ data }) => {
    const defRecommend = Array.from({ length: 3 }, (v, i) => `${i}`)
    const recommendPlanList = _.map(data?.recommendPlans, (item) => ({ category: 'plan', ...item }))
    const recommendProductList = _.map(data?.recommendProducts, (item) => ({ category: 'product', ...item }))
    const recommendList = _.concat(recommendPlanList, recommendProductList, defRecommend).slice(0, 3);
    const canEdit = data?.status === 'draft' ? true : false

    return {
        step1: {
            company: data?.company?._id,
            promotionTitle: data.promotionTitle || {},
            flipCardLabel: data?.flipCardLabel || {},
            flipCardIconImage: {
                fileKey: data?.flipCardIconImage || "",
                previewUrl: data?.flipCardIconImageUrl,
            },
            planName: data?.planName || {},
            localData: data?.localData || {},
            // localVoiceMins: data?.localVoiceMins || {},
            discountedPrice: data?.discountedPrice && !isNaN(data.discountedPrice) ? Number(data.discountedPrice) : null,
            originalPrice: data?.originalPrice && !isNaN(data.originalPrice) ? Number(data.originalPrice) : null,
            planId: data.planId,
            cardFront: (data?.cardFront && data?.cardFront.length > 0) 
                ? formatItemsWithImageKey(removeId(data.cardFront)) : (
                    canEdit ? [
                        { ...DefFrontCardFields },
                        { ...DefFrontCardFields },
                        { ...DefFrontCardFields },
                    ] : []
            ),
            cardBack: (data?.cardBack && data?.cardBack.length > 0) 
                ? formatItemsWithImageKey(removeId(data.cardBack)) : (
                    canEdit ? [
                        { ...DefFrontCardFields },
                    ] : []
            ),
        },
        step2: {
            planDetailsInclude: (data?.planDetailsInclude && data?.planDetailsInclude.length > 0) 
                ? formatItemsWithImageKey(removeId(data?.planDetailsInclude)) : (
                    canEdit ? [
                        { ...DefFrontCardFields },
                    ] : []
            ),
            contractPeriodDetails: data?.contractPeriodDetails ? removeId(data.contractPeriodDetails) : [],
            planAutoRenewalOption: isBoolean(data?.planAutoRenewalOption) ? (data?.planAutoRenewalOption ? "enable" : "disable") : "enable",
            // quantityDescription: data?.quantityDescription || {},
            quantityMin: data.quantityMin,
            quantityMax: data.quantityMax
        },
        step3: {
            detailPageTncs: data?.detailPageTncs,
            overallTncs: data?.overallTncs,
            remark: data?.remark || {}
        },
        step4: {
            freeVasMax: data?.freeVasMax,
            freeVas: data?.freeVas.map((item) => ({ ...item, isRecommended: (data?.recommendVasIds || []).includes(item?._id) || false })),
            paidVas: data?.paidVas.map((item) => ({ ...item, isRecommended: (data?.recommendVasIds || []).includes(item?._id) || false })),
},
        step5: {
            recommend: recommendList
        }
    }
}

function MobileDetailPlan(props) {

    const navigate = useNavigate();


    const [step, setStep] = useState(0);
    const [showSummary, setShowSummary] = useState(false);
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [companies, setCompanies, VIPcompanies, setVIPCompanies] = useContext(companiesContext);
    const [vasList] = useContext(vasContext);
    const [tncList] = useContext(tncContext);
    const [publishDialog, setPublishDialog] = useState({ open: false })
    const [user, setUser] = useContext(userContext);

    const { canView, canModify } = getPermission(user?.permissions?.plan)

    let { planId } = useParams();

    const { control, handleSubmit, watch, formState: { errors, dirtyFields }, reset, getValues, setValue, setError, clearErrors } = useForm({
        // resolver: joiResolver(schema),
    });

    const { data, error, isError, isLoading, refetch } = useQuery({
        queryKey: ["planDetail", { planId }],
        queryFn: async () => await fetchPlan(planId),
        onSuccess: (data) => {
            reset(getResetData({ data }))
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        },
        enabled: canView && !isUndefined(planId)
    });

    const mutation = useMutation({
        mutationFn: (payload) => updatePlan(planId, payload),
        onSuccess: (data) => {
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
            refetch()
            if (showSummary) {
                navigate(`/mobile`);
            }
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const canEdit = data?.status === 'draft' ? true : false



    const validateForm = () => {
        clearErrors();
        const values = getValues()
        const result = schema.validate(values, { abortEarly: false });
        if (result.error) {
            _.forEach(result.error.details, (item) => {
                setError(item.context.label, { message: item.message })
            })
            setValue('status', "draft", { shouldDirty: true })
            setValue('publishStartDate', undefined, { shouldDirty: true })
            setValue('publishEndDate', undefined, { shouldDirty: true })
            setShowSummary(false);
            setSnackbar({
                type: 'error',
                message: constants.INVALID_INPUT_ERR_MSG
            })
        } else {
            handleSubmit(handleOnSubmit)()
        }
    }

    const handleOnSubmit = (formValue) => {
        let simplifyFormValue = {}
        for (const property in formValue) {
            if (property === "publishStartDate" || property === "publishEndDate" || property === "status") {
                simplifyFormValue[property] = formValue[property]
            } else {
                simplifyFormValue = { ...simplifyFormValue, ...formValue[property] };
            }
        }

        let simplifyDirtyFields = {}
        for (const property in dirtyFields) {
            if (typeof dirtyFields[property] !== "object") {
                simplifyDirtyFields[property] = dirtyFields[property]
            } else {
                simplifyDirtyFields = { ...simplifyDirtyFields, ...dirtyFields[property] }
            }
            // simplifyDirtyFields[property] = dirtyFields[property]
        }

        let newDirtyFields = {}
        _.forEach(simplifyDirtyFields, (dirtyValue, dirtyKey) => {
            if (typeof dirtyValue === "object") {
                const check = isDirtyArray(dirtyValue)
                if (check === true) newDirtyFields[dirtyKey] = true
            } else if (dirtyValue === true) {
                newDirtyFields[dirtyKey] = true
            }
        })
        const dirty = getDirtyValues(newDirtyFields, simplifyFormValue);

        let payload = { status: "draft", ...dirty }
        if (formValue?.publishStartDate) {
            payload['publishStartDate'] = formValue?.publishStartDate
            payload['status'] = 'active'
        }
        if (formValue?.publishEndDate) {
            payload['publishEndDate'] = formValue?.publishEndDate
            payload['status'] = 'active'
        }

        _.forEach(payload, (item, key) => {
            if (key === 'recommend') {
                let tmpRecommend = [...payload.recommend];
                payload['recommendProducts'] = _.chain(tmpRecommend).filter((o) => o.category === "product" && o._id).map((v) => v._id).value();
                payload['recommendPlans'] = _.chain(tmpRecommend).filter((o) => o.category === "plan" && o._id).map((v) => v._id).value();
                delete payload.recommend
            } else if (key === "flipCardIconImage" && item?.fileKey) {
                payload['flipCardIconImage'] = item.fileKey
            } else if (key === "cardFront" || key === "cardBack" || key === "planDetailsInclude") {
                let tmpArray = [...item];
                payload[key] = _.map(tmpArray, (o) => ({ ...o, image: o?.image?.fileKey || "" }))
            } else if (["overallTncs", "detailPageTncs"].includes(key)) {
                payload[key] = toIds(item)
            } else if (["freeVas", "paidVas"].includes(key)) {
                payload[key] = toIds(item)

                // Add recommended VAS to recommendVasIds
                const { freeVas, paidVas } = formValue?.step4;
                const recommendVasIds = [...freeVas, ...paidVas].filter((o) => o.isRecommended).map((v) => v._id);
                payload['recommendVasIds'] = recommendVasIds
            } else if (key === "freeVasMax") {
                payload['freeVasMax'] = payload.freeVasMax === "" || isNaN(payload.freeVasMax) ? null : Number(payload.freeVasMax)
            } else if (key === "planAutoRenewalOption") {
                payload['planAutoRenewalOption'] = item === "enable" ? true : false
            }
        })
        // payload = removeEmptyElements(payload)
        console.log("payload", payload)

        mutation.mutate(payload)
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Mobile > ${data?.planId}`}</Typography>
            </>
        )
    }

    const renderForm = () => {
        switch (step) {
            case 0: {
                return (
                    <Content key={step}>
                        <Form fields={Step1Fields({ control, errors: errors.step1, name: "step1", readOnly: !canModify || !canEdit, companies: VIPcompanies })} p={`14px 0px`} />
                    </Content>
                )
            }

            case 1: {
                return (
                    <Content key={step}>
                        <Form fields={Step2Fields({ control, errors: errors.step2, name: "step2", readOnly: !canModify || !canEdit })} p={`14px 0px`} />
                    </Content>
                )
            }

            case 2: {
                return (
                    <Content key={step}>
                        <Form fields={TncFields({ control, errors: errors.step3, name: "step3", readOnly: !canModify || !canEdit, tncList })} p={`14px 0px`} />
                    </Content>
                )
            }

            case 3: {
                return (
                    <Content key={step}>
                        <Form fields={VasFields({ control, errors: errors.step5, name: "step4", readOnly: !canModify || !canEdit, vasList })} p={`14px 0px`} />
                    </Content>
                )
            }

            case 4: {
                return (
                    <Content key={step}>
                        <Form fields={RecommendFields({ control, errors, name: "step5", readOnly: !canModify || !canEdit })} p={`14px 0px`} />
                    </Content>
                )
            }

            case 5: {
                return (
                    <Content key={step}>
                        <ControlComponent control={control} reset={reset} getValues={getValues} />
                    </Content>
                )
            }

            default: {
                return null
            }
        }
    }

    const isLastStep = step === (Tabs.length - 1)

    const updateStep = (type) => {
        if (showSummary && type === "deduct") {
            if (type === "deduct") setShowSummary(false)
            return
        }
        if (type === 'add') {
            if (isLastStep && canEdit && canModify) {
                // handleSubmit(handleOnSubmit)()
                setShowSummary(true)
            } else {
                if (step < (Tabs.length - 1)) setStep(step + 1)
            }
        } else {
            if (step >= 1) setStep(step - 1)
        }
    }

    const CardHeader = () => {
        return (
            <RowDirectionContainer>
                <SaveButton variant='outlined' disabled={!step} onClick={() => updateStep('deduct')}>Previous</SaveButton>
                <SaveButton
                    variant='outlined'
                    disabled={!canEdit || !canModify}
                    loading={isLoading || mutation.isLoading}
                    onClick={() => {
                        handleSubmit(handleOnSubmit)()
                    }}
                >
                    Save As Draft
                </SaveButton>
                {
                    showSummary ?
                        <PositionedMenu
                            label="Publish"
                            isLoading={isLoading || mutation.isLoading}
                            options={[
                                {
                                    label: "Publish Now",
                                    id: "publish-now"
                                },
                                {
                                    label: "Select Publish Period",
                                    id: 'select-publish-period'
                                }
                            ]}
                            onSelect={(type) => {
                                if (type.id === "publish-now") {
                                    setValue('status', 'active', { shouldDirty: true });
                                    validateForm()
                                } else {
                                    setPublishDialog({ open: true })
                                }
                            }}
                            style={{ marginRight: 8 }}
                        /> :
                        <SaveButton disabled={isLastStep && (!canEdit || !canModify)} onClick={() => updateStep('add')}>{isLastStep && canEdit ? 'Preview' : 'Next'}</SaveButton>
                }
            </RowDirectionContainer>
        )
    }

    console.log("errors", errors)

    return (
        <PageWrapper
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            renderCardHeader={CardHeader}
        >
            {
                showSummary ?
                    <Summary control={control} reset={reset} getValues={getValues} />
                    :
                    <>
                        <Progressbar step={step} data={Tabs} error={Object.keys(errors)} />
                        <div style={{ height: '100%', overflow: 'auto' }}>
                            {renderForm()}
                        </div>

                    </>
            }
            <PublishPeriodDialog
                open={publishDialog.open}
                handleClose={() => setPublishDialog({ open: false })}
                control={control}
                onSubmit={() => validateForm()}
            />
        </PageWrapper>
    )
}

export default MobileDetailPlan