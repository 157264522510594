import { createContext, useState } from 'react';

//create a context, with createContext api
export const alertDialogContext = createContext();

const AlertDialogProvider = (props) => {
    // this state will be shared with all components 
    const [dialog, setDialog] = useState();

    return (
        // this is the provider providing state
        <alertDialogContext.Provider value={[dialog, setDialog]}>
            {props.children}
        </alertDialogContext.Provider>
    );
};

export default AlertDialogProvider;