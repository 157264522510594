import { useState, useContext, useCallback, useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Grid, Box } from '@mui/material';
import { Routes, Route, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash'
import Typography from '../../components/share/Typography';
import Button from '../../components/share/Button';
import Table, { UnderlineText, DeleteActionIcon } from '../../components/share/Table';
import PageWrapper from '../../components/share/PageWrapper';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import AlertDialogProvider, { alertDialogContext } from '../../context/AlertDialogProvider';
import Searchbar from '../../components/share/Search'
import constants from '../../constants';
import dayjs from 'dayjs';

function UserManagementPage(props) {
    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [dialog, setDialog] = useContext(alertDialogContext);
    const [filter, setFilter] = useState({
        keyword: "",
    })

    const columns = useMemo(() => {
        return [
            { field: 'name', headerName: 'User Name', width: 300 },
            { field: 'compenyName', headerName: 'Company Name',  width: 300 },
            { field: 'jobPosition', headerName: 'Job Position',  width: 300 },
            { field: 'email', headerName: 'Email', width: 300, sortable: false, },
            { field: 'createdAt', headerName: 'Create Date', width: 160, valueGetter: (params) => dayjs(params.value).format(constants.DATE_FORMAT) },
            {
                field: 'actions',
                headerName: 'Action',
                type: 'actions',
                width: 80,
                headerAlign: 'center',
                getActions: (params) => {
                    return [
                        <UnderlineText 
                            onClick={() => navigate(`/user/${params.row._id}`)}
                        >
                            Edit
                        </UnderlineText>,
                    ]
                }
            }
        ]
    }, [])

    const onApiError = (error) => {
        setSnackbar({
            type: 'error',
            message: `${error?.response?.data?.result?.errorMessage?.en || error?.message}`
        })
    }

    const Header = () => {
        return (
            <Typography bold style={{ flex: 1 }}>{`User Management`}</Typography>
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderCardHeader={() => (<div />)}
        >
            <Grid container direction='row' alignItems='center' style={{ paddingRight: 10 }}>
                <Searchbar value={filter.keyword} onChange={(e) => setFilter({ ...filter, keyword: e.target.value })} />
            </Grid>
            <Table
                style={{ margin: `0px 20px 20px 20px` }}
                color="grey"
                columns={columns}
                api={"/user/admin"}
                // apiParams={{
                //     category: categoryName,
                // }}
                sortingMode="server"
                onError={onApiError}
                setSnackbar={setSnackbar}
                // onRowClick={(params) => {
                //     navigate(`/terms-and-conditions/${params.id}`)
                // }}
            />
        </PageWrapper>
    )
}

export default UserManagementPage