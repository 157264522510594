import { useState } from 'react'
import styled from 'styled-components'
import { Grid } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import Typography from '../share/Typography';
import { zeroPad } from '../../utils';
import { styled as styledMui, createTheme, ThemeProvider } from '@mui/material/styles';

const Container = styledMui(Grid)(
    ({ theme, isActive, isDragged }) => `
    && {
        height: 100%;
        background: ${theme.palette.mainColor.light};
        border-radius: 5px;
        flex-wrap: nowrap;
        margin-bottom: 20px;
        ${isActive ? `opacity: 0.2;` : ""}
        ${isDragged ? `
            cursor: grabbing;
            .handle {
                cursor: grabbing;
            }
        `: ""}
    }
  `,
);

const Content = styled(Grid)`
    && {
        padding: 0px 40px 20px 40px;
    }
`

const IconButton = styledMui('div')(
    ({ theme }) => `
    && {
        cursor: pointer;
        margin: 0px 6px;
        color: primary.main;
    }
  `,
);

function QuestionContainer({ children, open, handleToggle, handleDragProps, isActive, isDragged, handleRemove, canDelete, position, ...props }) {

    const CollapseIcon = open ? RemoveIcon : AddIcon;

    return (
        <Container
            container
            direction='column'
            isActive={isActive}
            isDragged={isDragged}
            {...props}
        >
            <Grid item container direction='row' alignItems='center' style={{ padding: `20px 8px 20px 8px` }}>
                <IconButton {...handleDragProps}><DragHandleIcon /></IconButton>
                <Typography style={{ flex: 1 }}>Question {zeroPad(position+1, '2')}</Typography>
                {canDelete && <IconButton onClick={handleRemove}><DeleteIcon /></IconButton>}
                <IconButton>
                    <CollapseIcon onClick={handleToggle} />
                </IconButton>
            </Grid>

            {open &&
                <Content item container direction='row' nowrap>
                    {children}
                </Content>
            }

        </Container>
    )
}

export default QuestionContainer