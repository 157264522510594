import { useState, useContext, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import {
    useQuery,
    useMutation,
    useQueryClient
} from '@tanstack/react-query'
import _ from 'lodash'
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import {
    TableStatusFilter,
    PageWrapper,
    Typography,
    Button,
    Select,
    TextField,
    Form
} from '../../components/share';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import { fetchAdmin, updateAdmin, listRole } from '../../apis/adminManagement';
import { useParams, useNavigate } from 'react-router-dom';
import { userContext } from '../../context/UserProvider';
import { adminRoleContext } from '../../context/AdminRoleProvider';
import { getPermission } from '../../utils'
import { FIELDS, STATUS, schema } from './add';
import { Box, Grid } from '@mui/material'
import dayjs from 'dayjs';
import constants from '../../constants';
import { useTheme } from '@mui/material/styles';

const SaveButton = styled(Button)`
    && {
        align-self: end; 
        width: 80px;
        margin: 10px 20px 14px 20px;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
    overflow: auto;
`

const getResetData = ({ data }) => {
    return {
        costControlCentre: data?.costControlCentre || "",
        corphqId: data?.corphqId || "",
        familyName: data?.familyName || "",
        givenName: data?.givenName || "",
        email: data.email,
        role: data.role,
        status: data.status
    }
}

function AdminDetail(props) {
    const navigate = useNavigate();
    const theme = useTheme();

    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [user, setUser] = useContext(userContext);
    const [roles] = useContext(adminRoleContext)

    let { adminId } = useParams();

    const { canView, canModify } = getPermission(user?.permissions?.adminUser)

    const { control, handleSubmit, watch, formState: { errors, dirtyFields }, reset } = useForm({
        resolver: joiResolver(schema),
    });

    const { data, error, isError, isLoading } = useQuery({
        queryKey: ["adminDetail", { adminId }],
        queryFn: async () => await fetchAdmin(adminId),
    });

    useEffect(() => {
        if (!_.isEmpty(errors)) {
            setSnackbar({
                type: "error",
                message: constants.INVALID_INPUT_ERR_MSG
            })
        }
    }, [errors])

    useEffect(() => {
        if (!isLoading && data) {
            reset(getResetData({ data }))
        }
    }, [data])

    const mutation = useMutation({
        mutationFn: (payload) => updateAdmin(adminId, payload),
        onSuccess: (data) => {
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
            reset(getResetData({ data }))
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const handleOnSubmit = (formValue) => {
        if (_.isEmpty(dirtyFields)) {
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
            return
        }
        let payload = {};
        _.forEach(dirtyFields, (value, key) => {
            payload[key] = formValue[key]
        })
        mutation.mutate(payload)
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Admin Management`}</Typography>
            </>
        )
    }

    const CardHeader = () => {
        return (
            <Box display='flex' flexDirection='row' justifyContent='flex-end' alignItems="center">
                <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                        <TableStatusFilter
                            options={STATUS}
                            style={{ margin: `0px 10px` }}
                            titleStyle={{ color: theme.palette.primary.main }}
                            disabled={!canModify}
                            {...field}
                        />
                    )}
                />
                <Typography variant="header3" sx={{ mr: 1 }}>Edited on {dayjs(data?.updatedAt).format(constants.DATE_TIME_FORMAT)}</Typography>
                <SaveButton disabled={!canModify || mutation.isLoading} onClick={() => handleSubmit(handleOnSubmit)()}>Save</SaveButton>
            </Box>
        )
    }

    console.log("errors", errors)

    return (
        <PageWrapper
            loading={mutation.isLoading}
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            renderCardHeader={CardHeader}
        >
            <Content>
                <Form fields={FIELDS({ control, errors, roleList: roles, readOnly: !canModify })} p={`14px 0px`} />
            </Content>
        </PageWrapper>
    )
}

export default AdminDetail