import ComponentContainer from './share/ComponentContainer';
import styled from 'styled-components';
import { ChromePicker } from 'react-color';
import { useEffect, useState } from 'react';
import TextField from './share/TextField'
import Popover from '@mui/material/Popover';

const Cover = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
`

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const ColorBox = styled.div`
    min-height: 43px;
    min-width: 43px;
    border-radius: 5px;
    border: 1px solid #CACACA;
    background-color: ${props => props.color};
`

function ColorPicker({ value = "#fff", onChange, readOnly, ...props}) {

    // const [color, setColor] = useState(value);

    // useEffect(() => {
    //     if (value) {
    //         setColor(value)
    //     }
    // }, [value])

    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (event) => {
        if (readOnly) return
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (color) => {
        // setColor(color.hex);
        onChange && onChange(color.hex)
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <ComponentContainer direction='vertical' style={{ width: 'auto' }} {...props}>
            <RowContainer>
                <ColorBox color={value} onClick={handleOpen} />
                <TextField value={value} onChange={onChange} style={{ marginLeft: 20 }} readOnly={readOnly} />
            </RowContainer>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Cover onClick={handleClose} />
                <ChromePicker color={value} onChange={handleChange} />
            </Popover>
        </ComponentContainer>
    )
}

export default ColorPicker