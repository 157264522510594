import { useState, forwardRef } from 'react';
import { Column } from './index';
import IconButton from '@mui/material/IconButton'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const ColumnItem = forwardRef(({ children, sortable, ...props }, ref) => {

    const [isHover, setIsHover] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [direction, setDirection] = useState('up')

    const handleOnClickIcon = () => {
        if (isShow) {
            if (direction === 'up') {
                setDirection('down')
            } else {
                setIsShow(false);
                setDirection('up')
            }
        } else {
            setIsShow(true);
            setDirection('up')
        }
    }

    const renderSortingIcon = () => {

        if (!sortable) return null
        if (!isShow && !isHover) return (
            <IconButton size={'small'} style={{ margin: 8, cursor: 'default', opacity: 0 }}>
                <ArrowDownwardIcon size="small" style={{ height: 20, width: 20 }} />
            </IconButton>
        )

        return (
            <IconButton size={'small'} onClick={handleOnClickIcon} style={{ margin: 8 }}>
                {
                    direction === 'up' ?
                        <ArrowUpwardIcon size="small" style={{ height: 20, width: 20 }} /> :
                        <ArrowDownwardIcon size="small" style={{ height: 20, width: 20 }} />
                }
            </IconButton>
        )
    }

    const handleMouseEnter = () => {
        if (!sortable) return
        setIsHover(true)
    };

    const handleMouseLeave = () => {
        if (!sortable) return
        setIsHover(false)
    };

    return (
        <Column
            ref={ref}
            {...props}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {children}{renderSortingIcon()}
        </Column>
    )
})

export default ColumnItem