import { createContext, useState } from 'react';

//create a context, with createContext api
export const snackbarContext = createContext();

const SnackbarProvider = (props) => {
    // this state will be shared with all components 
    const [snackbar, setSnackbar] = useState();

    return (
        // this is the provider providing state
        <snackbarContext.Provider value={[snackbar, setSnackbar]}>
            {props.children}
        </snackbarContext.Provider>
    );
};

export default SnackbarProvider;