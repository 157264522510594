import { useContext, forwardRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DatePicker from './DatePicker';
import TimePicker from './TimePicker'
import Select from './Select';
import Typography from './Typography'
import constants from '../../constants';
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import Button from './Button'
import { Controller } from "react-hook-form";

const Content = styled(Grid)`
    && {
        > *:not(:last-child) {
            margin-bottom: 20px;
        }
    }
`

export default function PublishPeriodDialog({ open, handleClose, onChange, onSubmit, control }) {

    const onSave = () => {
        onSubmit && onSubmit()
        handleClose()
    }

    const currentIsoDate = new Date().toISOString();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
        >

            <DialogContent>
                <DialogTitle id="alert-dialog-title" style={{ padding: `16px 0px`, position: 'relative' }}>
                    <Typography variant="header">Publish Period</Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            color: '#000',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Content container direction="column" columns={1}>
                    <Grid item xs container direction='row' alignItems='center'>
                        <Controller
                            name={`publishStartDate`}
                            control={control}
                            defaultValue={currentIsoDate}
                            render={({ field }) => {
                                return (
                                    <>
                                        <Grid item xs>
                                            <DatePicker
                                                title="Start Date"
                                                fullWidth
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                        </Grid>
                                        <Grid item style={{ width: 20, height: 1 }} />
                                        <Grid item xs>
                                            <TimePicker
                                                title="Start Time"
                                                fullWidth
                                                ampm={false}
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                        </Grid>
                                    </>
                                )

                            }}
                        />
                    </Grid>
                    <Grid item xs container direction='row' alignItems='center'>
                        <Controller
                            name={`publishEndDate`}
                            control={control}
                            render={({ field }) => {
                                return (
                                    <>
                                        <Grid item xs>
                                            <DatePicker
                                                title="End Date"
                                                fullWidth
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                        </Grid>
                                        <Grid item style={{ width: 20, height: 1 }} />
                                        <Grid item xs>
                                            <TimePicker
                                                title="End Time"
                                                fullWidth
                                                ampm={false}
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                        </Grid>
                                    </>
                                )

                            }}
                        />
                    </Grid>

                </Content>
            </DialogContent>
            <DialogActions style={{ marginRight: 14, marginBottom: 20 }}>
                <Button onClick={onSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}