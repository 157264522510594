import React, { createContext, useContext, useState } from "react";

export const ContextMenuContext = createContext();

export const ContextMenuProvider = (props) => {
    const [disableMenu, setDisableMenu] = useState(false);

    const toggleMenu = (status) => {
        setDisableMenu(!status);
    }

    return (
        <ContextMenuContext.Provider value={{disableMenu, setDisableMenu, toggleMenu}}>
            <div onContextMenu={(event) => disableMenu && event.preventDefault()}>
                {props.children}
            </div>
        </ContextMenuContext.Provider>
    );
}

const useContextMenu = () => {
    return useContext(ContextMenuContext);
}

export default useContextMenu;