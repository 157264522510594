import { useState, useContext, useCallback, useMemo, useEffect, useLayoutEffect, useRef } from 'react';
import Image, { ImgContainer } from '../share/ImgGrid/ImageV2';
import Button from '../share/Button'
import styled from 'styled-components';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SelectImageDialog from '../share/SelectImageDialog';

const Container = styled.div`
    position: relative;
    height: 209px;
    width: 100%;
`

const EditButton = styled(Button)`
    && {
        position: absolute;
        top: 12px;
        right: 12px;
        z-index: 10;
    }
`


function ImageUpload({ value, onChange, readOnly, images }) {
    return (
        <Container>
            <SelectImageDialog
                images={images}
                onChange={onChange}
                selectedImage={value}
                renderComponent={({ openDialog }) => (
                    <>
                        {value || readOnly ?
                            <>
                                <Image
                                    url={value?.previewUrl}
                                    height={209}
                                    width={'100%'}
                                    hideRemove
                                    onClick={() => value?.previewUrl && window.open(value.previewUrl)}
                                />
                                {!readOnly &&
                                    <EditButton
                                        variant="outlined"
                                        onClick={openDialog}
                                    >
                                        Edit Image
                                    </EditButton>}
                            </> :
                            <ImgContainer
                                height={209}
                                width={'100%'}
                                onClick={() => {
                                    openDialog();
                                    // onClick && onClick()
                                }}
                                style={{ flexDirection: 'column', flexWrap: 'nowrap', cursor: 'pointer' }}
                            >
                                <AddIcon />
                                <Typography fontSize={12}>Upload Image</Typography>
                            </ImgContainer>
                        }
                    </>
                )}
            />
        </Container>
    )
}

export default ImageUpload