import { Controller } from "react-hook-form";
import TextField from '../../components/share/TextField';
import Image from '../../components/share/ImgGrid/ImageV2';
import Typography from '../../components/share/Typography';
import InputAdornment from '@mui/material/InputAdornment'
import { ReactComponent as UploadIcon } from '../../assets/upload-background.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import Button from '../../components/share/Button'
import DynamicForm from '../../components/share/form/DynamicForm';
import Counter from '../../components/share/Counter'
import Grid from '@mui/material/Grid';
import ComponentContainer from '../../components/share/ComponentContainer'
import Select from '../../components/share/Select'
import { DefFrontCardFields, DefPlanDetailFields, DefContractPeriodDetailFields, } from './add'
import AutoCompleteWithList from "../../components/share/AutocompleteWithList";
import HtmlEditor from '../../components/share/HtmlEditor';
import RecommendProduct from "../../components/RecommendProduct";
import UploadComponent from "../../components/share/UploadComponent";
import SelectImageDialog from '../../components/share/SelectImageDialog'
import VasAutoCompleteWithListAndOptionCheckbox from "../../components/VasAutoCompleteWithListAndCheckbox";
import { Checkbox, RadioGroup } from "../../components/share";

const CardFrontFields = [
    {
        id: 'header',
        xs: 2,
        renderComponent: ({ control, error, position, name }) => (
            <Typography variant="header1" sx={{ marginBottom: 1.5 }}>Field {position + 1} - image with Description</Typography>
        )
    },
    {
        id: 'imageWithDesc',
        xs: 1.5,
        renderComponent: ({ control, error, position, name, readOnly }) => (
            <Grid container direction='row'>
                <Grid item xs>
                    <Controller
                        name={`${name}.${position}.image`}
                        control={control}
                        render={({ field }) => {
                            if (!!field.value || readOnly) {
                                return (
                                    <ComponentContainer direction="vertical" error={error?.image} helperText={error?.image?.message}>
                                        <Image
                                            url={field?.value?.previewUrl}
                                            height={120}
                                            width={120}
                                            onChange={field.onChange}
                                            readOnly={readOnly}
                                        />
                                    </ComponentContainer>
                                )
                            }
                            return (
                                <ComponentContainer direction="vertical" error={error?.image} helperText={error?.image?.message}>
                                    <SelectImageDialog
                                        onChange={field.onChange}
                                        renderComponent={({ openDialog }) => (
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={openDialog}
                                            >
                                                + Add Image
                                            </Button>
                                        )}
                                    />
                                </ComponentContainer>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs container direction='column'>
                    <Grid item style={{ marginBottom: 10 }}>
                        <Controller
                            name={`${name}.${position}.description.en`}
                            control={control}
                            render={({ field }) => {
                                return (
                                    <TextField
                                        {...field}
                                        title="Description (English)"
                                        direction="vertical"
                                        fullWidth
                                        readOnly={readOnly}
                                        error={error?.description?.en}
                                        helperText={error?.description?.en?.message}
                                    />
                                )
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Controller
                            name={`${name}.${position}.description.zh`}
                            control={control}
                            render={({ field }) => {
                                return (
                                    <TextField
                                        {...field}
                                        title="Description (Chinese)"
                                        direction="vertical"
                                        fullWidth
                                        readOnly={readOnly}
                                        error={error?.description?.zh}
                                        helperText={error?.description?.zh?.message}
                                    />
                                )
                            }}
                        />
                    </Grid>
                    <Grid item sx={{ display: 'none' }}>
                        <Controller
                            name={`${name}.${position}.sort`}
                            control={control}
                            defaultValue={position + 1}
                            render={({ field }) => {
                                return (
                                    <div>{field.value}</div>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    }
]

const CardBackFields = [
    {
        id: 'header',
        xs: 2,
        renderComponent: ({ control, error, position, name }) => (
            <Typography variant="header1" sx={{ marginBottom: 1.5 }}>Field {position + 4} - image with Description</Typography>
        )
    },
    {
        id: 'imageWithDesc',
        xs: 1.5,
        renderComponent: ({ control, error, position, name, readOnly, remove, canDelete }) => (
            <Grid container direction='row'>
                <Grid item xs>
                    <Controller
                        name={`${name}.${position}.image`}
                        control={control}
                        render={({ field }) => {
                            if (!!field.value || readOnly) {
                                return (
                                    <ComponentContainer direction="vertical" error={error?.image} helperText={error?.image?.message}>
                                        <Image
                                            url={field?.value?.previewUrl}
                                            height={120}
                                            width={120}
                                            onChange={field.onChange}
                                            readOnly={readOnly}
                                        />
                                    </ComponentContainer>
                                )
                            }
                            return (
                                <ComponentContainer direction="vertical" error={error?.image} helperText={error?.image?.message}>
                                    <SelectImageDialog
                                        onChange={field.onChange}
                                        renderComponent={({ openDialog }) => (
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={openDialog}
                                            >
                                                + Add Image
                                            </Button>
                                        )}
                                    />
                                </ComponentContainer>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs container direction='column'>
                    <Grid item style={{ marginBottom: 10 }}>
                        <Controller
                            name={`${name}.${position}.description.en`}
                            control={control}
                            render={({ field }) => {
                                return (
                                    <TextField
                                        {...field}
                                        title="Description (English)"
                                        direction="vertical"
                                        fullWidth
                                        readOnly={readOnly}
                                        error={error?.description?.en}
                                        helperText={error?.description?.en?.message}
                                    />
                                )
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Controller
                            name={`${name}.${position}.description.zh`}
                            control={control}
                            render={({ field }) => {
                                return (
                                    <TextField
                                        {...field}
                                        title="Description (Chinese)"
                                        direction="vertical"
                                        fullWidth
                                        readOnly={readOnly}
                                        error={error?.description?.zh}
                                        helperText={error?.description?.zh?.message}
                                    />
                                )
                            }}
                        />
                    </Grid>
                    <Grid item sx={{ display: 'none' }}>
                        <Controller
                            name={`${name}.${position}.sort`}
                            control={control}
                            defaultValue={position + 1}
                            render={({ field }) => {
                                return (
                                    <div>{field.value}</div>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
                {canDelete &&
                    <Grid item>
                        <DeleteIcon style={{ cursor: 'pointer', marginTop: 40, marginLeft: 40 }} onClick={() => remove(position)} />
                    </Grid>}
            </Grid>
        )
    }
]

const Step1Fields = ({ control, errors = {}, name, readOnly, companies }) => [
    {
        id: 'header',
        component: (
            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Fields marked * are required</Typography>
        )
    }, { id: "spacing" },
    {
        id: "company",
        component: (
            <Controller
                name={`${name}.company`}
                control={control}
                render={({ field }) => (
                    <Select
                        title="Select Company"
                        direction="vertical"
                        fullWidth
                        textFieldProps={{
                            placeholder: "Select..."
                        }}
                        options={companies}
                        value={field.value}
                        onChange={(value) => {
                            field.onChange(value)
                        }}
                        disabled={readOnly}
                        error={errors?.company}
                        helperText={errors?.company?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "promotionTitle_en",
        component: (
            <Controller
                name={`${name}.promotionTitle.en`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Promotion Title (English)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.promotionTitle?.en}
                        helperText={errors?.promotionTitle?.en?.message}
                    />
                )}
            />
        )
    },
    {
        id: "promotionTitle_zh",
        component: (
            <Controller
                name={`${name}.promotionTitle.zh`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Promotion Title (Chinese)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.promotionTitle?.zh}
                        helperText={errors?.promotionTitle?.zh?.message}
                    />
                )}
            />
        )
    },
    {
        id: "flipCardLabel_en",
        component: (
            <Controller
                name={`${name}.flipCardLabel.en`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Card Label (English)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.flipCardLabel?.en}
                        helperText={errors?.flipCardLabel?.en?.message}
                    />
                )}
            />
        )
    },
    {
        id: "flipCardLabel_zh",
        component: (
            <Controller
                name={`${name}.flipCardLabel.zh`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Card Label (Chinese)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors.flipCardLabel?.zh}
                        helperText={errors?.flipCardLabel?.zh?.message}
                    />
                )}
            />
        )
    },
    {
        id: "cardIcon",
        component: (
            <Controller
                name={`${name}.flipCardIconImage`}
                control={control}
                render={({ field }) => {
                    if (!!field.value || readOnly) {
                        return (
                            <Image
                                title="Card Icon*"
                                url={field?.value?.previewUrl}
                                height={120}
                                width={120}
                                onChange={field.onChange}
                                readOnly={readOnly}
                            />
                        )
                    }

                    return (
                        <ComponentContainer direction="vertical" title="Card Icon*" error={errors.flipCardIconImage} helperText={errors?.flipCardIconImage?.message}>
                            <SelectImageDialog
                                onChange={field.onChange}
                                renderComponent={({ openDialog }) => (
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={openDialog}
                                    >
                                        + Add Image
                                    </Button>
                                )}
                            />
                        </ComponentContainer>
                    )
                }}
            />
        )
    },
    {
        id: 'spacing'
    },
    {
        id: "planName_en",
        component: (
            <Controller
                name={`${name}.planName.en`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Plan Name (English)*"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.planName?.en}
                        helperText={errors.planName?.en?.message}
                    />
                )}
            />
        )
    },
    {
        id: "planName_zh",
        component: (
            <Controller
                name={`${name}.planName.zh`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Plan Name (Chinese)*"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.planName?.zh}
                        helperText={errors?.planName?.zh?.message}
                    />
                )}
            />
        )
    },
    {
        id: "localData_en",
        component: (
            <Controller
                name={`${name}.localData.en`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Local Data (English)*"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.localData?.en}
                        helperText={errors?.localData?.en?.message}
                    />
                )}
            />
        )
    },
    {
        id: "localData_zh",
        component: (
            <Controller
                name={`${name}.localData.zh`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Local Data (Chinese)*"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.localData?.zh}
                        helperText={errors?.localData?.zh?.message}
                    />
                )}
            />
        )
    },
    // {
    //     id: "localVoiceMins_en",
    //     component: (
    //         <Controller
    //             name={`${name}.localVoiceMins.en`}
    //             control={control}
    //             render={({ field }) => (
    //                 <TextField
    //                     {...field}
    //                     title="Local Voice Mins (English)*"
    //                     direction="vertical"
    //                     fullWidth
    //                     readOnly={readOnly}
    //                     error={errors?.localVoiceMins?.en}
    //                     helperText={errors?.localVoiceMins?.en?.message}
    //                 />
    //             )}
    //         />
    //     )
    // },
    // {
    //     id: "localVoiceMins_en",
    //     component: (
    //         <Controller
    //             name={`${name}.localVoiceMins.zh`}
    //             control={control}
    //             render={({ field }) => (
    //                 <TextField
    //                     {...field}
    //                     title="Local Voice Mins (Chinese)*"
    //                     direction="vertical"
    //                     fullWidth
    //                     readOnly={readOnly}
    //                     error={errors?.localVoiceMins?.zh}
    //                     helperText={errors?.localVoiceMins?.zh?.message}
    //                 />
    //             )}
    //         />
    //     )
    // },
    {
        id: "originalPrice",
        component: (
            <Controller
                name={`${name}.originalPrice`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Original Price"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors.originalPrice}
                        helperText={errors.originalPrice?.message}
                        InputProps={{
                            startAdornment: <InputAdornment style={{ marginTop: 0 }} position="start">HKD</InputAdornment>,
                        }}
                    />
                )}
            />
        )
    }, { id: 'spacing' },
    {
        id: "discountedPrice",
        component: (
            <Controller
                name={`${name}.discountedPrice`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Discounted Price"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors.discountedPrice}
                        helperText={errors.discountedPrice?.message}
                        InputProps={{
                            startAdornment: <InputAdornment style={{ marginTop: 0 }} position="start">HKD</InputAdornment>,
                        }}
                    />
                )}
            />
        )
    }, { id: 'spacing' },
    {
        id: "planId",
        component: (
            <Controller
                name={`${name}.planId`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Plan ID"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors.planId}
                        helperText={errors.planId?.message}
                    />
                )}
            />
        )
    }, { id: 'spacing' },
    {
        id: 'header',
        component: (
            <Typography variant="header">Show on Filp card (Front):</Typography>
        )
    }, { id: "spacing" },
    {
        id: 'dynamicList',
        component: (
            <DynamicForm
                control={control}
                errors={errors?.["cardFront"]}
                name={`${name}.cardFront`}
                fieldComponents={CardFrontFields}
                style={{ padding: 0 }}
                readOnly={readOnly}
            />
        )
    },
    {
        id: 'header',
        component: (
            <>
                <Typography variant="header">{`Show on Filp card (back):`}</Typography>
                <Typography variant="header">{`Maximum with 8 fields`}</Typography>
            </>
        )
    }, { id: "spacing" },
    {
        id: "dynamicList",
        component: (
            <DynamicForm
                control={control}
                errors={errors?.["cardBack"]}
                name={`${name}.cardBack`}
                addButtonLabel="+ Add More Field"
                fieldComponents={CardBackFields}
                appendData={DefFrontCardFields}
                style={{ padding: 0 }}
                readOnly={readOnly}
                max={8}
            />
        )
    },
]

const PlanDetailFields = [
    {
        id: 'header',
        xs: 2,
        renderComponent: ({ control, error, position, name }) => (
            <Typography variant="header1" sx={{ marginBottom: 1.5 }}>Field {position + 4} - image with Description</Typography>
        )
    },
    {
        id: 'imageWithDesc',
        xs: 1.5,
        renderComponent: ({ control, error, position, name, readOnly, remove, canDelete }) => (
            <Grid container direction='row'>
                <Grid item xs={4}>
                    <Controller
                        name={`${name}.${position}.image`}
                        control={control}
                        render={({ field }) => {
                            if (!!field.value || readOnly) {
                                return (
                                    <ComponentContainer direction="vertical" title="Card Icon*" error={error?.image} helperText={error?.image?.message}>
                                        <Image
                                            url={field?.value?.previewUrl}
                                            height={120}
                                            width={120}
                                            onChange={field.onChange}
                                            readOnly={readOnly}
                                        />
                                    </ComponentContainer>
                                )
                            }
                            return (
                                <ComponentContainer direction="vertical" title="Card Icon*" error={error?.image} helperText={error?.image?.message}>
                                    <SelectImageDialog
                                        onChange={field.onChange}
                                        renderComponent={({ openDialog }) => (
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={openDialog}
                                            >
                                                + Add Image
                                            </Button>
                                        )}
                                    />
                                </ComponentContainer>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs container direction='column'>
                    <Grid item xs container direction='row' style={{ marginBottom: 10 }} spacing={2}>
                        <Grid item xs>
                            <Controller
                                name={`${name}.${position}.title.en`}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            title="Title (English)"
                                            direction="vertical"
                                            fullWidth
                                            readOnly={readOnly}
                                            error={error?.title?.en}
                                            helperText={error?.title?.en?.message}
                                        />
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs>
                            <Controller
                                name={`${name}.${position}.title.zh`}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            title="Title (Chinese)"
                                            direction="vertical"
                                            fullWidth
                                            readOnly={readOnly}
                                            error={error?.title?.zh}
                                            helperText={error?.title?.zh?.message}
                                        />
                                    )
                                }}
                            />
                        </Grid>
                        {canDelete &&
                            <Grid item>
                                <DeleteIcon style={{ cursor: 'pointer', marginTop: 40, marginLeft: 40 }} onClick={() => remove(position)} />
                            </Grid>}
                    </Grid>
                    <Grid item xs container direction='row' style={{ marginBottom: 10 }} spacing={2}>
                        <Grid item xs>
                            <Controller
                                name={`${name}.${position}.description.en`}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            title="Description (English)"
                                            direction="vertical"
                                            fullWidth
                                            readOnly={readOnly}
                                            error={error?.description?.en}
                                            helperText={error?.description?.en?.message}
                                        />
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs>
                            <Controller
                                name={`${name}.${position}.description.zh`}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            title="Description (Chinese)"
                                            direction="vertical"
                                            fullWidth
                                            readOnly={readOnly}
                                            error={error?.description?.zh}
                                            helperText={error?.description?.zh?.message}
                                        />
                                    )
                                }}
                            />
                        </Grid>
                        {canDelete &&
                            <Grid item>
                                <DeleteIcon style={{ opacity: 0, marginTop: 40, marginLeft: 40 }} />
                            </Grid>}
                    </Grid>
                    <Grid item xs container direction='row' style={{ marginBottom: 10 }} spacing={2}>
                        <Grid item xs>
                            <Controller
                                name={`${name}.${position}.remark.en`}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            title="Remark (English)"
                                            direction="vertical"
                                            fullWidth
                                            multiline
                                            readOnly={readOnly}
                                            error={error?.remark?.en}
                                            helperText={error?.remark?.en?.message}
                                        />
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs>
                            <Controller
                                name={`${name}.${position}.remark.zh`}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            title="Remark (Chinese)"
                                            direction="vertical"
                                            fullWidth
                                            multiline
                                            readOnly={readOnly}
                                            error={error?.remark?.zh}
                                            helperText={error?.remark?.zh?.message}
                                        />
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item sx={{ display: 'none' }}>
                            <Controller
                                name={`${name}.${position}.sort`}
                                control={control}
                                defaultValue={position + 1}
                                render={({ field }) => {
                                    return (
                                        <div>{field.value}</div>
                                    )
                                }}
                            />
                        </Grid>
                        {canDelete &&
                            <Grid item>
                                <DeleteIcon style={{ opacity: 0, marginTop: 40, marginLeft: 40 }} />
                            </Grid>}
                    </Grid>
                </Grid>
            </Grid>
        )
    },
    {
        id: "custom",
        xs: 2,
        renderComponent: () => {
            return (
                <div style={{ height: 40, width: 1 }} />
            )
        }
    }
]

const ContractPeriodDetails = [
    {
        id: 'custom',
        xs: 2,
        renderComponent: ({ position, remove, canDelete }) => {
            if (!canDelete) return null
            return (
                <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                    <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => remove(position)} />
                </div>

            )
        }
    },
    {
        id: 'promotionTitle_en',
        renderComponent: ({ control, error, position, name, readOnly }) => (
            <Controller
                name={`${name}.${position}.promotionTitle.en`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Promotion Title (English)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={error?.promotionTitle?.en}
                        helperText={error?.promotionTitle?.en?.message}
                    />
                )}
            />
        )
    },
    {
        id: 'promotionTitle_zh',
        renderComponent: ({ control, error, position, name, readOnly }) => (
            <Controller
                name={`${name}.${position}.promotionTitle.zh`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Promotion Title (Chinese)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={error?.promotionTitle?.zh}
                        helperText={error?.promotionTitle?.en?.message}
                    />
                )}
            />
        )
    },
    {
        id: 'contactPeriod',
        name: "period",
        renderComponent: ({ control, error, position, name, readOnly }) => {
            console.log("error-peeriod", error, name, position)
            return (
                (
                    <Controller
                        name={`${name}.${position}.period`}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                title="Contact Period"
                                direction="vertical"
                                fullWidth
                                readOnly={readOnly}
                                error={error?.period}
                                helperText={error?.period?.message}
                            />
                        )}
                    />
                )
            )
        }
    },
    {
        id: 'ratePlanCoasId',
        name: "ratePlanCoasId",
        renderComponent: ({ control, error, position, name, readOnly }) => {
            return (
                (
                    <Controller
                        name={`${name}.${position}.coasId`}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                title="Rate Plan COAS ID"
                                direction="vertical"
                                fullWidth
                                readOnly={readOnly}
                                error={error?.period}
                                helperText={error?.period?.message}
                            />
                        )}
                    />
                )
            )
        }
    },
    {
        id: 'contractDiscountedPrice',
        renderComponent: ({ control, error, position, name, readOnly }) => (
            <Controller
                name={`${name}.${position}.discountedPrice`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Contract Discounted Price"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={error?.discountedPrice}
                        helperText={error?.discountedPrice?.message}
                        InputProps={{
                            startAdornment: <InputAdornment style={{ marginTop: 0 }} position="start">HKD</InputAdornment>,
                        }}
                    />
                )}
            />
        )
    }, { id: 'spacing' },
    {
        id: 'contactOriginalPrice',
        renderComponent: ({ control, error, position, name, readOnly }) => (
            <Controller
                name={`${name}.${position}.originalPrice`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Contact Original Price"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={error?.originalPrice}
                        helperText={error?.originalPrice?.message}
                        InputProps={{
                            startAdornment: <InputAdornment style={{ marginTop: 0 }} position="start">HKD</InputAdornment>,
                        }}
                    />
                )}
            />
        )
    }, { id: 'spacing' },
    {
        id: "custom",
        xs: 2,
        renderComponent: () => {
            return (
                <div style={{ height: 40, width: 1 }} />
            )
        }
    }
]

const Step2Fields = ({ name, control, errors = {}, readOnly }) => [
    {
        id: 'header',
        component: (
            <Typography variant="header3">Fields marked * are required</Typography>
        )
    }, { id: "spacing" },
    {
        id: "promotion_en",
        component: (
            <>
                <Typography variant="header" bold>Show on plan Details:</Typography>
                <Typography variant="header" bold>Maximum With 3 Fields</Typography>
            </>
        )
    }, { id: "spacing" },
    {
        id: 'dynamicList',
        component: (
            <DynamicForm
                control={control}
                errors={errors?.["planDetailsInclude"]}
                name={`${name}.planDetailsInclude`}
                fieldComponents={PlanDetailFields}
                style={{ padding: 0 }}
                addButtonLabel="Add More Field"
                readOnly={readOnly}
            />
        )
    },
    {
        id: 'hr'
    },
    {
        id: 'header2',
        component: (
            <Typography variant="header">Annual Renewal</Typography>
        )
    }, { id: "spacing" },
    {
        id: "default-annual-renewal",
        component: (
            <Controller
                name={`${name}.planAutoRenewalOption`}
                control={control}
                render={({ field }) => (
                    <RadioGroup
                        title={"Default Annual Renewal*"}
                        direction="vertical"
                        options={[
                            {
                                label: 'Disable',
                                value: "disable"
                            },
                            {
                                label: 'Enable',
                                value: "enable"
                            },
                        ]}
                        row
                        disabled={readOnly}
                        {...field}
                        error={errors?.planAutoRenewalOption}
                        helperText={errors?.planAutoRenewalOption?.message}
                    />
                )}
            />
        )
    },
    {
        id: 'hr'
    },
    {
        id: "desc",
        component: (
            <Typography variant="header" bold>Contract Period Details</Typography>
        )
    }, { id: "spacing" },
    {
        id: 'dynamicList',
        component: (
            <DynamicForm
                control={control}
                name={`${name}.contractPeriodDetails`}
                errors={errors?.["contractPeriodDetails"]}
                addButtonLabel="+ Add More Field"
                fieldComponents={ContractPeriodDetails}
                appendData={DefContractPeriodDetailFields}
                style={{ padding: 0 }}
                readOnly={readOnly}
            />
        )
    },
    {
        id: 'hr'
    },
    {
        id: "header",
        component: (
            <Typography variant="header">Quantity</Typography>
        )
    }, { id: "spacing" },
    // {
    //     id: "quantityDescription_en",
    //     component: (
    //         <Controller
    //             name={`${name}.quantityDescription.en`}
    //             control={control}
    //             render={({ field }) => (
    //                 <TextField
    //                     {...field}
    //                     title="Description (English)"
    //                     direction="vertical"
    //                     fullWidth
    //                     readOnly={readOnly}
    //                     error={errors?.quantityDescription?.en}
    //                     helperText={errors?.quantityDescription?.en?.message}
    //                 />
    //             )}
    //         />
    //     )
    // },
    // {
    //     id: "quantityDescription_zh",
    //     component: (
    //         <Controller
    //             name={`${name}.quantityDescription.zh`}
    //             control={control}
    //             render={({ field }) => (
    //                 <TextField
    //                     {...field}
    //                     title="Description (Chinese)"
    //                     direction="vertical"
    //                     fullWidth
    //                     readOnly={readOnly}
    //                     error={errors?.quantityDescription?.zh}
    //                     helperText={errors?.quantityDescription?.zh?.message}
    //                 />
    //             )}
    //         />
    //     )
    // },
    {
        id: 'quantity_min_and_max',
        component: (
            <Grid container direction='row' alignItems='center' justifyContent='center'>
                <Grid item xs>
                    <Controller
                        name={`${name}.quantityMin`}
                        control={control}
                        render={({ field }) => (
                            <Counter
                                title="Minimum No.*"
                                {...field}
                                disabled={readOnly}
                                error={errors?.quantityMin}
                                helperText={errors?.quantityMin?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item style={{ margin: `20px 20px 0px 20px` }}><Typography>To</Typography></Grid>
                <Grid item xs>
                    <Controller
                        name={`${name}.quantityMax`}
                        control={control}
                        render={({ field }) => (
                            <Counter
                                title="Maximum No.*"
                                {...field}
                                disabled={readOnly}
                                error={errors?.quantityMax}
                                helperText={errors?.quantityMax?.message}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        )
    }
]

const TncFields = ({ control, errors = {}, readOnly, name, tncList }) => [
    {
        id: 'header',
        component: (
            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Fields marked * are required</Typography>
        )
    }, { id: "spacing" },
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Overall T&C Agreement</Typography>
        )
    }, { id: "spacing" },
    {
        id: 'tnc-overall',
        component: (
            <Controller
                name={`${name}.overallTncs`}
                control={control}
                render={({ field }) => (
                    <AutoCompleteWithList
                        title="Select T&C*"
                        direction="vertical"
                        fullWidth
                        multiple
                        api={"/tnc"}
                        apiParams={{ category: "MOBILE_PLAN" }}
                        getOptionLabel={(option) => `${option?.title?.en || ""} ${option?.title?.zh || ""}`}
                        {...field}
                        error={errors?.overallTncs}
                        helperText={errors?.overallTncs?.message}
                        readOnly={readOnly}
                        disabled={readOnly}
                        childrenContainerStyle={{
                            width: 'inherit'
                        }}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "hr"
    },
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Detail Page T&C</Typography>
        )
    }, { id: "spacing" },
    {
        id: 'tnc-detail-page',
        component: (
            <Controller
                name={`${name}.detailPageTncs`}
                control={control}
                render={({ field }) => (
                    <AutoCompleteWithList
                        title="Select T&C*"
                        direction="vertical"
                        fullWidth
                        multiple
                        options={tncList}
                        getOptionLabel={(option) => `${option?.title?.en || ""} ${option?.title?.zh || ""}`}
                        {...field}
                        readOnly={readOnly}
                        disabled={readOnly}
                        error={errors?.detailPageTncs}
                        helperText={errors?.detailPageTncs?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "hr"
    },
    {
        id: "remark_en",
        xs: 2,
        component: (
            <Controller
                name={`${name}.remark.en`}
                control={control}
                render={({ field }) => (
                    <HtmlEditor
                        title="Remark(English)*"
                        value={field.value}
                        onChange={field.onChange}
                        height={300}
                        readOnly={readOnly}
                        error={errors.remark?.en}
                        helperText={errors?.remark?.en?.message}
                    />
                )}
            />
        )
    },
    {
        id: "hr"
    },
    {
        id: "remark_zh",
        xs: 2,
        component: (
            <Controller
                name={`${name}.remark.zh`}
                control={control}
                render={({ field }) => (
                    <HtmlEditor
                        title="Remark(Chinese)*"
                        value={field.value}
                        onChange={field.onChange}
                        height={300}
                        readOnly={readOnly}
                        error={errors.remark?.zh}
                        helperText={errors?.remark?.zh?.message}
                    />
                )}
            />
        )
    },
]

const VasFields = ({ control, errors = {}, readOnly, name, vasList }) => [
    {
        id: 'header',
        component: (
            <div style={{ backgroundColor: "#fff6c6", borderRadius: 5, padding: "15px", marginBottom: "15px"}}>
                <Typography color={"#333333"} fontSize={14} bold>
                    {"Click the VAS item checkbox to mark it as a Recommended item."}
                </Typography>
            </div>
        )
    }, { id: "spacing" },
    {
        id: 'header',
        component: (
            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Fields Marked * Are Required</Typography>
        )
    }, { id: "spacing" },
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Free-VAS Service</Typography>
        )
    }, { id: "spacing" },
    {
        id: 'free-vas',
        component: (
            <Controller
                name={`${name}.freeVas`}
                control={control}
                render={({ field }) => {
                    return (
                        <VasAutoCompleteWithListAndOptionCheckbox
                            title="Add VAS"
                            direction="vertical"
                            fullWidth
                            multiple
                            options={vasList}
                            getOptionLabel={(option) => `${option?.name?.en || ""} ${option?.name?.zh || ""}`}
                            readOnly={readOnly}
                            {...field}
                            error={errors.freeVas}
                            helperText={errors.freeVas?.message}
                            onPickSelectedItem={(item, checked) => {
                                const updatedValue = field.value.map((vas) => {
                                    if (vas._id === item._id) {
                                        return {
                                            ...vas,
                                            isRecommended: checked
                                        }
                                    }
                                    return vas
                                })
                                field.onChange(updatedValue, { shouldDirty: true })
                            }}
                            onSortSelectedItem={(items) => {
                                field.onChange(items, { shouldDirty: true })
                            }}
                        />
                    )
                }}
            />
        )
    }, { id: "spacing" },
    {
        id: "vas-quantity",
        component: (
            <Controller
                name={`${name}.freeVasMax`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Chosen Quantity On Free-VAS"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors.freeVasMax}
                        helperText={errors.freeVasMax?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "hr"
    },
    // {
    //     id: 'header',
    //     component: (
    //         <Typography variant="header" sx={{ marginBottom: 1.5 }}>Bundle-VAS Service</Typography>
    //     )
    // }, { id: "spacing" },
    // {
    //     id: 'bundle-free-vas',
    //     component: (
    //         <Controller
    //             name={`${name}.bundleFreeVas`}
    //             control={control}
    //             render={({ field }) => (
    //                 <AutoCompleteWithList
    //                     title="Add VAS*"
    //                     direction="vertical"
    //                     fullWidth
    //                     api="/vas"
    //                     getOptionLabel={(option) => `${option?.name?.en || ""} ${option?.name?.zh || ""}`}
    //                     readOnly={readOnly}
    //                     {...field}
    //                     error={errors.bundleFreeVas}
    //                     helperText={errors.bundleFreeVas?.message}
    //                 />
    //             )}
    //         />
    //     )
    // }, { id: "spacing" },
    // {
    //     id: "hr"
    // },
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Paid-VAS Service</Typography>
        )
    }, { id: "spacing" },
    {
        id: 'paid-vas',
        component: (
            <Controller
                name={`${name}.paidVas`}
                control={control}
                render={({ field }) => (
                    <VasAutoCompleteWithListAndOptionCheckbox
                        title="Add VAS"
                        direction="vertical"
                        fullWidth
                        multiple
                        options={vasList}
                        getOptionLabel={(option) => `${option?.name?.en || ""} ${option?.name?.zh || ""}`}
                        readOnly={readOnly}
                        {...field}
                        error={errors.paidVas}
                        helperText={errors.paidVas?.message}
                        onPickSelectedItem={(item, checked) => {
                            const updatedValue = field.value.map((vas) => {
                                if (vas._id === item._id) {
                                    return {
                                        ...vas,
                                        isRecommended: checked
                                    }
                                }
                                return vas
                            })
                            field.onChange(updatedValue, { shouldDirty: true })
                        }}
                        onSortSelectedItem={(items) => {
                            field.onChange(items, { shouldDirty: true })
                        }}
                    />
                )}
            />
        )
    }, { id: "spacing" },
]

const RecommendProductFields = [
    {
        id: 'select-product',
        renderComponent: ({ control, error, position, name, readOnly }) => (
            <Controller
                name={`${name}.${position}`}
                control={control}
                render={({ field }) => {
                    return (
                        <RecommendProduct
                            value={field.value}
                            readOnly={readOnly}
                            error={error}
                            position={position}
                            onChange={field.onChange}
                        />
                    )
                }}
            />
        )
    }, { id: "spacing" },
]

const RecommendFields = ({ control, errors = {}, readOnly, name }) => [
    {
        id: 'header',
        component: (
            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Suggested to choose 3 Products</Typography>
        )
    }, { id: "spacing" },
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Recommend-Product</Typography>
        )
    }, { id: "spacing" },
    {
        id: 'dynamicList',
        component: (
            <DynamicForm
                control={control}
                errors={errors?.["recommend"]}
                name={`${name}.recommend`}
                fieldComponents={RecommendProductFields}
                style={{ padding: 0 }}
                readOnly={readOnly}
            />
        )
    }
]

export {
    Step1Fields,
    Step2Fields,
    ContractPeriodDetails,
    VasFields,
    TncFields,
    RecommendFields
}