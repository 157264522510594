import * as React from 'react';
import Button from '../share/Button';
import FilterDialog from './FilterDialog';


export default function FilterButton({style, ...props}) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    return (
        <div>
            <Button
                color="green"
                onClick={handleClickOpen}
                style={style}
            >
                Filter
            </Button>
            <FilterDialog
                open={open}
                onClose={handleClose}
                {...props}
            />
        </div>
    );
}
