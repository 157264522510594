import { useState } from "react";
import styled from "styled-components";
import Typography from './Typography';
import { IconButton } from "@mui/material";
import Grid from '@mui/material/Grid';
import ComponentContainer from './ComponentContainer'
import { styled as styledMui } from '@mui/material/styles';


const Container = styledMui('div')(
    ({ theme, disabled }) => `
    && {
        height: 43px;
        background-color: ${disabled ? theme.palette.secondary.dark : theme.palette.secondary.light};
        display: flex;
        align-items: center;
    }
  `,
);

const CustomIconButton = styled(IconButton)`
    width: 30px;
    height: 30px;
`


function Counter({ value = 0, onChange, title, disabled, error, helperText }) {

    const [count, setCount] = useState(value);

    const onAdd = () => {
        setCount(count + 1)
        onChange && onChange(count + 1)
    }

    const onDeduct = () => {
        if (count > 1) {
            setCount(count - 1)
            onChange && onChange(count - 1)
        }
    }

    return (
        <ComponentContainer direction="vertical" title={title} error={error} helperText={helperText}>
            <Container disabled={disabled}>
                <CustomIconButton style={{ marginLeft: 10 }} onClick={onDeduct} disabled={disabled} ><Typography variant='secondary' bold >-</Typography></CustomIconButton>
                <Typography color={disabled ? "info.light" : "info.main"} align='center' style={{ flex: 1 }}>{count}</Typography>
                <CustomIconButton style={{ marginRight: 10 }} onClick={onAdd} disabled={disabled}><Typography variant='secondary' bold >+</Typography></CustomIconButton>
            </Container>
        </ComponentContainer>
    )
}

export default Counter