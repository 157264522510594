import { useState, useContext, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import _ from 'lodash'
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import PageWrapper from '../../components/share/PageWrapper';
import Typography from '../../components/share/Typography';
import Button from '../../components/share/Button';
import Select from '../../components/share/Select';
import GreyDivider from '../../components/GreyDivider';
import TextField from '../../components/share/TextField';
import DatePicker from '../../components/share/DatePicker';
import Form from '../../components/share/form/Form';
import Grid from '@mui/material/Grid';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import { fetchPromoCode, updatePromoCode } from '../../apis/promoCode';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import DynamicForm from '../../components/share/form/DynamicForm'
import { Fields } from './formFields'
import { companiesContext } from '../../context/CompaniesProvider';
import { tncContext } from '../../context/TncProvider';
import TableStatusFilter from '../../components/share/TableStatusFilter'
import constants from '../../constants';

const SaveButton = styled(Button)`
    && {
        align-self: end; 
        min-width: 80px;
        margin: 10px 20px;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
    overflow: auto;
`

const margin = 10;

const schema = Joi.object({
    name: Joi.object({
        en: Joi.string().empty("").required(),
        zh: Joi.string().empty("").required(),
    }),
    code: Joi.string().pattern(/^[a-zA-Z0-9]+$/).max(30).required(),  // TODO: only accept a-z, A-z, 0-9
    discountAmount: Joi.number().required().min(0),
    minSpend: Joi.number().empty('').default(0).min(0),
    status: Joi.string().required(),
    usedCount: Joi.number(),
    label: Joi.string().empty("").allow(null),
}).messages({
    "any.required": "Required",
    "object.base": "Required",
    "string.empty": "Required",
    "string.max": "Maximum 30 characters",
    "string.pattern.base": "Only accept a-z, A-z, 0-9",
    "number.base": "Must be a number",
    "number.min": "Must be greater than or equal to 0",
});

export const DefPlans = [
    {
        product: {},
        plan: {},
    }
]

function PromotionCodeDetail(props) {

    // let { categoryId } = useParams();
    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useContext(snackbarContext);

    let { promotionId } = useParams();

    const { getValues, control, handleSubmit, watch, formState: { errors, isDirty }, reset } = useForm({
        resolver: joiResolver(schema),
    });

    console.log('errors', errors)
    console.log('values', getValues())

    const { data, error, isError, isLoading, refetch } = useQuery({
        queryKey: ["bundleDetail", { promotionId }],
        queryFn: async () => await fetchPromoCode(promotionId)
    });

    useEffect(() => {
        if (!_.isEmpty(errors)) {
            setSnackbar({
                type: 'error',
                message: constants.INVALID_INPUT_ERR_MSG
            })
        }
    }, [errors])

    useEffect(() => {
        if (!isLoading && data) {
            reset({
                name: data.name,
                code: data.code,
                discountAmount: data.discountAmount,
                minSpend: data.minSpend || 0,
                status: data.status,
                usedCount: data.usedCount || 0,
                label: data.label,
            })
        }
    }, [data])

    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (payload) => updatePromoCode(promotionId, payload),
        onSuccess: (data) => {
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
            refetch();
            queryClient.invalidateQueries({ queryKey: ["/promo", { promotionId }] })
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const handleOnSubmit = (formValue) => {
        if (!isDirty) {
            return setSnackbar({
                type: 'success',
                message: 'Success'
            });
        }

        const payload = {
            ...formValue,
            minSpend: formValue.minSpend || 0,
        }
        delete payload.usedCount;
        mutation.mutate(payload);
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Promotion Code > ${data?.name?.en}`}</Typography>
            </>
        )
    }

    const CardHeader = () => {
        return (
            <Grid container direction='row' alignItems='center' justifyContent='flex-end'>
                <Controller
                    name={"status"}
                    control={control}
                    initialValue={data?.status}
                    render={({ field }) => {
                        return (
                            <TableStatusFilter 
                                width={200}
                                options={[
                                    {
                                        name: "Active",
                                        _id: "active",
                                    },
                                    {
                                        name: "Suspended",
                                        _id: "suspended",
                                    },
                                    {
                                        name: "Draft",
                                        _id: "draft",
                                        disabled: data?.status !== 'draft',
                                    },
                                ]}
                                value={data?.status}
                                {...field}
                            />
                        )
                    }}
                />
                
                {/* <SaveButton
                    onClick={() => {
                        handleSubmit(handleOnSubmitDraft)();
                    }}
                    variant='outlined'
                >
                    Save
                </SaveButton> */}

                <SaveButton
                    onClick={() => {
                        handleSubmit(handleOnSubmit)();
                    }}
                >
                    Save
                </SaveButton>
            </Grid>
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            renderCardHeader={CardHeader}
        >
            <Content>
                <Form fields={Fields({ control, errors, readOnly: data?.status === 'active', isCreate: data?.status === 'draft' })} p={`14px 0px`} />
            </Content>
        </PageWrapper>
    )
}

export default PromotionCodeDetail