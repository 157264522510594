import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: calc(-200px + 100vh);

    border: 1px solid #e0e0e0;
    border-radius: 5px;

    padding: 10px;
`;

const StyledImg = styled.img`
    max-width: 100%;
    max-height: inherit;

    ${(props) => props.preventRightClick && `-webkit-user-select: none;`}
    ${(props) => props.preventRightClick && `-moz-user-select: none;`}
    ${(props) => props.preventRightClick && `-ms-user-select: none;`}
    ${(props) => props.preventRightClick && `user-select: none;`}

    @media print {
        ${(props) => props.preventRightClick && `display: none;`}
    }
`;

const ImageViewer = (props) => {
    const { data, preventRightClick=true } = props;

    const preventDefault = (event) => {
        preventRightClick && event.preventDefault();
    }

    return (
        <Container>
            <StyledImg
                src={data}
                alt="file"
                onContextMenu={preventDefault}
                onDragStart={preventDefault}
                preventRightClick={preventRightClick}
            />
        </Container>
    )
}

export default ImageViewer;