import styled from 'styled-components';

const Card = styled.div`
    height: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 29px -12px #00000040;
    border-radius: 5px;
    margin: 14px;
    display: flex;
    flex-direction: column;
`

export default Card