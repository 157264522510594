import { Grid, Breadcrumbs, Link, Box, Tooltip } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams, Link as RouterLink } from 'react-router-dom';
import {
    Button,
    PageWrapper,
    Searchbar,
    Tab,
    Table,
    Typography,
    TableStatusFilter,
    FilterButton
} from '../../components/share';
import { alertDialogContext } from '../../context/AlertDialogProvider';
import { snackbarContext } from '../../context/SnackbarProvider';
import withTabSearchParams from '../../hoc/withTabSearchParams';
import { userContext } from '../../context/UserProvider';
import { getPermission } from '../../utils'
import { STATUS } from './index'
import SwitchButton from '../../components/share/SwitchButton';
import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchOrderDetails, lockDocumentUpload } from '../../apis/order';
import { isNull } from 'lodash';
import styled from 'styled-components';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

const margin = 10

//listTNC: sort

const LockedButton = styled.div`
    background-color: #e2e2e2;
    border-radius: 13px;
    padding: 5px 10px;
    margin-right: 10px;
    cursor: default;

    font-size: 14px;
    font-weight: 600;
    color: #9B9B9B;
`;

const TABS = [
    {
        name: "All",
        id: undefined
    },
    {
        name: "Mobile",
        id: 'plan'
    },
    {
        name: "Product",
        id: 'product'
    },
]

function OrderItemListPage({ updateTab, tabIndex, columns, permission = { canView: false, canModify: false } }) {
    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [dialog, setDialog] = useContext(alertDialogContext);

    const { canView, canModify } = permission

    let [searchParams, setSearchParams] = useSearchParams();
    // const [selectMode, setSelectMode] = useState(false);

    const orderId = searchParams.get('orderId');
    const orderNumber = searchParams.get('orderNumber');

    const [filter, setFilter] = useState({
        // status: null,
        keyword: null,
    })

    const [documentUpload, setDocumentUpload] = useState(null);

    const isDocumentLocked = documentUpload?.checked === false && documentUpload?.disabled === true;
    const isLockDocumentAllowed = !(documentUpload?.checked && documentUpload?.disabled);

    const isProductTab = tabIndex === TABS.findIndex((item) => item.id === "product")

    const { data, isLoading, isError, refetch } = useQuery({
        queryKey: ['orderDetails', orderId],
        queryFn: () => fetchOrderDetails(orderId),
        onSuccess: (data) => {
            const { havePlan, status, isCmsLockedDocuments } = data?.data;

            // hide document lock toggle when no plan item included in order
            if (!havePlan) return;

            // if already locked document
            if (isCmsLockedDocuments) {
                return setDocumentUpload({ checked: false, disabled: true })
            }

            const isDocumentUploaded = ["documentSubmitted", "verified"].includes(status);
            setDocumentUpload({
                checked: true,
                disabled: !isDocumentUploaded,
            })
        },
        enabled: orderId ? true : false,
    })

    const lockDocumentMutation = useMutation({
        mutationFn: () => lockDocumentUpload(orderId),
        onSuccess: () => {
            refetch();
            setDocumentUpload({ checked: false, disabled: true });
        },
        onError: (error) => {
            setSnackbar({
                type: "error",
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const onClickTab = (index) => {
        updateTab(TABS[index], index);
        setFilter({})
    };

    const onLockDocument = () => {
        console.log('onLockDocument');
        lockDocumentMutation.mutate();
    }

    const onToggleDocumentUpload = (value) => {
        if (value) return;

        setDialog({
            open: true,
            title: "Confirm the change of status",
            message: "Are you sure to turn off the document upload?\nYou won't be able to make changes next time.",
            actionText: "Lock",
            handleOnClickOK: onLockDocument,
        })
    }

    const onApiError = (error) => {
        setSnackbar({
            type: 'error',
            message: `${error?.response?.data?.result?.errorMessage?.en || error?.message}`
        })
    }

    const Header = () => {
        return (
            <Box display={"flex"} sx={{ flex: 1 }}>
                <Breadcrumbs aria-label="breadcrumb" separator=">" sx={{ flex: 1 }}>
                    <Link underline="hover" color="inherit" component={RouterLink} to="/order?type=orders">
                        <Typography variant="main" bold>All Orders</Typography>
                    </Link>
                    {orderNumber ?
                        <Typography variant="main" bold>{orderNumber && orderNumber !== "null" ? orderNumber : ""}</Typography>
                        : null
                    }
                </Breadcrumbs>
                {/* {
                    canView &&
                    <FilterButton
                        style={{ marginRight: margin }}
                        filters={[
                            {
                                type: 'date-range',
                                startDataKey: 'startDate',
                                endDataKey: 'endDate',
                            },
                            {
                                type: 'select',
                                dataKey: 'status',
                                options: STATUS
                            }
                        ]}
                        disabled={!canView}
                        value={filter}
                        onChange={(newFilterValue) => setFilter({ ...filter, ...newFilterValue })}
                    />
                } */}
                {/* <Button color="orange" style={{ marginRight: margin }}>Export</Button>
                <Button color="green" style={{ marginRight: margin }} onClick={handleOnOpenFilter}>Filter</Button> */}
            </Box>
        )
    }

    const CardHeader = () => {
        return (
            <Tab
                tabs={TABS}
                selected={tabIndex}
                onChange={onClickTab}
            />
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderCustomCardHeader={CardHeader}
        >
            <Grid container direction='row' justifyContent={"space-between"} alignItems='center' style={{ paddingRight: 10 }}>
                {/* <TableStatusFilter width={200} options={STATUS} value={filter.status} disabled={!canView} onChange={(newValue) => setFilter({ ...filter, status: newValue })} /> */}
                <Grid item xs>
                    <Searchbar value={filter.keyword} onChange={(e) => setFilter({ ...filter, keyword: e.target.value })} />
                </Grid>
                {/* <Checkbox label="Select" fullWidth={false} checked={selectMode} onChange={(event) => setSelectMode(event.target.checked)} /> */}
                { !isProductTab && !isNull(documentUpload) && (
                    <Grid item xs container justifyContent={"flex-end"} alignItems={"center"} gap={1}>
                        { !isLockDocumentAllowed && (
                            <Tooltip title={"Documents are still pending to upload"}>
                                <PriorityHighIcon fontSize={"12"} color="error" />
                            </Tooltip>
                        )}
                        <Typography fontSize={14}>{"Document Upload"}</Typography>
                        { isDocumentLocked ? (
                            <LockedButton>{"Locked"}</LockedButton>
                        ) : (
                            <SwitchButton
                                onClick={onToggleDocumentUpload}
                                checked={documentUpload?.checked}
                                disabled={documentUpload?.disabled}
                            />
                        )}
                    </Grid>
                )}
            </Grid>
            <Table
                style={{ margin: `0px 20px 20px 20px` }}
                columns={columns}
                api={"/order/items"}
                apiParams={{
                    type: TABS[tabIndex].id,
                    orderId: orderId,
                    ...filter
                }}
                sortingMode="server"
                onError={onApiError}
                setSnackbar={setSnackbar}
            // onRowClick={(params) => {
            //     navigate(`/terms-and-conditions/${params.id}`)
            // }}
            // checkboxSelection={selectMode}
            />
        </PageWrapper>
    )
}

export default withTabSearchParams(OrderItemListPage, TABS)