import { Controller } from "react-hook-form";
import {
    TextField,
    Typography,
    Button,
    ComponentContainer,
    Select,
    UploadComponent,
    AutoCompleteWithList
} from '../../components/share';
import MultipleDatePicker from "../../components/share/MultipleDatePicker";
import { Box, Grid } from "@mui/material";
import { isEmpty } from "lodash";
import { DeleteActionIcon, UnderlineText } from "../../components/share/Table";

const DeliveryFields = ({ control, errors, readOnly }) => {
    return [
        {
            id: 'header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1.5 }}>Delivery Setting</Typography>
            )
        }, { id: "spacing" },
        {
            id: "delivery-day",
            component: (
                <Controller
                    name={`estimatedDeliveryDay`}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            title="Delivery Day (T+N Day)"
                            direction="vertical"
                            fullWidth
                            error={errors?.estimatedDeliveryDay}
                            helperText={errors?.estimatedDeliveryDay?.message}
                            readOnly={readOnly}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
        {
            id: "pickup-day",
            component: (
                <Controller
                    name={`estimatePickupDay`}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            title="Pick Up day (T+N Day)"
                            direction="vertical"
                            fullWidth
                            error={errors?.estimatePickupDay}
                            helperText={errors?.estimatePickupDay?.message}
                            readOnly={readOnly}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
        {
            id: 'header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1.5 }}>Delivery Fee</Typography>
            )
        }, { id: "spacing" },
        {
            id: "minimum-free-shipping-amount",
            component: (
                <Controller
                    name={`freeShippingMinSpent`}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            title="Minimum Free Shipping Amount"
                            direction="vertical"
                            fullWidth
                            error={errors?.freeShippingMinSpent}
                            helperText={errors?.freeShippingMinSpent?.message}
                            readOnly={readOnly}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
        {
            id: "delivery-fee-per-order",
            component: (
                <Controller
                    name={`deliveryFee`}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            title="Delivery Fee per order"
                            direction="vertical"
                            fullWidth
                            error={errors?.deliveryFee}
                            helperText={errors?.deliveryFee?.message}
                            readOnly={readOnly}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
    ]
}

const DocFields = ({ onPreview, control, errors }) => {

    const UploadDocComponent = ({ field, title }) => {
        return (
            <ComponentContainer direction="vertical" title={title}>
                {
                    (!isEmpty(field.value?.value)) ? (
                        <Grid container item xs>
                            <Grid item xs><Button onClick={() => onPreview && onPreview(field.value.valueUrl)}>Preview</Button></Grid>
                            <Grid item xs container alignItems={"center"} onClick={() => field.onChange({})}>
                                <DeleteActionIcon />
                                <UnderlineText>Delete</UnderlineText>
                            </Grid>
                        </Grid>
                    ) : (
                        <UploadComponent
                            renderComponent={({ isLoading, selectFile }) => (
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={selectFile}
                                    loading={isLoading}
                                >
                                    + Upload
                                </Button>
                            )}
                            accept="application/pdf"
                            onChange={(e, response) => {
                                field.onChange({
                                    valueUrl: response?.previewUrl,
                                    value: response?.fileKey
                                })
                            }} 
                        />
                    )
                }
            </ComponentContainer>
        )
    }

    return [
        {
            id: 'header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1.5 }}>Document Upload</Typography>
            )
        }, { id: "spacing" },
        {
            id: "document-upload",
            component: (
                <Controller
                    name={`mnpDownloadForm`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <UploadDocComponent field={field} title={"Mobile No. Portability (MNP) Download Form"} />
                        )
                    }}
                />
            )
        }, { id: "spacing" },
        // {
        //     id: "document-upload",
        //     component: (
        //         <Controller
        //             name={`changeOwnershipForm`}
        //             control={control}
        //             render={({ field }) => {
        //                 return (
        //                     <UploadDocComponent field={field} title={"Change Ownership Form"} />
        //                 )
        //             }}
        //         />
        //     )
        // }, { id: "spacing" },
    ]
}

const TncFields = ({ control, errors, readOnly, tncList }) => {
    return [
        {
            id: 'header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1.5 }}>Plan Before Buy</Typography>
            )
        }, { id: "spacing" },
        {
            id: "before-buy-tnc",
            component: (
                <Controller
                    name={`planBeforeBuy`}
                    control={control}
                    render={({ field }) => (
                        <AutoCompleteWithList
                            title="Select T&C*"
                            direction="vertical"
                            fullWidth
                            textFieldProps={{
                                placeholder: "Select..."
                            }}
                            options={tncList}
                            value={field.value}
                            onChange={(value) => {
                                field.onChange(value)
                            }}
                            getOptionLabel={(option) => option?.title?.en}
                            disabled={readOnly}
                            error={errors?.planBeforeBuy}
                            helperText={errors?.planBeforeBuy?.message}
                            multiple
                        />
                    )}
                />
            )
        }, { id: "spacing" },
        {
            id: "hr"
        },
        {
            id: 'header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1.5 }}>Postpaid</Typography>
            )
        }, { id: "spacing" },
        {
            id: "postpaid-tnc",
            component: (
                <Controller
                    name={`postPaid`}
                    control={control}
                    render={({ field }) => (
                        <Select
                            title="Select T&C*"
                            direction="vertical"
                            fullWidth
                            textFieldProps={{
                                placeholder: "Select..."
                            }}
                            options={tncList}
                            value={field.value}
                            onChange={(value) => {
                                field.onChange(value)
                            }}
                            getOptionLabel={(option) => option?.title?.en}
                            disabled={readOnly}
                            error={errors?.postPaid}
                            helperText={errors?.postPaid?.message}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
        {
            id: "hr"
        },
        {
            id: 'header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1.5 }}>Prepaid</Typography>
            )
        }, { id: "spacing" },
        {
            id: "prepaid-tnc",
            component: (
                <Controller
                    name={`prePaid`}
                    control={control}
                    render={({ field }) => (
                        <Select
                            title="Select T&C*"
                            direction="vertical"
                            fullWidth
                            textFieldProps={{
                                placeholder: "Select..."
                            }}
                            options={tncList}
                            value={field.value}
                            onChange={(value) => {
                                field.onChange(value)
                            }}
                            getOptionLabel={(option) => option?.title?.en}
                            disabled={readOnly}
                            error={errors?.prePaid}
                            helperText={errors?.prePaid?.message}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
        {
            id: "hr"
        },
        {
            id: 'header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1.5 }}>Privacy Statement</Typography>
            )
        }, { id: "spacing" },
        {
            id: 'header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1.5 }}>Save Personal Info for next purchase</Typography>
            )
        }, { id: "spacing" },
        {
            id: "save-personal-info-for-next-purchase",
            component: (
                <Controller
                    name={`savePersonalInformation`}
                    control={control}
                    render={({ field }) => (
                        <Select
                            title="Select T&C*"
                            direction="vertical"
                            fullWidth
                            textFieldProps={{
                                placeholder: "Select..."
                            }}
                            options={tncList}
                            value={field.value}
                            onChange={(value) => {
                                field.onChange(value)
                            }}
                            getOptionLabel={(option) => option?.title?.en}
                            disabled={readOnly}
                            error={errors?.savePersonalInformation}
                            helperText={errors?.savePersonalInformation?.message}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
        {
            id: 'header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1.5 }}>Rejection for Direct Sale Promotion use</Typography>
            )
        }, { id: "spacing" },
        {
            id: "rejection-for-direct-sale-promotion-use",
            component: (
                <Controller
                    name={`directSale`}
                    control={control}
                    render={({ field }) => (
                        <Select
                            title="Select T&C (This Services / The Club & MyHKT)*"
                            direction="vertical"
                            fullWidth
                            textFieldProps={{
                                placeholder: "Select..."
                            }}
                            options={tncList}
                            value={field.value}
                            onChange={(value) => {
                                field.onChange(value)
                            }}
                            getOptionLabel={(option) => option?.title?.en}
                            disabled={readOnly}
                            error={errors?.promotion}
                            helperText={errors?.promotion?.message}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
        {
            id: "hr"
        },
        {
            id: 'header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1.5 }}>Statutory Free Removal Service</Typography>
            )
        }, { id: "spacing" },
        {
            id: "statutory-free-removal-service",
            component: (
                <Controller
                    name={`freeRemovalServices`}
                    control={control}
                    render={({ field }) => (
                        <Select
                            title="Select T&C*"
                            direction="vertical"
                            fullWidth
                            textFieldProps={{
                                placeholder: "Select..."
                            }}
                            options={tncList}
                            value={field.value}
                            onChange={(value) => {
                                field.onChange(value)
                            }}
                            getOptionLabel={(option) => option?.title?.en}
                            disabled={readOnly}
                            error={errors?.freeRemovalServices}
                            helperText={errors?.freeRemovalServices?.message}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
    ]
}

const PlanFields = ({ control, errors, readOnly }) => {
    return [
        {
            id: 'header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1.5 }}>Plan</Typography>
            )
        }, { id: "spacing" },
        {
            id: "mnp-activation-holidays",
            component: (
                <Controller
                    name={`mnpActivationHolidays`}
                    control={control}
                    render={({ field }) => (
                        <MultipleDatePicker
                            {...field}
                            title="MNP Activation Holidays"
                            direction="vertical"
                            fullWidth
                            error={errors?.mnpActivationHolidays}
                            helperText={errors?.mnpActivationHolidays?.message}
                            readOnly={readOnly}
                        />
                    )}
                />
            )
        }, { id: "spacing" }, { id: "hr" },
        {
            id: 'header2',
            component: (
                <Typography variant="header">MNP Number Activation Setting</Typography>
            )
        }, { id: "spacing" },
        {
            id: "mnp-number-activation-min-day",
            component: (
                <Controller
                    name={`mnpNumberActivationMinDay`}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            title={"Min Day"}
                            direction="vertical"
                            fullWidth
                            readOnly={readOnly}
                            error={errors?.mnpNumberActivationMinDay}
                            helperText={errors?.mnpNumberActivationMinDay?.message}
                        />
                    )}
                />
            )
        },
        {
            id: "mnp-number-activation-max-day",
            component: (
                <Controller
                    name={`mnpNumberActivationMaxDay`}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            title={"Max Day"}
                            direction="vertical"
                            fullWidth
                            readOnly={readOnly}
                            error={errors?.mnpNumberActivationMaxDay}
                            helperText={errors?.mnpNumberActivationMaxDay?.message}
                        />
                    )}
                />
            )
        }, { id: 'hr' },
        {
            id: 'header2',
            component: (
                <Typography variant="header">New Number Activation Setting</Typography>
            )
        }, { id: "spacing" },
        {
            id: "new-number-activation-min-day",
            component: (
                <Controller
                    name={`newNumberActivationMinDay`}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            title={"Min Day"}
                            direction="vertical"
                            fullWidth
                            readOnly={readOnly}
                            error={errors?.newNumberActivationMinDay}
                            helperText={errors?.newNumberActivationMinDay?.message}
                        />
                    )}
                />
            )
        },
        {
            id: "new-number-activation-max-day",
            component: (
                <Controller
                    name={`newNumberActivationMaxDay`}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            title={"Max Day"}
                            direction="vertical"
                            fullWidth
                            readOnly={readOnly}
                            error={errors?.newNumberActivationMaxDay}
                            helperText={errors?.newNumberActivationMaxDay?.message}
                        />
                    )}
                />
            )
        },
    ]
}

export {
    DeliveryFields,
    DocFields,
    TncFields,
    PlanFields,
}