import * as React from 'react';
import SelectImageDialog from './selectImgDialog';

export default function SelectImgDialog({ renderComponent, ...props }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    return (
        <div>
            {renderComponent && renderComponent({ openDialog: handleClickOpen })}
            <SelectImageDialog
                open={open}
                handleClose={handleClose}
                {...props}
            />
        </div>
    );
}
