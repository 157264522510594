import { Grid } from '@mui/material';
import styled from 'styled-components';
import Typography from './Typography';
import { styled as styledMui } from '@mui/material/styles';

const Container = styledMui(Grid)(
    ({ theme}) => `
        padding: 16px 12px;
        background-color: ${theme.palette.mainColor.light};
        cursor: default;
    `
);

const Indicator = styledMui(Typography)(
    ({ theme, selected, error }) => `
        height: 25px;
        width: 25px;
        border-radius: 50%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 6px;
        background-color: ${theme.palette.secondary.main};
        ${selected ? `background-color: ${theme.palette.mainColor.main};` : ""}
        ${error ? `background-color: ${theme.palette.error.main};` : ""}
  `,
);

const Name = styledMui(Typography)(
    ({ theme, selected }) => `
    color: ${selected ? theme.palette.mainColor.main : theme.palette.secondary.main};
    font-size: 14px;
  `,
);

const ListItem = styled(Grid)`
    && {
        > * {
            margin-right: 16px;
        }
    }
`

function Progressbar({ data, step, error }) {
    return (
        <Container container direction="row" alignItems="center">
            {
                data.map((item, i) => {
                    const selected = step === i
                    const hasError = error.includes(item.id)
                    return (
                        <ListItem item key={item.id}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Indicator selected={selected} error={hasError}>{i + 1}</Indicator>
                                <Name selected={selected}>{item.name}</Name>
                            </div>
                        </ListItem>
                    )
                })
            }
        </Container>
    )
}

export default Progressbar