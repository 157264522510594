import { forwardRef } from 'react'
import { Grid, CircularProgress } from '@mui/material';
import Image, { ImgContainer } from './ImageV2';
import UploadImgBox from './UploadImgBox';

function ImgGrid({ selected, data, onImageUploaded, imgSize, spacing = 2, readOnly, onRemove, style, onClickImg }) {

    return (
        <Grid container spacing={spacing} justify="flex-start" style={style}>
            {
                data.map((item, position) => {

                    if (item.id === 'upload') {
                        return (
                            <Grid
                                item
                                // xs={1}
                                key={item.id}
                                style={{ marginBottom: 10 }}
                            >
                                <UploadImgBox imgSize={imgSize} onImageUploaded={onImageUploaded} />
                            </Grid>
                        )
                    }

                    if (item.id === 'custom') {
                        return (
                            <Grid
                                item
                                // xs={1}
                                key={item.id}
                                style={{ marginBottom: 10 }}
                            >
                                {item?.renderComponent()}
                            </Grid>
                        )
                    }

                    const isSelected = selected?.id === item.id;
                    return (
                        <Grid item key={item.id} style={{ marginBottom: 10 }}>
                            <Image
                                height={imgSize}
                                width={imgSize}
                                url={item.url}
                                readOnly={readOnly}
                                imgStyle={{ border: `${isSelected ? 3 : 1}px solid ${isSelected ? '#607FF7' : '#9B9B9B'}`, borderRadius: 5 }}
                                onClick={() => {
                                    if (onClickImg) {
                                        onClickImg(item)
                                    } else {
                                        window.open(item.url)
                                    }
                                }}
                                hideRemove={readOnly || item?.canDelete === false}
                                onRemove={() => onRemove && onRemove({ position })}
                            />
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

export default ImgGrid