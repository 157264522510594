import * as React from 'react';
import Typography from './Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import FormControl from '@mui/material/FormControl'
import _ from 'lodash'
import constants from '../../constants';

function TableStatusFilter({ title = "Status", style, titleStyle, value, onChange, options, disabled, width, ...props }) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);

    React.useEffect(() => {
        if (value) {
            const findIndex = _.findIndex(options, { _id: value });
            setSelectedIndex(findIndex >= 0 ? findIndex : -1)
        }
    }, [value, options])

    const handleMenuItemClick = (
        event,
        index,
    ) => {
        if (disabled) return;
        setSelectedIndex(index);
        onChange && onChange(options[index]._id)
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target)
        ) {
            return;
        }

        setOpen(false);
    };

    return (
        <div style={{...style}}>
            <FormControl sx={{ m: 1, minWidth: 80, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography variant="title" header style={{ marginBottom: 0, color: '#000', fontSize: 12, marginRight: 4 }}>{title}</Typography>
                <React.Fragment>
                    <ButtonGroup
                        variant="contained"
                        ref={anchorRef}
                        aria-label="split button"
                        disableFocusRipple
                        disableRipple
                        disableElevation
                        sx={{
                            width: width || 170,
                            height: 30,
                            backgroundColor: disabled ? "secondary.dark" : "secondary.light",
                            border: `1px solid #9B9B9B`,
                            boxShadow: "none",
                        }}
                    >
                        <Button
                            // onClick={handleToggle}
                            disableRipple
                            sx={{
                                textTransform: 'none',
                                flex: 1,
                                backgroundColor: disabled ? "secondary.dark" : "secondary.light",
                                color: "info.main",
                                "&:hover": {
                                    background: "none"
                                }
                            }}
                            style={{
                                border: 'none',
                                justifyContent: 'left'
                            }}
                        >
                            <Typography
                                variant="ellipsis"
                                color={disabled ? "info.light" : "info.main"}
                                align="left"
                                sx={{ fontSize: 14 }}
                            >
                                {options?.[selectedIndex]?.name || "Select..."}
                            </Typography>
                        </Button>
                        <Box sx={{
                            background: "#9B9B9B",
                            backgroundColor: "#9B9B9B",
                            height: '20px',
                            width: '1px',
                            alignSelf: 'center',
                        }}
                        />
                        <Button
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                            sx={{
                                color: "secondary.main",
                                // cursor: 'pointer',
                                backgroundColor: disabled ? "secondary.dark" : "secondary.light",
                                minWidth: 22,
                                width: 22,
                                "&:hover": {
                                    background: "none"
                                },
                            }}
                            disableRipple={disabled}
                        >
                            <KeyboardArrowDownIcon style={{ fontSize: `1rem` }} />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        sx={{
                            zIndex: 1,
                        }}
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu" autoFocusItem>
                                            {options.map((option, index) => {
                                                return (
                                                    <MenuItem
                                                        key={option._id}
                                                        selected={index === selectedIndex}
                                                        onClick={(event) => handleMenuItemClick(event, index)}
                                                        disabled={option.disabled || disabled}
                                                    >
                                                        {option.name}
                                                    </MenuItem>
                                                )
                                            })}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </React.Fragment>
            </FormControl>
        </div>
    );
}

export default TableStatusFilter