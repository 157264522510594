import { useEffect, useState } from "react";
import { pdfjs } from 'react-pdf';
import styled from "styled-components";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const Conatiner = styled.div`
    width: 100%;
    height: auto;
    overflow-y: hidden;
`;

const Viewer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 100%;
    background: gray;

    overflow-y: auto;
`;

const Page = styled.div`
    margin: 20px 0;

    img {
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
    }

    @media print {
        display: none;
    }
`;


const EncryptedPDFViewer = ({ data }) => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchPdf = async () => {
            const loadingTask = pdfjs.getDocument(data);
            const pdf = await loadingTask.promise;
            const pagesPromises = [];

            for (let i = 1; i <= pdf.numPages; i++) {
                pagesPromises.push(pdf.getPage(i));
            }

            const pages = await Promise.all(pagesPromises);

            const imagesPromises = pages.map(page => {
                const viewport = page.getViewport({ scale: 1 });
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                return page.render({ canvasContext: context, viewport }).promise.then(() => canvas.toDataURL());
            });

            const images = await Promise.all(imagesPromises);
            setImages(images);
        };

        fetchPdf();
    }, [data]);

    return (
        <Conatiner>
            <Viewer>
                {images.map((src, index) => (
                    <Page 
                        style={{ width: "auto", height: "100%" }}
                        onDragStart={(e) => e.preventDefault()}
                        onContextMenu={(e) => e.preventDefault()}
                    >
                        <img key={index} src={src} alt={`PDF Page ${index + 1}`} />
                    </Page>
                ))}
            </Viewer>
        </Conatiner>
    );
};

export default EncryptedPDFViewer;