import { useState, useContext, useCallback, useMemo, useEffect, useRef, useLayoutEffect } from 'react';
import { Grid } from '@mui/material';
import {
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import _ from 'lodash'
import {
    Typography,
    Button,
    Tab,
    PageWrapper,
    Searchbar,
    Image,
    FilterButton
} from '../../components/share';
import Table, { DeleteActionIcon } from '../../components/share/Table';
import { snackbarContext } from '../../context/SnackbarProvider';
import { alertDialogContext } from '../../context/AlertDialogProvider';
import { userContext } from '../../context/UserProvider';
import { companiesContext } from '../../context/CompaniesProvider';
import { deleteBanner } from '../../apis/banner';
import withTabSearchParams from '../../hoc/withTabSearchParams'
import dayjs from 'dayjs';
import constants from '../../constants';
import { getPermission } from '../../utils';

const margin = 10

export const TabList = [
    {
        name: "Desktop",
        id: 'desktop'
    },
    {
        name: "Mobile",
        id: 'mobile'
    },
];

function PublicBanner({ updateTab, tabIndex }) {
    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [dialog, setDialog] = useContext(alertDialogContext);
    const [user, setUser] = useContext(userContext);
    const [companies] = useContext(companiesContext);
    const [filter, setFilter] = useState({
        keyword: "",
    })

    const { canView, canModify } = getPermission(user?.permissions?.banner)

    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: (id) => deleteBanner(id),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['/banner'] });
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
        },
        onError: (error) => {
            console.log("error", error)
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        },
    })

    const onDeleteBanner = (id) => {
        setDialog({
            open: true,
            title: "Are you sure to delete this banner?",
            handleOnClickOK: () => mutation.mutate(id)
        })
    }

    const columns = useMemo(() => {
        return [
            {
                field: tabIndex === 0 ? "desktopImageUrlEn" : 'mobileImageUrlEn',
                headerName: 'Preview',
                flex: 2,
                sortable: false,
                renderCell: (params) => {
                    return (
                        <Image
                            height={86}
                            width={168}
                            url={params.value}
                            readOnly
                            disableClick
                        />
                    )
                }
            },
            {
                field: 'allowedCompany',
                headerName: 'Company Name',
                flex: 1,
                valueGetter: (params) => params?.row?.companyNames?.map(item => item.name).join(', ')
            },
            {
                field: 'animationLine1',
                headerName: 'Line 1',
                flex: 1,
                sortable: false,
                valueGetter: (params) => params?.value?.en
            },
            {
                field: 'updatedAt',
                headerName: 'Last Update Date',
                flex: 1,
                sortable: false,
                valueGetter: (params) => dayjs(params.value).format(constants.DATE_FORMAT)
            },
            {
                field: 'actions',
                headerName: 'Action',
                type: 'actions',
                width: 80,
                headerAlign: 'center',
                getActions: (params) => [
                    <DeleteActionIcon disabled={!canModify} onClick={() => onDeleteBanner(params.row._id)} />,
                ],
            }
        ]
    }, [tabIndex, canModify])

    const onClickTab = (index) => {
        updateTab(TabList[index], index)
        setFilter({})
    }

    const onApiError = (error) => {
        setSnackbar({
            type: 'error',
            message: `${error?.response?.data?.result?.errorMessage?.en || error?.message}`
        })
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Public Banner > ${TabList[tabIndex].name}`}</Typography>
                <FilterButton
                    style={{ marginRight: margin }}
                    filters={[
                        {
                            type: 'select',
                            dataKey: 'company',
                            title: "Company Name",
                            options: companies
                        }
                    ]}
                    value={filter}
                    disabled={!canView}
                    onChange={(newFilterValue) => setFilter({ ...newFilterValue })}
                />
            </>
        )
    }

    const CardHeader = () => {
        return (
            <Tab
                tabs={TabList}
                selected={tabIndex}
                onChange={onClickTab}
                renderButton={() => {
                    return (
                        <Button
                            style={{
                                alignSelf: 'flex-end',
                                margin: `0px 20px 14px 20px`,
                            }}
                            onClick={() => navigate(`/public-banner/add`)}
                            type="add"
                            disabled={!canModify}
                        >
                            Add New
                        </Button>
                    )
                }}
            />
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderCustomCardHeader={CardHeader}
        >
            <Grid container direction='row' alignItems='center' style={{ paddingRight: 10 }}>
                <Searchbar value={filter.keyword} onChange={(e) => setFilter({ ...filter, keyword: e.target.value })} />
            </Grid>
            <Table
                style={{ margin: `0px 20px 20px 20px` }}
                columns={columns}
                api={"/banner"}
                apiParams={{
                    ...filter
                }}
                sortingMode="server"
                onError={onApiError}
                setSnackbar={setSnackbar}
                rowHeight={120}
                onRowClick={(params, event, details) => {
                    navigate(`/public-banner/${params.id}`)
                }}
            />
        </PageWrapper>
    )
}

export default withTabSearchParams(PublicBanner, TabList)