import { useState, useContext, useCallback, useMemo, useEffect, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import {
    GridActionsCellItem,
} from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import { Routes, Route, useParams, useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import _ from 'lodash'
import Typography from '../../components/share/Typography';
import Button from '../../components/share/Button';
import Tab from '../../components/share/Tab';
import PageWrapper from '../../components/share/PageWrapper';
import { deleteMedia, listMedia } from '../../apis/medias';
import withTabSearchParams from '../../hoc/withTabSearchParams'
import { styled as styledMui } from '@mui/material/styles';
import Image from '../../components/share/ImgGrid/ImageV2';
import { snackbarContext } from '../../context/SnackbarProvider';
import { alertDialogContext } from '../../context/AlertDialogProvider';

const Container = styled(Grid)`
    && {
        padding: 30px;
        overflow: auto;
    }
`


const GridItem = styledMui(Grid)(
    ({ theme }) => `
    && {
        height: 180px;
        width: 180px;
        min-width: 180px;
        min-height: 180px;
        background-color: #F4F4F4;
        border: 1px solid ${theme.palette.secondary.main};
        border-radius: 5px;
        cursor: pointer;
    }
  `,
);

const StyledImg = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
`

const TABS = [
    { name: "All", id: undefined },
    { name: "Banner", id: "banner" },
    { name: "Others", id: "others" }
]

function MediaPage({ updateTab, tabIndex }) {

    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [dialog, setDialog] = useContext(alertDialogContext);

    const { data, error, isError, isLoading } = useQuery({
        queryKey: ["media", { category: TABS[tabIndex]?.id, limit: 9999 }],
        queryFn: listMedia
    });

    const deleteMutation = useMutation({
        mutationFn: (id) => deleteMedia(id),
        onSuccess: () => {
            setSnackbar({
                type: "success",
                message: "Media deleted successfully"
            })
            queryClient.invalidateQueries("media")
        },
        onError: (error) => {
            setSnackbar({
                type: "error",
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const onClickTab = (index) => {
        updateTab(TABS[index], index)
    }

    const onClickDelete = (id) => {
        setDialog({
            open: true,
            title: "Delete Media",
            message: "Are you sure you want to delete this media?",
            handleOnClickOK: () => deleteMutation.mutate(id)
        })
    }

    const Header = () => {
        return (
            <Typography bold style={{ flex: 1 }}>{`Media > ${TABS[tabIndex].name}`}</Typography>
        )
    }

    const CardHeader = () => {
        return (
            <Tab
                tabs={TABS}
                selected={tabIndex}
                onChange={onClickTab}
                renderButton={() => {
                    return (
                        <Button
                            style={{
                                alignSelf: 'flex-end',
                                margin: `0px 20px 14px 20px`,
                            }}
                            onClick={() => {
                                navigate({
                                    pathname: '/media/add',
                                    search: createSearchParams({
                                        ...(TABS[tabIndex]?.id !== undefined && { category: TABS[tabIndex]?.id }),
                                    }).toString()
                                })
                            }}
                            type="add"
                        >
                            Add New
                        </Button>
                    )
                }}
            />
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderCustomCardHeader={CardHeader}
            loading={isLoading || deleteMutation.isLoading}
        >
            <Container container direction='row' gap={2}>
                {(data || []).map((item) => {
                    return (
                        <Grid item key={item.id}>
                            <Image
                                height={180}
                                width={180}
                                url={item.imageUrl}
                                imgStyle={{ borderRadius: 5, border: '1px solid #9B9B9B' }}
                                onClick={() => navigate(`/media/${item._id}`)}
                                hideRemove={false}
                                onRemove={() => onClickDelete(item._id)}
                            />
                        </Grid>
                    )
                })}
            </Container>

        </PageWrapper>
    )
}

export default withTabSearchParams(MediaPage, TABS)