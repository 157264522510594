import { request } from './index';
import constants from '../constants';

const updateOrderStatus = (id, payload) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "put",
            url: `${constants.API_ENDPOINT}/order/detail/${id}/status`,
            data: payload
        })
            .then(res => {
                // resolve(res.data)
                resolve(res)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const fetchOrderDetails = (id) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "get",
            url: `${constants.API_ENDPOINT}/order/detail/${id}`
        })
            .then(res => {
                // resolve(res.data)
                resolve(res)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const fetchOrderItemDetails = (id) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "get",
            url: `${constants.API_ENDPOINT}/order/items/detail/${id}`
        })
            .then(res => {
                // resolve(res.data)
                resolve(res)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const updateOrder = (id, payload) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "put",
            url: `${constants.API_ENDPOINT}/order/items/detail/${id}`,
            data: payload
        })
            .then(res => {
                // resolve(res.data)
                resolve(res)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const addOrderItemRemark = (id, payload) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "post",
            url: `${constants.API_ENDPOINT}/order/items/detail/${id}/remarks`,
            data: payload
        })
            .then(res => {
                // resolve(res.data)
                resolve(res)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const viewDocumentById = (orderId, documentId) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "post",
            url: `${constants.API_ENDPOINT}/order/items/detail/${orderId}/userDocuments`,
            data: {
                documentId,
            },
            responseType: 'arraybuffer',
        })
            .then(res => {
                resolve(res);
            })
            .catch((error) => {
                reject(error.result)
            })
    });
}

const lockDocumentUpload = (orderId) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "put",
            url: `${constants.API_ENDPOINT}/order/detail/${orderId}/lockDocument`,
        })
            .then(res => {
                resolve(res?.data);
            })
            .catch((error) => {
                reject(error.result)
            })
    });
}

export {
    updateOrderStatus,
    fetchOrderDetails,
    fetchOrderItemDetails,
    updateOrder,
    addOrderItemRemark,
    viewDocumentById,
    lockDocumentUpload,
}