import { useRef, useState } from 'react';
import TextField from './TextField';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from './Typography'
import { InputAdornment, Grid, Box } from "@mui/material";
import ComponentContainer from './ComponentContainer';

function HKIdInput({ title, height, inputProps = {}, ...props }) {

    const renderInput = () => {
        return (
            <Grid container direction='row' alignItems="center">
                <Grid item style={{ marginRight: 18, width: 36 }}>
                    <TextField fullWidth {...inputProps} inputProps={{ maxLength: 1, style: { textAlign: 'center', height, ...inputProps?.inputProps?.style } }} />
                </Grid>
                <Grid item xs style={{ marginRight: 18 }}>
                    <TextField fullWidth {...inputProps} inputProps={{ maxLength: 6, style: { height, ...inputProps?.inputProps?.style } }}/>
                </Grid>
                <Typography>{`(`}</Typography>
                <Grid item style={{ margin: `0px 8px`, width: 36 }}>
                    <TextField {...inputProps} inputProps={{ maxLength: 1, style: { textAlign: 'center', height, ...inputProps?.inputProps?.style } }} />
                </Grid>
                <Typography>{`)`}</Typography>
            </Grid>
        )
    }

    return (
        <ComponentContainer title={title} {...props} >
            {renderInput()}
        </ComponentContainer>
    )
}

export default HKIdInput