import { useState, useContext, useMemo, useLayoutEffect } from 'react';
import styled from 'styled-components';
import Typography from '../../components/share/Typography';
import Button from '../../components/share/Button';
import Tab from '../../components/share/Tab';
import { Grid } from '@mui/material';
import Table, { UnderlineText, DeleteActionIcon } from '../../components/share/Table';
import { Routes, Route, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import AlertDialogProvider, { alertDialogContext } from '../../context/AlertDialogProvider';
import PageWrapper from '../../components/share/PageWrapper';
import _ from 'lodash';
import {
    GridActionsCellItem,
} from '@mui/x-data-grid';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import TableStatusFilter from '../../components/share/TableStatusFilter'
import Searchbar from '../../components/share/Search'
import withTabSearchParams from '../../hoc/withTabSearchParams'
import StatusButton from '../../components/share/StatusButtonV2'
import constants from '../../constants';
import dayjs from 'dayjs';
import { deleteCampaign } from '../../apis/campaign'
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'

const margin = 10

const TABS = [
    {
        name: 'Mobile',
        id: 'mobile'
    },
    {
        name: 'Local Telephone Service',
        id: "local-telephone-service"
    },
    {
        name: 'Limited Offer',
        id: 'limited-offer'
    }
]

const columns = ({ onDelete }) => [
    { field: 'planId', headerName: 'PlanID', width: 100, sortable: false, },
    { field: 'name', headerName: 'Camgaign Name', width: 160,  },
    { field: 'effectivePeriod', headerName: 'Effective Period', width: 160, sortable: false, },
    { field: 'updatedAt', headerName: 'Last Update Date', width: 160, sortable: false, },
    {
        field: 'status',
        headerName: 'Status',
        width: 130,
        sortable: false,
        renderCell: (params) => {
            return (
                <StatusButton value={params.value} />
            )
        }
    },
    {
        field: 'actions',
        headerName: 'Action',
        type: 'actions',
        width: 140,
        headerAlign: 'flex-start',
        getActions: (params) => {
            return [
                <UnderlineText sx={{ color: 'primary.main' }}>Edit</UnderlineText>,
                <DeleteActionIcon />
            ]
        }
    }
]

function CampaignPage({ updateTab, tabIndex }) {
    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [dialog, setDialog] = useContext(alertDialogContext);

    const queryClient = useQueryClient()

    const [filter, setFilter] = useState({
        keyword: "",
        status: null
    })

    const onClickTab = (index) => {
        updateTab(TABS[index], index)
        setFilter({})
    }

    const mutation = useMutation({
        mutationFn: (id) => deleteCampaign(id),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['/campaign'] })
        },
    })

    const hadnleOnDelete = (params) => {
        setDialog({ 
            open: true, 
            title: "Are you sure to delete this compaign?",
            handleOnClickOK: () => mutation.mutate(params.id) 
        })
    }

    const onApiError = (error) => {
        setSnackbar({
            type: 'error',
            message: `${error?.response?.data?.result?.errorMessage?.en || error?.message}`
        })
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }} >{`Campaign > ${TABS[tabIndex].name}`}</Typography>
                <Button color="green" style={{ marginRight: margin }} >Filter</Button>
            </>
        )
    }

    const CardHeader = () => {
        return (
            <Tab
                tabs={TABS}
                selected={tabIndex}
                onChange={onClickTab}
                renderButton={() => {
                    if (!tabIndex) {
                        return (
                            <Button
                                style={{
                                    alignSelf: 'flex-end',
                                    margin: `0px 20px 14px 20px`,
                                }}
                                type="add"
                                onClick={() => navigate(`/campaign/add`)}
                            >
                                Add New
                            </Button>
                        )
                    }
                }}
            />
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderCustomCardHeader={CardHeader}
        >
            <Grid container direction='row' alignItems='center' style={{ paddingLeft: 10 }}>
                <Searchbar value={filter.keyword} onChange={(e) => setFilter({ ...filter, keyword: e.target.value })} />
                <Grid item xs />
            </Grid>
            <Table
                style={{ margin: `0px 20px 20px 20px` }}
                color="grey"
                columns={columns({ onDelete: hadnleOnDelete })}
                // api={tabIndex ? "/numberPool" : "/plan"}
                // apiParams={{
                //     ...filter
                // }}
                sortingMode="server"
                onError={onApiError}
                setSnackbar={setSnackbar}
            />
        </PageWrapper>
    )

}

export default withTabSearchParams(CampaignPage, TABS);