import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import MyTypography from "./Typography";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, ...props }) => {
  const width = props?.width || 55;
  const thumbSize = 22;
  const trackSize = 26;
  const thumbTranslateX = width - thumbSize - 5;
  const thumbBorderColor = "#fff";
  const thumbBackgroundColor = "#edd34c";
  const trackBackgroundColor = "#afafaf";
  const trackOpacity = 1;
  const trackTransitionDuration = 500;
  const disabledThumbColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[600];
  const disabledTrackOpacity = 0.7;

  return {
    width: width,
    height: trackSize,
    padding: 0,
    marginRight: 10,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: `translateX(${thumbTranslateX}px)`,
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: thumbBackgroundColor,
          opacity: trackOpacity,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: disabledTrackOpacity,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        backgroundColor: thumbBackgroundColor,
        border: `6px solid ${thumbBorderColor}`,
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: disabledThumbColor,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: disabledTrackOpacity,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: thumbSize,
      height: thumbSize,
    },
    "& .MuiSwitch-track": {
      borderRadius: trackSize / 2,
      backgroundColor: trackBackgroundColor,
      opacity: trackOpacity,
      transition: theme.transitions.create(["background-color"], {
        duration: trackTransitionDuration,
      }),
    },
  };
});

const SwitchButton = ({
  checked,
  title,
  onClick,
  disableEllipsis,
  disabled,
  width,
}) => {
  const handleOnToggle = (event) => {
    if (disabled) return;
    onClick && onClick(event.target.checked);
  };

  return (
    <div
      style={{ display: "flex", alignItems: "center", gap: 5 }}
    >
      {disableEllipsis ? (
        <MyTypography fontSize={14}>{title}</MyTypography>
      ) : (
        <MyTypography
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
          }}
          fontSize={14}
        >
          {title}
        </MyTypography>
      )}
      <IOSSwitch checked={checked} disabled={disabled} onClick={handleOnToggle} width={width} />
    </div>
  );
};

export default SwitchButton;
