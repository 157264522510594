import * as React from 'react';
import DatePicker from './DatePicker';
import Typography from './Typography'
import Grid from '@mui/material/Grid'
import dayjs from 'dayjs';
import { FormControl } from '@mui/material';

const TableDateFilter = (props) => {
    const {
        type="date-range",
        filter,
        title="Date",
        fromLabel=null,
        toLabel=null,
        fromId="dateFrom",
        toId="dateTo",
        setFilter,
    } = props;

    return (
        <FormControl sx={{ display: "flex", flexDirection: "row", alignItems: "center", m: 1 }}>
            <Typography variant="title" header style={{ marginBottom: 0, color: '#000', fontSize: 12, marginRight: 4 }}>{title}</Typography>
            <React.Fragment>
                {
                    (type === "date-range") && (
                        <Grid item xs container direction='row' alignItems='center'>
                            <Grid item xs>
                                <DatePicker
                                    title={fromLabel}
                                    fullWidth
                                    value={filter?.[fromId]}
                                    onChange={(v) => setFilter({ ...filter, [fromId]: v ? dayjs(v).hour(0).minute(0).second(0).toISOString() : undefined })}
                                />
                            </Grid>
                            <Grid item style={{ margin: `0 10px` }}>
                                <Typography style={{ fontSize: 12 }}>To</Typography>
                            </Grid>
                            <Grid item xs>
                                <DatePicker
                                    title={toLabel}
                                    fullWidth
                                    value={filter?.[toId]}
                                    onChange={(v) => setFilter({ ...filter, [toId]: v ? dayjs(v).hour(23).minute(59).second(59).toISOString() : undefined })}
                                />
                            </Grid>
                        </Grid> 
                    )
                }
                {
                    (type === "date") && (
                        <Grid item xs container direction='row' alignItems='center'>
                            <Grid item xs>
                                <DatePicker
                                    title={fromLabel}
                                    fullWidth
                                    value={filter.date}
                                    onChange={(v) => setFilter({ ...filter, date: v ? dayjs(v).hour(0).minute(0).second(0).toISOString() : undefined })}
                                />
                            </Grid>
                        </Grid> 
                    )
                }
            </React.Fragment>
        </FormControl>
    )
}

export default TableDateFilter;