import { useState, createContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { listMedia } from "../apis/medias";

export const mediaContext = createContext();

const MediaProvider = ({ children }) => {
    const [mediaData, setMediaData] = useState(null);
    const token = localStorage.getItem('access_token');

    const { data, isLoading, refetch } = useQuery({
        queryKey: ["listMedia", { category: undefined }],
        queryFn: listMedia,
        onSuccess: (data) => {
            const formatted = data.map((item) => ({
                url: item?.imageUrl,
                previewUrl: item?.imageUrl,
                id: item?._id,
                fileKey: item?.image,
            }))
            setMediaData(formatted);
        },
        enabled: token ? true : false,
    })

    return (
        <mediaContext.Provider value={{mediaData, setMediaData, refetch}}>
            {children}
        </mediaContext.Provider>
    )
}

export default MediaProvider;