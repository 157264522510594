import { useState, useContext, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import {
    useQuery,
    useMutation,
    useQueryClient
} from '@tanstack/react-query'
import _, { isEmpty } from 'lodash'
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import {
    PageWrapper,
    Typography,
    Button,
    Form
} from '../../components/share';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import { tncContext } from '../../context/TncProvider';
import { createVas, fetchVas } from '../../apis/vas';
import { Routes, Route, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { FIELDS } from './formFields';
import { getPermission } from '../../utils'
import { userContext } from '../../context/UserProvider';
import constants from '../../constants';

const SaveButton = styled(Button)`
    && {
        align-self: end; 
        width: 80px;
        margin: 10px 20px 14px 20px;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
    height: 100%;
    overflow: auto;
    flex-wrap: nowrap;
`

export const schema = Joi.object({
    promotionTitle: Joi.object({
        en: Joi.string().empty(""),
        zh: Joi.string().empty(""),
    }),
    name: Joi.object({
        en: Joi.string().empty("").required(),
        zh: Joi.string().empty("").required(),
    }),
    discountedPrice: Joi.number(),
    originalPrice: Joi.number(),
    priceType: Joi.string().required(),
    detail: Joi.object({
        en: Joi.string().empty("").required(),
        zh: Joi.string().empty("").required(),
    }),
    vasId: Joi.string(),
    // tncs: Joi.array(),
    tncs: Joi.any(),
    vasType: Joi.string().required(),
    newImageKey: Joi.object().required(),
    //fee
    //image
}).messages({
    "any.required": "Required",
    "object.base": "Required"
});

function AddVas(props) {

    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [user, setUser] = useContext(userContext);

    const { canView, canModify } = getPermission(user?.permissions?.vas)
    const [tncList] = useContext(tncContext);
    const id = searchParams.get('vasId');

    const { control, handleSubmit, watch, formState: { errors }, reset, getValues } = useForm({
        resolver: joiResolver(schema),
    });

    const { data, error, isError, isLoading } = useQuery({
        queryKey: ["vasDetail", { vasId: id, canModify }],
        queryFn: async () => await fetchVas(id),
        enabled: Boolean(canModify && id)
    });

    useEffect(() => {
        if (!_.isEmpty(errors)) {
            setSnackbar({
                type: "error",
                message: constants.INVALID_INPUT_ERR_MSG
            })
        }
    }, [errors])

    useEffect(() => {
        if (id && !isLoading && data) {
            reset({
                promotionTitle: {
                    en: data?.promotionTitle?.en,
                    zh: data?.promotionTitle?.zh,
                },
                name: {
                    en: data?.name?.en,
                    zh: data?.name?.zh,
                },
                discountedPrice: data?.discountedPrice,
                originalPrice: data?.originalPrice,
                priceType: data?.priceType,
                detail: {
                    en: data?.detail?.en,
                    zh: data?.detail?.zh,
                },
                tncs: (data?.tncs?.[0] && tncList?.length > 0) ? tncList.find(t => t._id === data?.tncs?.[0]) : undefined,
                vasType: data?.vasType,
                vasId: data?.vasId,
                newImageKey: data?.imageUrl ? {
                    fileKey: data?.image,
                    previewUrl: data?.imageUrl
                } : null,
            })
        }
    }, [data, id])

    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (payload) => createVas(payload),
        onSuccess: (data) => {
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
            navigate(`/vas`)
            queryClient.invalidateQueries({ queryKey: ['allVas'] })
        },
    })


    const handleOnSubmit = (formValue) => {
        // console.log("formValue", formValue)
        const payload = {
            ...formValue,
            newImageKey: formValue?.newImageKey?.fileKey,
            tncs: !isEmpty(formValue?.tncs) ? [formValue?.tncs] : []
        };

        mutation.mutate(payload)
    }

    console.log("errors", errors)

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`VAS > Mobile > Add New`}</Typography>
            </>
        )
    }

    const CardHeader = () => {
        return (
            <SaveButton
                onClick={() => {
                    handleSubmit(handleOnSubmit)();
                }}
                disabled={!canModify}
            >
                Save
            </SaveButton>
        )
    }

    return (
        <PageWrapper
            loading={(id && isLoading) || mutation.isLoading}
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            renderCardHeader={CardHeader}
        >
            <Content>
                <Typography variant="header3">Fields marked * are required</Typography>
                <Form fields={FIELDS({ control, errors, tncList })} p={`14px 0px`} />
            </Content>
        </PageWrapper>
    )
}

export default AddVas