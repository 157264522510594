import styled from 'styled-components';
import { Divider } from '@mui/material';

const GreyDivider = styled(Divider)`
    && {
        background: #E2EBF4;
        border-width: 1px;
    }
`

export default GreyDivider