import { useState } from 'react'
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Typography from './Typography';

function CollpaseContainer({ renderTitle,title, children, arrowColor }) {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <div>
            <Grid container direction='row' alignItems="center" onClick={handleClick} sx={{ height: 50, cursor: 'pointer' }}>
                <Grid item>
                    {title && <Typography variant="header3" style={{ marginLeft: 10 }}>{title}</Typography>}
                    {renderTitle && renderTitle()}
                </Grid>
                <Grid item>
                    {open ? <ExpandLess style={{ color: arrowColor }} /> : <ExpandMore style={{ color: arrowColor }} />}
                </Grid>
            </Grid>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </div>

    )
}

export default CollpaseContainer