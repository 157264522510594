import { useMemo, useState } from 'react';
import styled from 'styled-components';
import {
    Typography,
    TextField,
    Button,
    ComponentContainer
} from '../share';
import { Grid, InputAdornment, FormHelperText } from '@mui/material';
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import Joi from 'joi';
import GreyDivider from '../GreyDivider';
import {
    DataGrid,
} from '@mui/x-data-grid';
import _ from 'lodash';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import ProductSliderGallery from './ProductSliderGallery'
import AddImageDialogWithButton from './AddImageDialogWithButton';

const schema = Joi.object({
    variationProducts: Joi.array().items(
        Joi.object({
            title: Joi.object({
                en: Joi.string(),//.required(),
                zh: Joi.string(),//.required()
            }),
            options: Joi.array().items(
                Joi.object({
                    en: Joi.string(),//.required(),
                    zh: Joi.string().empty("")
                })
            )
        })
    ),
}).messages({
    "any.required": "Required",
    "object.base": "Required",
    "string.empty": "Required"
});

const Container = styled(Grid)`
    && {
        > * {
            padding-bottom: 18px;
        }
    }
`

const columns = ({ readOnly, control, name, errors }) => [
    // {
    //     field: 'productImage',
    //     headerName: 'ProductImage',
    //     width: 180,
    //     sortable: false,
    //     renderCell: (params) => {
    //         const index = params.api.getRowIndex(params.id)
    //         if (index === -1) return null
    //         return (
    //             <Controller
    //                 name={`${name}.variationProducts.${index}.images`}
    //                 control={control}
    //                 render={({ field }) => {
    //                     return (
    //                         <ComponentContainer
    //                             direction={"vertical"}
    //                             error={errors?.[index]?.images}
    //                             helperText={errors?.[index]?.images?.message}
    //                         >
    //                             <AddImageDialogWithButton
    //                                 {...field}
    //                                 rowData={params.row}
    //                                 readOnly={readOnly}
    //                                 title="Add Image"
    //                                 imgDesc="Recommended image size to be 760 x 760 pixel"
    //                             />
    //                         </ComponentContainer>
    //                     )
    //                 }}
    //             />
    //         )
    //     }
    // },
    {
        field: 'en',
        headerName: 'Variant (En)',
        width: 160,
        sortable: false,
    },
    {
        field: 'zh',
        headerName: 'Variant (Zh)',
        width: 160,
        sortable: false,
    },
    {
        field: 'discountPrice',
        headerName: 'Discount Price',
        width: 160,
        sortable: false,
        renderCell: (params) => {
            const index = params.api.getRowIndex(params.id)
            if (index === -1) return null
            return (
                <Controller
                    name={`${name}.variationProducts.${index}.sellingPrice`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <TextField
                                {...field}
                                InputProps={{
                                    startAdornment: <InputAdornment style={{ marginTop: 0 }} position="start">HKD</InputAdornment>,
                                }}
                                fullWidth
                                readOnly={readOnly}
                            // error={errors?.title?.en}
                            // helperText={errors?.title?.en?.message}
                            />
                        )
                    }}
                />
            )
        }
    },
    {
        field: 'originalPrice',
        headerName: 'Original Price*',
        width: 160,
        sortable: false,
        renderCell: (params) => {
            const index = params.api.getRowIndex(params.id)
            if (index === -1) return null
            return (
                <Controller
                    name={`${name}.variationProducts.${index}.markedPrice`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <TextField
                                {...field}

                                InputProps={{
                                    startAdornment: <InputAdornment style={{ marginTop: 0 }} position="start">HKD</InputAdornment>,
                                }}
                                fullWidth
                                readOnly={readOnly}
                                error={errors?.[index]?.markedPrice}
                                helperText={errors?.[index]?.markedPrice?.message}
                            />
                        )
                    }}
                />
            )
        }
    },
    {
        field: 'internalCode',
        headerName: 'Internal item code *',
        width: 160,
        sortable: false,
        renderCell: (params) => {
            const index = params.api.getRowIndex(params.id)
            if (index === -1) return null
            return (
                <Controller
                    name={`${name}.variationProducts.${index}.internalCode`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <TextField
                                {...field}
                                fullWidth
                                readOnly={readOnly}
                                error={errors?.[index]?.internalCode}
                                helperText={errors?.[index]?.internalCode?.message}
                            />
                        )
                    }}
                />
            )
        }
    },
    {
        field: 'sku',
        headerName: 'Product SKU *',
        width: 160,
        sortable: false,
        renderCell: (params) => {
            const index = params.api.getRowIndex(params.id)
            if (index === -1) return null
            return (
                <Controller
                    name={`${name}.variationProducts.${index}.productSku`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <TextField
                                {...field}
                                fullWidth
                                readOnly={readOnly}
                                error={errors?.[index]?.productSku}
                                helperText={errors?.[index]?.productSku?.message}
                            />
                        )
                    }}
                />
            )
        }
    },
    {
        field: 'limit',
        headerName: 'Purchase Limit Per User',
        width: 240,
        sortable: false,
        renderCell: (params) => {
            const index = params.api.getRowIndex(params.id)
            if (index === -1) return null
            return (
                <Controller
                    name={`${name}.variationProducts.${index}.purchaseLimitPerUser`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <TextField
                                {...field}
                                fullWidth
                                readOnly={readOnly}
                                error={errors?.[index]?.purchaseLimitPerUser}
                                helperText={errors?.[index]?.purchaseLimitPerUser?.message}
                            />
                        )
                    }}
                />
            )
        }
    },
    // { field: 'status', headerName: 'Status', width: 200, renderCell: ({ value }) => <StatusButton label={value} color={value} /> },
]

const VariationItem = ({ control, name, readOnly, errors, position }) => {

    const { fields = [], append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: `${name}.options`,
    });

    const optionsErrorMsg = errors?.options?.message

    return (
        <Container container direction='column'>
            <Typography variant="header">Variation {position + 1}</Typography>
            <Grid item container direction='row'>
                <Grid item xs>
                    <Controller
                        name={`${name}.title.en`}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                title="Title 1 (English)*"
                                direction="vertical"
                                fullWidth
                                readOnly={readOnly}
                                error={errors?.title?.en}
                                helperText={errors?.title?.en?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item style={{ width: 20, height: 1 }} />
                <Grid item xs>
                    <Controller
                        name={`${name}.title.zh`}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                title="Title 1 (Chinese)*"
                                direction="vertical"
                                fullWidth
                                readOnly={readOnly}
                                error={errors?.title?.zh}
                                helperText={errors?.title?.zh?.message}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            {
                fields.map((item, position2) => {
                    return (
                        <Grid item container direction='column' key={`v-${item.id}`} alignItems='flex-end' >
                            {!readOnly &&
                                <Grid item>
                                    <DeleteIcon style={{ cursor: "pointer" }} onClick={() => remove(position2)} />
                                </Grid>
                            }
                            <Grid item container direction='row' >
                                <Grid item xs>
                                    <Controller
                                        name={`${name}.options.${position2}.en`}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                key={item.id}
                                                title="Option (English)*"
                                                direction="vertical"
                                                fullWidth
                                                readOnly={readOnly}
                                                error={errors?.options?.[position2]?.en}
                                                helperText={errors?.options?.[position2]?.en?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item style={{ width: 20, height: 1 }} />
                                <Grid item xs>
                                    <Controller
                                        name={`${name}.options.${position2}.zh`}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                key={item.id}
                                                title="Option (Chinese)"
                                                direction="vertical"
                                                fullWidth
                                                readOnly={readOnly}
                                                error={errors?.options?.[position2]?.en}
                                                helperText={errors?.options?.[position2]?.en?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })
            }
            {!readOnly &&
                <Grid item>
                    <Button
                        variant="underline"
                        color="neon"
                        onClick={() => append({ en: "", zh: "" })}
                    >
                        + Add More Option
                    </Button>
                    {optionsErrorMsg && <FormHelperText error>{optionsErrorMsg}</FormHelperText>}
                </Grid>
            }
        </Container>
    )
}

function VariationTable({ openDialog, readOnly, control, errors, name, setValue, getValues }) {

    const [imageDialog, setImageDialog] = useState({ open: false })

    const { fields = [] } = useFieldArray({
        control,
        name: `${name}.options`
    });

    const onSelectImg = (v) => {
        console.log("onSelectImg", v)
    }

    const title1 = useWatch({
        control,
        name: `${name}.options.0.title`,
    })

    const title2 = useWatch({
        control,
        name: `${name}.options.1.title`,
    })

    const options1 = useWatch({
        control,
        name: `${name}.options.0.options`,
    });

    const options2 = useWatch({
        control,
        name: `${name}.options.1.options`,
    });

    const getCombinations = useMemo(() => {
        try {
            const savedVariations = getValues(`${name}.variationProducts`) || [];
            // console.log("savedVariations", savedVariations)
            let combinations = []
            if (!options2?.length) {
                combinations = options1.map((v, i) => {
                    const variation = savedVariations?.[i] || {}
                    return {
                        ...variation,
                        en: v.en,
                        zh: v.zh,
                        _id: v.en,
                        options: [
                            {
                                optionKey: title1?.en,
                                valueKey: v.en
                            }
                        ]
                    }
                })
            } else {
                let index = -1
                combinations = options1.flatMap((d, i) => {
                    return options2.map((v) => {
                        index++
                        const variation = savedVariations?.[index] || {}
                        return {
                            ...variation,
                            en: `${d.en},${v.en}`,
                            zh: `${d?.zh || ""},${v?.zh || ""}`,
                            _id: `${d.en}_${v?.en || ""}`,
                            options: [
                                {
                                    optionKey: title1?.en,
                                    valueKey: d.en
                                },
                                {
                                    optionKey: title2?.en,
                                    valueKey: v.en
                                }
                            ]
                        }
                    })
                });
            }

            setValue && setValue(`${name}.variationProducts`, combinations, { shouldDirty: true })
            return combinations
        } catch (error) {
            console.log("combinations-error", error)
            setValue && setValue(`${name}.variationProducts`, [], { shouldDirty: true })
            return []
        }
    }, [options1, options2, title1, title2, fields])

    const handleClickOpen = () => {
        setImageDialog({ open: true });
    };
    const handleClose = () => {
        setImageDialog({ open: false });
    };

    return (
        <Grid container direction='column' style={{ flexWrap: 'nowrap', }}>
            <Typography variant="header3" style={{ marginBottom: 20 }} >Fields marked * are required</Typography>
            {
                fields.map((item, position) => {
                    return (
                        <>
                            <Grid item>
                                <VariationItem
                                    control={control}
                                    name={`${name}.options.${position}`}
                                    readOnly={readOnly}
                                    errors={errors?.['options']?.[position]}
                                    position={position}
                                />
                            </Grid>
                            {!position && <GreyDivider style={{ marginBottom: 20 }} />}
                        </>
                    )
                })
            }


            <Grid item style={{ marginTop: 20 }}>
                <Typography variant="header" style={{ marginBottom: 20 }}>Variation List</Typography>
                <Typography variant="header">Total: {getCombinations.length}</Typography>
                <div style={{ padding: `20px 0px` }}>
                    <DataGrid
                        rows={getCombinations || []}
                        columns={columns({ readOnly: readOnly, control, name, errors: errors?.variationProducts })}
                        hideFooterSelectedRowCount
                        disableColumnMenu
                        rowSelection={false}
                        isRowSelectable={() => false}
                        getRowId={(row) => row._id}
                        rowCount={getCombinations.length || 0}
                        hideFooter
                        rowHeight={80}
                        sx={{
                            '.MuiDataGrid-columnSeparator': {
                                display: 'none',
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: 'mainColor.light',
                                borderBottom: 'none',
                                color: 'primary.main',
                                borderBottom: `1px solid #C9C9C9`,
                                borderRadius: 0
                            },
                            '& .MuiDataGrid-cell': {
                                borderBottom: `1px solid #C9C9C9`,
                                color: 'primary.main',
                                // height: 100
                            },
                            "& .MuiDataGrid-cell:focus-within": {
                                outline: "none !important",
                            },
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: 'transparent'
                            },
                            borderColor: "secondary.main",
                            borderRadius: 0,
                            height: 300
                        }}

                    // loading={isLoading}
                    />
                </div>
            </Grid>
            <ProductSliderGallery
                open={imageDialog.open}
                control={control}
                handleClose={handleClose}
            // setValue={setValue}
            />
        </Grid>
    )
}

export default VariationTable;
