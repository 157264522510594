import { Controller } from "react-hook-form";
import TextField from '../../components/share/TextField';
import Image from '../../components/share/ImgGrid/ImageV2';
import Typography from '../../components/share/Typography';
import InputAdornment from '@mui/material/InputAdornment'
import { ReactComponent as UploadIcon } from '../../assets/upload-background.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import Button from '../../components/share/Button'
import DynamicForm from '../../components/share/form/DynamicForm';
import Counter from '../../components/share/Counter'
import Grid from '@mui/material/Grid';
import ComponentContainer from '../../components/share/ComponentContainer'
import Select from '../../components/share/Select'
import AutoCompleteWithList from "../../components/share/AutocompleteWithList";
import HtmlEditor from '../../components/share/HtmlEditor';
import RecommendProduct from "../../components/RecommendProduct";
import DatePicker from '../../components/share/DatePicker';
import { DefPlans } from './add'

const Plans = [
    {
        id: "select-product",
        renderComponent: ({ control, error, position, name, openDialog, readOnly }) => (
            <Controller
                name={`${name}.${position}.product`}
                control={control}
                render={({ field }) => {
                    return (
                        <Select
                            title={`Select Product Name ${position + 1}`}
                            direction="vertical"
                            fullWidth
                            multiple
                            textFieldProps={{
                                placeholder: "Select..."
                            }}
                            api='/company'
                            value={field.value}
                            onChange={(value) => {
                                field.onChange(value)
                            }}
                            disabled={readOnly}
                            error={error?.product}
                            helperText={error?.product?.message}
                        />
                    )
                }}
            />
        )
    }, { id: 'spacing' },
    {
        id: "select-plan",
        renderComponent: ({ control, error, position, name, openDialog, readOnly }) => (
            <Controller
                name={`${name}.${position}.plan`}
                control={control}
                render={({ field }) => {
                    return (
                        <Select
                            title={`Select Plan ${position + 1}`}
                            direction="vertical"
                            fullWidth
                            multiple
                            textFieldProps={{
                                placeholder: "Select..."
                            }}
                            api='/company'
                            value={field.value}
                            onChange={(value) => {
                                field.onChange(value)
                            }}
                            disabled={readOnly}
                            error={error?.plan}
                            helperText={error?.plan?.message}
                        />
                    )
                }}
            />
        )
    }, { id: 'spacing' },
    // {
    //     id: "promotion-period",
    //     renderComponent: ({ control, error, position, name, readOnly }) => (
    //         <Controller
    //             name={`${name}.${position}.promotionPeriod`}
    //             control={control}
    //             render={({ field }) => {
    //                 return (
    //                     <TextField
    //                         {...field}
    //                         title="Promotion Period"
    //                         direction="vertical"
    //                         fullWidth
    //                         readOnly={readOnly}
    //                         error={error?.promotionPeriod}
    //                         helperText={error?.promotionPeriod?.message}
    //                     />
    //                 )
    //             }}
    //         />
    //     )
    // }, { id: 'spacing' },
    // {
    //     id: "quota",
    //     renderComponent: ({ control, error, position, name, readOnly }) => (
    //         <Controller
    //             name={`${name}.${position}.quota`}
    //             control={control}
    //             render={({ field }) => {
    //                 return (
    //                     <TextField
    //                         {...field}
    //                         title="Quota"
    //                         direction="vertical"
    //                         fullWidth
    //                         readOnly={readOnly}
    //                         error={error?.quota}
    //                         helperText={error?.quota?.message}
    //                     />
    //                 )
    //             }}
    //         />
    //     )
    // }, { id: 'spacing' },
]

const Fields = ({ control, errors = {}, readOnly }) => [
    {
        id: 'header',
        component: (
            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Fields marked * are required</Typography>
        )
    }, { id: "spacing" },
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Bundle-Plan (Combo offer)</Typography>
        )
    }, { id: "spacing" },
    {
        id: "offer-name-en",
        component: (
            <Controller
                name={`offerName.en`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Offer Name(English)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.offerName?.en}
                        helperText={errors?.offerName?.en?.message}
                    />
                )}
            />
        )
    },
    {
        id: "offer-name-zh",
        component: (
            <Controller
                name={`offerName.zh`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Offer Name(Chinese)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.offerName?.zh}
                        helperText={errors?.offerName?.zh?.message}
                    />
                )}
            />
        )
    },
    {
        id: "description-en",
        component: (
            <Controller
                name={`description.en`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Description(English)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.description?.en}
                        helperText={errors?.description?.en?.message}
                    />
                )}
            />
        )
    },
    {
        id: "description-zh",
        component: (
            <Controller
                name={`description.zh`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Description(Chinese)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.description?.zh}
                        helperText={errors?.description?.zh?.message}
                    />
                )}
            />
        )
    },
    {
        id: "start-date-start-time",
        component: (
            <Grid item container direction='row' spacing={1}>
                <Grid item xs>
                    <Controller
                        name={`startDate`}
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                {...field}
                                title="Start Date"
                                direction="vertical"
                                fullWidth
                                readOnly={readOnly}
                                error={errors?.startDate}
                                helperText={errors?.startDate?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs>
                    <Controller
                        name={`startTime`}
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                {...field}
                                title="Start Time"
                                type='time'
                                direction="vertical"
                                fullWidth
                                readOnly={readOnly}
                                error={errors?.startTime}
                                helperText={errors?.startTime?.message}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        )
    }, { id: "spacing" },
    {
        id: "end-date-end-time",
        component: (
            <Grid item container direction='row' spacing={1}>
                <Grid item xs>
                    <Controller
                        name={`endDate`}
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                {...field}
                                title="End Date"
                                direction="vertical"
                                fullWidth
                                readOnly={readOnly}
                                error={errors?.endDate}
                                helperText={errors?.endDate?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs>
                    <Controller
                        name={`endTime`}
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                {...field}
                                title="End Time"
                                type='time'
                                direction="vertical"
                                fullWidth
                                readOnly={readOnly}
                                error={errors?.endTime}
                                helperText={errors?.endTime?.message}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        )
    }, { id: "spacing" },
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Bundle-Plan</Typography>
        )
    }, { id: "spacing" },
    {
        id: "select-product",
        component: (
            <Controller
                name={`product`}
                control={control}
                render={({ field }) => (
                    <Select
                        title={`Select Product Name 1`}
                        direction="vertical"
                        fullWidth
                        multiple
                        textFieldProps={{
                            placeholder: "Select..."
                        }}
                        api='/company'
                        value={field.value}
                        onChange={(value) => {
                            field.onChange(value)
                        }}
                        disabled={readOnly}
                        error={errors?.product}
                        helperText={errors?.product?.message}
                    />
                )}
            />
        )
    }, , { id: "spacing" },
    {
        id: "select-plan",
        component: (
            <Controller
                name={`plan`}
                control={control}
                render={({ field }) => (
                    <Select
                        title={`Select Service Name 1`}
                        direction="vertical"
                        fullWidth
                        multiple
                        textFieldProps={{
                            placeholder: "Select..."
                        }}
                        api='/company'
                        value={field.value}
                        onChange={(value) => {
                            field.onChange(value)
                        }}
                        disabled={readOnly}
                        error={errors?.plan}
                        helperText={errors?.plan?.message}
                    />
                )}
            />
        )
    }, , { id: "spacing" },
    {
        id: "promotion-period",
        component: (
            <Controller
                name={`promotion-period`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Promotion Period"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.promotionPeriod}
                        helperText={errors?.promotionPeriod?.message}
                    />
                )}
            />
        )
    }, , { id: "spacing" },
    {
        id: "quota",
        component: (
            <Controller
                name={`quota`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Quota"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.quota}
                        helperText={errors?.quota?.message}
                    />
                )}
            />
        )
    }, , { id: "spacing" },
    {
        id: 'dynamicList',
        component: (
            <DynamicForm
                control={control}
                errors={errors?.["plans"]}
                name={`plans`}
                fieldComponents={Plans}
                style={{ padding: 0 }}
                readOnly={readOnly}
                addButtonLabel="+ Add More Plan"
                appendData={DefPlans}
            />
        )
    },
]

export { Fields }