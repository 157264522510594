import { useState, useContext, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import {
    useQuery,
    useMutation,
    useQueryClient
} from '@tanstack/react-query'
import _ from 'lodash'
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import PageWrapper from '../../components/share/PageWrapper';
import Typography from '../../components/share/Typography';
import Button from '../../components/share/Button';
import Select from '../../components/share/Select';
import TextField from '../../components/share/TextField';
import Form from '../../components/share/form/Form';
import GreyDivider from '../../components/GreyDivider';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import { fetchStock, updateStock } from '../../apis/stock';
import { useParams, useNavigate } from 'react-router-dom';
import { Fields, FieldsForView } from './formFields'
import { userContext } from '../../context/UserProvider';
import { getPermission } from '../../utils'

const SaveButton = styled(Button)`
    && {
        align-self: end; 
        width: 80px;
        margin: 10px 20px 14px 20px;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
    height: 100%;
    overflow: auto;
`

const schema = Joi.object({
    category: Joi.string(),
    internalCode: Joi.string(),
    productDescription: Joi.string().empty(""),
    totalQuantity: Joi.any(),
    holdingQuantity: Joi.any(),
    inStockQuantity: Joi.number().min(0),
    soldQuantity: Joi.any()
}).messages({
    "any.required": "Required",
    "object.base": "Required"
});

const getResetData = ({ data }) => {
    return {
        category: data?.category,
        internalCode: data?.internalCode,
        productDescription: data?.productDescription,
        totalQuantity: data?.totalQuantity,
        inStockQuantity: data?.inStockQuantity,
        holdingQuantity: data?.holdingQuantity,
        soldQuantity: data?.soldQuantity
    }
}

function StockDetail(){

    const navigate = useNavigate();

    let { stockId } = useParams();

    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [user, setUser] = useContext(userContext);

    const { canView, canModify } = getPermission(user?.permissions?.stock)

    const { control, handleSubmit, watch, formState: { errors, dirtyFields }, reset } = useForm({
        resolver: joiResolver(schema),
    });

    const { data, error, isError, isLoading } = useQuery({
        queryKey: ["stockDetail", { stockId }],
        queryFn: async () => await fetchStock(stockId)
    });

    useEffect(() => {
        if (!isLoading && data) {
            reset(getResetData({ data }))
        }
    }, [data])

    const mutation = useMutation({
        mutationFn: (payload) => updateStock(stockId, payload),
        onSuccess: (data) => {
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
            reset(getResetData({ data }))
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const handleOnSubmit = (formValue) => {
        let payload = {
            addInStock: formValue['inStockQuantity'],
            productDescription: formValue?.['productDescription'] || ""
        };
        console.log("payload", payload)
        mutation.mutate(payload)
    }

    console.log("errors", errors)

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Stock > ${data?._id}`}</Typography>
            </>
        )
    }

    const CardHeader = () => {
        return (
            <SaveButton disabled={!canModify} onClick={() => handleSubmit(handleOnSubmit)()}>Save</SaveButton>
        )
    }

    return (
        <PageWrapper
            loading={isLoading}
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            renderCardHeader={CardHeader}
        >
            <Content>
                <Form fields={FieldsForView({ control, errors, readOnly: !canModify })} p={`14px 0px`} />
            </Content>
        </PageWrapper>
    )
}

export default StockDetail