import { Autocomplete, Checkbox, InputAdornment, IconButton, TextField, Box, Grid, } from "@mui/material";
import ComponentContainer from "./share/ComponentContainer";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import { CustomTextField, EndAdorment } from './Select'
// import { TextField, Box, IconButton } from "@mui/material";
import { styled as styledMui } from '@mui/material/styles';
import styled from 'styled-components'
import Typography from './share/Typography'
import CustomTextField from './share/TextField'
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import { request, axios } from '../apis'
import constants from '../constants';
import { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { ReactComponent as DeleteIcon } from '../assets/delete-icon.svg';
import _ from "lodash"
import CircularProgress from '@mui/material/CircularProgress';
import { closestCenter, DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import DragHandleIcon from '@mui/icons-material/DragHandle';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Underline = styled.span`
    && {
        text-decoration: underline;
        margin-left: 10px;
    }
`

const Container = styled.div`
    > * {
        padding-top: 10px;
    }
`

const SelectedList = (props) => {
    const { selected=[], onRemove, getOptionLabel, onPickSelectedItem, onSortSelectedItem } = props;

    const itemIds = useMemo(() => selected.map((item) => item._id), [selected]);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: { distance: 5 },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragStart = useCallback((event) => {}, [])

    const handleDragEnd = useCallback((event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const oldIndex = itemIds.indexOf(active.id);
            const newIndex = itemIds.indexOf(over.id);
            const sortedArray = arrayMove(selected, oldIndex, newIndex);
            onSortSelectedItem && onSortSelectedItem(sortedArray);
        }
    }, [itemIds, onSortSelectedItem, selected])

    const handleDragCancel = () => {}

    const SortableItem = (props) => {
        const { item, index } = props;

        const display = getOptionLabel(item)
        const isRecommended = item?.isRecommended

        const {
            attributes,
            listeners,
            setNodeRef,
            transform,
            transition,
        } = useSortable({
            id: item._id,
        });

        const style = {
            transform: CSS.Transform.toString(transform),
            transition,
        }

        return (
            <Grid
                item
                xs={12}
                key={item?._id}
                ref={setNodeRef}
                style={{
                    ...style,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '10px',
                }}
            >
                <Grid item {...attributes} {...listeners}>
                    <DragHandleIcon style={{ cursor: "grab", color: "grey" }} />
                </Grid> 
                <Grid item xs>
                    <Typography color="#BB9202">
                        <Underline>{display}</Underline>
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton sx={{ marginLeft: '4px' }} onClick={() => onRemove(item)}>
                        <DeleteIcon  />
                    </IconButton>
                    { onPickSelectedItem && (
                        <Checkbox
                            checked={isRecommended}
                            onChange={(event, value) => onPickSelectedItem && onPickSelectedItem(item, value)} 
                        />
                    )}
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container flexDirection={"column"}>
            <DndContext
                sensors={sensors}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
                collisionDetection={closestCenter}
                modifiers={[restrictToFirstScrollableAncestor]}
            >
                <SortableContext
                    items={itemIds}
                    strategy={verticalListSortingStrategy}
                >
                    { selected.map((item, i) => (
                        <SortableItem
                            item={item}
                            index={i}
                        />
                    ))}
                </SortableContext>
            </DndContext>
        </Grid>
    )
}

function VasAutoCompleteWithListAndOptionCheckbox({
    direction,
    title,
    titleStyle,
    error,
    helperText,

    api,
    apiParams,

    options,
    onError,
    readOnly,
    getOptionLabel,
    value = [],
    onChange,

    pickedSelectedItems=[],
    onPickSelectedItem,
    onSortSelectedItem,
}) {

    const [selected, setSelected] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const { data: response, isLoading, refetch } = useQuery({
        queryKey: [api, { params: { ...apiParams, keyword: inputValue } }],
        queryFn: () => axios.get(`${constants.API_ENDPOINT}${api}`, {
            params: {
                ...apiParams,
                keyword: inputValue !== "" ? inputValue : undefined
            }
        }).then((res) => res.data),
        retry: false,
        enabled: api ? true : false,
        onError: (error) => {
            onError && onError(error)
        },
    })

    useEffect(() => {
        if (value && value.length > 0) {
            setSelected(value)
        }
    }, [value])

    const onRemove = (v) => {
        const tmpArr = [...value]
        const index = _.findIndex(value, {_id: v._id})
        if (index > -1) {
            tmpArr.splice(index, 1);
            setSelected(tmpArr)
            onChange && onChange(tmpArr)
        }
    }

    const handleOnChange = () => {
        onChange && onChange(selected)
    }

    const renderReadOnly = () => {
        if (api && isLoading) {
            return <CircularProgress size={20} />
        }
        if (!selected || !selected?.length) {
            return (
                <CustomTextField 
                    readOnly
                    value={""}
                />
            )
        }
        return (
            <>
                { selected.map((item, i) => {
                    const display = getOptionLabel(item)
                    const isRecommended = item?.isRecommended
                    return (
                        <Box display='flex' flexDirection='row' alignItems='center' justifyContent={"space-between"} key={`${display}-${i}`}>
                            <Typography variant="secondary">
                                {"+"}
                                <Underline>{display}</Underline>
                            </Typography>

                            { onPickSelectedItem && (<Checkbox checked={isRecommended} disabled />) }
                        </Box>
                    )
                })}
            </>
        )
    }

    return (
        <ComponentContainer title={title} titleStyle={titleStyle} error={error} helperText={helperText} direction={direction}>
            {!readOnly &&
                <Autocomplete
                    multiple
                    value={selected}
                    onChange={(e, newValue, reason) => {
                        setSelected(newValue)
                    }}
                    onInputChange={(e, newValue) => {
                        setInputValue(newValue)
                        api && refetch()
                    }}
                    onClose={handleOnChange}
                    loading={isLoading}
                    options={response?.data || options || []}
                    forcePopupIcon={false}
                    getOptionLabel={getOptionLabel}
                    disableCloseOnSelect
                    selectOnFocus={false}
                    disableClearable
                    renderTags={() => null}
                    disabled={readOnly}
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    renderOption={(props, option, { selected }) => {
                        const display = getOptionLabel(option)
                        return (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {display}
                            </li>
                        )
                    }}
                    style={{ minWidth: 300 }}
                    sx={{
                        "& .MuiAutocomplete-endAdornment": {
                            marginRight: 0.5
                        },
                        backgroundColor: "#f6f6f6",
                        borderRadius: 5
                    }}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder="Select..."
                                sx={{
                                    height: 43,
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    style: {
                                        ...params.inputProps.style,
                                        padding: `9px 10px`,
                                    }
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    style: {
                                        ...params.InputProps.style,
                                        backgroundColor: "#f6f6f6",
                                        borderRadius: 5
                                    },
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            sx={{
                                                height: '100%',
                                                width: 40,
                                                borderLeft: 'solid',
                                                borderWidth: 1,
                                                borderColor: 'primary.dark',
                                                display: 'flex',
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginLeft: 0
                                            }}
                                        >
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onMouseDown={params?.inputProps?.onMouseDown}
                                                edge="end"
                                                sx={{
                                                    marginRight: 0,
                                                    color: 'primary.dark'
                                                }}
                                            >
                                                <KeyboardArrowDownIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )
                    }}
                />
            }
            <Container>
                { readOnly 
                    ? renderReadOnly() 
                    : (
                        <SelectedList 
                            selected={selected}
                            onRemove={onRemove}
                            getOptionLabel={getOptionLabel}
                            onPickSelectedItem={onPickSelectedItem}
                            onSortSelectedItem={onSortSelectedItem}
                        />
                    )
                }
            </Container>
        </ComponentContainer>
    )
}

export default VasAutoCompleteWithListAndOptionCheckbox