import styled from "styled-components";
import { Box, Grid } from "@mui/material";
import { ComponentContainer, TextField, Typography } from ".";
import { DeleteActionIcon } from "./Table";
import { LocalizationProvider, PickersDay, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const DATE_FORMAT = 'YYYY-MM-DD';

const Underline = styled.span`
    && {
        text-decoration: underline;
        margin-left: 10px;
    }
`

/**
 * Sorts an array of dates in ascending order.
 *
 * @param {Date[]} dates - The array of dates to be sorted.
 * @returns {Date[]} - The sorted array of dates.
 */
const getSortedDates = (dates) => {
    return dates.sort((a, b) => {
        return dayjs(a).isBefore(b) ? -1 : 1;
    });
}

const SelectedDateItem = ({ date, onDelete }) => {
    return (
        <Box display='flex' flexDirection='row' alignItems='center' justifyContent={"flex-start"} pl={2} gap={5}>
            <Typography color="#BB9202">
                {`+`}
                <Underline>{date}</Underline>
            </Typography>
            <DeleteActionIcon onClick={onDelete} />
        </Box>
    )
}

const MultipleDatePicker = (props) => {
    const {
        value: dates=[],
        onChange,
        error,
        readOnly,
        helperText,
        title = "Select Dates",
    } = props;

    const handleDeleteDate = (date) => {
        onChange && onChange(dates.filter((selectedDate) => selectedDate !== date));
    }

    const handleSelectDate = (date) => {
        const formattedDate = dayjs(date).format(DATE_FORMAT);
        const index = dates.findIndex((date) => date === formattedDate);
        if (index === -1) {
            onChange && onChange(getSortedDates([...dates, formattedDate]));
        } else {
            handleDeleteDate(formattedDate);
        }
    }

    const findDate = (dates, date) => {
        const formattedDate = dayjs(date).format(DATE_FORMAT);
        return dates.find((item) => item === formattedDate);
    };

    const renderPickerDay = (date, selectedDates, pickersDayProps) => {
        const selected = findDate(dates, date);

        return (
            <PickersDay
                {...pickersDayProps}
                // disableMargin
                selected={selected}
            />
        );
    };

    return (
        <ComponentContainer direction="vertical" title={title} {...props}>
            <Grid container item xs={12} gap={2} >
                <Grid item xs>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
                        <StaticDatePicker
                            displayStaticWrapperAs="desktop"
                            dates={dates}
                            onChange={(newValue) => handleSelectDate(newValue)}
                            renderDay={renderPickerDay}
                            disabled={readOnly}
                            sx={{
                                // no padding
                            }}
                        />
                    </LocalizationProvider>
                </Grid>

                {/* Selected date */}
                <Grid container item xs flexDirection='column' justifyContent={"flex-start"}>
                    <Typography variant="header3" style={{ marginBottom: "10px" }}>Selected Dates</Typography>
                    <div style={{ overflow: "auto", maxHeight: "300px" }}>
                        {dates.length > 0 ? dates.map((date, index) => (
                            <SelectedDateItem
                                key={date}
                                date={date}
                                onDelete={() => handleDeleteDate(date)}
                            />
                        )) : (
                            <Typography color="secondary">
                                {`No date selected`}
                            </Typography>
                        )}
                    </div>
                </Grid>
            </Grid>
        </ComponentContainer>
    )
}

export default MultipleDatePicker;