import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import constants from '../constants';

let isRefreshing = false;
let failedQueue = [];

const getApiUrl = (url) => {
    return `${constants.API_ENDPOINT}${url}`
}

const getCMSLogoutUrl = () => {
    return getApiUrl('/user/adminLogout')
}

const getCMSLoginUrl = () => {
    return getApiUrl('/user/adminLogin')
}

const getCMSRefreshTokenUrl = () => {
    return getApiUrl('/user/adminToken')
}

const axiosPublic = axios.create({

});

function getAccessToken() {
    return localStorage.getItem("access_token");
    // return localStorage.getItem("id_token");
}

function getClientId() {
    const loginType = localStorage.getItem("login_type");
    return loginType === "dev" ? constants.CLIENT_ID_FOR_ZENSIS : constants.CLIENT_ID;
}

function getRefreshToken() {
    return localStorage.getItem("refresh_token");
}

axiosPublic.interceptors.request.use(request => {
    request.headers['Authorization'] = `Bearer ${getAccessToken()}`;
    return request;
});

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })

    failedQueue = [];
}

axiosPublic.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    if ((error.response.status === 403 || error.response.status === 401) && !originalRequest._retry) {
        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({ resolve, reject })
            }).then(token => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                return axios(originalRequest);
            }).catch(err => {
                return Promise.reject(err);
            })
        }
        originalRequest._retry = true;
        isRefreshing = true;

        // const clientId = getClientId();
        const token = getRefreshToken();

        return new Promise((resolve, reject) => {
            const params = new URLSearchParams();
            params.append('refresh_token', token);
            const requestUrl = `${getCMSRefreshTokenUrl()}`

            axios.post(
                requestUrl,
                params
            )
                .then(({ data }) => {
                    console.log("refreshResponse", data.data)
                    const accessToken = data.data.accessToken
                    const refreshToken = data.data.refreshToken
                    // localStorage.setItem('access_token', data.access_token)
                    localStorage.setItem('access_token', accessToken)
                    // localStorage.setItem('id_token', data.id_token)
                    localStorage.setItem('refresh_token', refreshToken)
                    axiosPublic.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
                    originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;
                    processQueue(null, accessToken);
                    resolve(axiosPublic(originalRequest));
                })
                .catch((error) => {
                    console.log("refreshResponse-error", error)
                    // processQueue(error, null);
                    // reject(error);
                    localStorage.removeItem('access_token')
                    localStorage.removeItem('refresh_token')
                    localStorage.removeItem('id_token')
                    window.location.assign('/auth/admin-login-redirect')
                })
                .finally(() => { isRefreshing = false })
        })
    }
    return Promise.reject(error);
});

const request = (param) => {
    return new Promise((resolve, reject) => {
        return axiosPublic({
            ...param
        }).then((res) => {
            resolve(res.data || res)
        })
        .catch((error) => {
            console.log('debug', error);
            reject(error?.response?.data || error)
            // if (error.response.status === 401 || error.response.status === 403) {
            //     localStorage.removeItem('access_token')
            //     localStorage.removeItem('refresh_token')
            //     window.location.reload();
            // } else {
            //     reject(error.response.data)
            // }
        });
    })
}

const fetchToken = (client_id, redirect_url, code, sessionState, codeChallenge) => {
    return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append('grant_type', 'authorization_code');
        params.append('client_id', client_id);
        params.append('redirect_uri', `${redirect_url}`);
        params.append('code', code);
        params.append('code_verifier', codeChallenge)

        
        const callbackEndpoint = `${getCMSLoginUrl()}/callback?code=${code}&sessionState=${sessionState}&code_verifier=${codeChallenge}&redirect_uri=${redirect_url}`

        return axios.get(
            callbackEndpoint,
            params
        )
            .then(res => {
                console.log("api res success", res?.data)
                resolve(res.data)
                // console.log(res?.data?.id_token)
                // console.log(res?.data?.access_token)
                // setIdToken(res?.data?.id_token)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

const fetchTokenForInternal = (client_id, redirect_url, code, sessionState, codeChallenge) => {
    return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append('grant_type', 'authorization_code');
        params.append('client_id', client_id);
        params.append('redirect_uri', `${redirect_url}`);
        params.append('sessionState', sessionState);
        params.append('code', code);
        params.append('code_verifier', codeChallenge)

        const callbackEndpoint = `${getCMSLoginUrl()}/callback?dev=1&code=${code}&sessionState=${sessionState}&code_verifier=${codeChallenge}&redirect_uri=${redirect_url}`

        return axios.get(
            callbackEndpoint,
            params
        )
            .then(res => {
                console.log("api res success", res?.data)
                resolve(res.data)
                // console.log(res?.data?.id_token)
                // console.log(res?.data?.access_token)
                // setIdToken(res?.data?.id_token)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

const getPresignedUrl = (fileType) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "GET",
            url: `${constants.API_BASE_ENDPOINT}/presignedUrl/uploadToTemp/public/${fileType}`
        })
            .then(res => {
                resolve(res)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

async function file2Buffer(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        const readFile = function (event) {
            const buffer = reader.result;
            resolve(buffer);
        };

        reader.addEventListener('load', readFile);
        reader.readAsArrayBuffer(file);
    });
}


const uploadImgToS3 = async (uploadUrl, file) => {

    const data = await file2Buffer(file);
    return new Promise((resolve, reject) => {
        return axios.request({
            method: "PUT",
            url: uploadUrl,
            data: data,
            headers: {
                "Content-Type": "",
            },
        })
            .then(res => {
                resolve(res)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
};


export {
    request,
    axiosPublic as axios,
    fetchToken, fetchTokenForInternal,
    getPresignedUrl,
    uploadImgToS3,

    getApiUrl,
    getCMSLogoutUrl,
    getCMSLoginUrl,
    getCMSRefreshTokenUrl,
}