import { useForm, Controller, useFieldArray } from "react-hook-form";
import _ from 'lodash';
import Typography from '../Typography';
import Button from '../Button';
import Select from '../Select';
import GreyDivider from '../../GreyDivider';
import TextField from '../TextField';
import Grid from '@mui/material/Grid';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';


function DynamicForm({ name = "dynamicFields", fieldComponents, addButtonLabel, control, errors, appendData, style, itemProps, readOnly, max }) {

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: name,
    });

    const canDelete = fields.length > 1 && !readOnly
    const canAdd = max ? fields.length < max : true

    return (
        <Grid container columns={2} spacing={2} style={{ padding: 20, ...style }}>
            {
                fields.map((item, position) => {
                    return fieldComponents.map((layoutItem, layoutPosition) => {
                        if (layoutItem.id === "spacing") return <Grid item xs={1} key={item.id} />
                        if (layoutItem.id == "delete") return (
                            <Grid item xs={layoutItem.xs === undefined ? 2 : layoutItem.xs} key={item.id}>
                                {
                                    layoutItem?.renderComponent ? 
                                        layoutItem?.renderComponent({ control, name, canDelete: canDelete, onClick: () => remove(position) }) :
                                        <DeleteIcon style={{ curson: "pointer" }} onClick={() => remove(position)} />
                                }
                            </Grid>
                        )
                        if (layoutItem.id === 'hr') return (
                            <Grid item xs={2}>
                                <GreyDivider />
                            </Grid>
                        )

                        return (
                            <>
                                <Grid item xs={layoutItem.xs || 1} key={item.id}>
                                    {layoutItem?.renderComponent({ control, position, name, error: errors?.[position], readOnly, remove, canDelete, ...itemProps })}
                                </Grid>
                            </>
                        )
                    })
                })
            }
            {!readOnly && addButtonLabel &&
            <Grid item xs={2}>
                <Button 
                    variant={"underline"}
                    color="neon"
                    style={{ display: 'inline-block' }} 
                    onClick={() => canAdd && append(appendData)}
                    disabled={!canAdd}
                >
                    {addButtonLabel}
                </Button>
            </Grid>}
        </Grid>
    )
}

export default DynamicForm;