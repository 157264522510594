import { useState, useContext, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import {
    useQuery,
    useMutation,
} from '@tanstack/react-query'
import _ from 'lodash'
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import PageWrapper from '../../components/share/PageWrapper';
import Typography from '../../components/share/Typography';
import Button from '../../components/share/Button';
import Select from '../../components/share/Select';
import GreyDivider from '../../components/GreyDivider';
import TextField from '../../components/share/TextField';
import DatePicker from '../../components/share/DatePicker';
import Form from '../../components/share/form/Form';
import Grid from '@mui/material/Grid';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import { listCategory, createTnc } from '../../apis/termsAndConditions';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import DynamicForm from '../../components/share/form/DynamicForm'
import { Fields } from './formFields'

const SaveButton = styled(Button)`
    && {
        align-self: end; 
        width: 80px;
        margin: 10px 20px;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
    overflow: auto;
`

const margin = 10;

const schema = Joi.object({
    offerName: Joi.object({
        en: Joi.string().empty(""),
        zh: Joi.string().empty(""),
    }),
    description: Joi.object({
        en: Joi.string().empty(""),
        zh: Joi.string().empty(""),
    }),
    startDate: Joi.string(),
    endDate: Joi.string(),
    product1: Joi.any(),
    product2: Joi.any()
}).messages({
    "any.required": "Required",
    "object.base": "Required"
});

export const DefPlans = [
    {
        product: {},
        plan: {},
    }
]

function AddBundle(props) {

    // let { categoryId } = useParams();
    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useContext(snackbarContext);

    const { control, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: joiResolver(schema),
        // defaultValues: {
        //     bundlePlans: BundlePlanField
        // }
    });

    const handleOnSubmit = (formValue) => {

    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Bundle > Add New`}</Typography>
            </>
        )
    }

    const CardHeader = () => {
        return (
            <SaveButton 
                onClick={() => {
                    // console.log("getValues", getValues())
                    handleSubmit(handleOnSubmit)();
                }}
            >
                Save
            </SaveButton>
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            renderCardHeader={CardHeader}
        >
            <Content>
                <Form fields={Fields({ control, errors })} p={`14px 0px`} />
            </Content>
        </PageWrapper>
    )
}

export default AddBundle