import { useState, useContext, useCallback, useMemo, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import _ from 'lodash'
import { useForm } from "react-hook-form";
import { Form } from '../../components/share';
import { CircularProgress, Box, Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
    DocFields,
} from './formFields';
import { getDirtyValues } from '../../utils'
import PDFViewer from '../../components/share/PDFViewer';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

const getResetData = ({ data }) => {
    const getStructuredData = ({ value=undefined, valueUrl=undefined }) => ({ value, valueUrl });

    return {
        mnpDownloadForm: {
            ...getStructuredData({..._.find(data, { keyPath: "doc_upload.mnpDownloadForm" })})
        },
        // changeOwnershipForm: {
        //     ...getStructuredData({..._.find(data, { keyPath: "doc_upload.changeOwnershipForm" })})
        // },
    }
}

const docCommonSchema = Joi.object({
    value: Joi.string().required().allow(null).optional(),
    valueUrl: Joi.string().required().allow(null).optional(),
});

const docSchema = Joi.object({
    mnpDownloadForm: docCommonSchema,
    // changeOwnershipForm: docCommonSchema,
})

const DocConfiguration = forwardRef((props, ref) => {
    const { data, isLoading, setSnackbar, permission, onSubmit } = props;

    const [previewDialog, setPreviewDialog] = useState({});

    const { control, handleSubmit, watch, formState: { errors, dirtyFields }, reset, setValue, getValues } = useForm({
        resolver: joiResolver(docSchema),
    });

    useEffect(() => {
        if (data) {
            reset(getResetData({ data: data }))
        }
    }, [data])

    const handleOnSubmit = (formValue) => {
        if (_.isEmpty(dirtyFields)) {
            return setSnackbar({
                type: "success",
                message: "Success"
            })
        }
        const dirty = getDirtyValues(dirtyFields, formValue);
        const payload = {};
        if (dirty?.mnpDownloadForm) {
            payload.mnpDownloadForm = dirty.mnpDownloadForm?.value || null;
        }
        if (dirty?.changeOwnershipForm) {
            payload.changeOwnershipForm = dirty.changeOwnershipForm?.value || null;
        }

        onSubmit && onSubmit(payload);
    }

    const handlePreview = (file) => {
        setPreviewDialog({
            open: true,
            data: file
        });
    }

    const handleClosePreviewDialog = () => {
        setPreviewDialog({});
    }

    useImperativeHandle(ref, () => ({
        onSubmit: () => handleSubmit(handleOnSubmit)()
    }));

    if (isLoading) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" height="100％">
            </Box>
        )
    }

    return (
        <>
        <Form fields={DocFields({ control, errors: errors, readOnly: permission.canModify ? false : true, onPreview: handlePreview })} p={`14px 0px`} />
        { previewDialog?.open && (
            <Dialog
                open={previewDialog?.open}
                onClose={handleClosePreviewDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                fullWidth
                PaperProps={{
                    sx: {
                        minHeight: '80%',
                    }
                }}
            >
                <DialogTitle style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <CloseIcon fontSize={"large"} onClick={handleClosePreviewDialog} style={{ cursor: "pointer" }} />
                </DialogTitle>
                <DialogContent style={{ display: "flex", }}>
                    <PDFViewer data={previewDialog?.data} /> 
                </DialogContent>
            </Dialog>
        )}
        </>
    )
})

export default DocConfiguration