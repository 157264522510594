import { alertDialogContext } from '../context/AlertDialogProvider';
import { useContext, forwardRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog() {
    const [dialog, setDialog] = useContext(alertDialogContext);

    const handleClose = () => {
        setDialog({ open: false });
        dialog && dialog.handleOnClickCancel && dialog.handleOnClickCancel()
    };

    const onClickOK = () => {
        dialog && dialog.handleOnClickOK && dialog.handleOnClickOK()
        handleClose()
    }

    return (
        <Dialog
            open={dialog?.open || false}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">
                    {dialog?.title}
                </DialogTitle>
            { dialog?.message && (
                <DialogContent>
                    <DialogContentText>
                        {dialog?.message}
                    </DialogContentText>
                </DialogContent>
            )}
            <DialogActions>
                <Button onClick={handleClose}>{dialog?.cancelText || "Cancel"}</Button>
                <Button onClick={onClickOK} autoFocus>
                    {dialog?.actionText || "OK"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}