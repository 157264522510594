import { request } from './index';
import constants from '../constants';

// const listCategory = () => {
//     return new Promise((resolve, reject) => {

//         return request({
//             method: "get",
//             url: `${constants.API_ENDPOINT}/tnc/category`
//         })
//             .then(res => {
//                 resolve(res.data)
//             })
//             .catch((error) => {
//                 reject(error.toJSON())
//             })
//     })
// }

const fetchStock = (id) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "get",
            url: `${constants.API_ENDPOINT}/stock/${id}`
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const createStock = (payload) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "post",
            url: `${constants.API_ENDPOINT}/stock`,
            data: payload
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const updateStock = (id, payload) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "put",
            url: `${constants.API_ENDPOINT}/stock/${id}`,
            data: payload
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

export {
    createStock,
    updateStock,
    fetchStock
}
