import dayjs from 'dayjs';
import { useMemo, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UnderlineText } from '../../components/share/Table';
import { StatusButton } from '../../components/share';
import constants from '../../constants';
import OrderItemsComponent from './allOrderItems';
import OrdersComponent from './allOrders';
import { useEffect, useLayoutEffect } from 'react'
import { userContext } from '../../context/UserProvider';
import { getPermission } from '../../utils'
import { updateOrderStatus } from '../../apis/order'
import {
    useMutation,
} from '@tanstack/react-query'
import { snackbarContext } from '../../context/SnackbarProvider';

const margin = 10

export const STATUS = [
    {
        name: "All",
        _id: null
    },
    {
        name: "Payment Success",
        _id: "paymentSuccess",
    },
    {
        name: "Payment Failure",
        _id: "paymentFailure",
    },
    {
        name: "Pending Document",
        _id: "pendingDocument",
    },
    // {
    //     name: "Submitted",
    //     _id: "submitted",
    // },
    {
        name: "Submitted / Document Submitted",
        _id: "documentSubmitted",
    },
    {
        name: "Verified",
        _id: "verified",
    },
    {
        name: "Cancelled",
        _id: "cancelled",
    },
    {
        name: "Completed",
        _id: "completed",
    },
]

export const columns = ({ navigateTo, canModify, canView, type, statusMutation }) => [
    { field: type === "order" ? 'orderNumber' : "subOrderNumber", headerName: type === "order" ? 'Order No.' : 'Sub-Order No.', width: 250 },
    { field: 'orderDate', headerName: 'Order Date', width: 160, valueGetter: (params) => params.value ? dayjs(params.value).format(constants.DATE_TIME_FORMAT) : "" },
    {
        field: 'status',
        headerName: type === "order" ? 'Status' : 'Internal Status',
        width: 200,
        headerAlign: 'flex-start',
        sortable: false,
        renderCell: (params) => {
            const { id, api, value, row } = params

            const onChange = (newStatusValue) => {
                statusMutation.mutate(
                    {
                        id,
                        payload: { status: newStatusValue }
                    },
                    {
                        onSuccess: () => {
                            api.updateRows([{ id: id, ...row, status: newStatusValue }])
                        },
                        onError: () => {
                            api.updateRows([{ id: id, ...row, status: "" }])  // To force row reset status value at next line
                            api.updateRows([{ id: id, ...row, status: value }])
                        }
                    }
                )
            }

            return (
                <StatusButton
                    value={params.value}
                    hideIcon
                    onChange={onChange}
                    readOnly={!canModify}
                    options={[
                        {
                            name: "Payment Success",
                            id: "paymentSuccess",
                            color: "#5D4986",
                            canEdit: type === "order" ? true : false,
                            // canSelect: true,
                        },
                        {
                            name: "Payment Failure",
                            id: "paymentFailure",
                            color: "#9B9B9B",
                            canEdit: type === "order" ? true : false,
                            // canSelect: true,
                        },
                        {
                            name: "Pending Document",
                            id: "pendingDocument",
                            color: "#5D4986",
                            canEdit: type === "order" ? true : false,
                            // canSelect: true,
                        },
                        {
                            name: params.row.hasDocumentSubmission ? "Document Submitted" : "Submitted",
                            id: "documentSubmitted",
                            color: "#9A482E",
                            canEdit: type === "order" ? true : false,
                            canSelect: type === "order" ? true : false,
                        },
                        {
                            name: "Verified",
                            id: "verified",
                            color: "#9A482E",
                            canEdit: type === "order" ? true : false,
                            canSelect: type === "order" ? true : false,
                        },
                        {
                            name: "Cancelled",
                            id: "cancelled",
                            color: "#f5f5f5",
                            textColor: "#9B9B9B",
                            canEdit: false,
                            canSelect: type === "order" ? true : false,
                        },
                        {
                            name: "Completed",
                            id: "completed",
                            color: "#306097",
                            canEdit: type === "order" ? true : false,
                            canSelect: type === "order" ? true : false,
                        },
                    ]}
                />
            )
        }
    },
    {
        field: 'name',
        headerName: 'Product Name',
        width: 160,
        hide: type === 'order',
        sortable: false
    },
    {
        field: 'customerName',
        headerName: 'Customer Name',
        width: 160,
        hide: type === 'orderItems',
        valueGetter: (params) => `${params?.row?.contactUserInfo?.fullName?.en || ""}`,
        sortable: false,
    },
    { field: 'deliveryMethod', headerName: 'Delivery Method', width: 160, hide: type === 'orderItems', sortable: false, },
    { field: 'lastEditedAt', headerName: 'Last Edit Date', width: 160, sortable: false, valueGetter: (params) => params.value ? dayjs(params.value).format(constants.DATE_TIME_FORMAT) : "" },
    { field: 'lastEditedBy', headerName: 'Last Edit By', width: 160, sortable: false, },
    {
        field: 'actions',
        headerName: 'Action',
        type: 'actions',
        width: 80,
        headerAlign: 'flex-start',
        getActions: (params) => {
            return [
                <UnderlineText disabled={!canView} sx={{ color: 'primary.main' }} onClick={() => navigateTo(params.row._id, params.row.orderNumber)}>View</UnderlineText>,
            ]
        }
    }
]

function OrderPage() {
    const navigate = useNavigate();
    const [user, setUser] = useContext(userContext);
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const orderPermission = getPermission(user?.permissions?.order)
    const orderItemPermission = getPermission(user?.permissions?.orderItem)
    const rspReportPermission = getPermission(user?.permissions?.report)
    let [searchParams, setSearchParams] = useSearchParams();

    const type = searchParams.get('type');

    const statusMutation = useMutation({
        mutationFn: (payload) => updateOrderStatus(payload.id, payload.payload),
        onSuccess: (data, variables) => {
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
        },
        onError: (error, variables) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const getOrderColumns = useMemo(() => {
        return columns({
            navigateTo: (id, orderNumber) => navigate(`/order?type=orderItems&tab=all&orderId=${id}&orderNumber=${orderNumber}`),
            canModify: orderPermission.canModify,
            canView: orderItemPermission.canView,
            type: 'order',
            statusMutation
        })
    }, [orderPermission])

    const getOrderItemColumns = useMemo(() => {
        return columns({
            navigateTo: (id) => navigate(`/order/orderItems/${id}`),
            canModify: orderItemPermission.canModify,
            canView: orderItemPermission.canView,
            type: 'orderItems'
        })
    }, [orderItemPermission])

    useEffect(() => {
        if (!type) {
            navigate('/order?type=orders')
        }
    }, [type])

    if (type === 'orders') {
        return <OrdersComponent columns={getOrderColumns} orderPermission={orderPermission} reportPermission={rspReportPermission} />
    }

    return <OrderItemsComponent columns={getOrderItemColumns} permission={orderItemPermission} />
}

export default OrderPage