import Typography from './Typography'
import { Grid, FormHelperText } from "@mui/material";

function ComponentContainer({ title, titleStyle, direction, children, error, helperText, align = 'center', style, childrenContainerStyle = {} }) {

    if (direction === "vertical") {
        return (
            <Grid container direction="column" style={style} >
                {title && <Typography variant="header1" header style={titleStyle}>{title}</Typography>}
                <Grid item sx={{ width: 'inherit', ...childrenContainerStyle }}>
                    {children}
                </Grid>
                {error && helperText && 
                    <Grid item><FormHelperText error>{helperText}</FormHelperText></Grid>
                }
            </Grid>
        )
    }

    return (
        <Grid container direction="row" alignItems="center" justifyContent={align} style={style}>
            {title && <Typography variant="header1" header style={titleStyle}>{title}</Typography>}
            <Grid item>
                {children}
            </Grid>
            {error && helperText && <Grid item xs={12}><FormHelperText error>{helperText}</FormHelperText></Grid>}
        </Grid>
    )
}

export default ComponentContainer