import _ from 'lodash'

function isDev() {
    return process.env.REACT_APP_IS_DEVELOPMENT || false;
}

function zeroPad(num, places) {
    return (
        String(num).padStart(places, '0')
    )
}

const findKey = (obj, val) => {
    if (typeof obj === "object") {
        for (const key in obj) {
            if (obj[key] === val) {
                return key;
            } else {
                const result = findKey(obj[key], val);
                if (result !== null) return result;
            }
        }
    }

    return null;
};

function isDirtyArray(v) {
    if (Array.isArray(v) && v.length <= 0) return false;
    let isDirty = false;
    _.forEach(v, (item) => {
        if (typeof item === "object") {
            const result = findKey(item, true);
            if (result) isDirty = true
        } else {
            if (item === true) {
                isDirty = true;
            }
        }
    })
    return isDirty
}

function getDirtyValues(dirtyFields, allValues) {
    const dirtyValues = Object.keys(dirtyFields).reduce((prev, key) => {
        if (Array.isArray(dirtyFields[key]) && !isDirtyArray(dirtyFields[key])) {
            return prev;
        }
        if (!dirtyFields[key]) return prev;
        return {
            ...prev,
            [key]:
                typeof dirtyFields[key] === 'object' && !Array.isArray(dirtyFields[key])
                    ? getDirtyValues(dirtyFields[key], allValues[key])
                    : allValues[key],
        };
    }, {});

    return dirtyValues;
};

function removeEmptyElements(object) {
    Object
        .entries(object)
        .forEach(([k, v]) => {
            if (v && typeof v === 'object') {
                removeEmptyElements(v);
            }
            if (v && typeof v === 'object' && !Object.keys(v).length || v === null || v === undefined) {
                if (Array.isArray(object)) {
                    object.splice(k, 1);
                } else {
                    delete object[k];
                }
            }
        });
    return object;
}

function removeId(array) {
    if (!array.length) return []
    return array.map(({ _id, ...others }) => ({ ...others }))
}

function toIds(array) {
    if (!array || !array?.length || !Array.isArray(array)) return []
    return array.map(({ _id, ...others }) => _id)
}

function getPermission(arr) {
    if (!arr) return {
        canView: true,
        canModify: true,
    }
    if (!arr.length) return {
        canView: false,
        canModify: false,
    }
    const canView = _.includes(arr, 'r');
    const canModify = _.includes(arr, 'w');
    return {
        canView,
        canModify
    }
}
function toDisplayName(name) {
    if (!name) return ""
    let tmp = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    return tmp.replace("_", " ")
}

function getPIIDataKeys(keys, data) {
    let tmp = []
    _.forEach(keys, (item) => {
        const v = _.get(data, item)
        if (v === undefined) return
        tmp.push(item)
    })
    return tmp
}

function exportCSV(data, fileName="CSVFile") {
    const url = window.URL.createObjectURL(new Blob(["\uFEFF"+data], { type: 'text/csv; charset=utf-8' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export {
    isDev,
    zeroPad,
    getDirtyValues,
    removeEmptyElements,
    removeId,
    toIds,
    isDirtyArray,
    getPermission,
    toDisplayName,
    getPIIDataKeys,
    exportCSV,
}