import { useContext, forwardRef, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DatePicker from './DatePicker';
import Select from './Select';
import Typography from './Typography'
import constants from '../../constants';
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import Button from './Button'
import dayjs from 'dayjs';

const Content = styled(Grid)`
    && {
        > *:not(:last-child) {
            margin-bottom: 20px;
        }
    }
`

export default function FilterDialog({ open, onClose, filters = [], value, onChange }) {

    const [filter, setFilter] = useState({})

    useEffect(() => {
        if (value) {
            setFilter(value)
        }
    }, [value])

    const handleOnApply = () => {
        onChange && onChange(filter)
        onClose && onClose()
    }

    const handleOnClear = () => {
        setFilter({})
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
        >

            <DialogContent>
                <DialogTitle id="alert-dialog-title" style={{ padding: `16px 0px`, position: 'relative' }}>
                    <Typography variant="header">Filter</Typography>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            color: '#000',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Content container direction="column" columns={1}>
                    {
                        filters.map(({ type, options, title, api, dataKey }) => {
                            if (type === 'date-range') {
                                return (
                                    <Grid item xs container direction='row' alignItems='center'>
                                        <Grid item xs>
                                            <DatePicker 
                                                title="Start Date" 
                                                fullWidth 
                                                value={filter.dateFrom}
                                                onChange={(v) => setFilter({ ...filter, dateFrom: v ? dayjs(v).toISOString() : undefined })} 
                                            />
                                        </Grid>
                                        <Grid item style={{ margin: `30px 20px 0px 20px` }}>
                                            <Typography>To</Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <DatePicker 
                                                title="End Date" 
                                                fullWidth 
                                                value={filter.dateTo}
                                                onChange={(v) => setFilter({ ...filter, dateTo: v ? dayjs(v).toISOString() : undefined })} 
                                            />
                                        </Grid>
                                    </Grid>
                                )
                            } else if (type === 'select') {
                                return (
                                    <Grid item xs>
                                        <Select
                                            title={title}
                                            options={options}
                                            api={api}
                                            direction='vertical'
                                            fullWidth
                                            value={filter?.[dataKey]}
                                            onChange={(v) => setFilter({ ...filter, [dataKey]: v })}
                                        />
                                    </Grid>
                                )
                            }
                        })
                    }
                </Content>
            </DialogContent>
            <DialogActions style={{ margin: "0px 14px 20px", display: "flex", justifyContent: "space-between" }}>
                <Button onClick={handleOnClear} type={"outlined"}>Clear</Button>
                <Button onClick={handleOnApply}>Apply</Button>
            </DialogActions>
        </Dialog>
    );
}