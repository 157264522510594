import { useState, useContext, useMemo, useRef, useEffect } from 'react';
import {
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Grid } from '@mui/material';
import { snackbarContext } from '../../context/SnackbarProvider';
import { alertDialogContext } from '../../context/AlertDialogProvider';
import { userContext } from '../../context/UserProvider';
import { companiesContext } from '../../context/CompaniesProvider';
import withTabSearchParams from '../../hoc/withTabSearchParams'
import {
    Typography,
    Button,
    Tab,
    PageWrapper,
    TableStatusFilter,
    Searchbar,
    StatusButton,
    UploadComponent,
    FilterButton
} from '../../components/share'
import Table, { UnderlineText, DeleteActionIcon } from '../../components/share/Table';
import constants from '../../constants';
import { deletePlan, updatePlan, importNumber, disableNumber, fetchSortingPlan, updateSortingPlan } from '../../apis/plan';
import { getPermission } from '../../utils'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PlanSortableList from '../../components/mobile/PlanSortableList';

const margin = 20

const TABS = [
    {
        name: 'Mobile Service Plans',
        id: 'mobile-service-plans'
    },
    {
        name: 'Number Pool',
        id: "number-pool"
    },
    {
        name: 'Reordering',
        id: 'reordering'
    }
]

const NUMBER_COL_STATUS = [
    {   
        name: "All",
        _id: undefined,
    },
    {   
        name: "Available",
        _id: 'available'
    },
    {
        name: "Used",
        _id: 'used'
    },
    {   
        name: "On Hold",
        _id: 'onHold'
    },
    {
        name: "Pending Payment",
        _id: 'pendingPayment'
    },
    {
        name: "Unavailable",
        _id: 'unavailable'
    },
]

const STATUS = [
    {
        name: "All",
        _id: null
    },
    {
        name: "Active",
        _id: "active",
    },
    {
        name: "Suspended",
        _id: "suspended",
    },
    {
        name: "Expired",
        _id: "expired",
    },
    {
        name: "Draft",
        _id: "draft",
    },
]

const MobileServicePlanColumns = ({ onDelete, navigateToPlanDetail, navigateToClonePlan, mutation, canModify, canView }) => [
    { field: 'planId', headerName: 'PlanID', width: 100, sortable: false, },
    { field: 'planName.en', headerName: 'Plan Name(English)', width: 160, valueGetter: (params) => params.row?.planName?.en },
    { field: 'planName.zh', headerName: 'Plan Name(Chinese)', width: 160, valueGetter: (params) => params.row?.planName?.zh },
    { field: 'company.name', headerName: 'Company Name', sortable: false, width: 160, valueGetter: (params) => params.row?.company?.name },
    { field: 'publishStartDate', headerName: 'Start Date & Time', width: 160, valueGetter: (params) => params.value ? dayjs(params.value).format(constants.DATE_TIME_FORMAT) : "" },
    { field: 'publishEndDate', headerName: 'End Date & Time', width: 160, valueGetter: (params) => params.value ? dayjs(params.value).format(constants.DATE_TIME_FORMAT) : "" },
    { field: 'originalPrice', headerName: 'Original Price', width: 140, },
    { field: 'discountedPrice', headerName: 'Discounted Price', width: 140 },
    {
        field: 'status',
        headerName: 'Status',
        width: 130,
        sortable: false,
        type: 'actions',
        renderCell: (params) => {
            const { id, api, value, row } = params

            const onChange = (newStatusValue) => {
                // api.updateRows([{ id: id, ...row, status: newStatusValue }])
                // onUpdateStatus && onUpdateStatus(id, newStatusValue)
                mutation.mutate(
                    {
                        id,
                        payload: { status: newStatusValue }
                    },
                    {
                        onSuccess: () => {
                            api.updateRows([{ id: id, ...row, status: newStatusValue }])
                        },
                        onError: () => {
                            api.updateRows([{ id: id, ...row }])
                        }
                    }
                )
            }

            return (
                <StatusButton
                    value={value}
                    onChange={onChange}
                    readOnly={!canModify}
                    options={[
                        {
                            name: "Draft",
                            id: "draft",
                            color: "#4BA28D",
                            Icon: (<EditNoteIcon />)
                        },
                        {
                            name: "Pending",
                            id: "pending",
                            color: "#5D4986",
                            Icon: (<CheckCircleOutlineIcon />)
                        },
                        {
                            name: "Active",
                            id: "active",
                            // id: "published",
                            color: "#306097",
                            canEdit: true,
                            Icon: (<CheckCircleOutlineIcon />)
                        },
                        {
                            name: "Suspended",
                            id: "suspended",
                            color: "#f6f6f6",
                            textColor: "#9B9B9B",
                            canSelect: true,
                            Icon: (<RemoveCircleOutlineIcon />)
                        },
                        {
                            name: "Expired",
                            id: "expired",
                            color: "#8D9A66",
                            Icon: (<CheckCircleOutlineIcon />)
                        },
                    ]}
                />
            )
        }
    },
    {
        field: 'actions',
        headerName: 'Action',
        type: 'actions',
        width: 140,
        headerAlign: 'flex-start',
        getActions: (params) => {
            const canDelete = params?.row?.status === 'active' ? false : true
            return [
                <UnderlineText sx={{ color: 'primary.main' }} disabled={!canView} onClick={() => navigateToPlanDetail(params.row._id)}>View</UnderlineText>,
                <UnderlineText sx={{ color: 'primary.main' }} disabled={!canModify} onClick={() => navigateToClonePlan(params.row._id)}>Clone</UnderlineText>,
                <DeleteActionIcon disabled={!canModify} hide={!canDelete} onClick={() => onDelete(params.row._id)} />
            ]
        }
    }
]

const NumberPoolColumns = ({ onDelete, canModify }) => [
    {
        field: 'number',
        headerName: 'Number',
        flex: 1
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 150,
        align: 'left',
        renderCell: (params) => {
            const item = NUMBER_COL_STATUS.find((item) => item._id === params.value)
            return (
                <div>{item?.name}</div>
            )
        }
    },
    {
        field: 'actions',
        headerName: 'Action',
        type: 'actions',
        width: 80,
        headerAlign: 'flex-start',
        getActions: (params) => {
            const isDisabled = !canModify || params.row.status !== 'available'
            return [
                <DeleteActionIcon disabled={isDisabled} onClick={() => onDelete(params.row._id)} />
            ]
        }
    }
]

function MobilePage({ updateTab, tabIndex }) {

    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [dialog, setDialog] = useContext(alertDialogContext);
    const [user, setUser] = useContext(userContext);
    const [companies, setCompanies, VIPCompanies] = useContext(companiesContext);

    const { canView, canModify } = getPermission(user?.permissions?.plan)
    const numPoolPermission = getPermission(user?.permissions?.numberPool)

    const queryClient = useQueryClient()

    const [filter, setFilter] = useState({
        keyword: "",
        status: null
    })

    const reorderingTableRef = useRef(null);

    const isPlanList = tabIndex === 0;
    const isNumberPoolList = tabIndex === 1;
    const isReordering = tabIndex === 2;

    const { 
        data: sortingData,
        error: sortingError,
        isError: isSortingError,
        isLoading: isSortingLoading,
        refetch: refetchSorting,
    } = useQuery({
        queryKey: ["getSortingPlan", { company: filter?.company || undefined }],
        queryFn: async () => await fetchSortingPlan({ company: filter?.company || undefined }),
        enable: canView && isReordering,
    });

    useEffect(() => {
        if (isReordering) {
            refetchSorting()
        }
    }, [canView, isReordering])

    const onClickTab = (index) => {
        updateTab(TABS[index], index)
        setFilter({})
    }

    const deleteMutation = useMutation({
        mutationFn: (id) => deletePlan(id),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['/plan'] });
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const statusMutation = useMutation({
        mutationFn: (payload) => updatePlan(payload.id, payload.payload),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["plan"] })
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const disableNumberMutation = useMutation({
        mutationFn: (id) => disableNumber(id),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['/numberPool'] });
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const importNumberMutation = useMutation({
        mutationFn: (payload) => importNumber(payload),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['/numberPool'] });
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const reorderMutation = useMutation({
        mutationFn: (payload) => updateSortingPlan({payload}),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["getSortingPlan"] })
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
        },
        onError: (error, variables) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const hadnleOnDelete = (id) => {
        if (isNumberPoolList) {
            setDialog({
                open: true,
                title: "Are you sure to delete this number?",
                handleOnClickOK: () => disableNumberMutation.mutate(id)
            })
        } else {
            setDialog({
                open: true,
                title: "Are you sure to delete this plan?",
                handleOnClickOK: () => deleteMutation.mutate(id)
            })
        }
    }

    const navigateToPlanDetail = (id) => {
        navigate(`/mobile/${id}`)
    }

    const navigateToClonePlan = (id) => {
        navigate(`/mobile/add?planId=${id}`)
    }

    const columns = useMemo(() => {
        if (isNumberPoolList) return NumberPoolColumns({ onDelete: hadnleOnDelete, canModify: numPoolPermission.canModify })
        return MobileServicePlanColumns({ onDelete: hadnleOnDelete, navigateToPlanDetail, navigateToClonePlan, mutation: statusMutation, canModify, canView })
    }, [tabIndex])

    const onApiError = (error) => {
        setSnackbar({
            type: 'error',
            message: `${error?.response?.data?.result?.errorMessage?.en || error?.message}`
        })
    }

    const handleUploadNumberFile = async (file) => {
        // check extension to accept .csv only
        if (!file.name.endsWith('.csv')) {
            return setSnackbar({
                type: 'error',
                message: 'Support .csv file only.'
            })
        }

        importNumberMutation.mutate(file)
    }

    const handleOnSubmitReordering = (formValue) => {
        const payload = {
            sorting: formValue.plan.map((item) => item?._id),
        }

        reorderMutation.mutate(payload)
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }} >{`Mobile > ${TABS[tabIndex].name}`}</Typography>
                {canView && !tabIndex &&
                    <FilterButton
                        style={{ marginRight: margin }}
                        filters={[
                            {
                                type: 'date-range',
                                startDataKey: 'startDate',
                                endDataKey: 'endDate',
                            },
                            {
                                type: 'select',
                                dataKey: 'status',
                                options: STATUS
                            }
                        ]}
                        value={filter}
                        disabled={!canView}
                        onChange={(newFilterValue) => setFilter({ ...newFilterValue })}
                    />
                }
            </>
        )
    }

    const CardHeader = () => {
        return (
            <Tab
                tabs={TABS}
                selected={tabIndex}
                onChange={onClickTab}
                renderButton={() => {
                    if (!canModify) return null
                    if (isPlanList) {
                        return (
                            <Button
                                style={{
                                    alignSelf: 'flex-end',
                                    margin: `0px 20px 14px 20px`,
                                }}
                                type="add"
                                onClick={() => navigate(`/mobile/add`)}
                            >
                                Add New
                            </Button>
                        )
                    }
                    if (isReordering) {
                        return (
                            <Button
                                style={{
                                    alignSelf: 'flex-end',
                                    margin: `0px 20px 14px 20px`,
                                }}
                                onClick={() => reorderingTableRef?.current && reorderingTableRef.current?.handleSubmit(handleOnSubmitReordering)()}
                            >
                                Save
                            </Button>
                        )
                    }
                }}
            />
        )
    }

    const tabFilter = useMemo(() => {
        if (isReordering) {
            return {
                key: 'company',
                options: [{ _id: undefined, name: "All" }, ...(VIPCompanies || [])],
                title: "Company"
            }
        }

        return {
            key: 'status',
            options: isPlanList ? STATUS : NUMBER_COL_STATUS,
        }
    }, [tabIndex])

    return (
        <PageWrapper
            renderHeader={Header}
            renderCustomCardHeader={CardHeader}
        >

            <Grid container direction='row' alignItems='center' style={{ paddingLeft: 10 }}>
                <TableStatusFilter 
                    title={tabFilter.title}
                    options={tabFilter.options}
                    value={filter?.[tabFilter.key]} 
                    disabled={!canView} 
                    onChange={(newValue) => setFilter({ ...filter, [tabFilter.key]: newValue })}
                    style={{ margin: "16px 0" }} 
                />
                { !isReordering && (<Searchbar value={filter.keyword} disabled={!canView} onChange={(e) => setFilter({ ...filter, keyword: e.target.value })} />) }
                {/* <Checkbox label="Select" fullWidth={false} checked={selectMode} onChange={(event) => setSelectMode(event.target.checked)} /> */}
                <Grid item xs />
                {isNumberPoolList && numPoolPermission.canModify ?
                    <UploadComponent
                        renderComponent={({ selectFile, isLoading }) => (
                            <Button
                                color="blue"
                                style={{ marginRight: margin, minWidth: 167 }}
                                onClick={selectFile}
                                loading={isLoading}
                            >
                                Import Number Pool
                            </Button>
                        )}
                        onUpload={handleUploadNumberFile}
                    />
                    : null
                }
            </Grid>
            {
                isReordering ? (
                    <PlanSortableList
                        ref={reorderingTableRef}
                        data={sortingData}
                        defaultColumn={columns}
                        statusMutation={statusMutation}
                        canView={canView}
                        canModify={canModify}
                        navigateToPlanDetail={navigateToPlanDetail}
                        navigateToClonePlan={navigateToClonePlan}
                        handleOnDeletePlan={hadnleOnDelete}
                        onSuccessChangeStatus={() => reorderMutation.reset()}
                        onFailChangeStatus={(error) => reorderMutation.reset()}
                    />
                ) : (
                    <Table
                        style={{ margin: `0px 20px 20px 20px` }}
                        columns={columns}
                        api={tabIndex ? "/numberPool" : "/plan"}
                        apiParams={{
                            ...filter
                        }}
                        sortingMode="server"
                        onError={onApiError}
                        setSnackbar={setSnackbar}
                    />
                )
            }
        </PageWrapper>
    )

}

export default withTabSearchParams(MobilePage, TABS);