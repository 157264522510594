import { useState, useEffect, createContext } from "react";
import { listCompany } from '../apis/company'
import {
    useQuery,
} from '@tanstack/react-query'

export const adminRoleContext = createContext();
function AdminRoleProvider({ children }){
    const [roles, setRoles] = useState(null);

    return (
        <adminRoleContext.Provider value={[roles, setRoles]}>
            {children}
        </adminRoleContext.Provider>
    )
}

export default AdminRoleProvider