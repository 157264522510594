import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Button from './Button';

const ConfirmStatusChangeDialog = (props) => {
    const {
        open,
        onClose,
        onCancel,
        onSubmit,
        originIndex,
        selectedIndex,
        options,
    } = props;

    const originOption = options?.[originIndex] || null;
    const selectedOption = options?.[selectedIndex] || null;

    const handleCancel = () => {
        onCancel && onCancel();
        onClose && onClose();
    }

    const handleSubmit = () => {
        onSubmit && onSubmit(selectedOption?.id);
        onClose && onClose();
    }

    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>{"Confirm the change of status"}</DialogTitle>
            <DialogContent>
                {`Are you sure to change the status from "${originOption?.name}" to "${selectedOption?.name}"?`}
            </DialogContent>
            <DialogActions>
                <Button type="outlined" autoFocus onClick={handleCancel}>{"Cancel"}</Button>
                <Button onClick={handleSubmit}>{"Confirm"}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmStatusChangeDialog;