import { request } from './index';
import constants from '../constants';

const fetchUser = () => {
    return new Promise((resolve, reject) => {

        return request({
            method: "get",
            url: `${constants.API_ENDPOINT}/user/me`
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

export {
    fetchUser
}