import Select from './share/Select';
import { Grid, Autocomplete, TextField, InputAdornment, IconButton } from '@mui/material';
import constants from '../constants';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import { request, axios } from '../apis'
import { useState, useContext, useEffect } from 'react';
import GreyDivider from './GreyDivider';
import SnackbarProvider, { snackbarContext } from '../context/SnackbarProvider';
import {
    ComponentContainer
} from '../components/share'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';

const categoryOptions = [
    {
        name: "Plan",
        _id: "plan",
    },
    {
        name: "Product",
        _id: "product",
    },
]
function RecommendProduct({ readOnly, error, value, onChange, position }) {

    const [category, setCategory] = useState();
    const [snackbar, setSnackbar] = useContext(snackbarContext);

    const [keyword, setKeyword] = useState("");

    useEffect(() => {
        if (value?.category) {
            setCategory(value.category)
        }
    }, [value?.category])

    const { data, error: apiError, isError, isLoading } = useQuery({
        queryKey: ['recommendProduct', category, keyword],
        queryFn: () => axios.get(`${constants.API_ENDPOINT}/${category}?quickSearch=1${keyword ? `&keyword=${keyword}` : ""}`).then((res) => res.data),
        retry: false,
        enabled: category ? true : false,
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        },
    })

    const clearValue = () => {
        setKeyword("")
        onChange && onChange({
            category: category,
        })
    }

    return (
        <Grid container direction='column'>
            {position ?
                <Grid item style={{ margin: `20px 0px` }}>
                    <GreyDivider />
                </Grid>
                : null
            }
            <Grid item style={{ marginBottom: 20 }}>
                <Select
                    title="Select Product Type"
                    direction="vertical"
                    fullWidth
                    textFieldProps={{
                        placeholder: "Select..."
                    }}
                    options={categoryOptions}
                    value={category}
                    onChange={(newValue) => {
                        setCategory(newValue)
                        onChange && onChange({})
                        setKeyword("")
                    }}
                    disabled={readOnly}
                // error={error}
                // helperText={error?.message}
                />
            </Grid>
            <Grid item>
                <ComponentContainer title="Select Product Name" direction="vertical">
                    <Autocomplete
                        value={value?._id ? value : null}
                        onChange={(e, newValue, reason) => {
                            // if (!newValue?._id) return
                            const v = newValue?._id ? newValue : undefined
                            onChange && onChange({
                                category: category,
                                ...v
                            })
                        }}
                        inputValue={keyword}
                        onInputChange={(e, value, reason) => {
                            setKeyword(value)
                        }}
                        disableClearable
                        loading={keyword && isLoading}
                        options={data?.data || []}
                        forcePopupIcon={false}
                        getOptionLabel={(option) => {
                            if (category === 'plan') {
                                return `${option?.planName?.en} ${option?.planName?.zh}`
                            }
                            return `${option?.productName?.en} ${option?.productName?.zh}`
                        }}
                        renderTags={() => null}
                        disabled={readOnly}
                        isOptionEqualToValue={(option, value) => {
                            return option?._id === value?._id
                        }}
                        style={{ minWidth: 300 }}
                        sx={{
                            backgroundColor: "#f6f6f6",
                            borderRadius: 5,
                        }}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    fullWidth
                                    variant="standard"
                                    placeholder="Select..."
                                    sx={{
                                        height: 43,
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        style: {
                                            ...params.inputProps.style,
                                            padding: `9px 10px`,
                                        }
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                        style: {
                                            ...params.InputProps.style,
                                            backgroundColor: "#f6f6f6",
                                            borderRadius: 5
                                        },
                                        endAdornment: (
                                            <>
                                                {!readOnly && keyword &&
                                                <InputAdornment
                                                    position="end"
                                                    sx={{
                                                        paddingRight: 2
                                                    }}
                                                >
                                                    <IconButton size="small" onClick={clearValue}>
                                                        <CloseIcon
                                                            sx={{ color: "secondary.main" }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>}
                                                <InputAdornment
                                                    position="end"
                                                    sx={{
                                                        height: '100%',
                                                        width: 40,
                                                        borderLeft: 'solid',
                                                        borderWidth: 0.5,
                                                        borderColor: "#333333",
                                                        display: 'flex',
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        marginLeft: 0
                                                    }}
                                                >
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        disabled={readOnly}
                                                        disableRipple
                                                        onMouseDown={params?.inputProps?.onMouseDown}
                                                        edge="end"
                                                        sx={{
                                                            marginRight: 0,
                                                            color: 'primary.dark'
                                                        }}
                                                    >
                                                        <KeyboardArrowDownIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            </>
                                        )
                                    }}
                                />
                            )
                        }}
                    />
                </ComponentContainer>
            </Grid>
        </Grid>
    )
}

export default RecommendProduct