import { useState, useContext, useCallback, useMemo, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import _, { isUndefined } from 'lodash'
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Form } from '../../components/share';
import {
    TncFields
} from './formFields';
import { getDirtyValues, toIds } from '../../utils'
import { CircularProgress, Box } from '@mui/material';
import { tncContext } from '../../context/TncProvider';

const getResetData = ({ data }) => {
    return {
        prePaid: _.find(data, { keyPath: "tnc.prePaid" })?.value?._id,
        postPaid: _.find(data, { keyPath: "tnc.postPaid" })?.value?._id,
        savePersonalInformation: _.find(data, { keyPath: "tnc.savePersonalInformation" })?.value?._id,
        // directSaleThisService & directSaleMyClubHKT are the same (ref: hkt-eshop/followup#90)
        directSale: _.find(data, { keyPath: "tnc.directSaleThisServices" })?.value?._id,
        freeRemovalServices: _.find(data, { keyPath: "tnc.freeRemovalServices" })?.value?._id,
        planBeforeBuy: _.find(data, { keyPath: "tnc.planBeforeBuy" })?.value,
    }
}

const TncSystemConfiguration = forwardRef((props, ref) => {
    const { data, isLoading, setSnackbar, permission, onSubmit } = props;

    const [tncList] = useContext(tncContext);

    const { control, handleSubmit, watch, formState: { errors, dirtyFields }, reset, getValues, setValue } = useForm({
        // resolver: joiResolver(schema),
    });

    useEffect(() => {
        if (data) {
            reset(getResetData({ data: data }))
        }
    }, [data])

    const handleOnSubmit = (formValue) => {
        if (_.isEmpty(dirtyFields)) {
            return setSnackbar({
                type: "success",
                message: "Success"
            })
        }
        const dirty = getDirtyValues(dirtyFields, formValue);
        
        if (dirty.planBeforeBuy) {
            dirty.planBeforeBuy = toIds(dirty.planBeforeBuy)
        }

        if (!isUndefined(dirty.directSale)) {
            dirty.directSaleThisServices = dirty.directSale
            dirty.directSaleMyClubHKT = dirty.directSale
            delete dirty.directSale
        }

        onSubmit && onSubmit(dirty)
    }

    useImperativeHandle(ref, () => ({
        onSubmit: () => handleSubmit(handleOnSubmit)()
    }));

    if (isLoading) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" height="100％">
            </Box>
        )
    }

    return (
        <Form fields={TncFields({ control, errors: errors, readOnly: permission.canModify ? false : true, tncList })} p={`14px 0px`} />
    )
})

export default TncSystemConfiguration