import styled from 'styled-components';
import { Grid } from '@mui/material'
import Typography from './Typography'
import { styled as styledMui } from '@mui/material/styles';

const Container = styled(Grid)`
    && {
        height: 70px;
        align-items: center;
        padding: 0px 30px;
        
        > * {
            margin-right: 20px;
        }
    }
`

const Label = styledMui(Typography)(
    ({ theme, selected, error }) => `
        cursor: pinter;
        color: ${theme.palette.secondary.main};
        ${selected ? `color: ${theme.palette.mainColor.main};` : ""}
        ${error ? `color: ${theme.palette.error.main};` : ""}
  `,
);

function Tab2({ tabs = [], selected, onChange }){

    const handleOnClick = (id, index) => {
        onChange && onChange(id, index)
    }
    
    return (
        <Container container direction='row' sx={{ backgroundColor: "mainColor.light" }}>
            {
                tabs.map((item, i) => {
                    const isSelected = selected === item.id
                    return (
                        <Grid item style={{ cursor: "pointer" }} onClick={() => handleOnClick(item.id, i)}>
                            <Label selected={isSelected}>{item.label}</Label>
                        </Grid>
                    )
                })
            }
        </Container>
    )
}

export default Tab2