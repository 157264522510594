import { Controller } from "react-hook-form";
import TextField from '../../components/share/TextField';
import Typography from '../../components/share/Typography';
import Select from '../../components/share/Select'


const Fields = ({ control, readOnly, errors }) => [
    {
        id: 'header',
        component: (
            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Fields marked * are required</Typography>
        )
    }, { id: "spacing" },
    {
        id: "category",
        component: (
            <Controller
                name={`category`}
                control={control}
                render={({ field }) => (
                    <Select
                        title="Category*"
                        direction="vertical"
                        fullWidth
                        textFieldProps={{
                            placeholder: "Select..."
                        }}
                        options={[
                            {
                                name: "Apple",
                                _id: 'apple'
                            },
                            {
                                name: "Android",
                                _id: 'android'
                            },
                            {
                                name: "Accessories & Gaming",
                                _id: 'accessoriesGaming'
                            },
                            {
                                name: "Home & lifestyle",
                                _id: 'homeLifestyle'
                            },
                        ]}
                        value={field.value}
                        onChange={(value) => {
                            field.onChange(value)
                        }}
                        disabled={readOnly}
                        error={errors?.category}
                        helperText={errors?.category?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "internal-item-code",
        component: (
            <Controller
                name="internalCode"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Internal Item Code*"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.internalCode}
                        helperText={errors?.internalCode?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "product-description",
        component: (
            <Controller
                name="productDescription"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Production Description"
                        direction="vertical"
                        fullWidth
                        multiline
                        readOnly={readOnly}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "available-quantity",
        component: (
            <Controller
                name="availableQty"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Available Quantity*"
                        direction="vertical"
                        fullWidth
                        error={errors?.availableQty}
                        helperText={errors?.availableQty?.message}
                        readOnly={readOnly}
                    />
                )}
            />
        )
    }, { id: "spacing" },
]

const FieldsForView = ({ control, readOnly, errors }) => [
    {
        id: 'header',
        component: (
            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Fields marked * are required</Typography>
        )
    }, { id: "spacing" },
    {
        id: "category",
        component: (
            <Controller
                name={`category`}
                control={control}
                render={({ field }) => (
                    <Select
                        title="Category"
                        direction="vertical"
                        fullWidth
                        textFieldProps={{
                            placeholder: "Select..."
                        }}
                        options={[
                            {
                                name: "Apple",
                                _id: 'apple'
                            },
                            {
                                name: "Android",
                                _id: 'android'
                            },
                            {
                                name: "Accessories & Gaming",
                                _id: 'accessoriesGaming'
                            },
                            {
                                name: "Home & lifestyle",
                                _id: 'homeLifestyle'
                            },
                        ]}
                        value={field.value}
                        onChange={(value) => {
                            field.onChange(value)
                        }}
                        disabled
                        error={errors?.category}
                        helperText={errors?.category?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "internal-item-code",
        component: (
            <Controller
                name="internalCode"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Internal Item Code"
                        direction="vertical"
                        fullWidth
                        readOnly
                        error={errors?.internalCode}
                        helperText={errors?.internalCode?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "product-description",
        component: (
            <Controller
                name="productDescription"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Production Description"
                        direction="vertical"
                        fullWidth
                        multiline
                        readOnly={readOnly}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "total-quantity",
        component: (
            <Controller
                name="totalQuantity"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Total Quantity"
                        direction="vertical"
                        fullWidth
                        readOnly
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "instock",
        component: (
            <Controller
                name="inStockQuantity"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="In-Stock*"
                        direction="vertical"
                        fullWidth
                        error={errors?.inStockQuantity}
                        helperText={errors?.inStockQuantity?.message}
                        readOnly={readOnly}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "holding",
        component: (
            <Controller
                name="holdingQuantity"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Holding"
                        direction="vertical"
                        fullWidth
                        readOnly
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "sold",
        component: (
            <Controller
                name="soldQuantity"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Sold"
                        direction="vertical"
                        fullWidth
                        readOnly
                    />
                )}
            />
        )
    }, { id: "spacing" },
]

export {
    Fields,
    FieldsForView
}