import { useState, useLayoutEffect, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { drawerItems } from "../components/Drawer";
import Drawer from '../components/Drawer';

function Home() {
    const navigate = useNavigate();
    const drawerRef = useRef({});

    useEffect(() => {
        if (navigate) navigate(`${drawerRef.current.getDrawerItems[0].path}`)
    }, [navigate, drawerRef.current ])

    return (<div style={{ opacity: 0, display: 'none', "pointer-events": "none" }} ><Drawer ref={drawerRef} /></div>)
}

export default Home;