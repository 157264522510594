import OrangeTab from "../OrangeTab"
import styled from 'styled-components';
import { Grid, Box } from '@mui/material';
import { useState, useRef } from 'react';
import Form from '../share/form/Form'
import {
    GeneralFields
} from '../../pages/Product/formFields';
import VariationTable from "./VariationTable";
import GallarySlider from './GallarySlider'
import Typography from '../share/Typography'
import Counter from '../share/Counter';
import { Controller } from "react-hook-form";
import HtmlEditor from "../share/HtmlEditor";
import { styled as styledMui } from '@mui/material/styles';

const TABS = [
    {
        name: "Variations",
        id: "variation"
    },
    {
        name: "Product Details",
        id: "productDetails"
    },
    {
        name: "Product Specifications",
        id: "productSpecifications"
    },
    {
        name: "Gallery Slider",
        id: "gallerySlider"
    },
    {
        name: "General",
        id: "general"
    },
]


const Content = styledMui(Grid)(
    ({ theme }) => `
    border: 1px solid ${theme.palette.secondary.main};
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: auto;
  `,
);

function ProductDetail({ control, errors, openDialog, readOnly, name, setValue, getValues }) {

    const [selected, setSelected] = useState(0);

    const handleOnSelectTab = (v) => {
        setSelected(v);
    }

    const renderContent = () => {
        switch (selected) {
            case 0: {
                return (
                    <VariationTable
                        openDialog={openDialog}
                        errors={errors?.variation}
                        readOnly={readOnly}
                        control={control}
                        name={`${name}.variation`}
                        setValue={setValue}
                        getValues={getValues}
                    />
                )
            }

            case 1: {
                return (
                    <div key={selected}>
                        <Box sx={{ mb: 1 }}>
                            <Controller
                                name={`${name}.productDetails.description.en`}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <HtmlEditor
                                            title="English"
                                            readOnly={readOnly}
                                            errors={errors?.productDetails?.description}
                                            helperText={errors?.productDetails?.description?.message}
                                            {...field}
                                        />
                                    )
                                }}
                            />
                        </Box>
                        <Controller
                            name={`${name}.productDetails.description.zh`}
                            control={control}
                            render={({ field }) => {
                                return (
                                    <HtmlEditor
                                        title="Chinese"
                                        readOnly={readOnly}
                                        errors={errors?.productDetails?.description}
                                        helperText={errors?.productDetails?.description?.message}
                                        {...field}
                                    />
                                )
                            }}
                        />
                    </div>
                )
            }

            case 2: {
                return (
                    <div key={selected}>
                        <Box sx={{ mb: 1 }} >
                            <Controller
                                name={`${name}.productSpecifications.specification.en`}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <HtmlEditor
                                            title="English"
                                            readOnly={readOnly}
                                            errors={errors?.productSpecifications?.specification}
                                            helperText={errors?.productSpecifications?.specification?.message}
                                            {...field}
                                        />
                                    )
                                }}
                            />
                        </Box>
                        <Controller
                            name={`${name}.productSpecifications.specification.zh`}
                            control={control}
                            render={({ field }) => {
                                return (
                                    <HtmlEditor
                                        title="Chinese"
                                        readOnly={readOnly}
                                        errors={errors?.productSpecifications?.specification}
                                        helperText={errors?.productSpecifications?.specification?.message}
                                        {...field}
                                    />
                                )
                            }}
                        />
                    </div>
                )
            }

            case 3: {
                return (
                    <GallarySlider
                        control={control}
                        errors={errors?.gallerySlider}
                        readOnly={readOnly}
                        name={`${name}.gallerySlider`}
                        setValue={setValue}
                    />
                )
            }

            case 4: {
                return (
                    <Form fields={GeneralFields({ name: `${name}.general`, control, errors: errors?.general, readOnly })} p={`14px 0px`} />
                )

            }
        }
    }

    return (
        <Grid container direction='column'>
            <OrangeTab data={TABS} selected={selected} onUpdate={handleOnSelectTab} errors={errors} />
            <Content container direction='column'>
                {renderContent()}
            </Content>
        </Grid>
    )
}

export default ProductDetail