import { useState, useContext, useCallback, useMemo, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import {
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import { Routes, Route, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash'
import Typography from '../../components/share/Typography';
import Button from '../../components/share/Button';
import Tab from '../../components/share/Tab';
import Table, { UnderlineText, DeleteActionIcon } from '../../components/share/Table';
import PageWrapper from '../../components/share/PageWrapper';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import AlertDialogProvider, { alertDialogContext } from '../../context/AlertDialogProvider';
import { vasContext } from '../../context/VasProvider';
import { deleteVas } from '../../apis/vas';
import Searchbar from '../../components/share/Search'
import Checkbox from '../../components/share/Checkbox'
import constants from '../../constants'
import dayjs from 'dayjs'
import UploadComponent from '../../components/share/UploadComponent';
import { getPermission } from '../../utils'
import { userContext } from '../../context/UserProvider';

const margin = 10

//listTNC: sort

const TABS = [
    {
        name: "All",
        id: 'all'
    },
    // {
    //     name: "Mobile",
    //     id: 'mobile'
    // },
    // {
    //     name: "Broadband",
    //     id: 'broadband'
    // },
    // {
    //     name: "Other Equipment",
    //     id: 'otherEquipment'
    // }
]

function VasPage(props) {
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [dialog, setDialog] = useContext(alertDialogContext);
    const [user, setUser] = useContext(userContext);
    const [vasList, setVasList] = useContext(vasContext);

    const { canView, canModify } = getPermission(user?.permissions?.vas)

    const [filter, setFilter] = useState({
        keyword: ""
    })
    const [selectMode, setSelectMode] = useState(false);

    let [searchParams, setSearchParams] = useSearchParams();

    const queryClient = useQueryClient()

    const mutation = useMutation({
        mutationFn: (id) => deleteVas(id),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['/vas'] })
            queryClient.invalidateQueries({ queryKey: ['allVas'] })
        },
        onError: (error) => {
            console.log("error", error)
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        },
    })

    const onDeleteVas = (id) => {
        setDialog({
            open: true,
            title: "Are you sure to delete this VAS?",
            handleOnClickOK: () => mutation.mutate(id)
        })
    }

    const columns = useMemo(() => {
        return [
            { field: 'vasId', headerName: 'VAS ID', width: 160, sortable: false, },
            { field: 'name.en', headerName: 'VAS Name (en)', flex: 1, valueGetter: (params) => params.row?.name?.en || "" },
            { field: 'name.zh', headerName: 'VAS Name (chi)', flex: 1, valueGetter: (params) => params.row?.name?.zh || "" },
            { field: 'vasType', headerName: 'Fee', width: 100, sortable: false, },
            { field: 'updatedAt', headerName: 'Last Updated Date', width: 140, sortable: false, valueGetter: (params) => dayjs(params.value).format(constants.DATE_FORMAT) },
            {
                field: 'actions',
                headerName: 'Action',
                type: 'actions',
                width: 140,
                headerAlign: 'flex-start',
                getActions: (params) => {
                    return [
                        <UnderlineText disabled={!canView} onClick={() => navigate(`/vas/${params.row._id}`)}>View</UnderlineText>,
                        <UnderlineText disabled={!canModify} onClick={() => navigate(`/vas/add?vasId=${params.row._id}`)}>Clone</UnderlineText>,
                        <DeleteActionIcon disabled={!canModify} onClick={() => onDeleteVas(params.row._id)} />,
                    ]
                }
            }
        ]
    }, [canModify, canView])

    const onClickTab = (value) => {
        setSearchParams({ tab: TABS[value].id })
        setTabIndex(value)
    };

    const onApiError = (error) => {
        setSnackbar({
            type: 'error',
            message: `${error?.response?.data?.result?.errorMessage?.en || error?.message}`
        })
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`VAS`}</Typography>
                {/* <Button color="orange" style={{ marginRight: margin }}>Export</Button>
                <UploadComponent
                    renderComponent={({ selectFile }) => (
                        <Button
                            color="blue"
                            style={{ marginRight: margin }}
                            onClick={selectFile}
                        >
                            Import
                        </Button>
                    )}
                /> */}
                {/* <Button color="green" style={{ marginRight: margin }}>Filter</Button> */}
            </>
        )
    }

    const CardHeader = () => {
        return (
            <Tab
                tabs={TABS}
                selected={tabIndex}
                onChange={onClickTab}
                renderButton={() => {
                    return (
                        <Button
                            style={{
                                alignSelf: 'flex-end',
                                margin: `0px 20px 14px 20px`,
                            }}
                            onClick={() => navigate(`/vas/add`)}
                            type="add"
                            disabled={!canModify}
                        >
                            Add New
                        </Button>
                    )
                }}
            />
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderCustomCardHeader={CardHeader}
        >
            <Grid container direction='row' alignItems='center' style={{ paddingRight: 10 }}>
                <Searchbar value={filter.keyword} onChange={(e) => setFilter({ ...filter, keyword: e.target.value })} />
                {/* <Checkbox label="Select" fullWidth={false} checked={selectMode} onChange={(event) => setSelectMode(event.target.checked)} /> */}
            </Grid>
            <Table
                style={{ margin: `0px 20px 20px 20px` }}
                color="grey"
                columns={columns}
                api={"/vas"}
                apiParams={{
                    ...filter
                }}
                sortingMode="server"
                onError={onApiError}
                setSnackbar={setSnackbar}
                // onRowClick={(params) => {
                //     navigate(`/terms-and-conditions/${params.id}`)
                // }}
                checkboxSelection={selectMode}
                onSelectionModelChange={(ids) => {
                    console.log("onSelectionModelChange", ids)
                }}
            />
        </PageWrapper>
    )
}

export default VasPage