import { request } from './index';
import constants from '../constants';

/**
 * Retrieves the configuration by type.
 *
 * @param {string} type - The type of configuration to retrieve.
 * @returns {Promise<any>} A promise that resolves with the retrieved configuration data.
 */
const getConfigByType = (type) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "get",
            url: `${constants.API_ENDPOINT}/systemConfig/${type}`
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

/**
 * Updates the configuration by type.
 *
 * @param {string} type - The type of configuration to update.
 * @param {object} payload - The payload containing the updated configuration data.
 * @returns {Promise<object>} A promise that resolves to the updated configuration data.
 */
const updateConfigByType = (type, payload) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "put",
            url: `${constants.API_ENDPOINT}/systemConfig/${type}`,
            data: payload
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}


export {
    getConfigByType,
    updateConfigByType,
}
