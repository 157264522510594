import React, { useCallback, useMemo, useState } from "react";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragOverlay,
    DragStartEvent,
    KeyboardSensor,
    PointerSensor,
    UniqueIdentifier,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import SortableItem from '../share/SortableList/SortableItem';

function SortableList({ data, onSort, renderRow }) {

    const itemIds = useMemo(() => data.map((item) => item._id), [data]);
    const [activeId, setActiveId] = useState();

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const handleDragStart = useCallback((event) => {
        setActiveId(event.active.id);
    }, []);

    const handleDragEnd = useCallback((event) => {
        const { active, over } = event;
        if (over && active.id !== over.id) {
            const oldIndex = itemIds.indexOf(active.id);
            const newIndex = itemIds.indexOf(over.id);
            if (onSort) onSort(oldIndex, newIndex);
        }
        setActiveId(undefined);
    }, [itemIds, onSort]);

    const handleDragCancel = () => {
        setActiveId(undefined);
    };

    return (
        <DndContext
            sensors={sensors}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
            collisionDetection={closestCenter}
            modifiers={[restrictToFirstScrollableAncestor]}
        >
            <SortableContext
                items={itemIds}
                strategy={verticalListSortingStrategy}
            >
                {itemIds.map((id, i) => (
                    <SortableItem
                        key={i}
                        id={id}
                        item={data[i]}
                        renderFunction={renderRow}
                        position={i}
                    />
                ))}
            </SortableContext>
            <DragOverlay adjustScale={false} dropAnimation={null}>
                {activeId ? (
                    <SortableItem
                        id={activeId}
                        item={data[itemIds.indexOf(activeId)]}
                        renderFunction={renderRow}
                        isDragged
                    />
                ) : (
                    <></>
                )}
            </DragOverlay>
        </DndContext>
    )
}

export default SortableList