import { request } from './index';
import constants from '../constants';

const listPromoCode = () => {
    return new Promise((resolve, reject) => {
        return request({
            method: "get",
            url: `${constants.API_ENDPOINT}/promo`
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const fetchPromoCode = (id) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "get",
            url: `${constants.API_ENDPOINT}/promo/${id}`
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const createPromoCode = (payload) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "post",
            url: `${constants.API_ENDPOINT}/promo`,
            data: payload
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const updatePromoCode = (id, payload) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "put",
            url: `${constants.API_ENDPOINT}/promo/${id}`,
            data: payload
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const deletePromoCode = (id) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "delete",
            url: `${constants.API_ENDPOINT}/promo/${id}`,
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

export {
    listPromoCode,
    fetchPromoCode,
    createPromoCode,
    updatePromoCode,
    deletePromoCode,
}