import React, { useContext, useEffect } from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from "./components/Layout";
import { userContext } from "./context/UserProvider";
// import Login from './pages/Login';
import Home from './pages/Home';
// import TextConfigurationPage from "./pages/TextConfiguration";
import MobilePage from './pages/Mobile'
import AddMobilePlanPage from './pages/Mobile/add'
import MobileDetailPage from "./pages/Mobile/detail";
import TermsAndConditionsPage from './pages/TermsAndConditions'
import TermsAndConditionsDetailPage from './pages/TermsAndConditions/detail'
import AddTermsAndConditionsPage from './pages/TermsAndConditions/add'
import VasPage from "./pages/Vas";
import VasAddPage from './pages/Vas/add'
import VasDetailPage from './pages/Vas/detail'
import MediaPage from './pages/Media'
import MediaAddPage from "./pages/Media/add";
import MediaDetailPage from './pages/Media/detail';
import BundlePage from './pages/Bundle';
import BundleAddPage from './pages/Bundle/add';
import BundleDetailPage from './pages/Bundle/detail';
import CampaignPage from './pages/Campaign'
import CampaignAddPage from './pages/Campaign/add'
import CampaignDetailPage from './pages/Campaign/detail'
import AdminManagementPage from './pages/AdminManagement';
import AdminPage from './pages/AdminManagement/detail'
import AdminAddPage from './pages/AdminManagement/add'
import ProductPage from './pages/Product'
import ProductAddPage from './pages/Product/add'
import ProductDetailPage from "./pages/Product/detail";
import StockPage from './pages/Stock'
import StockDetailPage from './pages/Stock/detail'
import StockAddPage from './pages/Stock/add';
import CompanyPage from './pages/Company'
import CompanyAddPage from './pages/Company/add'
import CompanyDetailPage from "./pages/Company/detail";
import UserManagementPage from "./pages/UserManagement";
import UserDetailPage from './pages/UserManagement/detail'
import PublicBannerPage from './pages/PublicBanner';
import PublicBannerAddPage from './pages/PublicBanner/add';
import PublicBannerDetailPage from './pages/PublicBanner/detail';
import OrderPage from './pages/Order'
import OrderItemDetailPage from './pages/Order/orderItemDetail'
import PromotionCodePage from './pages/PromotionCode'
import PromotionCodeAddPage from './pages/PromotionCode/add'
import PromotionCodeDetailPage from './pages/PromotionCode/detail'
import TutorialPage from './pages/Tutorial'
import TutorialDetailPage from './pages/Tutorial/detail'
import GlobalSettingsPage from "./pages/GlobalSettings";

import Login from './components/Login'
import axios from 'axios';
import { getCMSLogoutUrl } from "./apis";
import { snackbarContext } from "./context/SnackbarProvider";

function CustomRoutes() {

  const [snackbar, setSnackbar] = useContext(snackbarContext);

  const onLogout = () => {
    const logoutAction = ({ logoutSsoUrl = null }) => {
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('id_token')
      if (logoutSsoUrl) {
        window.location.assign(logoutSsoUrl);
      } else {
        window.location.reload();
      }
    }
    const logoutUrl = getCMSLogoutUrl()

    const params = new URLSearchParams();
    params.append('refresh_token', localStorage.getItem('refresh_token'));

    // Get Host URL
    const baseURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/`;
    console.log("Logout Base URL: ", baseURL)
    params.append('logout_redirect_url', baseURL);
    axios.post(logoutUrl, params)
      .then(res => {

        // https://gitlab.zensis.com/hkt-eshop/uat-comments/issues/234
        // logoutAction({ logoutSsoUrl: null })

        // TODO: To Logout SSO, use code below
        // API will response a URL to logout SSO
        logoutAction({ logoutSsoUrl: res.data.data.ssoLogoutURL })
      })
      .catch((error) => {

        // Show a logout Error
        setSnackbar({
          type: "error",
          message: `Logout Error: ${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
        })
      })
  }

  return (
    <Routes>
      {/* <Route exact path="/Login" element={<Login />} /> */}
      <Route exact path="/auth/admin-login-redirect" element={<Login />} />
      <Route
        exact
        path="/"
        element={<Layout onLogout={onLogout} />}
      >
        <Route
          index
          // path="CommercialBroadband&TelephoneService"
          element={<Home />}
        />

        <Route
          path="admin"
        >
          <Route index element={<AdminManagementPage />} />
          <Route path="/admin/add" element={<AdminAddPage />} />
          <Route path=":adminId" element={<AdminPage />} />
        </Route>

        <Route
          exact
          path="/mobile"
        >
          <Route index element={<MobilePage />} />
          <Route exact path="/mobile/add" element={<AddMobilePlanPage />} />
          <Route path=":planId" element={<MobileDetailPage />} />
        </Route>

        <Route
          path="product"
        >
          <Route index element={<ProductPage />} />
          <Route path=":productType/add" element={<ProductAddPage />} />
          <Route path=":productId" element={<ProductDetailPage />} />
        </Route>

        <Route
          path="stock"
        >
          <Route index element={<StockPage />} />
          <Route path=":stockId" element={<StockDetailPage />} />
          <Route path="/stock/add" element={<StockAddPage />} />
        </Route>

        {/* <Route
          path="text-configuration"
          element={<TextConfigurationPage />}
        /> */}

        <Route
          path="company"
        >
          <Route index element={<CompanyPage />} />
          <Route path=":companyId" element={<CompanyDetailPage />} />
          <Route path=":companyType/add" element={<CompanyAddPage />} />
        </Route>

        <Route
          path="user"
        >
          <Route index element={<UserManagementPage />} />
          <Route path=":userId" element={<UserDetailPage />} />
          {/* 
          <Route path=":userId" element={<AdminPage />} /> */}
        </Route>

        <Route
          path="bundle"
        >
          <Route index element={<BundlePage />} />
          <Route path="/bundle/add" element={<BundleAddPage />} />
          <Route path=":bundleId" element={<BundleDetailPage />} />
        </Route>

        <Route
          path="campaign"
        >
          <Route index element={<CampaignPage />} />
          <Route path="/campaign/add" element={<CampaignAddPage />} />
          <Route path=":campaignId" element={<CampaignDetailPage />} />
        </Route>

        <Route
          path="public-banner"
        >
          <Route index element={<PublicBannerPage />} />
          <Route path=":bannerId" element={<PublicBannerDetailPage />} />
          <Route path="/public-banner/add" element={<PublicBannerAddPage />} />
        </Route>

        <Route
          path="vas"
        >
          <Route index element={<VasPage />} />
          <Route path="/vas/add" element={<VasAddPage />} />
          <Route path=":vasId" element={<VasDetailPage />} />
          {/* <Route path=":categoryId/add" element={<VasAddPage />} />
          <Route path=":mediaId" element={<VasDetailPage />} /> */}
        </Route>

        <Route
          path="terms-and-conditions"
        >
          <Route index element={<TermsAndConditionsPage />} />
          <Route path=":tncId" element={<TermsAndConditionsDetailPage />} />
          <Route path=":categoryId/add" element={<AddTermsAndConditionsPage />} />
        </Route>

        <Route
          path="order"
        >
          <Route index element={<OrderPage />} />
          <Route path="/order/orderItems/:orderItemId" element={<OrderItemDetailPage />} />
        </Route>

        <Route
          path="promotion-code"
        >
          <Route index element={<PromotionCodePage />} />
          <Route path="/promotion-code/add" element={<PromotionCodeAddPage />} />
          <Route path=":promotionId" element={<PromotionCodeDetailPage />} />
        </Route>

        <Route
          path="media"
        >
          <Route index element={<MediaPage />} />
          <Route path="/media/add" element={<MediaAddPage />} />
          <Route path=":mediaId" element={<MediaDetailPage />} />
        </Route>

        <Route
          path="shopping-tutorial"
        >
          <Route index element={<TutorialPage />} />
          <Route path="/shopping-tutorial/detail" element={<TutorialDetailPage />} />
          {/* <Route path=":companyId" element={<CompanyDetailPage />} /> */}
        </Route>

        <Route
          path="globel-settings"
          element={<GlobalSettingsPage />}
        />

        <Route path='*' element={<Navigate to="/" />} />

      </Route>
    </Routes>
  );
}

export default CustomRoutes;