import { Controller } from "react-hook-form";
import TextField from '../../components/share/TextField';
import Image from '../../components/share/ImgGrid/ImageV2';
import Typography from '../../components/share/Typography';
import InputAdornment from '@mui/material/InputAdornment'
import { ReactComponent as UploadIcon } from '../../assets/upload-background.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import Button from '../../components/share/Button'
import DynamicForm from '../../components/share/form/DynamicForm';
import Counter from '../../components/share/Counter'
import Grid from '@mui/material/Grid';
import ComponentContainer from '../../components/share/ComponentContainer'
import Select from '../../components/share/Select'
import { DefFrontCardFields, DefPlanDetailFields, DefContractPeriodDetailFields, } from './add'
import AutoCompleteWithList from "../../components/share/AutocompleteWithList";
import HtmlEditor from '../../components/share/HtmlEditor'
import UploadComponent from "../../components/share/UploadComponent";
import { SelectImageDialog } from "../../components/share";

const FIELDS = ({ control, categoryList, errors, readOnly, tncList }) => [
    // {
    //     id: "promotionTitle_en",
    //     component: (
    //         <Controller
    //             name="promotionTitle.en"
    //             control={control}
    //             render={({ field }) => (
    //                 <TextField
    //                     {...field}
    //                     title="Promotion Title (English)"
    //                     direction="vertical"
    //                     fullWidth
    //                     readOnly={readOnly}
    //                     error={errors.promotionTitle?.en}
    //                     helperText={errors.promotionTitle?.en?.message}
    //                 />
    //             )}
    //         />
    //     )
    // },
    // {
    //     id: "promotionTitle_zh",
    //     component: (
    //         <Controller
    //             name="promotionTitle.zh"
    //             control={control}
    //             render={({ field }) => (
    //                 <TextField
    //                     {...field}
    //                     title="Promotion Title (Chinese)"
    //                     direction="vertical"
    //                     fullWidth
    //                     readOnly={readOnly}
    //                     error={errors.promotion?.zh}
    //                     helperText={errors.promotion?.zh?.message}
    //                 />
    //             )}
    //         />
    //     )
    // },
    {
        id: "name_en",
        component: (
            <Controller
                name="name.en"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="VAS Name (English)*"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors.name?.en}
                        helperText={errors.name?.en?.message}
                    />
                )}
            />
        )
    },
    {
        id: "name_zh",
        component: (
            <Controller
                name="name.zh"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="VAS Name (Chinese)*"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors.name?.zh}
                        helperText={errors.name?.zh?.message}
                    />
                )}
            />
        )
    },
    {
        id: "originalPrice",
        component: (
            <Controller
                name="originalPrice"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Original Price"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors.originalPrice}
                        helperText={errors.originalPrice?.message}
                    />
                )}
            />
        )
    },
    {
        id: "priceType",
        component: (
            <Controller
                name="priceType"
                control={control}
                render={({ field }) => (
                    <Select
                        title="Price Of Unit*"
                        direction="vertical"
                        fullWidth
                        textFieldProps={{
                            placeholder: "Select..."
                        }}
                        options={[
                            {
                                name: "None",
                                _id: 'oneTime'
                            },
                            {
                                name: "Monthly",
                                _id: 'monthly'
                            }
                        ]}
                        value={field.value}
                        onChange={(value) => {
                            field.onChange(value)
                        }}
                        disabled={readOnly}
                        error={errors?.priceType}
                        helperText={errors?.priceType?.message}
                    />
                )}
            />
        )
    },
    {
        id: "discountedPrice",
        component: (
            <Controller
                name="discountedPrice"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Discount Price"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors.discountedPrice}
                        helperText={errors.discountedPrice?.message}
                    />
                )}
            />
        )
    }, { id: 'spacing' },
    {
        id: "details-en",
        xs: 2,
        component: (
            <Controller
                name={`detail.en`}
                control={control}
                render={({ field }) => (
                    <HtmlEditor
                        title="Details (English)*"
                        value={field.value}
                        onChange={field.onChange}
                        readOnly={readOnly}
                        error={errors?.detail?.en}
                        helperText={errors?.detail?.en?.message}
                    />
                )}
            />
        )
    },
    {
        id: "details-zh",
        xs: 2,
        component: (
            <Controller
                name={`detail.zh`}
                control={control}
                render={({ field }) => (
                    <HtmlEditor
                        title="Details (Chinese)*"
                        value={field.value}
                        onChange={field.onChange}
                        readOnly={readOnly}
                        error={errors?.detail?.zh}
                        helperText={errors?.detail?.zh?.message}
                    />
                )}
            />
        )
    },
    {
        id: "tnc",
        component: (
            <Controller
                name={`tncs`}
                control={control}
                render={({ field }) => (
                    <Select
                        title="Select T&C on VAS"
                        direction="vertical"
                        fullWidth
                        textFieldProps={{
                            placeholder: "Select..."
                        }}
                        options={tncList}
                        value={field.value}
                        onChange={(value) => {
                            field.onChange(value)
                        }}
                        disabled={readOnly}
                        error={errors?.tncs}
                        helperText={errors?.tncs?.message}
                        getOptionLabel={(option) => option?.title?.en || ""}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "fee",
        component: (
            <Controller
                name={`vasType`}
                control={control}
                render={({ field }) => (
                    <Select
                        title="Select Fee on VAS*"
                        direction="vertical"
                        fullWidth
                        textFieldProps={{
                            placeholder: "Select..."
                        }}
                        options={[
                            {
                                name: "Free",
                                _id: 'free'
                            },
                            {
                                name: "Bundle Free",
                                _id: 'bundleFree'
                            },
                            {
                                name: "Paid",
                                _id: 'paid'
                            }
                        ]}
                        value={field.value}
                        onChange={(value) => {
                            field.onChange(value)
                        }}
                        disabled={readOnly}
                        error={errors?.vasType}
                        helperText={errors?.vasType?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "vasId",
        component: (
            <Controller
                name="vasId"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="VAS Id"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors.vasId}
                        helperText={errors.vasId?.message}
                    />
                )}
            />
        )
    }, { id: 'spacing' },
    {
        id: 'image-upload',
        component: (
            <Controller
                name={`newImageKey`}
                control={control}
                render={({ field }) => {
                    if (field?.value?.previewUrl || readOnly) {
                        return (
                            <ComponentContainer direction="vertical" title="VAS image (Hardware Only)*" error={errors.newImageKey} helperText={errors.newImageKey?.message}>
                                <Image
                                    url={field?.value?.previewUrl}
                                    height={120}
                                    width={120}
                                    onChange={field.onChange}
                                    readOnly={readOnly}
                                    onClick={() => field?.value?.previewUrl && window.open(field?.value.previewUrl)}
                                />
                            </ComponentContainer>
                        )
                    }
                    return (
                        <ComponentContainer direction="vertical" title="VAS image (Hardware Only)*" error={errors.newImageKey} helperText={errors.newImageKey?.message}>
                            <SelectImageDialog
                                onChange={field.onChange}
                                renderComponent={({ openDialog }) => (
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={openDialog}
                                    >
                                        + Add Image
                                    </Button>
                                )}
                            />
                        </ComponentContainer>
                    )
                }}
            />
        )
    }, { id: 'spacing' },
]

export { FIELDS }