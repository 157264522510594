import { request } from './index';
import constants from '../constants';

const fetchRSPReport = (payload) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "post",
            url: `${constants.API_ENDPOINT}/order/report/rsp`,
            data: payload
        })
            .then(res => {
                // resolve(res.data)
                resolve(res)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

export {
    fetchRSPReport,
}