import { useState, useEffect, createContext } from "react";
import { listVas } from '../apis/vas'
import {
    useQuery,
} from '@tanstack/react-query'

export const vasContext = createContext();
function VasProvider({ children }){
    const [vasList, setVasList] = useState(null);
    const token = localStorage.getItem('access_token');

    const { data, isLoading } = useQuery({
        queryKey: ["allVas"],
        queryFn: async () => await listVas(),
        enabled: token ? true : false,
        // retry: false,
        onSuccess: (data) => {
            setVasList(data)
        }
    });

    return (
        <vasContext.Provider value={[vasList, setVasList]}>
            {children}
        </vasContext.Provider>
    )
}

export default VasProvider