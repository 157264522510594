import { useState, useContext, useCallback, useMemo, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import {
    useQuery,
    useMutation,
    useQueryClient
} from '@tanstack/react-query'
import _ from 'lodash'
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import PageWrapper from '../../components/share/PageWrapper';
import Typography from '../../components/share/Typography';
import Button from '../../components/share/Button';
import Select from '../../components/share/Select';
import GreyDivider from '../../components/GreyDivider';
import TextField from '../../components/share/TextField';
import Form from '../../components/share/form/Form';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import { userContext } from '../../context/UserProvider';
import { createCompany, fetchCompany } from '../../apis/company';
import { Routes, Route, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Fields } from './formFields'
import { getPermission } from '../../utils'
import constants from '../../constants'

const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
    overflow: auto;
`

export const schema = Joi.object({
    name: Joi.string().required(),
    emailDomains: Joi.array().items(
        Joi.object({
            value: Joi.string().domain().required()
        })
    ),
    banner: Joi.any(),
}).messages({
    "any.required": "Required",
    "object.base": "Required",
    "string.empty": "Required",
    "string.domain": "Must contain a valid domain name",
    "string.email": "Must contain a valid email"
});

export const vipSchema = schema.append({
    // urlPath: Joi.string().required(),
    // image: Joi.object().required(),
    notificationEmails: Joi.array().items(
        Joi.object({
            value: Joi.string().email({ tlds: false }).allow(null, '')
        })
    )
})

function CompanyAddPage(props) {

    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [user, setUser] = useContext(userContext);

    const { canView, canModify } = getPermission(user?.permissions?.company)

    const { companyType } = useParams();

    const isVip = companyType === 'vip';

    const getDefaultValues = () => {
        const values = {
            emailDomains: [{ value: "" }],
        }

        if (isVip) {
            values.notificationEmails = [{ value: "" }]
        }

        return values;
    }

    const { control, handleSubmit, watch, formState: { errors }, reset } = useForm({
        resolver: joiResolver(isVip ? vipSchema : schema),
        defaultValues: getDefaultValues(),
    });

    useEffect(() => {
        if (!_.isEmpty(errors)) {
            setSnackbar({
                type: "error",
                message: constants.INVALID_INPUT_ERR_MSG
            })
        }
    }, [errors])

    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (payload) => createCompany(payload),
        onSuccess: (data) => {
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
            navigate(`/company?tab=${companyType}`);
            queryClient.invalidateQueries({ queryKey: ['allCompany'] })
            queryClient.invalidateQueries({ queryKey: ['vipCompany'] })
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const handleOnSubmit = (formValue) => {
        const payload = {
            ...formValue,
            companyType: companyType === "vip" ? "vip" : "normal",
            emailDomains: _.map(formValue.emailDomains, (item) => item.value),
            notificationEmails: _.map(formValue.notificationEmails, (item) => item.value).filter((item) => item && item !== ""),
        };
        if (payload?.image) {
            payload['image'] = payload.image.fileKey
        }
        mutation.mutate(payload)
    }

    console.log("errors", errors)

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Company > Add New`}</Typography>
            </>
        )
    }

    const CardHeader = () => {
        return (
            <>
                <Button
                    disabled={!canModify || mutation.isLoading}
                    style={{
                        alignSelf: "end",
                        margin: `10px 8px`,
                        height: 30
                    }}
                    onClick={() => handleSubmit(handleOnSubmit)()}
                >
                    Save
                </Button>
            </>
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            loading={mutation.isLoading}
            renderCardHeader={CardHeader}
        >
            <Content>
                <Form fields={Fields({ control, errors, companyType: companyType === "generic" ? 'normal' : companyType })} p={`14px 0px`} />
            </Content>
        </PageWrapper>
    )
}

export default CompanyAddPage