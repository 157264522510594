import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Divider, IconButton, CircularProgress } from '@mui/material'
import { ReactComponent as CloseIcon } from '../../../assets/Close.svg';
import Button from '../Button'
import ImgGrid from '../ImgGrid/ImgGrid'
import AddImageDialog from './addDialog';
import { ImgContainer } from '../ImgGrid/ImageV2'
import AddIcon from '@mui/icons-material/Add';
import Typography from '../Typography'
import _ from 'lodash'
import { mediaContext } from '../../../context/MediaProvider';

export default function SelectImgDialog({ open, handleClose, onChange, images, onRemove, selectedImage, imageSizeHint=null }) {
    const { mediaData, refetch: refetchMediaCentre } = React.useContext(mediaContext);

    const [openAddImgDialog, setOpenAddImgDialog] = React.useState(false);
    const [selected, setSelected] = React.useState(null)
    const [imageList, setImageList] = React.useState([
        {
            id: 'custom',
            xs: 1,
            renderComponent: () => {
                return (
                    <ImgContainer
                        height={120}
                        width={120}
                        onClick={() => setOpenAddImgDialog(true)}
                        style={{ flexDirection: 'column', flexWrap: 'nowrap', cursor: 'pointer' }}
                    >
                        <AddIcon />
                        <Typography fontSize={12}>Upload Image</Typography>
                    </ImgContainer>
                )
            }
        }
    ])

    React.useEffect(() => {
        if (open) {
            setImageList([
                {
                    id: 'custom',
                    xs: 1,
                    renderComponent: () => {
                        return (
                            <ImgContainer
                                height={120}
                                width={120}
                                onClick={() => setOpenAddImgDialog(true)}
                                style={{ flexDirection: 'column', flexWrap: 'nowrap', cursor: 'pointer' }}
                            >
                                <AddIcon />
                                <Typography fontSize={12}>Upload Image</Typography>
                            </ImgContainer>
                        )
                    }
                },
                ...(Array.isArray(images) ? images : []),
                ...(Array.isArray(mediaData) ? mediaData.map((img) => ({ ...img, canDelete: false })) : []),
            ])
        }
    }, [images, open, mediaData])

    React.useEffect(() => {
        if (open && selectedImage) {
            setSelected(selectedImage)
        } else {
            setSelected(null)
        }
    }, [selectedImage, open])

    React.useEffect(() => {
        if (open) {
            refetchMediaCentre();
        }
    }, [open]);

    const onImageUploaded = (response) => {
        if (!response || !response?.fileKey) return
        const newImg = {
            ...response,
            id: response.fileKey,
            url: response.previewUrl,
            xs: 1,
            isUploadImage: true,
        }
        setImageList([
            ...imageList,
            { ...newImg }
        ])
        setSelected(newImg)
    }

    const handleCloseAddImgDialog = (value) => {
        setOpenAddImgDialog(false);
    };

    const handleOnClickSelect = () => {
        const tmp = _.filter(imageList, (item) => {
            return item.id !== 'custom' && item.id !== 'upload'
        })
        onChange && onChange(selected, tmp)
        handleClose && handleClose()
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'md'}
                PaperProps={{
                    sx: {
                        height: 600,
                    }
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <DialogTitle id="alert-dialog-title">
                        Add Image
                    </DialogTitle>
                    <IconButton style={{ marginRight: 20, height: 40, width: 40 }} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Divider sx={{ borderColor: 'mainColor.cardDivider', borderWidth: 2, margin: `0px 20px` }} />
                <DialogContent>
                    {imageSizeHint && <Typography variant="header" sx={{ mb: 2 }}>{imageSizeHint}</Typography>}
                    <ImgGrid
                        data={imageList}
                        selected={selected}
                        // onImageUploaded={onImageUploaded}
                        onClickImg={(v) => setSelected(v)}
                        imgSize={120}
                        onRemove={({ position }) => {
                            if (onRemove) {
                                onRemove({ position })
                            } else {
                                setImageList((oldState) => {
                                    let clone = [...oldState]
                                    clone.splice(position, 1)
                                    return clone
                                })
                                setSelected(null)
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClickSelect}>Select</Button>
                </DialogActions>
            </Dialog>
            <AddImageDialog
                open={openAddImgDialog}
                handleClose={handleCloseAddImgDialog}
                onChange={(imgResp) => onImageUploaded(imgResp)}
            />
        </>
    );
}
