import { Box, IconButton } from "@mui/material";
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { Controller } from "react-hook-form";
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import {
    ColorPicker,
    RecommendProduct
} from '../../components';
import {
    AutoCompleteWithList,
    Button,
    Checkbox,
    ComponentContainer,
    Counter,
    HtmlEditor,
    Image,
    RadioGroup,
    Select,
    SelectImageDialog,
    TextField,
    Typography,
    DynamicForm
} from "../../components/share";
import { DefColorFields } from './add';
import { ProductCategoryOptions } from "../../components/Drawer";
import VasAutoCompleteWithListAndOptionCheckbox from "../../components/VasAutoCompleteWithListAndCheckbox";

const FlipCardColorFields = [
    {
        id: "colors-color",
        renderComponent: ({ control, error, position, name, readOnly, canDelete, remove }) => (
            <Controller
                name={`${name}.${position}`}
                control={control}
                render={({ field }) => {
                    return (
                        <Box display='flex' flexDirection='row' alignItems='center'>
                            <ColorPicker
                                {...field}
                                readOnly={readOnly}
                                error={error}
                                helperText={error?.message}
                                keyId={`color-${position}-${field.value}`}
                            />
                            {!readOnly && canDelete &&
                                <IconButton sx={{ height: 30, width: 30, ml: `14px` }} onClick={() => remove(position)}>
                                    <DeleteIcon />
                                </IconButton>}
                        </Box>
                    )
                }}
            />
        )
    }
]

const Step1Fields = ({ control, errors = {}, name, readOnly, companies }) => [
    {
        id: 'header',
        component: (
            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Fields marked * are required</Typography>
        )
    }, { id: "spacing" },
    {
        id: "category",
        component: (
            <Controller
                name={`${name}.productCategory`}
                control={control}
                render={({ field }) => (
                    <Select
                        title="Category*"
                        direction="vertical"
                        fullWidth
                        textFieldProps={{
                            placeholder: "Select..."
                        }}
                        options={ProductCategoryOptions}
                        value={field.value}
                        onChange={(value) => {
                            field.onChange(value)
                        }}
                        disabled={readOnly}
                        error={errors?.productCategory}
                        helperText={errors?.productCategory?.message}
                        required
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "company",
        component: (
            <Controller
                name={`${name}.company`}
                control={control}
                render={({ field }) => (
                    <Select
                        title="Select Company"
                        direction="vertical"
                        fullWidth
                        textFieldProps={{
                            placeholder: "Select..."
                        }}
                        options={companies}
                        value={field.value}
                        onChange={(value) => {
                            field.onChange(value)
                        }}
                        disabled={readOnly}
                        error={errors?.company}
                        helperText={errors?.company?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "promotionTitle_en",
        component: (
            <Controller
                name={`${name}.promotionTitle.en`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Promotion Title (English)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.promotion?.en}
                        helperText={errors?.promotion?.en?.message}
                    />
                )}
            />
        )
    },
    {
        id: "promotion_zh",
        component: (
            <Controller
                name={`${name}.promotionTitle.zh`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Promotion Title (Chinese)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors.promotion?.zh}
                        helperText={errors.promotion?.zh?.message}
                    />
                )}
            />
        )
    },
    {
        id: "flip_card_label_en",
        component: (
            <Controller
                name={`${name}.flipCardLabel.en`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Card Label (English)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.flipCardLabel?.en}
                        helperText={errors?.flipCardLabel?.en?.message}
                    />
                )}
            />
        )
    },
    {
        id: "flip_card_label_zh",
        component: (
            <Controller
                name={`${name}.flipCardLabel.zh`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Card Label (Chinese)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.flipCardLabel?.zh}
                        helperText={errors.flipCardLabel?.zh?.message}
                    />
                )}
            />
        )
    },
    {
        id: "cardIcon",
        component: (
            <Controller
                name={`${name}.flipCardIconImage`}
                control={control}
                render={({ field }) => {
                    if (field?.value?.previewUrl || readOnly) {
                        return (
                            <Image
                                title="Card Icon*"
                                url={field?.value?.previewUrl}
                                height={120}
                                width={120}
                                onChange={field.onChange}
                                readOnly={readOnly}
                                onClick={() => window.open(field?.value?.previewUrl)}
                                error={errors.flipCardIconImage}
                                helperText={errors?.flipCardIconImage?.message || errors?.flipCardIconImage?.fileKey?.message}
                            />
                        )
                    }
                    return (
                        <ComponentContainer
                            direction="vertical"
                            title="Card Icon*"
                            error={errors.flipCardIconImage}
                            helperText={errors?.flipCardIconImage?.message || errors?.flipCardIconImage?.fileKey?.message}
                        >
                            <SelectImageDialog
                                onChange={field.onChange}
                                renderComponent={({ openDialog }) => (
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={openDialog}
                                    >
                                        + Add Image
                                    </Button>
                                )}
                                imageSizeHint={"Recommended image size to be 550 x 550 pixel"}
                            />
                        </ComponentContainer>
                    )
                }}
            />
        )
    },
    {
        id: 'spacing'
    },
    {
        id: "brandName_en",
        component: (
            <Controller
                name={`${name}.brandName.en`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Brand Name (English)*"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors.brandName?.en}
                        helperText={errors.brandName?.en?.message}
                    />
                )}
            />
        )
    },
    {
        id: "brandName_zh",
        component: (
            <Controller
                name={`${name}.brandName.zh`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Brand Name (Chinese)*"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors.brandName?.zh}
                        helperText={errors.brandName?.zh?.message}
                    />
                )}
            />
        )
    },
    {
        id: "productName_en",
        component: (
            <Controller
                name={`${name}.productName.en`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Product Name (English)*"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors.productName?.en}
                        helperText={errors.productName?.en?.message}
                    />
                )}
            />
        )
    },
    {
        id: "productName_zh",
        component: (
            <Controller
                name={`${name}.productName.zh`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Product Name (Chinese)*"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors.productName?.zh}
                        helperText={errors.productName?.zh?.message}
                    />
                )}
            />
        )
    },
    {
        id: "internalItemCode",
        component: (
            <Controller
                name={`${name}.productSku`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Product SKU*"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors.productSku}
                        helperText={errors.productSku?.message}
                    />
                )}
            />
        )
    }, { id: 'spacing' },
    // {
    //     id: "productSku",
    //     component: (
    //         <Controller
    //             name={`${name}.productSku`}
    //             control={control}
    //             render={({ field }) => (
    //                 <TextField
    //                     {...field}
    //                     title="SKU No.*"
    //                     direction="vertical"
    //                     fullWidth
    //                     readOnly={readOnly}
    //                     error={errors.productSku}
    //                     helperText={errors.productSku?.message}
    //                 />
    //             )}
    //         />
    //     )
    // }, { id: 'spacing' },
    {
        id: "originalPrice",
        component: (
            <Controller
                name={`${name}.markedPrice`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Original Price*"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors.markedPrice}
                        helperText={errors.markedPrice?.message}
                        InputProps={{
                            startAdornment: <InputAdornment style={{ marginTop: 0 }} position="start">HKD</InputAdornment>,
                        }}
                    />
                )}
            />
        )
    }, { id: 'spacing' },
    {
        id: "discountedPrice",
        component: (
            <Controller
                name={`${name}.sellingPrice`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Discounted Price"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors.sellingPrice}
                        helperText={errors.sellingPrice?.message}
                        InputProps={{
                            startAdornment: <InputAdornment style={{ marginTop: 0 }} position="start">HKD</InputAdornment>,
                        }}
                    />
                )}
            />
        )
    }, { id: 'spacing' },
    {
        id: 'header',
        component: (
            <Typography variant="header">Show on Filp card (Front):</Typography>
        )
    }, { id: "spacing" },
    {
        id: "dynamicList",
        component: (
            <DynamicForm
                control={control}
                errors={errors?.["flipCardColors"]}
                name={`${name}.flipCardColors`}
                addButtonLabel="+ Add More Color"
                fieldComponents={FlipCardColorFields}
                appendData={DefColorFields}
                style={{ padding: 0 }}
                readOnly={readOnly}
            />
        )
    },
]

const OptionFields = [
    {
        id: "option.en",
        renderComponent: ({ control, error, position, name }) => (
            <Controller
                name={`${name}.${position}.option.en`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title={`Option ${position} (English)`}
                        direction="vertical"
                        fullWidth
                        error={error}
                        helperText={error?.message}
                    />
                )}
            />
        )
    },
    {
        id: "option.zh",
        renderComponent: ({ control, error, position, name }) => (
            <Controller
                name={`${name}.${position}.option.zh`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title={`Option ${position} (Chineese)`}
                        direction="vertical"
                        fullWidth
                        error={error}
                        helperText={error?.message}
                    />
                )}
            />
        )
    },
]

const GeneralFields = ({ control, errors = {}, name, readOnly }) => [
    {
        id: 'header',
        component: (
            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Fields marked * are required</Typography>
        )
    }, { id: "spacing" },
    {
        id: 'header2',
        component: (
            <Typography variant="header">Quantity</Typography>
        )
    }, { id: "spacing" },
    {
        id: 'quantity',
        component: (
            <Grid container direction='row' alignItems='center' justifyContent='center'>
                <Grid item xs>
                    <Controller
                        name={`${name}.minQuantity`}
                        control={control}
                        render={({ field }) => (
                            <Counter
                                title="Minimum No.*"
                                disabled={readOnly}
                                {...field}
                                error={errors?.minQuantity}
                                helperText={errors?.minQuantity?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item style={{ margin: `20px 20px 0px 20px` }}><Typography>To</Typography></Grid>
                <Grid item xs>
                    <Controller
                        name={`${name}.maxQuantity`}
                        control={control}
                        render={({ field }) => (
                            <Counter
                                title="Maximum No.*"
                                disabled={readOnly}
                                {...field}
                                error={errors?.maxQuantity}
                                helperText={errors?.maxQuantity?.message}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        )
    }, { id: "spacing" },
    {
        id: 'hr'
    },
    {
        id: 'header2',
        component: (
            <Typography variant="header">Delivery Method</Typography>
        )
    }, { id: "spacing" },
    {
        id: "pickup",
        component: (
            <Controller
                name={`${name}.isAllowedPickUp`}
                control={control}
                render={({ field }) => (
                    <ComponentContainer direction={"vertical"} error={errors?.isAllowedPickUp} helperText={errors?.isAllowedPickUp?.message}>
                        <Checkbox
                            label="Pick Up"
                            align='flex-start'
                            checked={field.value}
                            onChange={field.onChange}
                            disabled={readOnly}
                            typographyProps={{ color: readOnly ? "secondary" : "primary" }}
                        />
                    </ComponentContainer>
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "shop-delivery",
        component: (
            <Controller
                name={`${name}.isAllowedDelivery`}
                control={control}
                render={({ field }) => (
                    <ComponentContainer direction={"vertical"} error={errors?.isAllowedDelivery} helperText={errors?.isAllowedDelivery?.message}>
                        <Checkbox
                            label="Shop Delivery"
                            align='flex-start'
                            checked={field.value}
                            onChange={field.onChange}
                            disabled={readOnly}
                            typographyProps={{ color: readOnly ? "secondary" : "primary" }}
                        />
                    </ComponentContainer>
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: 'hr'
    },
    {
        id: 'header2',
        component: (
            <Typography variant="header">Free Removal Service*</Typography>
        )
    }, { id: "spacing" },
    {
        id: "free-removal-service",
        component: (
            <Controller
                name={`${name}.haveFreeRemoval`}
                control={control}
                render={({ field }) => (
                    <RadioGroup
                        direction="vertical"
                        options={[
                            {
                                label: 'Disable',
                                value: 'disable'
                            },
                            {
                                label: 'Enable',
                                value: 'enable'
                            },
                        ]}
                        row
                        disabled={readOnly}
                        {...field}
                        error={errors?.haveFreeRemoval}
                        helperText={errors?.haveFreeRemoval?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
]

const TncFields = ({ name, control, errors = {}, readOnly, tncList }) => [
    {
        id: 'header',
        component: (
            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Fields marked * are required</Typography>
        )
    }, { id: "spacing" },
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Detail Page T&C</Typography>
        )
    }, { id: "spacing" },
    {
        id: 'tnc',
        component: (
            <Controller
                name={`${name}.detailTncs`}
                control={control}
                render={({ field }) => (
                    <AutoCompleteWithList
                        title="Select T&C"
                        direction="vertical"
                        fullWidth
                        options={tncList}
                        getOptionLabel={(option) => `${option?.title?.en || ""} ${option?.title?.zh || ""}`}
                        readOnly={readOnly}
                        error={errors?.detailTncs}
                        helperText={errors?.detailTncs?.message}
                        {...field}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: 'hr'
    },
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Overall T&C Agreement</Typography>
        )
    }, { id: "spacing" },
    {
        id: 'tnc',
        component: (
            <Controller
                name={`${name}.overallTncs`}
                control={control}
                render={({ field }) => (
                    <AutoCompleteWithList
                        title="Select T&C*"
                        direction="vertical"
                        fullWidth
                        options={tncList}
                        getOptionLabel={(option) => `${option?.title?.en || ""} ${option?.title?.zh || ""}`}
                        readOnly={readOnly}
                        error={errors?.overallTncs}
                        helperText={errors?.overallTncs?.message}
                        {...field}
                    />
                )}
            />
        )
    }, { id: "spacing" },
]

const VasFields = ({ name, control, errors = {}, readOnly, vasList }) => [
    {
        id: 'header',
        component: (
            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Fields Marked * Are Required</Typography>
        )
    }, { id: "spacing" },
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Free-VAS Service</Typography>
        )
    }, { id: "spacing" },
    {
        id: 'free-vas',
        component: (
            <Controller
                name={`${name}.freeVas`}
                control={control}
                render={({ field }) => (
                    <VasAutoCompleteWithListAndOptionCheckbox
                        title="Add VAS"
                        direction="vertical"
                        fullWidth
                        options={vasList}
                        getOptionLabel={(option) => `${option?.name?.en || ""} ${option?.name?.zh || ""}`}
                        readOnly={readOnly}
                        error={errors.freeVas}
                        helperText={errors.freeVas?.message}
                        {...field}
                        onSortSelectedItem={(items) => {
                            field.onChange(items, { shouldDirty: true });
                        }}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "vas-quantity",
        component: (
            <Controller
                name={`${name}.quantityOnFreeVas`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Chosen Quantity On Free-VAS"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors.freeVasMax}
                        helperText={errors.freeVasMax?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "hr"
    },
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Paid-VAS Service</Typography>
        )
    }, { id: "spacing" },
    {
        id: 'paid-free-vas',
        component: (
            <Controller
                name={`${name}.paidVas`}
                control={control}
                render={({ field }) => (
                    <VasAutoCompleteWithListAndOptionCheckbox
                        title="Add VAS"
                        direction="vertical"
                        fullWidth
                        options={vasList}
                        getOptionLabel={(option) => `${option?.name?.en || ""} ${option?.name?.zh || ""}`}
                        readOnly={readOnly}
                        error={errors.paidVas}
                        helperText={errors.paidVas?.message}
                        {...field}
                        onSortSelectedItem={(items) => {
                            field.onChange(items, { shouldDirty: true });
                        }}
                    />
                )}
            />
        )
    }, { id: "spacing" },
]

const RecommendProductFields = [
    {
        id: 'select-product',
        renderComponent: ({ control, error, position, name, readOnly }) => (
            <Controller
                name={`${name}.${position}`}
                control={control}
                render={({ field }) => {
                    return (
                        <RecommendProduct
                            value={field.value}
                            readOnly={readOnly}
                            error={error}
                            position={position}
                            onChange={field.onChange}
                        />
                    )
                }}
            />
        )
    }, { id: "spacing" },
]

const RecommendFields = ({ control, errors = {}, readOnly, name }) => [
    {
        id: 'header',
        component: (
            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Suggested to choose 3 Products</Typography>
        )
    }, { id: "spacing" },
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Recommend-Product</Typography>
        )
    }, { id: "spacing" },
    {
        id: 'dynamicList',
        component: (
            <DynamicForm
                control={control}
                errors={errors?.["recommend"]}
                name={`${name}.recommend`}
                fieldComponents={RecommendProductFields}
                style={{ padding: 0 }}
                readOnly={readOnly}
            />
        )
    }
]

export { FlipCardColorFields, GeneralFields, OptionFields, RecommendFields, Step1Fields, TncFields, VasFields };
