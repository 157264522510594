import { useState, useContext, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import {
    useQuery,
    useMutation,
    useQueryClient
} from '@tanstack/react-query'
import _ from 'lodash'
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import {
    PageWrapper,
    Typography,
    Button,
    Form
} from '../../components/share';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import { listCategory, fetchTnc, updateTnc } from '../../apis/termsAndConditions';
import { useParams, useNavigate } from 'react-router-dom';
import { FIELDS } from './formFields';
import { schema }  from './add'
import { getPermission, toDisplayName } from '../../utils'
import { userContext } from '../../context/UserProvider';
import constants from '../../constants';

const SaveButton = styled(Button)`
    && {
        align-self: end; 
        width: 80px;
        margin: 10px 20px 14px 20px;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 20px 14px 20px;
    height: 100%;
    overflow: auto;
`

const getResetData = ({ tncData }) => {
    return {
        code: tncData?.code || "",
        title: tncData?.title || {},
        shortDescription: tncData?.shortDescription || {},
        description: tncData?.description || {},
    }
}

function TermsAndConditionsDetail(props) {
    const navigate = useNavigate();

    let { tncId } = useParams();

    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [user, setUser] = useContext(userContext);

    const { canView, canModify } = getPermission(user?.permissions?.tnc)

    const { control, handleSubmit, watch, formState: { errors, dirtyFields }, reset } = useForm({
        resolver: joiResolver(schema),
    });

    // const { data, error, isError, isLoading } = useQuery({
    //     queryKey: ["category"],
    //     queryFn: listCategory
    // });

    const { data:tncData, error:tncError, isError:isTncError, isLoading:isTncLoading } = useQuery({
        queryKey: ["tncDetail", { tncId }],
        queryFn: async() => await fetchTnc(tncId),
    });

    useEffect(() => {
        if (!isTncLoading && tncData) {
            reset(getResetData({ tncData }))
        }
    }, [tncData])

    useEffect(() => {
        if (!_.isEmpty(errors)) {
            setSnackbar({
                type: "error",
                message: constants.INVALID_INPUT_ERR_MSG
            })
        }
    }, [errors])

    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (payload) => updateTnc(tncId, payload),
        onSuccess: (data) => {
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
            queryClient.invalidateQueries({ queryKey: ['allTnc'] })
            reset(getResetData({ tncData: data }))
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const handleOnSubmit = (formValue) => {
        if (_.isEmpty(dirtyFields)) {
            return setSnackbar({
                type: 'success',
                message: 'Success'
            })
        }
        const payload = {
            category: tncData.category
        };
        _.forEach(dirtyFields, (value, key) => {
            payload[key] = formValue[key]
        })
        mutation.mutate(payload)
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`T&C > ${toDisplayName(tncData?.category)} > ${tncId}`}</Typography>
            </>
        )
    }

    const CardHeader = () => {
        return (
            <SaveButton disabled={!canModify} onClick={() => handleSubmit(handleOnSubmit)()}>Save</SaveButton>
        )
    }

    console.log("errors", errors)

    return (
        <PageWrapper
            loading={isTncLoading || mutation.isLoading}
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            renderCardHeader={CardHeader}
        >
            <Content>
                <Form fields={FIELDS({ control, readOnly: canModify ? false : true, errors })} p={`14px 0px`} />
            </Content>
        </PageWrapper>
    )
}

export default TermsAndConditionsDetail