import ComponentContainer from './share/ComponentContainer';
import { Box, Grid } from '@mui/material'
import { Button, Typography } from './share'
import styled from 'styled-components'
import { styled as styledMui, createTheme, ThemeProvider } from '@mui/material/styles';

const UploadStatus = styledMui(Typography)(
    ({ theme }) => `
    && {
        color: secondary.main;
        padding: 8px 12px;
        background-color: #CACACA;
        border-radius: 4px;
    }
  `,
);


function FileStatus(props){
    return (
        <ComponentContainer direction='vertical' {...props}>
            <Box display="flex" flexDirection="column">
                <Grid container direction="row" alignItems={"center"} spacing={2}>
                    <Grid item>
                        <Typography sx={{ color: "secondary.main" }}>****567A</Typography>
                    </Grid>
                    <Grid item>
                        <UploadStatus>Upload Status: Approved</UploadStatus>
                    </Grid>
                </Grid>
                <Button variant="text">Preview</Button>
            </Box>
        </ComponentContainer>
    )
}

export default FileStatus