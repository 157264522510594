import Header from './share/Header';
import Drawer from './Drawer';
import styled from "styled-components";
import { Outlet } from "react-router-dom";

const Root = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: auto
`

function Layout({ onLogout }){
    return (
        <Root>
            <Drawer onLogout={onLogout} />
            <Content>
                <Header />
                <Outlet />
            </Content>
        </Root>
    )
}

export default Layout;