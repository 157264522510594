import { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash'

function withTabSearchParams(Component, Tabs, extraSearchParams) {
    function NewComponent(props) {
        const [tab, setTab] = useState(0);
        let [searchParams, setSearchParams] = useSearchParams();

        const tabId = searchParams.get('tab');

        const allParams = Object.fromEntries(searchParams.entries())

        useEffect(() => {
            if (!tabId) {
                let param = _.pickBy({ ...allParams, tab: Tabs[0].id }, v => v !== undefined && v !== null && v !== "")
                setSearchParams(param, { replace: true });
            } else {

                const findTabIndex = _.findIndex(Tabs, { id: tabId })
                const curTab = findTabIndex < 0 ? Tabs[0] : Tabs[findTabIndex]
                let param = _.pickBy({ ...allParams, tab: curTab.id }, v => v !== undefined && v !== null && v !== "")
                setSearchParams(param, { replace: true })
                setTab(findTabIndex < 0 ? 0 : findTabIndex)
            }
        }, [Tabs, tabId])

        const updateTab = (value, index) => {
            let param = _.pickBy({ ...allParams, tab: value.id }, v => v !== undefined && v !== null && v !== "")
            setSearchParams(param)
            setTab(index)
        }

        return (
            <Component {...props} updateTab={updateTab} tabIndex={tab} />
        )
    }
    return NewComponent;
}

export default withTabSearchParams