import { joiResolver } from '@hookform/resolvers/joi';
import { Grid, TableCell, TableRow, Breadcrumbs, Link } from '@mui/material';
import Joi from 'joi';
import _ from 'lodash';
import { useContext, useMemo, useState, useEffect } from 'react';
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import {
    Button,
    DataTable,
    PageWrapper,
    Tab2,
    TableStatusFilter,
    OrderTableStatusFilter,
    TextField,
    Typography,
    Form
} from '../../../components/share';
import { snackbarContext } from '../../../context/SnackbarProvider';
import { CustomerInfoFields, DeliveryMethodFields, ProductInfoFields, PaymentFields, ExtraInfoFieldsForProduct, ExternalRemarks } from './formFields';
import {
    Content,
    EditButton,
} from './mobile';
import dayjs from 'dayjs';
import constants from '../../../constants'
import styled from 'styled-components'
import { updateOrder, addOrderItemRemark } from '../../../apis/order';
import {
    useMutation,
    useQueryClient
} from '@tanstack/react-query'
import { userContext } from "../../../context/UserProvider";
import {
    STATUS,
    activityLogSchema,
    generalActivityLogColumns,
    DefRemark
} from './index';
import { getDirtyValues, getPIIDataKeys } from '../../../utils';
import { useTheme } from '@mui/material/styles';

const SaveButton = styled(Button)`
    && {
        align-self: end; 
        min-width: 80px;
        margin: 10px 8px;
        height: 30px;
    }
`

const TABS = [
    {
        label: "Product Information",
        id: "productInformation",
    },
    {
        label: "Payment",
        id: "payment"
    },
    {
        label: "Delivery Method",
        id: "deliveryMethod"
    },
    {
        label: "Customer Information",
        id: "customerInformation",
        canEdit: false,
    },
    {
        label: "External Remarks",
        id: "externalRemarks",
    },
    {
        label: "Activity Log",
        id: "activityLog"
    },
    {
        label: "Extra Info",
        id: "extraInfo",
        canEdit: true,
    }
]

const getResetData = ({ data }) => {
    return {
        status: data?.status,
        externalRemark: data?.externalRemark,
        [`productInformation`]: {
            orderNumber: data?.orderNumber,
            product: data?.product,
            originalPrice: data?.originalPrice,
            discountedPrice: data?.discountedPrice,
            status: data?.status,
            freeVas: data?.freeVas?.length ? data?.freeVas : ["1"],
            paidVas: data?.paidVas?.length ? data?.paidVas : ["1"],
            needRemovalService: data.needRemovalService,
            orderAmountTotal: data?.orderAmountTotal,
            savePersonalInformation: data?.acceptedTncs?.savePersonalInformation,
            directSaleMyClubHKT: data?.acceptedTncs?.directSaleMyClubHKT,
            productTncs: data?.acceptedTncs?.productTncs,
            overallTncs: data?.acceptedTncs?.overallTncs,
            amountTotal: data?.amountTotal,
            quantity: data?.quantity,
            promoCode: {
                code: data?.promoCode,
                name: data?.promoName,
                discount: data?.promoDiscount,
            },
        },
        [`payment`]: {
            transactionCreateDate: data?.paymentInfo?.transactionCreateDate,
            transactionUpdateTime: data?.paymentInfo?.transactionUpdateTime,
            transactionCaptureTime: data?.paymentInfo?.transactionCaptureTime,
            ceksToken: data?.paymentInfo?.creditCard?.ceksToken,
            approvalCode: data?.paymentInfo?.creditCard?.approvalCode,
            cardType: data?.paymentInfo?.creditCard?.type,
            expiryDate: `${data?.paymentInfo?.creditCard?.expiryDate?.month} / ${data?.paymentInfo?.creditCard?.expiryDate?.year}`,
            holderName: data?.paymentInfo?.creditCard?.holderName,
            oppTransactionNumber: data?.paymentInfo?.oppTransactionNumber,
            oppTransactionStatus: data?.paymentInfo?.oppTransactionStatus,
            oppTransactionResponseCode: data?.paymentInfo?.oppTransactionResponseCode,
            oppTransactionPaymentResult: data?.paymentInfo?.oppTransactionPaymentResult,
            oppTransactionApprovalStatus: data?.paymentInfo?.oppTransactionApprovalStatus,
        },
        [`deliveryMethod`]: {
            deliveryInfo: {
                deliveryDate: data?.deliveryInfo?.deliveryDate || "",
                deliveryTime: data?.deliveryInfo?.deliveryTime || "",
                pickup: data?.deliveryInfo?.pickup,
                deliveryMethod: data?.deliveryInfo?.deliveryMethod,
                deliveryAddress: data?.deliveryInfo?.deliveryAddress?.filter(n => n)?.toString() || "",
                deliveryFee: data?.deliveryFee,
                mobileNumber: data?.userInfo?.mobileNumber,
            },
            contactUserInfo: {
                title: data?.contactUserInfo?.title,
                fullName: data?.contactUserInfo?.fullName,
                email: data?.userInfo?.email,
                mobileNumber: data?.contactUserInfo?.mobileNumber,
                idType: data?.contactUserInfo?.idType,
                idNumber: data?.contactUserInfo?.idNumber,
            },
            maillingAddress: {
                address1: data?.userInfo?.mailingAddress?.[0],
                address2: data?.userInfo?.mailingAddress?.[1],
                mailingDistrict: data?.userInfo?.mailingDistrict,
            }
        },
        [`customerInformation`]: {
            title: data?.userInfo?.title,
            fullName: data?.userInfo?.fullName,
            idType: data?.userInfo?.idType,
            idNumber: data?.userInfo?.idNumber,
            companyName: data?.userInfo?.companyName,
            companyPosition: data?.userInfo?.companyPosition,
            email: data?.userInfo?.email,
            loginEmail: data?.customerLoginEmail,
            mobileNumber: data?.userInfo?.mobileNumber,
            address1: data?.userInfo?.mailingAddress?.[0],
            address2: data?.userInfo?.mailingAddress?.[1],
            mailingDistrict: data?.userInfo?.mailingDistrict,
            // contactUserInfo: {
            //     title: data?.contactUserInfo?.title,
            //     fullName: data?.contactUserInfo?.fullName,
            //     email: data?.contactUserInfo?.email,
            //     mobileNumber: data?.contactUserInfo?.mobileNumber,
            //     idType: data?.contactUserInfo?.idType,
            //     idNumber: data?.contactUserInfo?.idNumber,
            // },
        },
        // [`externalRemarks`]: {
        //     externalRemark: data?.externalRemark
        // },
        [`activityLog`]: {
            remarks: data?.remarks
        },
        [`extraInfo`]: {
            ...data?.extraInfo
        }
    }
}

//mailingAddress, idNumber, mobileNumber

function ProductDetail({ data, permission, pIIPermission, isLoading }) {

    const navigate = useNavigate();
    const theme = useTheme();

    const [tab, setTab] = useState(0); //tabIndex
    const [user, setUser] = useContext(userContext);
    const [snackbar, setSnackbar] = useContext(snackbarContext);

    const queryClient = useQueryClient()

    let { orderItemId } = useParams();

    const { canView, canModify } = permission;
    const { canView: canViewPII } = pIIPermission

    const { control, handleSubmit, watch, formState: { errors, dirtyFields }, reset, getValues } = useForm({
        // resolver: joiResolver(schema),
    });

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: `${`activityLog`}.remarks`
    });

    useEffect(() => {
        if (data) {
            reset(getResetData({ data }))
        }
    }, [data])

    // const pIIKeys = getPIIDataKeys([
    //     "userInfo.idNumber", 
    //     "userInfo.mobileNumber", 
    //     "userInfo.mailingAddress", 
    //     // "contactUserInfo.idNumber", 
    //     "contactUserInfo.mobileNumber"
    // ], data)

    const mutation = useMutation({
        mutationFn: (payload) => updateOrder(orderItemId, payload),
        onSuccess: (responseData) => {
            setSnackbar({
                type: 'success',
                message: 'Success'
            });
            reset(getResetData({ data: responseData?.data }))
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const handleOnSubmit = (formValue) => {
        // console.log("formValue", formValue)
        let payload = getDirtyValues(dirtyFields, formValue)
        mutation.mutate(payload)
        console.log("payload", payload)
        return
        // if (tab === 5) {
        //     //extra info
        //     if (_.isEmpty(dirtyFields)) {
        //         return setSnackbar({
        //             type: 'success',
        //             message: 'Success'
        //         })
        //     }
        //     let payload = getDirtyValues(dirtyFields.extraInfo, formValue.extraInfo)
        //     console.log("payload", payload)
        //     mutation.mutate({ extraInfo: payload })
        // } else if (tab === 3) {
        //     //customer info
        //     if (_.isEmpty(dirtyFields?.[`customerInformation`])) {
        //         setSnackbar({
        //             type: 'success',
        //             message: 'Success'
        //         })
        //         return
        //     }
        //     let payload = getDirtyValues(dirtyFields[`customerInformation`], formValue[`customerInformation`])
        //     if (payload.fullName) {
        //         payload.fullName = {
        //             ...formValue[`customerInformation`].fullName,
        //             ...payload.fullName,
        //         }
        //     }
        //     if (payload?.['contactUserInfo']?.fullName) {
        //         payload['contactUserInfo'].fullName = {
        //             ...formValue[`customerInformation`]['contactUserInfo'].fullName,
        //             ...payload['contactUserInfo'].fullName,
        //         }
        //     }
        //     let mailingAddress = [];
        //     if (payload?.['address1'] || payload?.['address2']) {
        //         mailingAddress = data?.userInfo?.['mailingAddress'] || []
        //         if (payload?.['address1']) {
        //             if (!mailingAddress.length) {
        //                 mailingAddress.push(payload['address1'])
        //             } else {
        //                 mailingAddress[0] = payload['address1']
        //             }
        //             delete payload['address1']
        //         }
        //         if (payload?.['address2']) {
        //             if (!mailingAddress.length) {
        //                 mailingAddress.push(payload['address2'])
        //             } else {
        //                 mailingAddress[1] = payload['address2']
        //             }
        //             delete payload['address2']
        //         }
        //         payload['mailingAddress'] = mailingAddress
        //     }
        //     payload = {
        //         userInfo: payload
        //     }
        //     console.log("payload", payload)
        // }

    }

    const activityLogMutation = useMutation({
        mutationFn: (payload) => addOrderItemRemark(orderItemId, payload),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["orderDetail", { orderItemId }] })
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const handleOnSubmitActivityLog = (index) => {
        try {
            let tmp = getValues(`${`activityLog`}.remarks`)?.[index];
            const payload = {
                item: tmp?.item,
                reason: tmp?.reason,
                remark: tmp?.remark
            }
            const result = activityLogSchema.validate(payload);
            if (result.error) {
                const displayErr = _.map(result?.error.details, (error) => error.message).join(" , ")
                setSnackbar({
                    type: 'error',
                    message: displayErr
                })
            } else {
                activityLogMutation.mutate(payload)
            }
        } catch (error) {
            console.log("handleOnSubmitRemarks-error", error)
        }
    }

    const ActivityLogColumns = () => {
        return [
            ...generalActivityLogColumns(control, `${`activityLog`}.remarks`),
            {
                dataKey: 'remove',
                label: 'Action',
                width: 100,
                style: {
                    justifyContent: 'center',
                },
                renderCell: (item, index) => {
                    if (!item.canRemove) return null
                    return (
                        <Grid container direction='row'>
                            <Grid item xs>
                                <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => remove(index)} />
                            </Grid>
                            <Grid item xs>
                                <Button
                                    variant={"underline"}
                                    color="neon"
                                    onClick={() => handleOnSubmitActivityLog(index)}
                                    disabled={!canModify}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>

                    )
                }
            }
        ]
    }

    const Header = () => {
        return (
            <div style={{ flex: 1 }}>
                <Breadcrumbs aria-label="breadcrumb" separator=">">
                    <Link underline="hover" color="inherit" component={RouterLink} to={`/order?type=orders`}>
                        <Typography bold variant="main">All Order</Typography>
                    </Link>
                    <Link underline="hover" color="inherit" component={RouterLink} to={`/order?type=orderItems&orderId=${data?.orderId}&orderNumber=${data?.orderNumber}`}>
                        <Typography bold variant="main">{data?.orderNumber}</Typography>
                    </Link>
                    <Typography bold variant="main">{data?.subOrderNumber}</Typography>
                </Breadcrumbs>
            </div>
        )
    }

    const renderForm = () => {
        const currentTab = TABS[tab].id
        switch (currentTab) {
            case `productInformation`: {
                return (
                    <Form
                        fields={ProductInfoFields({
                            name: `productInformation`,
                            control,
                            errors: errors[`productInformation`],
                            readOnly: canModify && TABS[0]?.canEdit ? false : true
                        })}
                        p={`14px 0px`}
                    />
                )
            }

            case `payment`: {
                return (
                    <Form
                        fields={PaymentFields({
                            name: `payment`,
                            control,
                            errors: errors[`payment`],
                            readOnly: canModify && TABS[1]?.canEdit ? false : true
                        })}
                        p={`14px 0px`}
                    />
                )
            }

            case `deliveryMethod`: {
                return (
                    <Form
                        fields={DeliveryMethodFields({
                            name: `deliveryMethod`,
                            control,
                            errors: errors[`deliveryMethod`],
                            readOnly: canModify && TABS[2]?.canEdit ? false : true,
                            canViewPII: canViewPII
                        })}
                        p={`14px 0px`}
                    />
                )
            }

            case `customerInformation`: {
                return (
                    <Form
                        fields={CustomerInfoFields({
                            name: `customerInformation`,
                            control,
                            errors: errors[`customerInformation`],
                            readOnly: canModify && TABS[3]?.canEdit ? false : true,
                            canViewPII: canViewPII
                        })}
                        p={`14px 0px`}
                    />
                )
            }

            case `externalRemarks`: {
                return (
                    <Form
                        fields={ExternalRemarks({
                            name: "",
                            control,
                            // errors: errors[`externalRemarks`],
                        })}
                        p={`14px 0px`}
                    />
                )
            }

            case `activityLog`: {
                return (
                    <DataTable
                        data={fields}
                        columns={ActivityLogColumns()}
                        renderCustomListItem={() => {
                            const onClickAdd = () => append({ ...DefRemark, updatedBy: user, _id: _.uniqueId('remark-'), canRemove: true })
                            return (
                                <TableRow>
                                    <TableCell sx={{ border: 'none' }}>
                                        <Button
                                            variant={"underline"}
                                            color="neon"
                                            disabled={!canModify}
                                            onClick={onClickAdd}
                                        >
                                            {"+ Add remarks"}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )
                        }}
                    />
                )
            }

            case `extraInfo`: {
                return (
                    <Form
                        fields={ExtraInfoFieldsForProduct({
                            name: `extraInfo`,
                            control,
                            errors: errors[`extraInfo`],
                            readOnly: canModify && TABS[6]?.canEdit ? false : true
                        })}
                        p={`14px 0px`}

                    />
                )
            }
        }
    }

    return (
        <PageWrapper
            loading={isLoading || activityLogMutation.isLoading}
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
        >
            <Grid container direction='row' alignItems='center' justifyContent='flex-end'>
                <Controller
                    name={"status"}
                    control={control}
                    initialValue={data?.status}
                    render={({ field }) => {
                        return (
                            <TableStatusFilter
                                // value={data?.status} 
                                title="Internal Status"
                                options={STATUS}
                                style={{ margin: `0px 10px` }}
                                titleStyle={{ color: theme.palette.primary.main }}
                                disabled={field.value === 'cancelled'}
                                width={240}
                                {...field}
                            />
                        )
                    }
                    }
                />
                {/* <Typography variant="header3" sx={{ mr: 1 }}>Edited on {dayjs().format(constants.DATE_TIME_FORMAT)}</Typography> */}
                {/* <EditButton onClick={() => handleSubmit(handleOnSubmit)()}>Edit</EditButton> */}
                {<SaveButton disabled={!canModify} onClick={() => handleSubmit(handleOnSubmit)()}>Save</SaveButton>}
            </Grid>
            <Tab2
                tabs={TABS}
                selected={TABS[tab].id}
                onChange={(id, index) => setTab(index)}
            />
            <Content>
                {renderForm()}
            </Content>
        </PageWrapper>
    )
}

export default ProductDetail