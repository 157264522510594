import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragOverlay,
    DragStartEvent,
    KeyboardSensor,
    PointerSensor,
    UniqueIdentifier,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { useCallback, useMemo, useState, useRef, useLayoutEffect } from "react";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import TableCell from '@mui/material/TableCell'
import styled from 'styled-components';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import SortableItem from './SortableItem';
import ColumnItem from './ColumnItem';

const HeaderWrapper = styled.div`
    display: flex; 
    flex-wrap: nowrap; 
    ${props => props.height && `height: ${props.height}px;`}
    padding: 2px 10px;
`

export const Column = styled(TableCell)`
    && {
        display: flex;
        ${props => props.width && `width: ${props.width}px;`}
        ${props => props.height && `height: ${props.height}px;`}
        ${props => props.flexGrow && `flex-grow: ${props.flexGrow};`}
        align-items: center;
        box-sizing: border-box;
        padding: 0px;
        margin: 4px 0px;
        overflow: hidden;
        border: none;
    }
`

const RowWrapper = styled.div`
    height: calc(100% - 80px );
    overflow: auto;
    display: flex; 
    flex-wrap: nowrap; 
    flex-direction: column;
    padding: 0px 10px;
`

const ListItem = styled.li`
    list-style-type: none;
    ${props => props.isActive && `opacity: 0.2;`}
    ${props => props.isDragged && `
        cursor: grabbing;
        & .handle {
            cursor: grabbing;
        }
    `}
    display: flex;
    flex-wrap: nowrap;
`

function SortableList({ data, columns, rowHeight = 50, onRowClick, draggable = true, headerHeight = 50, headerStyle, rowStyle, onSort, containerStyle }) {

    const columnsRef = useRef({});
    const itemIds = useMemo(() => data.map((item) => item._id), [data]);
    const [activeId, setActiveId] = useState();

    const [forLayoutRerender, setForLayoutRerender] = useState(false)

    useLayoutEffect(() => {
        if (forLayoutRerender) return
        setForLayoutRerender(true)
    }, [columnsRef?.current])

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const handleDragStart = useCallback((event) => {
        setActiveId(event.active.id);
    }, []);

    const handleDragEnd = useCallback((event) => {
        const { active, over } = event;
        if (over && active.id !== over.id) {
            const oldIndex = itemIds.indexOf(active.id);
            const newIndex = itemIds.indexOf(over.id);
            if (onSort) onSort(oldIndex, newIndex);
        }
        setActiveId(undefined);
    }, [itemIds, onSort]);

    const handleDragCancel = () => {
        setActiveId(undefined);
    };

    const headerRenderer = (headerProps) => {
        const { label, columnIndex, dataKey, sortable = true } = headerProps
        // const Content = columns[columnIndex]?.headerContentRenderer || null;

        return (
            <ColumnItem
                ref={(e) => columnsRef.current[dataKey] = e}
                id={headerProps.dataKey}
                key={`${dataKey}`}
                component="div"
                variant="head"
                width={headerProps.width}
                flexGrow={headerProps.flexGrow}
                sx={{
                    display: 'block',
                }}
                align={'left'}
                sortable={sortable}
            >
                {label}
            </ColumnItem>
        );
    }

    const renderRow = ({item, isActive, isDragged, ref, props, handleProps }) => {

        return (
            <ListItem 
                ref={ref} 
                key={item._id} 
                isActive={isActive} 
                isDragged={isDragged} 
                {...props} 
                style={{
                    ...props.style, 
                    ...rowStyle,
                }} 
            >
                {
                    columns.map((headerProps, columnIndex) => {

                        if (columnIndex === 0) {
                            return (
                                <Column
                                    id={headerProps.dataKey}
                                    key={`cell-column-${headerProps.dataKey}`}
                                    component="div"
                                    variant="body"
                                    sx={{
                                        display: 'block',
                                    }}
                                    align={'left'}
                                    height={rowHeight}
                                    style={{
                                        width: headerProps?.width || 100
                                    }}
                                >
                                    <DragHandleIcon {...handleProps} style={{ cursor: 'pointer' }} />
                                </Column>
                            )
                        }

                        const renderContent = () => {
                            if (headerProps?.type === 'actions') {
                                return headerProps.getActions(item)
                            }
                            if(headerProps?.renderCell) {
                                return headerProps.renderCell(item)
                            }
                            if(headerProps?.valueGetter) {
                                return headerProps.valueGetter(item)
                            }
                            return item?.[headerProps.dataKey]
                        }

                        return (
                            <Column
                                id={headerProps.dataKey}
                                key={`cell-column-${headerProps.dataKey}`}
                                component="div"
                                variant="body"
                                align={'left'}
                                height={rowHeight}
                                style={{
                                    width: headerProps?.width || 100,
                                    gap: "4px",
                                }}
                            >
                                {renderContent()}
                            </Column>
                        )
                    })
                }
            </ListItem>
        );
    }

    return (
        <div style={{ height: '100%', width: '100%', overflow: 'hidden', ...containerStyle }}>
            <HeaderWrapper height={headerHeight} style={{ ...headerStyle }}>
                {columns.map((item, columnIndex) => {
                    return headerRenderer({ ...item, columnIndex })
                })}
            </HeaderWrapper>
            <RowWrapper>
                <DndContext
                    sensors={sensors}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    onDragCancel={handleDragCancel}
                    collisionDetection={closestCenter}
                    modifiers={[restrictToFirstScrollableAncestor]}
                >
                    <SortableContext
                        items={itemIds}
                        strategy={verticalListSortingStrategy}
                    >
                        <AutoSizer>
                            {({ height, width }) => (
                                <List
                                    height={height}
                                    itemCount={data.length}
                                    itemSize={rowHeight+8}  // +8 because of mui table cell margin
                                    width={width}
                                >
                                    {({ index, style }) => {
                                        const item = data[index];
                                        return (
                                            <div style={style} key={item._id}>
                                                <SortableItem
                                                    key={item._id}
                                                    id={item._id}
                                                    item={item}
                                                    renderFunction={renderRow}
                                                    position={index}
                                                />
                                            </div>
                                        );
                                    }}
                                </List>
                            )}
                        </AutoSizer>
                    </SortableContext>
                    <DragOverlay adjustScale={false} dropAnimation={null}>
                        {activeId ? (
                            <SortableItem
                                id={activeId}
                                item={data[itemIds.indexOf(activeId)]}
                                renderFunction={renderRow}
                                isDragged
                            />
                        ) : (
                            <></>
                        )}
                    </DragOverlay>
                </DndContext>
            </RowWrapper>
        </div>
    )
}

export default SortableList