import styled from 'styled-components';
import { Grid } from '@mui/material';
import Typography from '../components/share/Typography';
import { useState, useRef } from 'react';
import { ReactComponent as LeftArrow } from '../assets/select-white-left-arrow.svg'
import { ReactComponent as RightArrow } from '../assets/select-white-right-arrow.svg'
import { styled as styledMui, createTheme, ThemeProvider } from '@mui/material/styles';

const Arrow = styledMui(Grid)(
    ({ theme }) => `
    width: 40px;
    background-color: ${theme.palette.mainColor.main};
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    cursor: pointer;
  `,
);

const Tab = styledMui('div')(
    ({ theme, selected, error }) => `
    height: 40px;
    background-color: ${selected ? theme.palette.mainColor.main : theme.palette.secondary.main};
    ${error ? `background-color: ${theme.palette.error.main};` : ""}

    padding: 0px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    color:${selected ? "#fff" : "#7B7B7B"};
    ${error ? `color: #fff;` : ""}
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
  `,
);

const Content = styled(Grid)`
    && {
        overflow-y: hidden;
        overflow-x: hidden;
        flex-wrap: nowrap;

        > * {
            margin-right: 4px;
        }
    }
`

function OrangeTab({ data = [], selected, onUpdate, errors }) {

    // console.log("OrangeTab-errors", errors)

    const itemRef = useRef({})


    const executeLeftScroll = () => {
        const target = selected ? selected - 1 : 0;
        itemRef.current.scrollLeft -= 50
        onUpdate && onUpdate(target)
    }

    const executeRightScroll = () => {
        const max = data.length - 1;
        const target = selected >= max ? max : selected + 1;
        itemRef.current.scrollLeft += 50
        onUpdate && onUpdate(target)
    }

    const handleOnClickTab = (index) => {
        onUpdate && onUpdate(index)
    }


    return (
        <Grid container direction='row' alignItems='center'>
            <Arrow item onClick={executeLeftScroll}>
                <LeftArrow />
            </Arrow>
            <Content ref={itemRef} item xs container direction='row' alignItems='center'>
                {
                    data.map((item, position) => {
                        const isSelected = position === selected
                        return (
                            <Tab key={item.name} selected={isSelected} error={errors?.[item.id] ? true : false} onClick={() => handleOnClickTab(position)}>
                                <Typography noWrap>{item.name}</Typography>
                            </Tab>
                        )
                    })
                }
            </Content>
            <Arrow item onClick={executeRightScroll}>
                <RightArrow />
            </Arrow>
        </Grid>
    )
}

export default OrangeTab;