import { request } from './index';
import constants from '../constants';

const listTutorial = (category) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "get",
            url: `${constants.API_ENDPOINT}/tutorial?category=${category}&sort=sort`
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const updateAllTutorial = (payload) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "put",
            url: `${constants.API_ENDPOINT}/tutorial`,
            data: payload
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

export {
    listTutorial,
    updateAllTutorial,
}
