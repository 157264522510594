import InputBase from '@mui/material/InputBase';
import { ReactComponent as SearchIcon } from '../../assets/Search.svg';
import styled from "styled-components";

function Search(props) {
    return (
        <InputBase
            sx={{ m: 2, height: 36, width: 228, border: `1px solid #C3C1C1`, color: "info.main", borderRadius: 1 }}
            placeholder="Search"
            startAdornment={(<SearchIcon style={{ padding: 8 }} />)}
            {...props}
        />
    )
}

export default Search