import * as React from 'react';
import {
    Checkbox,
    FormGroup,
    FormControl,
    FormControlLabel
} from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { ReactComponent as SquareCheckedIcon } from '../../assets/checkbox-checked.svg';
import { ReactComponent as SquareUncheckIcon } from '../../assets/checkbox-uncheck.svg';
import { styled as styledMui } from '@mui/material/styles';
import ComponentContainer from './ComponentContainer';


const Container = styledMui(FormControlLabel)(
    ({ theme, direction }) => `
    ${direction === 'column' ? `margin-bottom: 15px;` : ""}
    ${direction && direction === 'row' ? `margin-left: 2px;` : ""}
    color: ${theme.palette.primary.main};
  `,
);

const Circle = {
    checked: <RadioButtonCheckedIcon />,
    uncheck: <RadioButtonUncheckedIcon />,
}

const Square = {
    checked: <SquareCheckedIcon />,
    uncheck: <SquareUncheckIcon />,
}

export default function RadioButtonsGroup({ title, options, row, fullWidth = true, disabled, titleStyle, onChange, value = null, iconType = 'circle', ...props }) {
    const handleChange = (event) => {
        const newValue = event.target.value
        onChange && onChange(value === newValue ? null : newValue)
    };


    const renderOptions = () => {
        if (!options) return []
        return options.map((item) => {
            const isChecked = value === item.value
            return (
                <Container
                    key={item.value}
                    direction={row ? 'row' : 'column'}
                    value={item.value}
                    control={<Checkbox
                        checkedIcon={iconType === 'square' ? Square.checked : Circle.checked}
                        icon={iconType === 'square' ? Square.uncheck : Circle.uncheck}
                        checked={isChecked}
                        onChange={handleChange}
                    />}
                    label={item.labelComponent ? item.labelComponent : item.label}
                    sx={{
                        "& .MuiFormControlLabel-label.Mui-disabled": {
                            color: "primary.main"
                        }
                    }}
                />
            )
        })
    }

    return (
        <FormControl fullWidth={fullWidth} disabled={disabled} {...props}>
            <ComponentContainer {...props} title={title} align='flex-start'>
                <FormGroup row>
                    {renderOptions()}
                </FormGroup>
            </ComponentContainer>
        </FormControl>
    )
}

RadioButtonsGroup.defaultProps = {
    options: [
        { value: "female", label: "Female" },
        { value: "male", label: "Male" },
    ],
};