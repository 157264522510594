import { Grid, Box, List, ListItem, Button } from '@mui/material';
import Typography from '../Typography'
import { Controller } from "react-hook-form";
import RadioGroup from '../RadioGroup';
// import Button from '../Button'
import styled from 'styled-components'

const OptionText = styled(Button)`
    && {
        color: #333333;
        ${props => props.selected && `color: #F2A000;`}
        cursor: pointer;
        background-color: transparent;
    }
`

const opts = [
    {
        label: 'Disable All',
        value: 'disable'
    },
    {
        label: 'Enable All',
        value: 'enable'
    },
    {
        label: 'Enable All With Mandatory',
        value: 'enableWithMandatory'
    }
];

function CheckAllComponent({ title, onChange, value, readOnly }) {

    const handleOnClick = (id) => {
        onChange && onChange(id)
    }

    return (
        <Box display="flex" flexDirection="row" alignItems='center' height={36} width="fit-content" bgcolor="#FFF6C6" style={{ padding: `10px 20px`, borderRadius: 5 }}>
            <Typography variant="header" noWrap style={{ marginRight: 40 }}>{title}</Typography>
            {
                opts.map((item) => {
                    // const selected = value === item.value
                    return (
                        <div style={{ marginRight: 40 }}>
                            <OptionText onClick={() => handleOnClick(item.value)}>{item.label}</OptionText>
                        </div>
                    )
                })
            }
        </Box>
    )
}

export default CheckAllComponent