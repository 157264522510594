import { useState, useContext, useCallback, useMemo, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash'
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import {
    PageWrapper,
    Typography,
    Button,
    Form
} from '../../components/share';
import { Fields } from './formFields';
import { fetchUser, updateUser } from '../../apis/userManagement'
import {
    useMutation,
    useQuery,
    useQueryClient
} from '@tanstack/react-query';
import { userContext } from '../../context/UserProvider';
import { getPermission } from '../../utils'

const SaveButton = styled(Button)`
    && {
        align-self: end; 
        width: 80px;
        margin: 10px 20px 14px 20px;
    }
`

const Content = styled.div`
    margin: 20px;
    height: 100%;
    overflow: auto;
`

const schema = Joi.object({
    en: Joi.string().empty(""),
    zh: Joi.string().empty(""),
}).messages({
    "any.required": "Required",
    "object.base": "Required"
});

function UserDetail() {
    const navigate = useNavigate();

    let { userId } = useParams();

    const [user, setUser] = useContext(userContext);

    const { canView, canModify } = getPermission(user?.permissions?.adminUser)

    const { control, handleSubmit, watch, formState: { errors }, reset, setValue } = useForm({
        // resolver: joiResolver(schema),
    });

    const { data, error, isError, isLoading } = useQuery({
        queryKey: ["/user", { userId }],
        queryFn: async () => await fetchUser(userId),
    });

    const handleOnSubmit = (formValue) => {
        console.log("formValue", formValue)
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`User Management`}</Typography>
            </>
        )
    }

    const CardHeader = () => {
        return (
            <SaveButton disabled={!canModify} onClick={() => handleSubmit(handleOnSubmit)()}>Save</SaveButton>
        )
    }

    return (
        <PageWrapper
            loading={isLoading}
            renderHeader={Header}
            renderCardHeader={CardHeader}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
        >
            <Content>
                <Form fields={Fields({ control, errors: errors })} p={`14px 0px`} />
            </Content>
        </PageWrapper>
    )
}

export default UserDetail;