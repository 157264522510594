import { Grid } from '@mui/material';
import {
    useQuery,
    useMutation,
    useQueryClient
} from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useContext, useLayoutEffect, useMemo, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Joi from 'joi';
import { deleteProduct, fetchSortingProduct, updateProductStatus, updateSortingProduct } from '../../apis/product';
import {
    Button,
    PageWrapper,
    Searchbar,
    StatusButton,
    TableStatusFilter,
    Typography,
    UploadComponent,
    FilterButton,
    Tab
} from '../../components/share';
import Table, { DeleteActionIcon, UnderlineText } from '../../components/share/Table';
import constants from '../../constants';
import { alertDialogContext } from '../../context/AlertDialogProvider';
import { snackbarContext } from '../../context/SnackbarProvider';
import { userContext } from '../../context/UserProvider';
import { companiesContext } from '../../context/CompaniesProvider';
import { getPermission, toDisplayName } from '../../utils';
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import _ from 'lodash'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditNoteIcon from '@mui/icons-material/EditNote';
import withTabSearchParams from '../../hoc/withTabSearchParams';
import ProductSortableList from '../../components/product/ProductSortableList';
import { ProductCategoryOptions } from '../../components/Drawer';

const STATUS = [
    {
        name: "All",
        _id: null
    },
    {
        name: "Active",
        _id: "active",
    },
    {
        name: "Suspended",
        _id: "suspended",
    },
    {
        name: "Expired",
        _id: "expired",
    },
    {
        name: "Draft",
        _id: "draft",
    },
]

export const TABS = [
    {
        name: "All",
        id: undefined
    },
    {
        name: "Reordering",
        id: 'reordering'
    },
]


const margin = 10;

const reorderSchema = Joi.object({
    product: Joi.any(),
})

const getResetInfo = ({ data }) => {
    return {
        product: [
            ...data,
        ]
    }
}

function ProductPage({ updateTab, tabIndex }) {
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [user, setUser] = useContext(userContext);
    const [dialog, setDialog] = useContext(alertDialogContext);
    const [companies, setCompanies, VIPCompanies] = useContext(companiesContext);
    const [filter, setFilter] = useState({
        keyword: "",
        status: null
    });

    const { canView, canModify } = getPermission(user?.permissions?.product)

    const queryClient = useQueryClient()
    const productType = searchParams.get('type');

    const currentTab = tabIndex ? TABS?.[tabIndex]?.id : TABS?.[0]?.id;
    const isReordering = tabIndex ? TABS?.[tabIndex]?.id === 'reordering' : false;

    const { data: sortingData, error: sortingError, isError: isSortingError, isLoading: isSortingLoading, refetch: refetchSorting } = useQuery({
        queryKey: ["getSortingProducts", { type: productType }],
        queryFn: async () => await fetchSortingProduct({ type: productType, company: filter?.company || undefined }),
        enable: canView && isReordering,
    });

    const { control, handleSubmit, watch, formState: { errors, dirtyFields }, reset, setValue, getValues } = useForm({
        resolver: joiResolver(reorderSchema),
    });

    useLayoutEffect(() => {
        if (!productType) {
            navigate('/product?type=apple')
        }
    }, [productType])

    useEffect(() => {
        if (isReordering && sortingData && VIPCompanies) {
            reset(getResetInfo({ data: sortingData }))
        }
    }, [isReordering, sortingData, VIPCompanies])

    useEffect(() => {
        if (isReordering && ((isSortingError && sortingError))) {
            const tmpErr = sortingError
            setSnackbar({
                type: 'error',
                message: `${tmpErr.code} ${tmpErr.err ? `- ${tmpErr.err}` : ""}`
            })
        }
    }, [isReordering, sortingError, isSortingError])

    useEffect(() => {
        if (isReordering) refetchSorting();
    }, [isReordering, filter])

    useEffect(() => {
        if (!_.isEmpty(errors)) {
            setSnackbar({
                type: "error",
                message: constants.INVALID_INPUT_ERR_MSG
            })
        }
    }, [errors])

    const deleteMutation = useMutation({
        mutationFn: (id) => deleteProduct(id),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['/product'] })
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
        },
        onError: (error, variables) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const statusMutation = useMutation({
        mutationFn: (payload) => updateProductStatus(payload.id, payload.payload),
        onSuccess: (data, variables) => {
            // queryClient.invalidateQueries({ queryKey: ["/product"] })

            setSnackbar({
                type: 'success',
                message: 'Success'
            })
        },
        onError: (error, variables) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const reorderMutation = useMutation({
        mutationFn: (payload) => updateSortingProduct({ type: productType, payload }),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ["getSortingProducts", { type: productType }] })

            setSnackbar({
                type: 'success',
                message: 'Success'
            })
        },
        onError: (error, variables) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    });

    const handleOnSubmit = (formValue) => {
        // console.log('submit form value', formValue)

        const payload = {
            sorting: formValue.product.map((item) => item?._id)
        }

        reorderMutation.mutate(payload)
    }

    const hadnleOnDelete = (id) => {
        setDialog({
            open: true,
            title: "Are you sure to delete this product?",
            handleOnClickOK: () => deleteMutation.mutate(id)
        })
    }

    const navigateToProductDetail = (id) => {
        navigate(`/product/${id}?type=${productType}`)
    }

    const navigateToCloneProduct = (id) => {
        navigate(`/product/${productType}/add?productId=${id}`)
    }

    // const onUpdateStatus = (id, newStatus, action) => {
    //     console.log("onUpdateStatus", newStatus, action)
    //     statusMutation.mutate({ id: id, payload: { status: newStatus }, action })
    // }

    const columns = useMemo(() => {
        return [
            { field: '_id', headerName: 'Product ID', width: 250, sortable: false, },
            { field: 'brandName.en', headerName: 'Brand Name (En)', width: 160, valueGetter: (params) => params.row?.brandName?.en },
            { field: 'brandName.zh', headerName: 'Brand Name (Zh)', width: 160, valueGetter: (params) => params.row?.brandName?.zh },
            { field: 'productName.en', headerName: 'Product Name (En)', width: 180, valueGetter: (params) => params.row?.productName?.en },
            { field: 'productName.zh', headerName: 'Product Name (Zh)', width: 180, valueGetter: (params) => params.row?.productName?.zh },
            { field: 'publishStartDate', headerName: 'Start Date & Time', width: 160, valueGetter: (params) => params.value ? dayjs(params.value).format(constants.DATE_TIME_FORMAT) : "" },
            { field: 'publishEndDate', headerName: 'End Date & Time', width: 160, valueGetter: (params) => params.value ? dayjs(params.value).format(constants.DATE_TIME_FORMAT) : "" },
            { field: 'markedPrice', headerName: 'Original Price', width: 140 },
            { field: 'sellingPrice', headerName: 'Discounted Price', width: 140 },
            {
                field: 'status',
                headerName: 'Status',
                width: 130,
                type: 'actions',
                sortable: false,
                renderCell: (params) => {
                    const { id, api, value, row } = params

                    const onChange = (newStatusValue) => {
                        statusMutation.mutate(
                            {
                                id,
                                payload: { status: newStatusValue }
                            },
                            {
                                onSuccess: () => {
                                    api.updateRows([{ id: id, ...row, status: newStatusValue }])
                                },
                                onError: () => {
                                    api.updateRows([{ id: id, ...row }])
                                }
                            }
                        )
                    }

                    return (
                        <StatusButton
                            value={value}
                            onChange={onChange}
                            readOnly={!canModify}
                            options={[
                                {
                                    name: "Draft",
                                    id: "draft",
                                    color: "#4BA28D",
                                    Icon: (<EditNoteIcon />)
                                },
                                {
                                    name: "Pending",
                                    id: "pending",
                                    color: "#5D4986",
                                    Icon: (<CheckCircleOutlineIcon />)
                                },
                                {
                                    name: "Active",
                                    id: "active",
                                    // id: "published",
                                    color: "#306097",
                                    canEdit: true,
                                    Icon: (<CheckCircleOutlineIcon />)
                                },
                                {
                                    name: "Suspended",
                                    id: "suspended",
                                    color: "#f6f6f6",
                                    textColor: "#9B9B9B",
                                    canSelect: true,
                                    Icon: (<RemoveCircleOutlineIcon />)
                                },
                                {
                                    name: "Expired",
                                    id: "expired",
                                    color: "#8D9A66",
                                    Icon: (<CheckCircleOutlineIcon />)
                                },
                            ]}
                        />
                    )
                }
            },
            {
                field: 'actions',
                headerName: 'Action',
                type: 'actions',
                width: 140,
                headerAlign: 'flex-start',
                getActions: (params) => {
                    const canDelete = params?.row?.status === 'draft' ? true : false
                    return [
                        <UnderlineText onClick={() => navigateToProductDetail(params.row._id)} disabled={!canView}>View</UnderlineText>,
                        <UnderlineText onClick={() => navigateToCloneProduct(params.row._id)} disabled={!canModify}>Clone</UnderlineText>,
                        <DeleteActionIcon disabled={!canModify} hide={!canDelete} onClick={() => hadnleOnDelete(params.row._id)} />
                    ]
                },
            }
        ]
    }, [canModify])

    const onApiError = (error) => {
        setSnackbar({
            type: 'error',
            message: `${error?.response?.data?.result?.errorMessage?.en || error?.message}`
        })
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Product > ${toDisplayName(productType)}`}</Typography>
                {/* <UploadComponent 
                        renderComponent={({ selectFile }) => (
                            <Button 
                                color="blue" 
                                style={{ marginRight: margin }}
                                onClick={selectFile}
                            >
                                Import
                            </Button>
                        )}
                    /> */}
                {
                    canView && !isReordering &&
                    <FilterButton
                        style={{ marginRight: margin }}
                        filters={[
                            {
                                type: 'date-range',
                                startDataKey: 'startDate',
                                endDataKey: 'endDate',
                            },
                            {
                                type: 'select',
                                dataKey: 'status',
                                options: STATUS
                            }
                        ]}
                        disabled={!canView}
                        value={filter}
                        onChange={(newFilterValue) => setFilter({ ...newFilterValue })}
                    />
                }
            </>

        )
    }

    const onClickTab = (index) => {
        updateTab(TABS[index], index);
        // setFilter({})
    }

    const companyList = useMemo(() => {
        const list = [{ name: "Generic", _id: undefined }];

        if (VIPCompanies) {
            VIPCompanies.filter(item => item.companyType === "vip").forEach((item) => {
                const { name, _id } = item;
                list.push({ name, _id })
            })
        }

        return list;
    }, [VIPCompanies])

    const CardHeader = () => {
        return (
            <Tab
                tabs={TABS}
                selected={tabIndex}
                onChange={onClickTab}
                renderButton={() => {
                    const isAbondonedProductType = ProductCategoryOptions.filter((item) => item?.disabled).map((item) => item?.id).includes(productType);
                    return (
                        <>
                        {
                            isReordering ? (
                                <Button
                                    style={{
                                        alignSelf: 'flex-end',
                                        margin: `0px 20px 10px 20px`,
                                    }}
                                    onClick={() => handleSubmit(handleOnSubmit)()}
                                    disabled={!canModify}
                                >
                                    Save
                                </Button>
                            ) : (
                                <Button
                                    style={{
                                        alignSelf: 'flex-end',
                                        margin: `0px 20px 10px 20px`,
                                    }}
                                    onClick={() => navigate(`/product/${productType}/add`)}
                                    type="add"
                                    disabled={!canModify || isAbondonedProductType}
                                >
                                    Add New
                                </Button>
                            )
                        }
                        </>
                    )
                }}
            />
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderCardHeader={CardHeader}
        >
            <Grid container direction='row' alignItems='center' style={{ paddingRight: 10 }}>
                {
                    isReordering ? (
                        <TableStatusFilter key="company-order" title={"Company"} options={companyList} value={filter.company} disabled={!canView} onChange={(newValue) => setFilter({ ...filter, company: newValue })} />
                    ) : (
                        <>
                        <TableStatusFilter options={STATUS} value={filter.status} disabled={!canView} onChange={(newValue) => setFilter({ ...filter, status: newValue })} />
                        <Searchbar value={filter.keyword} disabled={!canView} onChange={(e) => setFilter({ ...filter, keyword: e.target.value })} />
                        </>
                    )
                }
            </Grid>
            {
                isReordering ? (
                    <ProductSortableList
                        defaultColumn={columns}
                        control={control}
                        statusMutation={statusMutation}
                        canView={canView}
                        canModify={canModify}
                        navigateToProductDetail={navigateToProductDetail}
                        navigateToCloneProduct={navigateToCloneProduct}
                        handleOnDeleteProduct={hadnleOnDelete}
                        onSuccessChangeStatus={() => reorderMutation.reset()}
                        onFailChangeStatus={(error) => reorderMutation.reset()}
                    />
                ) : (
                    <Table
                        style={{ margin: `0px 20px 20px 20px` }}
                        columns={columns}
                        api={"/product"}
                        apiParams={{
                            category: productType,
                            ...filter,
                            company: undefined,
                        }}
                        sortingMode="server"
                        onError={onApiError}
                        setSnackbar={setSnackbar}
                    // onRowClick={(params) => {
                    //     navigate(`/terms-and-conditions/${params.id}`)
                    // }}
                    />
                )
            }
        </PageWrapper>
    )
}

export default withTabSearchParams(ProductPage, TABS)