import { useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    heigth: inherit;
`;

/**
 * PDFViewer component displays a PDF document using an iframe.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.data - The URL of the PDF document.
 * @returns {JSX.Element} The rendered PDFViewer component.
 */
const PDFViewer = (props) => {
    const {
        data,
    } = props;

    return (
        <Container>
            <embed
                src={data && `${data}#toolbar=0&navpanes=0&scrollbar=0`}
                title="PDF Viewer"
                width="100%"
                height="100%"
            />
        </Container>
    )
}

export default PDFViewer;