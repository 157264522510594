import { Controller } from "react-hook-form";
import { ReactComponent as UploadIcon } from '../../assets/upload-background.svg';
import { Grid, Box, IconButton } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import {
    UploadComponent,
    TextField,
    Image,
    Typography,
    Button,
    DynamicForm,
    ComponentContainer
} from '../../components/share'

const EmailDomainFields = [
    {
        id: "email-domains",
        renderComponent: ({ control, error, position, name, readOnly, canDelete, remove }) => (
            <Controller
                name={`${name}.${position}.value`}
                control={control}
                render={({ field }) => {
                    return (
                        <Box position={"relative"}>
                            <TextField
                                {...field}
                                title="Doamin Name*"
                                direction="vertical"
                                fullWidth
                                readOnly={readOnly}
                                error={error?.value}
                                helperText={error?.value?.message}
                            />
                            {!readOnly && canDelete &&
                                <IconButton
                                    sx={{
                                        height: 30,
                                        width: 30,
                                        ml: 1,
                                        position: "absolute",
                                        right: -50,
                                        top: 35
                                    }}
                                    onClick={() => remove(position)}
                                >
                                    <DeleteIcon />
                                </IconButton>}
                        </Box>
                    )
                }}
            />
        )
    }, { id: "spacing" }
]

const NotificationEmailFields = [
    {
        id: "notification-email",
        renderComponent: ({ control, error, position, name, readOnly, canDelete, remove }) => (
            <Controller
                name={`${name}.${position}.value`}
                control={control}
                render={({ field }) => {
                    return (
                        <Box position={"relative"}>
                            <TextField
                                {...field}
                                title="Notification Email"
                                direction="vertical"
                                fullWidth
                                readOnly={readOnly}
                                error={error?.value}
                                helperText={error?.value?.message}
                            />
                            {!readOnly && canDelete &&
                                <IconButton
                                    sx={{
                                        height: 30,
                                        width: 30,
                                        ml: 1,
                                        position: "absolute",
                                        right: -50,
                                        top: 35
                                    }}
                                    onClick={() => remove(position)}
                                >
                                    <DeleteIcon />
                                </IconButton>}
                        </Box>
                    )
                }}
            />
        )
    }, { id: "spacing" }
]

const Fields = ({ control, companyType, errors, readOnly }) => [
    {
        id: 'header',
        component: (
            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Fields marked * are required</Typography>
        )
    }, { id: "spacing" },
    {
        id: "companyName",
        component: (
            <Controller
                name="name"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Company Name*"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors.name}
                        helperText={errors?.name?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "dynamicList",
        component: (
            <DynamicForm
                control={control}
                errors={errors?.["emailDomains"]}
                name={`emailDomains`}
                addButtonLabel="+ Add More Domain"
                fieldComponents={EmailDomainFields}
                appendData={{ value: "" }}
                style={{ padding: 0 }}
                readOnly={readOnly}
            />
        )
    },
    // {
    //     id: "url-path",
    //     component: companyType === 'vip' && (
    //         <Controller
    //             name="urlPath"
    //             control={control}
    //             render={({ field }) => (
    //                 <TextField
    //                     {...field}
    //                     title="URL*"
    //                     direction="vertical"
    //                     fullWidth
    //                     readOnly={readOnly}
    //                     error={errors.urlPath}
    //                     helperText={errors?.urlPath?.message}
    //                 />
    //             )}
    //         />
    //     )
    // }, { id: "spacing" },
    // {
    //     id: "dynamicList",
    //     component: companyType === 'normal' && (
    //         <DynamicForm
    //             control={control}
    //             errors={errors?.["notificationEmails"]}
    //             name={`notificationEmails`}
    //             fieldComponents={NotificationEmailFields}
    //             style={{ padding: 0 }}
    //             readOnly={readOnly}
    //         />
    //     )
    // },
    {
        id: "dynamicList",
        component: companyType === 'vip' && (
            <DynamicForm
                control={control}
                errors={errors?.["notificationEmails"]}
                name={`notificationEmails`}
                addButtonLabel="+ Add New Email"
                fieldComponents={NotificationEmailFields}
                appendData={{ value: "" }}
                style={{ padding: 0 }}
                readOnly={readOnly}
            />
        )
    },
    // {
    //     id: "icon",
    //     component: companyType === 'vip' && (
    //         <Controller
    //             name="image"
    //             control={control}
    //             render={({ field }) => {
    //                 if (field?.value?.previewUrl || readOnly) {
    //                     return (
    //                         <ComponentContainer direction="vertical" title="Menu Bar Icon*">
    //                             <Image
    //                                 url={field?.value?.previewUrl}
    //                                 height={120}
    //                                 width={120}
    //                                 onChange={field.onChange}
    //                                 readOnly={readOnly}
    //                                 onClick={() => field?.value?.previewUrl && window.open(field?.value.previewUrl)}
    //                             />
    //                         </ComponentContainer>
    //                     )
    //                 }
    //                 return (
    //                     <ComponentContainer direction="vertical" title="Menu Bar Icon*" error={errors?.image} helperText={errors?.image?.message}>
    //                         <UploadComponent
    //                             renderComponent={({ isLoading, selectFile }) => {
    //                                 return (
    //                                     <Button
    //                                         variant="outlined"
    //                                         size="small"
    //                                         loading={isLoading}
    //                                         onClick={selectFile}
    //                                     >
    //                                         + Add Image
    //                                     </Button>
    //                                 )
    //                             }}
    //                             accept={'image/*'}
    //                             onChange={(event, fileResp) => {
    //                                 field.onChange(fileResp)
    //                             }}
    //                         />
    //                     </ComponentContainer>
    //                 )
    //             }}
    //         />
    //     )
    // }, { id: "spacing" },
]

export { Fields }