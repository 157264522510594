import { useState, useContext, useCallback, useMemo, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash'
import Typography from '../../components/share/Typography';
import Button from '../../components/share/Button';
import Searchbar from '../../components/share/Search';
import Table, { UnderlineText, DeleteActionIcon } from '../../components/share/Table';
import PageWrapper from '../../components/share/PageWrapper';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import AlertDialogProvider, { alertDialogContext } from '../../context/AlertDialogProvider';
import { userContext } from '../../context/UserProvider';
import { getPermission } from '../../utils'
import { adminRoleContext } from '../../context/AdminRoleProvider';
import { Grid } from '@mui/material';


const margin = 10

function AdminManagementPage(props) {
    const navigate = useNavigate();
    const [filter, setFilter] = useState({
        keyword: "",
    });
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [dialog, setDialog] = useContext(alertDialogContext);
    const [user, setUser] = useContext(userContext);
    const [roles] = useContext(adminRoleContext)

    const { canView, canModify } = getPermission(user?.permissions?.adminUser)
    // const { canView:canViewCreation, canModify:canModifyCreation } = getPermission(user?.permissions?.adminAccountCreation)

    const columns = useMemo(() => {
        return [
            { field: 'email', headerName: 'Email', width: 300, sortable: false },
            {
                field: 'role',
                headerName: 'Role',
                width: 200,
                sortable: false,
                valueGetter: (params) => {
                    return _.find(roles, { role: params.value })?.name || "";
                },
            },
            {
                field: 'costControlCentre',
                headerName: 'ccc',
                width: 140,
                sortable: false,
            },
            {
                field: 'corphqId',
                headerName: 'corphqId domain',
                width: 160,
                sortable: false,
            },
            {
                field: 'familyName',
                headerName: 'Family Name',
                width: 120,
                sortable: false,
            },
            {
                field: 'givenName',
                headerName: 'Given Name',
                width: 120,
                sortable: false,
            },
            {
                field: 'status',
                headerName: 'Status',
                width: 120,
                sortable: false,
            },
            {
                field: 'actions',
                headerName: 'Action',
                type: 'actions',
                width: 80,
                headerAlign: 'center',
                getActions: (params) => {
                    return [
                        <UnderlineText
                            onClick={() => navigate(`/admin/${params.row._id}`)}
                            disabled={!canView}
                        >
                            Edit
                        </UnderlineText>,
                    ]
                }
            }
        ]
    }, [])

    const onApiError = (error) => {
        setSnackbar({
            type: 'error',
            message: `${error?.response?.data?.result?.errorMessage?.en || error?.message}`
        })
    }

    const Header = () => {
        return (
            <Typography bold style={{ flex: 1 }}>{`Admin Management`}</Typography>
        )
    }

    const CardHeader = () => {
        return (
            <>
                <Button
                    style={{
                        alignSelf: 'flex-end',
                        margin: `0px 20px 10px 20px`,
                    }}
                    onClick={() => navigate(`/admin/add`)}
                    type="add"
                    disabled={!canModify}
                >
                    Add New
                </Button>
            </>
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderCardHeader={CardHeader}
        >
            <Grid container direction='row' alignItems='center' style={{ paddingRight: 10 }}>
                <Searchbar value={filter.keyword} disabled={!canView} onChange={(e) => setFilter({ ...filter, keyword: e.target.value })} />
            </Grid>
            <Table
                style={{ margin: `0px 20px 20px 20px` }}
                columns={columns}
                api={"/user/admin"}
                apiParams={filter}
                sortingMode="server"
                onError={onApiError}
                setSnackbar={setSnackbar}
            // onRowClick={(params) => {
            //     navigate(`/terms-and-conditions/${params.id}`)
            // }}
            />
        </PageWrapper>
    )
}

export default AdminManagementPage