import * as React from 'react';
import Typography from './Typography';
import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    List,
    Box,
    Checkbox,
    CircularProgress,
    IconButton
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash'
import ComponentContainer from './ComponentContainer';
import constants from '../../constants';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import { request, axios } from '../../apis'


const fetchData = (url) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "get",
            url: url,
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

function CustomSelect({ style, titleStyle, fullWidth, width, options, value, onChange, multiple, api, onError, getOptionLabel, disabled, ...props }) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(-1); //for single use
    const [selectedValue, setSelectedValue] = React.useState([]);//for multiple use

    const { data, error, isError, isLoading } = useQuery({
        queryKey: ['fetchData', api],
        queryFn: async () => await fetchData(`${constants.API_ENDPOINT}${api}`),
        retry: false,
        enabled: !!api,
        onError: (error) => {
            onError && onError(error)
        }
    })

    const selectOptions = React.useMemo(() => {
        if (options) {
            return options
        } else if (data) {
            return data || []
        }
        return []
    }, [data, options])

    // TODO:handle VALUE (findValueIndex)
    // single: findIndex
    // mutiple: findId
    React.useEffect(() => {
        if (!data && !value && !options) return
        const source = api ? data : options
        if (multiple) {
            const tmp = _.map(value, (valueId) => {
                if (typeof valueId === 'object') {
                    return valueId
                }
                let find = _.find(source, { _id: valueId })
                return find?._id
            })
            setSelectedValue(tmp)
        } else {
            let v = typeof value === 'object' ? value?._id : value
            let find = _.findIndex(source, { _id: v })
            setSelectedIndex(find)
        }
    }, [value, multiple, data, options])

    const isEmptyValue = !value || value.length <= 0 ? true : false

    const cleanValue = () => {
        if (multiple) {
            onChange && onChange([])
        } else {
            onChange && onChange(null)
        }
    }

    const handleMenuItemClick = (
        event,
        index,
    ) => {
        if (disabled) return;
        setSelectedIndex(index);
        if (api) {
            onChange && onChange(data[index]._id)
        } else {
            onChange && onChange(options[index]._id)
        }
        setOpen(false);
    };

    const handleListItemClick = (
        value
    ) => {
        if (disabled) return;
        const currentIndex = selectedValue?.indexOf(value._id);
        const newChecked = [...selectedValue];

        if (currentIndex === -1) {
            newChecked.push(value._id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedValue(newChecked);
    };

    const handleToggle = () => {
        // if (disabled) return;
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target)
        ) {
            return;
        }

        setOpen(false);
        multiple && onChange && onChange(selectedValue)
    };

    const getDisplayValue = () => {
        //selectedIndex/selectedValue change to Value 
        let v = getOptionLabel ? getOptionLabel(selectOptions?.[selectedIndex]) : selectOptions?.[selectedIndex]?.name
        if (multiple) {
            let tmp = []
            _.forEach(selectedValue, (itemId) => {
                const find = _.find(selectOptions, { _id: itemId });
                if (find) tmp.push(getOptionLabel ? getOptionLabel(find) : find.name)
            })
            v = tmp.toString()
        }

        return v || "Select..."
    }

    const renderList = () => {
        if ((api && !isLoading && !data) || (!api && !options?.length)) {
            return (
                <MenuList id="split-button-menu" autoFocusItem>
                    <MenuItem disabled={disabled}>
                        No Data
                    </MenuItem>
                </MenuList>
            )
        }
        if (multiple) {
            return (
                <List sx={{ width: '100%', bgcolor: 'background.paper', maxHeight: "30vh", overflow: "auto" }}>
                    {selectOptions.map((option) => {
                        const labelId = `checkbox-list-label-${option._id}`;
                        return (
                            <ListItem
                                key={option._id}
                                disablePadding
                            >
                                <ListItemButton role={undefined} onClick={() => handleListItemClick(option)} dense disabled={disabled}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={selectedValue.indexOf(option._id) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={getOptionLabel && getOptionLabel(option) || option.name} />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            )
        }
        return (
            <MenuList id="split-button-menu" autoFocusItem sx={{ width: '100%', maxHeight: "30vh", overflow: "auto" }}>
                {selectOptions.map((option, index) => {
                    return (
                        <MenuItem
                            key={option._id}
                            selected={index === selectedIndex}
                            onClick={(event) => handleMenuItemClick(event, index)}
                            disabled={option?.disabled || disabled}
                        >
                            <Typography sx={{ whiteSpace: 'break-spaces' }}>{getOptionLabel && getOptionLabel(option) || option.name}</Typography>
                        </MenuItem>
                    )
                })}
            </MenuList>
        )
    }

    return (
        <ComponentContainer {...props}>
            <React.Fragment>
                <ButtonGroup
                    variant="contained"
                    ref={anchorRef}
                    aria-label="split button"
                    fullWidth={fullWidth}
                    disableFocusRipple
                    disableRipple
                    disableElevation
                    sx={{
                        height: 43,
                        backgroundColor: disabled ? "secondary.dark" : "secondary.light",
                        boxShadow: "none",
                        width: width
                    }}
                >
                    <Button
                        fullWidth
                        sx={{
                            textTransform: 'none',
                            backgroundColor: disabled ? "secondary.dark" : "secondary.light",
                            "&:hover": {
                                background: "none"
                            },
                        }}
                        style={{
                            border: 'none',
                            justifyContent: 'left'
                        }}
                    >
                        <Typography
                            variant="ellipsis"
                            color={disabled ? "info.light" : "info.main"}
                            align="left"
                        >
                            {api && isLoading ? <CircularProgress size={20} /> : getDisplayValue()}
                        </Typography>
                        {!disabled && !isEmptyValue &&
                            <IconButton size="small" onClick={cleanValue}>
                                <CloseIcon
                                    sx={{ color: "secondary.main" }}
                                />
                            </IconButton>}
                    </Button>
                    <Box sx={{ 
                        backgroundColor: disabled ? "secondary.main" : 'primary.main', 
                        height: '30px', 
                        width: '1px', 
                        alignSelf: 'center' }} 
                    />
                    <Button
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                        style={{ width: "40px", }}
                        sx={{
                            color: disabled ? 'secondary.main' : 'primary.main',
                            backgroundColor: disabled ? "secondary.dark" : "secondary.light",
                            "&:hover": {
                                background: "none"
                            },
                        }}
                        disableRipple={disabled}
                        // disabled={disabled}
                    >
                        <KeyboardArrowDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper
                    sx={{
                        zIndex: 1400,
                        width: anchorRef.current?.offsetWidth
                    }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom' ? 'left top' : 'left bottom',
                            }}
                        >
                            <Paper style={{ width: '100%', overflowY: "auto" }}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    {renderList()}
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </React.Fragment>
        </ComponentContainer>
    );
}

export default CustomSelect