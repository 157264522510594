import styled from "styled-components"
import Checkbox from '@mui/material/Checkbox';
import Typography from "./Typography";
import { InputAdornment, Grid, Box } from "@mui/material";

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { ReactComponent as CheckboxIcon } from '../../assets/checkbox-uncheck.svg'
import { ReactComponent as CheckboxCheckedIcon } from '../../assets/checkbox-checked.svg'

const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const LabelText = styled(Typography)`
`

function MyCheckbox({ label, style, typographyProps, circle, checked, title, titleStyle, direction, fullWidth = true, align = 'center', disabled, ...props }) {

    const checkboxType = circle ? {
        icon: <RadioButtonUncheckedIcon />,
        checkedIcon: <CheckCircleIcon />,
    } : {
        icon: <CheckboxIcon />,
        checkedIcon: <CheckboxCheckedIcon />,
    }

    const renderInput = () => {
        return (
            <CheckboxContainer style={style} {...props}>
                <Checkbox key={`${title}-${label}-${checked}`} sx={{ padding: 0 }} checked={checked} {...checkboxType} disabled={disabled} />
                {label && <LabelText {...typographyProps} style={{ marginLeft: 10, ...typographyProps?.style }}>{label}</LabelText>}
            </CheckboxContainer>
        )
    }

    if (direction === "vertical") {
        return (
            <>
                {title && <Typography header variant="secondary" {...typographyProps}>{title}</Typography>}
                {renderInput()}
            </>
        )
    }

    return (
        <Grid container direction="row" alignItems='center' justifyContent={align} style={{ width: fullWidth ? "100%" : "auto" }}>
            {title && <Typography header variant="secondary" style={titleStyle}>{title}</Typography>}
            <Grid item>
                {renderInput()}
            </Grid>
        </Grid>
    )
}

export default MyCheckbox;