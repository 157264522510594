import React from 'react';
import { Input, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from './Typography'
import { InputAdornment, Grid, IconButton } from "@mui/material";
import { ReactComponent as CopyIcon } from '../../assets/copy.svg'

// const theme = createTheme({
//     palette: {
//         primary: {
//             main: '#333333',
//             contrastText: "#fff"
//         },
//         secondary: {
//             main: "#9B9B9B",
//             light: "#f6f6f6",
//             dark: "#E2E2E2"
//         },
//         green: {
//             main: "#309E9E",
//             contrastText: "#fff"
//         },
//         blue: {
//             main: "#306097",
//             contrastText: "#fff"
//         },
//         orange: {
//             main: "#C06902",
//             contrastText: "#fff"
//         },
//         neon: {
//             main: "#F2A000"
//         }
//     },
//     components: {
//         MuiTextField: {
//             styleOverrides: {
//                 root: {
  
//                 },
//             },
//         },
//     },
// });

const StyledTextField = ({ type = 'text', renderStartIcon, width, variant, title, direction, titleStyle, canCopy, multiline, rows = 4, readOnly, ...props }) => {

    const renderTextInput = () => {

        if (readOnly) {
            return (
                <Input 
                    {...props?.InputProps} 
                    disableUnderline 
                    value={props?.value}
                    disabled
                    fullWidth
                    sx={{
                        backgroundColor: "secondary.dark",
                        color: "info.light",
                        height: multiline ? 120 : 43,
                        borderRadius: 1,
                        padding: `0px 14px`,
                        alignItems: multiline && 'flex-start',
                        
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#9B9B9B",
                        },
                    }}
                    style={{
                        ...props.style
                    }}
                />
            )
        }

        if (variant === 'standard') {
            return (
                <TextField
                    {...props}
                    InputProps={{
                        ...props?.InputProps,
                        startAdornment: renderStartIcon ? (
                            <InputAdornment position="start">
                                {renderStartIcon()}
                            </InputAdornment>
                        ) : null,
                        style: {
                            padding: multiline && `0px 0px 0px 0px`,
                            alignItems: multiline && 'flex-start',
                            ...props?.InputProps?.style,
                        },
                    }}
                    variant="standard"
                    margin='none'
                    type={type}
                    style={{
                        width: width,
                        ...props.style
                    }}
                    multiline={multiline}
                    rows={multiline ? rows : undefined}
                />
            )
        } else {
            return (
                <TextField
                    {...props}
                    variant="filled"
                    type={type}
                    multiline={multiline}
                    rows={multiline ? rows : undefined}
                    InputProps={{
                        ...props?.InputProps,
                        disableUnderline: true,
                        sx: {
                            // height: multiline && 120,
                            borderRadius: 1,
                            backgroundColor: "secondary.light",
                            padding: multiline && `0px 0px 0px 0px`,
                            alignItems: multiline && 'flex-start',
                            ...props?.InputProps?.style,
                        },
                    }}
                    inputProps={{
                        ...props?.inputProps,
                        sx: {
                            padding: `12px 14px`,
                            color: "info.main",
                            border: 'none',
                            ...props?.inputProps?.style,
                        },
                    }}
                />
            )
        }
    }

    const onClickCopy = () => {
        // textAreaRef.current.select();
        // document.execCommand('copy');
        // // This is just personal preference.
        // // I prefer to not show the whole text area selected.
        // e.target.focus();
        // setCopySuccess('Copied!');
    }

    if (direction === "vertical") {
        return (
            <>
                {title && <Typography header variant="header1">{title}</Typography>}
                <Grid container direction='row' alignItems="center">
                    <Grid item xs>
                        {renderTextInput()}
                    </Grid>
                    {canCopy && <IconButton style={{ marginLeft: 8, height: 35, width: 35 }} onClick={onClickCopy}><CopyIcon /></IconButton>}
                </Grid>
            </>
        )
    }

    return (
        <Grid container direction="row" alignItems="center" justifyContent="center">
            {title && <Typography header variant="header1" style={titleStyle}>{title}</Typography>}
            <Grid item xs>
                {renderTextInput()}
            </Grid>
            {canCopy && <IconButton style={{ marginLeft: 8 }}><CopyIcon /></IconButton>}
        </Grid>
    )
}

export default StyledTextField