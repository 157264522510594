import { request } from './index';
import constants from '../constants';

const fetchProduct = (id) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "get",
            url: `${constants.API_ENDPOINT}/product/${id}`
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const createProduct = (payload) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "post",
            url: `${constants.API_ENDPOINT}/product`,
            data: payload
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const updateProduct = (id, payload) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "put",
            url: `${constants.API_ENDPOINT}/product/${id}`,
            data: payload
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const updateProductStatus = (id, payload) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "put",
            url: `${constants.API_ENDPOINT}/product/${id}/status`,
            data: payload
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const deleteProduct = (id) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "delete",
            url: `${constants.API_ENDPOINT}/product/${id}`,
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const fetchSortingProduct = ({ type, company=undefined }) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "get",
            url: `${constants.API_ENDPOINT}/product/reorder/${type}`,
            params: {
                company
            }
        })
            .then(res => {
                resolve(res)    // TODO: api wrapped by res.data?
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const updateSortingProduct = ({ type, payload }) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "post",
            url: `${constants.API_ENDPOINT}/product/reorder/${type}`,
            data: payload
        })
            .then(res => {
                resolve(res)    // TODO: api wrapped by res.data?
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

export {
    fetchProduct,
    createProduct,
    updateProduct,
    updateProductStatus,
    deleteProduct,
    fetchSortingProduct,
    updateSortingProduct,
}
