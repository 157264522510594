import { useState, useContext, useCallback, useMemo, useEffect, useLayoutEffect, useRef, forwardRef } from 'react';
import Image, { ImgContainer } from './ImgGrid/ImageV2';
import AddIcon from '@mui/icons-material/Add';
import Typography from './Typography'
import { getPresignedUrl, uploadImgToS3, uploadToS3V2 } from '../../apis/index'
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import { useDropzone } from 'react-dropzone';

function UploadComponent({ renderComponent, onChange, onUpload, accept }) {

    const inputRef = useRef({});

    const [snackbar, setSnackbar] = useContext(snackbarContext);

    const [isLoading, setIsLoading] = useState(false);

    const uploadHandler = async (file) => {
        return onUpload ? await onUpload(file) : await uploadFile(file);
    }

    const onDrop = async acceptedFiles => {
        if (!acceptedFiles) return
        setIsLoading(true)
        const file = acceptedFiles[0];
        const response = await uploadHandler(file);
        // const response = {
        //     fileKey: "dev/temp/64c10191551ac5b0163cfbcc1690370449999.png",
        //     previewUrl: "https://hkt-eshop-static-file.s3.ap-southeast-1.amazonaws.com/dev/temp/64c10191551ac5b0163cfbcc1690370449999.png?AWSAccessKeyId=ASIASL5DGJ2BXVKWQDZU&Expires=1690372250&Signature=CBOOWFSeo9etyLPNi0dDHzaSTho%3D&X-Amzn-Trace-Id=Root%3D1-64c10190-10008bc8097cd09d4a71436e%3BParent%3D2a47a9806604e26c%3BSampled%3D0%3BLineage%3D7562f262%3A0&x-amz-security-token=IQoJb3JpZ2luX2VjEKv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDmFwLXNvdXRoZWFzdC0xIkgwRgIhALEKQzazrtuR3WjsompCncey6VDWk1ITESW518a1pTPJAiEAnmIUa4GPb%2FwGMLhRuE7vngXteGFVhgdWJr3fp0kWIqUqpgMIRBAAGgwxNjMwMTQxMzU0MjciDNIAFbdF49W1TtvE%2BCqDA0vo03P4N%2FpBArgVo32zxaGOjBJfh99CwvpMhQX31Lqbu0lg4wFcaB9uxTfqFttM5Jknr4g5ojzSScAQIr3IfcS%2BDv6XDlsdLAUdNbHKlxI7KvD3Qc4SPs4%2FfVi39h1KYOdnVCafJKDK%2BrOIxU3QOBcPwn21DEF3Umv%2F5T1XuiGqdC%2B2jZx2%2BkwnH3IQN2OUybxP7uoYO7VpnF5aLbIfbMre0N3M5GfkkyenQDntAvxlFRi7SqwHoS8oIZcYNnE8q74i7A2h6gYbFAT2%2Fn6NazAfWtXMqpeZb6R%2BdP2Gx%2BJExpAl03bQHEoaiQyGt%2BtdCN%2FnddDE5CLTy93tpPo2SSmZAXrfDoCS8VanRJ1x77CdAo8jSLpHsxWGmRN%2FUOojzvsbghCQUuLLmyYS90Xm5YSd5jVwY9cxM6uG7GMxWC3oplwCNHviJDcGcFv0j25ah%2FKr82MtVJVivs%2FNl9Ryqkg6SUyagI3NtirUivHY%2FwEvWyYBO%2BnFdyH0zAgtxIm%2Ft3lqVjCQg4SmBjqcAQ2vPsb9xBJWdXMuFoYCTs48bJ0qgSZ5Jea1tVV2eraIGtTdb7lj7SJUXyhgQUp8CMgeShZmnuwc39Ik02hjE%2BgZ3PioI5DCX%2Bfgr2w9W7hftdudGAze9frDADyeVee7YWINuWEi3V5FB68f6rpmBQgipX4ojLyBHyw%2BQu4Hsv5jgHws%2BY8ezxLKapboGPv8u78f2tqtsfb98U5t9A%3D%3D",
        //     uploadUrl: "https://hkt-eshop-static-file.s3.ap-southeast-1.amazonaws.com/dev/temp/64c10191551ac5b0163cfbcc1690370449999.png?AWSAccessKeyId=ASIASL5DGJ2BXVKWQDZU&Expires=1690372250&Signature=4ZnWQeI7HlY5ID05JQF15FdKqw0%3D&X-Amzn-Trace-Id=Root%3D1-64c10190-10008bc8097cd09d4a71436e%3BParent%3D2a47a9806604e26c%3BSampled%3D0%3BLineage%3D7562f262%3A0&x-amz-security-token=IQoJb3JpZ2luX2VjEKv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDmFwLXNvdXRoZWFzdC0xIkgwRgIhALEKQzazrtuR3WjsompCncey6VDWk1ITESW518a1pTPJAiEAnmIUa4GPb%2FwGMLhRuE7vngXteGFVhgdWJr3fp0kWIqUqpgMIRBAAGgwxNjMwMTQxMzU0MjciDNIAFbdF49W1TtvE%2BCqDA0vo03P4N%2FpBArgVo32zxaGOjBJfh99CwvpMhQX31Lqbu0lg4wFcaB9uxTfqFttM5Jknr4g5ojzSScAQIr3IfcS%2BDv6XDlsdLAUdNbHKlxI7KvD3Qc4SPs4%2FfVi39h1KYOdnVCafJKDK%2BrOIxU3QOBcPwn21DEF3Umv%2F5T1XuiGqdC%2B2jZx2%2BkwnH3IQN2OUybxP7uoYO7VpnF5aLbIfbMre0N3M5GfkkyenQDntAvxlFRi7SqwHoS8oIZcYNnE8q74i7A2h6gYbFAT2%2Fn6NazAfWtXMqpeZb6R%2BdP2Gx%2BJExpAl03bQHEoaiQyGt%2BtdCN%2FnddDE5CLTy93tpPo2SSmZAXrfDoCS8VanRJ1x77CdAo8jSLpHsxWGmRN%2FUOojzvsbghCQUuLLmyYS90Xm5YSd5jVwY9cxM6uG7GMxWC3oplwCNHviJDcGcFv0j25ah%2FKr82MtVJVivs%2FNl9Ryqkg6SUyagI3NtirUivHY%2FwEvWyYBO%2BnFdyH0zAgtxIm%2Ft3lqVjCQg4SmBjqcAQ2vPsb9xBJWdXMuFoYCTs48bJ0qgSZ5Jea1tVV2eraIGtTdb7lj7SJUXyhgQUp8CMgeShZmnuwc39Ik02hjE%2BgZ3PioI5DCX%2Bfgr2w9W7hftdudGAze9frDADyeVee7YWINuWEi3V5FB68f6rpmBQgipX4ojLyBHyw%2BQu4Hsv5jgHws%2BY8ezxLKapboGPv8u78f2tqtsfb98U5t9A%3D%3D"
        // }
        setIsLoading(false)
        onChange && onChange(acceptedFiles, response)
    }

    const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
        accept: {
            'image/*': [],
        },
        onDrop,
        useFsAccessApi: false
    });

    // const { getRootProps: getInnerProps } = useDropzone({
    //     noDragEventsBubbling: true
    // });

    const uploadFile = async (file) => {
        try {
            let fileExtension = file.name && file.name.split('.').pop();
            fileExtension = fileExtension === 'jpg' ? 'jpeg' : fileExtension.toLowerCase();
            const response = await getPresignedUrl(fileExtension);
            const uploadResponse = await uploadImgToS3(response.uploadUrl, file);
            return response
        } catch (error) {
            console.log("uploadFile-err", error)
            setIsLoading(false)
            setSnackbar({
                type: 'error',
                message: `Upload Failed`
            })

        }
    }

    return (

        <div {...getRootProps()}>
            <input
                {...getInputProps()}
                accept={accept}
                type="file"
                onChange={async (event) => {
                    if (isLoading || !event?.target?.files?.[0]) return
                    setIsLoading(true)
                    const file = event.target.files[0];
                    const response = await uploadHandler(file);
                    setIsLoading(false)
                    // const response = {
                    //     fileKey: "dev/temp/64c10191551ac5b0163cfbcc1690370449999.png",
                    //     previewUrl: "https://hkt-eshop-static-file.s3.ap-southeast-1.amazonaws.com/dev/temp/64c10191551ac5b0163cfbcc1690370449999.png?AWSAccessKeyId=ASIASL5DGJ2BXVKWQDZU&Expires=1690372250&Signature=CBOOWFSeo9etyLPNi0dDHzaSTho%3D&X-Amzn-Trace-Id=Root%3D1-64c10190-10008bc8097cd09d4a71436e%3BParent%3D2a47a9806604e26c%3BSampled%3D0%3BLineage%3D7562f262%3A0&x-amz-security-token=IQoJb3JpZ2luX2VjEKv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDmFwLXNvdXRoZWFzdC0xIkgwRgIhALEKQzazrtuR3WjsompCncey6VDWk1ITESW518a1pTPJAiEAnmIUa4GPb%2FwGMLhRuE7vngXteGFVhgdWJr3fp0kWIqUqpgMIRBAAGgwxNjMwMTQxMzU0MjciDNIAFbdF49W1TtvE%2BCqDA0vo03P4N%2FpBArgVo32zxaGOjBJfh99CwvpMhQX31Lqbu0lg4wFcaB9uxTfqFttM5Jknr4g5ojzSScAQIr3IfcS%2BDv6XDlsdLAUdNbHKlxI7KvD3Qc4SPs4%2FfVi39h1KYOdnVCafJKDK%2BrOIxU3QOBcPwn21DEF3Umv%2F5T1XuiGqdC%2B2jZx2%2BkwnH3IQN2OUybxP7uoYO7VpnF5aLbIfbMre0N3M5GfkkyenQDntAvxlFRi7SqwHoS8oIZcYNnE8q74i7A2h6gYbFAT2%2Fn6NazAfWtXMqpeZb6R%2BdP2Gx%2BJExpAl03bQHEoaiQyGt%2BtdCN%2FnddDE5CLTy93tpPo2SSmZAXrfDoCS8VanRJ1x77CdAo8jSLpHsxWGmRN%2FUOojzvsbghCQUuLLmyYS90Xm5YSd5jVwY9cxM6uG7GMxWC3oplwCNHviJDcGcFv0j25ah%2FKr82MtVJVivs%2FNl9Ryqkg6SUyagI3NtirUivHY%2FwEvWyYBO%2BnFdyH0zAgtxIm%2Ft3lqVjCQg4SmBjqcAQ2vPsb9xBJWdXMuFoYCTs48bJ0qgSZ5Jea1tVV2eraIGtTdb7lj7SJUXyhgQUp8CMgeShZmnuwc39Ik02hjE%2BgZ3PioI5DCX%2Bfgr2w9W7hftdudGAze9frDADyeVee7YWINuWEi3V5FB68f6rpmBQgipX4ojLyBHyw%2BQu4Hsv5jgHws%2BY8ezxLKapboGPv8u78f2tqtsfb98U5t9A%3D%3D",
                    //     uploadUrl: "https://hkt-eshop-static-file.s3.ap-southeast-1.amazonaws.com/dev/temp/64c10191551ac5b0163cfbcc1690370449999.png?AWSAccessKeyId=ASIASL5DGJ2BXVKWQDZU&Expires=1690372250&Signature=4ZnWQeI7HlY5ID05JQF15FdKqw0%3D&X-Amzn-Trace-Id=Root%3D1-64c10190-10008bc8097cd09d4a71436e%3BParent%3D2a47a9806604e26c%3BSampled%3D0%3BLineage%3D7562f262%3A0&x-amz-security-token=IQoJb3JpZ2luX2VjEKv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDmFwLXNvdXRoZWFzdC0xIkgwRgIhALEKQzazrtuR3WjsompCncey6VDWk1ITESW518a1pTPJAiEAnmIUa4GPb%2FwGMLhRuE7vngXteGFVhgdWJr3fp0kWIqUqpgMIRBAAGgwxNjMwMTQxMzU0MjciDNIAFbdF49W1TtvE%2BCqDA0vo03P4N%2FpBArgVo32zxaGOjBJfh99CwvpMhQX31Lqbu0lg4wFcaB9uxTfqFttM5Jknr4g5ojzSScAQIr3IfcS%2BDv6XDlsdLAUdNbHKlxI7KvD3Qc4SPs4%2FfVi39h1KYOdnVCafJKDK%2BrOIxU3QOBcPwn21DEF3Umv%2F5T1XuiGqdC%2B2jZx2%2BkwnH3IQN2OUybxP7uoYO7VpnF5aLbIfbMre0N3M5GfkkyenQDntAvxlFRi7SqwHoS8oIZcYNnE8q74i7A2h6gYbFAT2%2Fn6NazAfWtXMqpeZb6R%2BdP2Gx%2BJExpAl03bQHEoaiQyGt%2BtdCN%2FnddDE5CLTy93tpPo2SSmZAXrfDoCS8VanRJ1x77CdAo8jSLpHsxWGmRN%2FUOojzvsbghCQUuLLmyYS90Xm5YSd5jVwY9cxM6uG7GMxWC3oplwCNHviJDcGcFv0j25ah%2FKr82MtVJVivs%2FNl9Ryqkg6SUyagI3NtirUivHY%2FwEvWyYBO%2BnFdyH0zAgtxIm%2Ft3lqVjCQg4SmBjqcAQ2vPsb9xBJWdXMuFoYCTs48bJ0qgSZ5Jea1tVV2eraIGtTdb7lj7SJUXyhgQUp8CMgeShZmnuwc39Ik02hjE%2BgZ3PioI5DCX%2Bfgr2w9W7hftdudGAze9frDADyeVee7YWINuWEi3V5FB68f6rpmBQgipX4ojLyBHyw%2BQu4Hsv5jgHws%2BY8ezxLKapboGPv8u78f2tqtsfb98U5t9A%3D%3D"
                    // }
                    onChange && onChange(event, response)
                    event.target.value = null
                }}
                ref={(el) => {
                    if (el) {
                        inputRef.current = el;
                    }
                }}
                // onClick={(event) => {
                //     event.target.value = null
                // }}
                style={{ display: 'none' }}
            />
            <div style={{ width: '100%', height: '100%' }}>
                {renderComponent && renderComponent({ isLoading, selectFile: () => inputRef.current.click() })}
            </div>
        </div>
    )
}

export default UploadComponent