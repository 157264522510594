import { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import {
    useQuery,
    useMutation,
} from '@tanstack/react-query'
import _ from 'lodash'
import { useForm, Controller, useFieldArray } from "react-hook-form";
import PageWrapper from '../../components/share/PageWrapper';
import Typography from '../../components/share/Typography';
import Button from '../../components/share/Button';
import TextField from '../../components/share/TextField';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import {useNavigate, useSearchParams } from 'react-router-dom';
import SortableList from '../../components/banner/SortableList'
import { Grid } from '@mui/material';
import QuestionContainer from '../../components/tutorial/QuestionContainer';
import HtmlEditor from '../../components/share/HtmlEditor';
import { CategoryList } from './index'
import withTabSearchParams from '../../hoc/withTabSearchParams'
import Tab from '../../components/share/Tab';
import { listTutorial, updateAllTutorial } from '../../apis/tutorial'


const AddButton = styled(Button)`
    && {
        padding-left: 30px;
        padding-bottom: 20px;
        display: inline-block;
    }
`

const Content = styled.div`
    margin: 20px;
    height: 100%;
    overflow: auto;
`

const ListItem = styled.li`
    list-style-type: none;
    margin: 0;
    padding: 0;
`

const ItemContent = styled(Grid)`
    && {
        background: #fff;
        border-radius: 5px;
        padding: 24px;
        overflow: auto;
        height: 100%;
    }
`

// const schema = Joi.object({
//     en: Joi.string().empty(""),
//     zh: Joi.string().empty(""),
// }).messages({
//     "any.required": "Required",
//     "object.base": "Required"
// });

const defValue = {
    en: {
        title: "",
        value: ""
    },
    zh: {
        title: "",
        value: ""
    }
}

const lang = [
    {
        id: "en",
        label: "English"
    },
    {
        id: "zh",
        label: "Chinese"
    }
];

function TutorialDetail({ updateTab, tabIndex }) {

    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [focusOn, setFocusOn] = useState(0);

    const category = CategoryList[tabIndex];

    const { control, handleSubmit, watch, formState: { errors, dirtyFields }, reset, setValue } = useForm({
        // resolver: joiResolver(schema),
        defaultValues: {
            questions: []
        }
    });

    const { data, error, isError, isLoading } = useQuery({
        queryKey: [category.id],
        queryFn: async () => await listTutorial(category.id),

        onSuccess: (data) => {
            console.log("onSuccess", data);
            reset({
                questions: data
            })
        }
    });

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: 'questions',
    });

    const mutation = useMutation({
        mutationFn: (payload) => updateAllTutorial(payload),
        onSuccess: (data) => {
            setSnackbar({
                type: "success",
                message: "Success"
            })
        },
        onError: (error, variables) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const handleOnSubmit = (formValue) => {
        // console.log("formValue", formValue)
        mutation.mutate({
            category: category.id,
            data: _.map(formValue.questions, (item, index) => ({
                ...item,
                sort: index + 1
            }))
        })
    }

    const handleToggle = (index) => {
        setFocusOn(index === focusOn ? -1 : index)
    }

    const onClickTab = (index) => {
        updateTab(CategoryList[index], index)
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Tutorial > ${category?.name || ""}`}</Typography>
            </>
        )
    }

    const CardHeader = () => {
        return (
            <Tab
                tabs={CategoryList}
                selected={tabIndex}
                onChange={onClickTab}
                renderButton={() => {
                    return (
                        <Button
                            style={{
                                alignSelf: 'flex-end',
                                margin: `0px 20px 14px 20px`,
                            }}
                            type="add"
                            onClick={() => handleSubmit(handleOnSubmit)()}
                        >
                            Save
                        </Button>
                    )
                }}
            />
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            renderCustomCardHeader={CardHeader}
        >
            <Content>
                <SortableList
                    data={fields}
                    renderRow={({
                        item,
                        isActive,
                        isDragged,
                        ref,
                        props,
                        handleProps,
                        index,
                        position
                    }) => {
                        return (
                            <ListItem ref={ref} key={item._id} {...props}>
                                <QuestionContainer
                                    open={focusOn === position}
                                    handleToggle={() => handleToggle(position)}
                                    handleDragProps={handleProps}
                                    isActive={isActive}
                                    isDragged={isDragged}
                                    handleRemove={() => remove(position)}
                                    canDelete={fields.length > 1}
                                    position={position}
                                >
                                    {
                                        lang.map((item, i) => {
                                            return (
                                                <ItemContent item xs style={{ marginRight: i ? 0 : 20 }}>
                                                    <Grid item container direction='row' style={{ marginBottom: 20 }}>
                                                        <Typography variant="header" style={{ flex: 1 }}>{item.label}</Typography>
                                                        {/* <DeleteIcon /> */}
                                                    </Grid>
                                                    <Grid item style={{ marginBottom: 20 }}>
                                                        <Controller
                                                            key={`${category?.id}_questions.${position}.title.${item.id}`}
                                                            name={`questions.${position}.title.${item.id}`}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    title="Title"
                                                                    direction='vertical'
                                                                    fullWidth
                                                                    {...field}
                                                                    value={field.value || ""}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item style={{ marginBottom: 20 }}>
                                                        <Controller
                                                            key={`${category?.id}_questions.${position}.title.${item.id}`}
                                                            name={`questions.${position}.content.${item.id}`}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <HtmlEditor
                                                                    value={field.value || ""}
                                                                    onChange={field.onChange}
                                                                    height="40vh"
                                                                // readOnly={readOnly}
                                                                // errors={errors?.productDetails?.description}
                                                                // helpeerText={errors?.productDetails?.description?.message}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </ItemContent>
                                            )
                                        })
                                    }
                                </QuestionContainer>
                            </ListItem>
                        )
                    }}
                    onSort={(oldIndex, newIndex) => {
                        const newItems = fields.slice();
                        newItems.splice(newIndex, 0, newItems.splice(oldIndex, 1)[0]);
                        setValue('questions', newItems)
                    }}
                />
            </Content>
            <Button
                variant="underline"
                color="neon"
                onClick={() => append({
                    category: category.id,
                    content: {
                        en: "",
                        zh: ""
                    },
                    title: {
                        en: "",
                        zh: ""
                    },
                    sort: fields.length,
                })}
            >
                + Add More Question
            </Button>
        </PageWrapper>
    )
}

export default withTabSearchParams(TutorialDetail, CategoryList);