import { useState, useContext, useCallback, useMemo, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import { Routes, Route, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash'
import Typography from '../../components/share/Typography';
import Button from '../../components/share/Button';
import Tab from '../../components/share/Tab';
import Table, { DeleteActionIcon } from '../../components/share/Table';
import PageWrapper from '../../components/share/PageWrapper';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import AlertDialogProvider, { alertDialogContext } from '../../context/AlertDialogProvider';
import { deleteBundle } from '../../apis/bundle';
import Searchbar from '../../components/share/Search'
import dayjs from 'dayjs';
import constants from '../../constants';
import withTabSearchParams from '../../hoc/withTabSearchParams';
import { toDisplayName } from '../../utils'

const margin = 10

//listTNC: sort
const TABS = [
    {
        name: 'Mobile',
        id: "mobile"
    }
]

function BundlePage({ updateTab, tabIndex }) {
    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [dialog, setDialog] = useContext(alertDialogContext);
    const [filter, setFilter] = useState({
        keyword: "",
    })

    let [searchParams, setSearchParams] = useSearchParams();

    const queryClient = useQueryClient()

    const mutation = useMutation({
        mutationFn: (id) => deleteBundle(id),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['/bundle'] })
        },
    })

    const onDeleteTnc = (params) => {
        setDialog({
            open: true,
            title: "Are you sure to delete this bundle?",
            handleOnClickOK: () => mutation.mutate(params.id)
        })
    }

    const columns = useMemo(() => {
        return [
            { field: '_id', headerName: 'Seq', width: 80, disableReorder: true, sortable: false, },
            { field: 'name.en', headerName: 'Name (en)', width: 150, valueGetter: (params) => params.row?.title?.en || "", },
            { field: 'name.zh', headerName: 'Name (chi)', width: 150, valueGetter: (params) => params.row?.title?.zh || "", },
            { field: 'effectiveDate', headerName: 'Effective Period', width: 250, sortable: false, valueGetter: (params) => params.value || "", },
            { field: 'lastUpdateDate', headerName: 'Last Update Date', width: 150, sortable: false, valueGetter: (params) => params.value ? dayjs(params.value).format(constants.DATE_TIME_FORMAT) : "" },
            { field: 'status', headerName: 'Status', width: 150, sortable: false, valueGetter: (params) => params.value || "", },
            {
                field: 'actions',
                headerName: 'Action',
                type: 'actions',
                width: 80,
                getActions: (params) => [
                    <DeleteActionIcon 
                        onClick={() => onDeleteTnc(params)}
                    />
                ],
            }
        ]
    }, [])

    const categoryName = searchParams.get('tab');

    // useEffect(() => {
    //     if (data && data.length > 0) {
    //         if (!searchParams.has('tab')) {
    //             setSearchParams({ tab: data[0] })
    //             setTab(0)
    //         } else {
    //             const tabIndex = _.indexOf(data, categoryName )
    //             setTab(tabIndex)
    //         }   
    //     }
    // }, [data])

    const onClickTab = (index) => {
        updateTab(TABS[index], index);
        setFilter({})
    };

    const onApiError = (error) => {
        setSnackbar({
            type: 'error',
            message: `${error?.response?.data?.result?.errorMessage?.en || error?.message}`
        })
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Bundle > ${toDisplayName(categoryName)}`}</Typography>
                <Button color="green" style={{ marginRight: margin }}>Filter</Button>
            </>
        )
    }

    const CardHeader = () => {
        return (
            <Tab
                tabs={TABS}
                selected={tabIndex}
                onChange={onClickTab}
                renderButton={() => {
                    return (
                        <Button
                            style={{
                                alignSelf: 'flex-end',
                                margin: `0px 20px 14px 20px`,
                            }}
                            type="add"
                            onClick={() => navigate(`/bundle/add`)}
                        >
                            Add New
                        </Button>
                    )
                }}
            />
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderCustomCardHeader={CardHeader}
        >
            <Grid container direction='row' alignItems='center' style={{ paddingRight: 10 }}>
                <Searchbar value={filter.keyword} onChange={(e) => setFilter({ ...filter, keyword: e.target.value })} />
            </Grid>
            <Table
                style={{ margin: `0px 20px 20px 20px` }}
                color="grey"
                columns={columns}
                api={"/bundle"}
                apiParams={{
                    category: categoryName,
                }}
                sortingMode="server"
                onError={onApiError}
                setSnackbar={setSnackbar}
            // onRowClick={(params) => {
            //     navigate(`/terms-and-conditions/${params.id}`)
            // }}
            />
        </PageWrapper>
    )
}

export default withTabSearchParams(BundlePage, TABS)