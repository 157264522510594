import { Controller } from "react-hook-form";
import { Grid, InputAdornment, Box } from '@mui/material';
import {
    RadioGroup,
    TextField,
    Image,
    Typography,
    Button,
    DynamicForm,
    Counter,
    ComponentContainer,
    Select,
    AutoCompleteWithList,
    HtmlEditor,
    DatePicker
} from '../../components/share'

const Fields = ({ control, errors = {}, readOnly, isCreate=false }) => [
    {
        id: "title-en",
        component: (
            <Controller
                name={`name.en`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="English Campaign Name*"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.name?.en}
                        helperText={errors?.name?.en?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "title-zh",
        component: (
            <Controller
                name={`name.zh`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Chinese Campaign Name*"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.name?.zh}
                        helperText={errors?.name?.zh?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "promotion-code",
        component: (
            <Controller
                name={`code`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title={`Promo Code*\n(Format: a-z, A-Z, 0-9 (case sensitive), max characters = 30)`}
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.code}
                        helperText={errors?.code?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "discount-amount",
        component: (
            <Controller
                name={`discountAmount`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Discount Amount*"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.discountAmount}
                        helperText={errors?.discountAmount?.message}
                        InputProps={{
                            startAdornment: <InputAdornment style={{ marginTop: 0 }} position="start">HKD</InputAdornment>,
                        }}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "minimum-spent",
        component: (
            <Controller
                name={`minSpend`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Minimum Spending"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.minSpend}
                        helperText={errors?.minSpend?.message}
                        InputProps={{
                            startAdornment: <InputAdornment style={{ marginTop: 0 }} position="start">HKD</InputAdornment>,
                        }}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "label",
        component: (
            <Controller
                name={`label`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Label"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors?.label}
                        helperText={errors?.label?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "usedCount",
        component: (
            !!!isCreate ? (
                <Controller
                    name={`usedCount`}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            title="Used"
                            direction="vertical"
                            fullWidth
                            readOnly={true}
                            error={errors?.usedCount}
                            helperText={errors?.usedCount?.message}
                        />
                    )}
                />
            ) : null
        )
    }, { id: "spacing" },
]

export { Fields }