import { useContext, useRef } from 'react';
import styled from 'styled-components';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import { Routes, Route, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash'
import { Typography, Button } from '../../components/share';
import PageWrapper from '../../components/share/PageWrapper';
import UploadMediaForm from '../../components/media/UploadMediaForm';
import { snackbarContext } from '../../context/SnackbarProvider';
import { createMedia } from '../../apis/medias';

const SaveButton = styled(Button)`
    && {
        align-self: end; 
        width: 80px;
        margin: 10px 20px 14px 20px;
    }
`

function MediaAddPage() {
    const navigate = useNavigate();
    const formRef = useRef();
    const [snackbar, setSnackbar] = useContext(snackbarContext);

    const [searchParams] = useSearchParams();
    const category = searchParams.get('category')

    const createMutation = useMutation({
        mutationFn: (data) => createMedia(data),
        onSuccess: (data) => {
            setSnackbar({
                type: 'success',
                message: 'Media created successfully'
            })
            navigate(`/media`)
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const Header = () => {
        return (
            <Typography bold style={{ flex: 1 }}>{`Media > Add`}</Typography>
        )
    }

    const handleOnSubmit = (formValue) => {
        console.log("onSubmit", formValue)
        const payload = {
            images: formValue?.images.map(item => {
                return {
                    newImageKey: item.image.fileKey,
                    title: item.title,
                    description: item.description,
                    category: item.category,
                }
            })
        }
        createMutation.mutate(payload)
    }

    const CardHeader = () => {
        return (
            <SaveButton onClick={() => formRef.current.submitForm(handleOnSubmit)()}>Save</SaveButton>
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            renderCardHeader={CardHeader}
        >
            <UploadMediaForm
                ref={formRef}
                type={"add"}
                categoryId={category}
            />
        </PageWrapper>
    )
}

export default MediaAddPage