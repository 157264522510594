import { useMemo } from "react";
import { Controller } from "react-hook-form";
import dayjs from 'dayjs';
import { StatusButton, SortableList, Typography } from "../share";
import { DeleteActionIcon, UnderlineText } from '../share/Table';
import constants from "../../constants";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditNoteIcon from '@mui/icons-material/EditNote';

const ProductSortableList = (props) => {
    const { 
        control, 
        defaultColumn=[], 
        statusMutation, 
        canView, 
        canModify, 
        navigateToProductDetail, 
        navigateToCloneProduct, 
        handleOnDeleteProduct, 
        onSuccessChangeStatus,
        onFailChangeStatus,
    } = props;

    const EllipsisTypography = (props) => {
        return (
            <Typography fontSize={14} style={{ height: "fit-content", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                {props.children}
            </Typography>
        )
    }

    const columns = useMemo(() => {
        const statusField = defaultColumn.find(item => item.field === 'status') || {}
        const actionField = defaultColumn.find(item => item.field === 'actions') || {}

        return [
            { dataKey: '_id', label: 'Seq', width: 50, sortable: false },
            { dataKey: 'productId', label: 'Product ID', width: 250, sortable: false, valueGetter: (params) => params._id },
            { dataKey: 'brandName.en', label: 'Brand Name (En)', width: 160, valueGetter: (params) => params.brandName?.en },
            { dataKey: 'brandName.zh', label: 'Brand Name (Zh)', width: 160, valueGetter: (params) => params.brandName?.zh },
            { dataKey: 'productName.en', label: 'Product Name (En)', width: 180, renderCell: (params) => (<EllipsisTypography>{params.productName?.en}</EllipsisTypography>)},
            { dataKey: 'productName.zh', label: 'Product Name (Zh)', width: 180, valueGetter: (params) => (<EllipsisTypography>{params.productName?.zh}</EllipsisTypography>) },
            { dataKey: 'publishStartDate', label: 'Start Date & Time', width: 160, valueGetter: (params) => params.publishStartDate ? dayjs(params.publishStartDate).format(constants.DATE_TIME_FORMAT) : "" },
            { dataKey: 'publishEndDate', label: 'End Date & Time', width: 160, valueGetter: (params) => params.publishEndDate ? dayjs(params.publishEndDate).format(constants.DATE_TIME_FORMAT) : "" },
            { dataKey: 'markedPrice', label: 'Original Price', width: 140 },
            { dataKey: 'sellingPrice', label: 'Discounted Price', width: 140 },
            {
                dataKey: 'status',
                label: 'Status',
                width: statusField.width || 130,
                sortable: statusField.sortable || false,
                renderCell: (params) => {
                    const { _id: id, status: value } = params

                    const onChange = (newStatusValue) => {
                        statusMutation.mutate(
                            {
                                id,
                                payload: { status: newStatusValue }
                            },
                            {
                                onSuccess: () => {
                                    onSuccessChangeStatus && onSuccessChangeStatus()
                                },
                                onError: () => {
                                    onFailChangeStatus && onFailChangeStatus()
                                }
                            }
                        )
                    }

                    return (
                        <StatusButton
                            value={value}
                            onChange={onChange}
                            readOnly={!canModify}
                            options={[
                                {
                                    name: "Draft",
                                    id: "draft",
                                    color: "#4BA28D",
                                    Icon: (<EditNoteIcon />)
                                },
                                {
                                    name: "Pending",
                                    id: "pending",
                                    color: "#5D4986",
                                    Icon: (<CheckCircleOutlineIcon />)
                                },
                                {
                                    name: "Active",
                                    id: "active",
                                    // id: "published",
                                    color: "#306097",
                                    canEdit: true,
                                    Icon: (<CheckCircleOutlineIcon />)
                                },
                                {
                                    name: "Suspended",
                                    id: "suspended",
                                    color: "#f6f6f6",
                                    textColor: "#9B9B9B",
                                    canSelect: true,
                                    Icon: (<RemoveCircleOutlineIcon />)
                                },
                                {
                                    name: "Expired",
                                    id: "expired",
                                    color: "#8D9A66",
                                    Icon: (<CheckCircleOutlineIcon />)
                                },
                            ]}
                        />
                    )
                }
            },
            {
                dataKey: 'actions',
                label: 'Actions',
                width: actionField.width || 140,
                sortable: actionField.sortable || false,
                type: 'actions',
                getActions: (params) => {
                    const canDelete = params?.status === 'draft' ? true : false
                    return [
                        <UnderlineText onClick={() => navigateToProductDetail(params._id)} disabled={!canView}>View</UnderlineText>,
                        <UnderlineText onClick={() => navigateToCloneProduct(params._id)} disabled={!canModify}>Clone</UnderlineText>,
                        <DeleteActionIcon disabled={!canModify} hide={!canDelete} onClick={() => handleOnDeleteProduct(params._id)} />
                    ]
                },
            }
        ]
    }, [defaultColumn])

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                overflowX: "auto",
                overflowY: "hidden",
            }}
        >
            <Controller
                name="product"
                control={control}
                render={({ field }) => {
                    return (
                        <SortableList
                            data={field.value || []}
                            columns={columns}
                            rowHeight={52}
                            containerStyle={{
                                overflowX: "auto",
                                overflowY: "hidden",
                                width: "fit-content",
                            }}
                            headerStyle={{
                                background: "#FFFBE0",
                                borderRadius: 5
                            }}
                            rowStyle={{
                                borderBottom: `1px dashed #E2EBF4`,
                            }}
                            onSort={(oldIndex, newIndex) => {
                                const newItems = field.value.slice();
                                newItems.splice(newIndex, 0, newItems.splice(oldIndex, 1)[0]);
                                // setValues(newItems);
                                field.onChange(newItems)
                            }}
                        />
                    )
                }}
            />
        </div>
    )
}

export default ProductSortableList;