import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Image, Typography, TextField, Select, Button, ComponentContainer } from '../share';
import { Divider, Grid } from '@mui/material';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import styled from 'styled-components'
import _, { isEmpty } from 'lodash';
import UploadMediaItem from './UploadMediaItem';
import constants from '../../constants';
import dayjs from 'dayjs';

const FormContainer = styled(Grid)`
    && {
        > * {
            margin-bottom: 20px;
        }
    }
`

const CategoryList = [
    {
        name: 'All',
        _id: 'all'
    },
    {
        name: 'Banner',
        _id: 'banner',
    },
    {
        name: 'Others',
        _id: 'others'
    }
]

//Media used Only

const schema = Joi.object({
    images: Joi.array().items(
        Joi.object({
            image: Joi.object({
                fileKey: Joi.string(),
                previewUrl: Joi.string(),
            }).required(),
            title: Joi.string().empty("").required(),
            description: Joi.string().empty("").required(),
            category: Joi.string().empty("").required(),
            imageType: Joi.string().empty("").optional(),
            uploadAt: Joi.string().empty("").optional(),
        })
    )
}).messages({
    "any.required": "Required",
    "number.base": "Must be a number",
    "string.base": "Required",
    "string.empty": "Required",
});

const getInitialValues = (data) => {
    return {
        images: [{
            image: {
                fileKey: data?.image,
                previewUrl: data?.imageUrl,
            },
            title: data.title,
            description: data.description,
            category: data.category,
            imageType: data?.image.split('.').pop().toUpperCase(),
            uploadAt: data?.createdAt ? dayjs(data.createdAt).format(constants.DATE_FORMAT) : "",
        }]
    }
}

const UploadMediaForm = forwardRef((props, ref) => {
    const { type, data, onRemove, categoryId } = props;

    const { control, formState: { errors }, handleSubmit, reset } = useForm({
        resolver: joiResolver(schema),
        defaultValues: {
            images: [{
                image: {
                    fileKey: '',
                    previewUrl: ''
                },
                title: '',
                description: '',
                category: categoryId || '',
            }]
        }
    });

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: 'images',
    });

    useEffect(() => {
        if (data) {
            reset(getInitialValues(data))
        }
    }, [data])

    const isEdit = type === 'edit';

    // console.log('errors', errors)

    const onClickAppend = () => {
        append({
            image: {
                fileKey: '',
                previewUrl: ''
            },
            title: '',
            description: '',
            category: categoryId || '',
        })
    }

    useImperativeHandle(ref, () => ({
        submitForm: handleSubmit,
    }), [])

    return (
        <div style={{ height: '100%', overflow: 'auto', padding: 30 }}>
            <Grid container direction='column' gap={3}>
                { fields.map((field, position) => (
                    <UploadMediaItem
                        {...field}
                        key={field.id}
                        position={position}
                        control={control}
                        isEdit={isEdit}
                        onRemove={() => remove(position)}
                        errors={errors}
                    />
                ))}
                { !isEdit && (
                    <Grid item>
                        <Button variant="underline" color="neon" onClick={onClickAppend}>+ Add More Image</Button>
                    </Grid>
                )}
            </Grid>
        </div>
    )
})

export default UploadMediaForm