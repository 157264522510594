import { useState, useContext, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import _ from 'lodash'
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import PageWrapper from '../../components/share/PageWrapper';
import Typography from '../../components/share/Typography';
import Button from '../../components/share/Button';
import Select from '../../components/share/Select';
import GreyDivider from '../../components/GreyDivider';
import TextField from '../../components/share/TextField';
import DatePicker from '../../components/share/DatePicker';
import Form from '../../components/share/form/Form';
import Grid from '@mui/material/Grid';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import { createPromoCode, fetchPromoCode } from '../../apis/promoCode';
import { Routes, Route, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import DynamicForm from '../../components/share/form/DynamicForm'
import { Fields } from './formFields'
import { companiesContext } from '../../context/CompaniesProvider';
import { tncContext } from '../../context/TncProvider';
import constants from '../../constants';

const SaveButton = styled(Button)`
    && {
        align-self: end; 
        min-width: 80px;
        margin: 10px 0px;
        margin-right: 20px;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
    overflow: auto;
`

const margin = 10;

const schema = Joi.object({
    name: Joi.object({
        en: Joi.string().empty("").required(),
        zh: Joi.string().empty("").required(),
    }),
    code: Joi.string().pattern(/^[a-zA-Z0-9]+$/).max(30).required(),  // TODO: only accept a-z, A-z, 0-9
    discountAmount: Joi.number().required().min(0),
    minSpend: Joi.number().empty('').default(0).min(0),
    label: Joi.string().empty("").allow(null),
}).messages({
    "any.required": "Required",
    "object.base": "Required",
    "string.empty": "Required",
    "string.max": "Maximum 30 characters",
    "string.pattern.base": "Only accept a-z, A-z, 0-9",
    "number.base": "Must be a number",
    "number.min": "Must be greater than or equal to 0",
});

export const DefPlans = [
    {
        product: {},
        plan: {},
    }
]

function AddPromotionCode(props) {

    // let { categoryId } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [snackbar, setSnackbar] = useContext(snackbarContext);

    const id = searchParams.get('promoId');

    const { control, handleSubmit, watch, formState: { errors }, reset } = useForm({
        resolver: joiResolver(schema),
        // defaultValues: {
        //     bundlePlans: BundlePlanField
        // }
    });

    const { data, error, isError, isLoading } = useQuery({
        queryKey: ["promoDetail", { promoId: id }],
        queryFn: async () => await fetchPromoCode(id),
        enabled: Boolean(id)
    })

    useEffect(() => {
        if (!_.isEmpty(errors)) {
            setSnackbar({
                type: 'error',
                message: constants.INVALID_INPUT_ERR_MSG
            })
        }
    }, [errors])

    useEffect(() => {
        if (id && !isLoading && data) {
            reset({
                code: data.code,
                name: data.name,
                discountAmount: data.discountAmount,
                minSpend: data.minSpend || 0,
                label: data.label,
            })
        }
    }, [data, id])

    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (payload) => createPromoCode(payload),
        onSuccess: (data) => {
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
            navigate('/promotion-code')
            queryClient.invalidateQueries({ queryKey: ['/promo'] })
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const handleSubmitPromoCode = (formValue, isDraft=false) => {
        const payload = {
            ...formValue,
            minSpend: formValue.minSpend || 0,
            status: isDraft ? "draft" : "active",
        };

        mutation.mutate(payload)
    }

    const handleOnSubmitDraft = (formValue) => {
        handleSubmitPromoCode(formValue, true)
    }

    const handleOnSubmit = (formValue) => {
        handleSubmitPromoCode(formValue)
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Promotion Code > Add New`}</Typography>
            </>
        )
    }
    const CardHeader = () => {
        return (
            <Grid container direction='row' alignItems='center' justifyContent='flex-end'>

                <SaveButton
                    onClick={() => {
                        handleSubmit(handleOnSubmitDraft)();
                    }}
                    variant='outlined'
                >
                    Save As Draft
                </SaveButton>

                <SaveButton
                    onClick={() => {
                        handleSubmit(handleOnSubmit)();
                    }}
                >
                    Publish
                </SaveButton>
            </Grid>
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            renderCardHeader={CardHeader}
        >
            <Content>
                <Form fields={Fields({ control, errors, isCreate: true })} p={`14px 0px`} />
            </Content>
        </PageWrapper>
    )
}

export default AddPromotionCode