import * as React from 'react';
import { Grid, FormHelperText, Divider, Box } from '@mui/material'
import { ReactComponent as AddIcon } from '../../assets/grey-add.svg';
import { ReactComponent as AddImageText } from '../../assets/grey-add-image-text.svg';
import { Controller, useFieldArray } from "react-hook-form";
import _ from 'lodash'
import { Typography, SortableImgGrid } from '../share';

const upload = {
    id: 'upload',
    xs: false,
}

function GallarySlider({ control, readOnly, name, errors, setValue }) {


    const { fields, append, remove } = useFieldArray({
        control,
        name: `${name}.images`
    });

    const { fields: variations, append: appendVariations, remove: removeVariations, update: updateVariations } = useFieldArray({
        control,
        name: `step2.variation.variationProducts`
    });

    const data = readOnly ? fields : [{ ...upload }, ...fields];

    const handleSort = (cloneSet, name, oldIndex, newIndex) => {
        const clone = _.clone(cloneSet);
        const item = clone.splice(oldIndex, 1);
        clone.splice(newIndex, 0, ...item);
        const newData = clone.filter(item => item.id !== 'upload');
        setValue(name, newData, { shouldDirty: true });
    }

    if (!data?.length) {
        return (
            <Typography variant="header3">No Image</Typography>
        )
    }


    return (
        <Grid container>
            <Box display={"flex"} flexDirection={"column"}>
            <Typography variant="header" sx={{ mb: 1 }}>Recommended image size to be 760 x 760 pixel</Typography>
            {errors?.images?.message && <FormHelperText error sx={{ mb: 1 }}>{errors?.images?.message}</FormHelperText>}
            </Box>
            <SortableImgGrid
                data={data}
                onImageUploaded={(response) => {
                    append({
                        ...response,
                        url: response.previewUrl,
                        id: response.fileKey,
                        xs: 1
                    })
                }}
                imgSize={197}
                readOnly={readOnly}
                onRemove={({ position }) => {
                    remove(position - 1)
                }}
                onClickImg={(item) => {
                    window.open(item.previewUrl)
                }}
                onSort={(oldIndex, newIndex) => handleSort(data, `${name}.images`, oldIndex, newIndex)}
            />

            {
                variations.map((item, index) => {
                    const imgs = item?.images || []
                    const imgData = readOnly ? imgs || [] : [{ ...upload }, ...imgs]
                    return (
                        <>
                            <Divider
                                sx={{
                                    borderWidth: 0.5,
                                    borderColor: 'secondary.main',
                                    width: '100%',
                                    m: '20px 0px 20px -20px',
                                    pr: `39px`
                                }}
                            />
                            <Box display='flex' flexDirection='column'>
                                <Typography variant="header1" sx={{ mb: `10px` }}>{item?.en || item?.productName?.en || ""}</Typography>
                                {
                                    readOnly && !item?.images?.length &&
                                    <Typography variant="header3" sx={{ mb: `10px` }}>No Image</Typography>
                                }
                            </Box>

                            <SortableImgGrid
                                data={imgData}
                                onImageUploaded={(response) => {
                                    let clone = _.clone(item?.images || [])
                                    clone.push({
                                        ...response,
                                        url: response.previewUrl,
                                        id: response.fileKey,
                                        xs: 1
                                    })
                                    let cloneVariations = _.cloneDeep(variations)
                                    cloneVariations[index] = {
                                        ...item,
                                        images: clone
                                    }
                                    setValue(`step2.variation.variationProducts`, cloneVariations)
                                }}
                                imgSize={197}
                                readOnly={readOnly}
                                onRemove={({ position }) => {
                                    let clone = _.clone(item?.images || [])
                                    clone.splice(position - 1, 1);
                                    let cloneVariations = _.cloneDeep(variations)
                                    cloneVariations[index] = {
                                        ...item,
                                        images: clone
                                    }
                                    setValue(`step2.variation.variationProducts`, cloneVariations, { shouldDirty: true })
                                }}
                                onClickImg={(item) => {
                                    window.open(item.previewUrl)
                                }}
                                onSort={(oldIndex, newIndex) => {
                                    const clone = _.clone(imgData);
                                    const imgItem = clone.splice(oldIndex, 1);
                                    clone.splice(newIndex, 0, ...imgItem);
                                    const newData = clone.filter(item => item.id !== 'upload');
                                    let cloneVariations = _.cloneDeep(variations)
                                    cloneVariations[index] = {
                                        ...item,
                                        images: newData
                                    }
                                    setValue(`step2.variation.variationProducts`, cloneVariations, { shouldDirty: true })
                                }}
                            />
                        </>
                    )
                })
            }
        </Grid>
    )
}

export default GallarySlider