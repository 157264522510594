import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import _ from 'lodash';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ConfirmStatusChangeDialog from './ConfirmStatusChangeDialog';

const allOptions = [
    {
        name: "Draft",
        id: "draft",
        color: "#4BA28D",
        Icon: (<EditNoteIcon />)
    },
    {
        name: "Pending",
        id: "pending",
        color: "#5D4986",
        Icon: (<CheckCircleOutlineIcon />)
    },
    {
        name: "Pending Payment",
        id: "pendingPayment",
        color: "#5D4986",
    },
    {
        name: "Pending Document",
        id: "pendingDocument",
        color: "#5D4986",
    },
    {
        name: "Need Follow Up",
        id: "needFollowUp",
        color: "#5D4986",
    },
    {
        name: "Processing",
        id: "processing",
        color: "#5D4986",
    },
    {
        name: "Active",
        id: "active",
        // id: "published",
        color: "#306097",
        canSelect: true,
        Icon: (<CheckCircleOutlineIcon />)
    },
    {
        name: "Suspended",
        id: "suspended",
        color: "#f6f6f6",
        textColor: "#9B9B9B",
        Icon: (<RemoveCircleOutlineIcon />)
    },
    {
        name: "Expired",
        id: "expired",
        color: "#8D9A66",
        Icon: (<CheckCircleOutlineIcon />)
    },
    {
        name: "Completed",
        id: "completed",
        color: "#306097",
    },
    {
        name: "Cancelled",
        id: "cancelled",
        color: "#f5f5f5",
        textColor: "#9B9B9B"
    },
    {
        name: "Failed",
        id: "failed",
        color: "#f5f5f5",
        textColor: "#9B9B9B"
    },
    {
        name: "Submitted",
        id: "documentSubmitted",
        color: "#9A482E",
    },
    {
        name: "Verified",
        id: "verified",
        color: "#9A482E",
    },
    {
        name: "Payment Success",
        id: "paymentSuccess",
        color: "#5D4986",
    },
    {
        name: "Payment Failure",
        id: "paymentFailure",
        color: "#9B9B9B",
    }

];

export default function StatusButton({ value, readOnly, onChange, hideIcon, options = [] }) {
    const [open, setOpen] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);

    const originIndex = _.findIndex(options, { id: value });

    React.useEffect(() => {
        if (value) {
            if (originIndex >= 0) {
                setSelectedIndex(originIndex)
            }
        }        
    }, [value])

    const handleMenuItemClick = (
        event,
        index,
    ) => {
        console.info(`You clicked`, options[index]);
        setOpen(false);

        if (index === selectedIndex) return;
        setSelectedIndex(index);
        setDialogOpen(true);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
        // if (!onClose || !onOpen) return
        // open ? onClose() : onOpen()
    };

    const handleClose = (event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target)
        ) {
            return;
        }
        // onClose && onClose()
        setOpen(false);
    };

    if (!value) return null

    return (
        <React.Fragment>
            <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label="split button"
                sx={{
                    minWidth: 114,
                    backgroundColor: options[selectedIndex]?.color,
                    height: 30,
                }}
            >
                <Button
                    // onClick={handleToggle}
                    disableRipple
                    sx={{
                        textTransform: 'none',
                        flex: 1,
                        "& .MuiButton-startIcon": {
                            marginRight: "3px",
                            marginLeft: "-6px"
                        }
                    }}
                    style={{
                        border: 'none',
                        backgroundColor: options[selectedIndex]?.color,
                        color: options[selectedIndex]?.textColor,
                        justifyContent: 'left'
                    }}
                    startIcon={!hideIcon && options[selectedIndex]?.Icon}
                >
                    {options[selectedIndex]?.name || value}
                </Button>
                {!readOnly && options[selectedIndex]?.canEdit && 
                    <>
                        <div style={{ backgroundColor: '#fff', height: '20px', width: 1, alignSelf: 'center' }} />
                        <Button
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                            style={{ minWidth: 22, width: 22, backgroundColor: options[selectedIndex]?.color }}
                        >
                            <KeyboardArrowDownIcon style={{ fontSize: `1rem` }} />
                        </Button>
                    </>
                }
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                keepMounted={true}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {options.map((option, index) => {
                                        if (!option.canSelect) return null
                                        return (
                                            <MenuItem
                                                key={option.id}
                                                selected={index === selectedIndex}
                                                onClick={(event) => handleMenuItemClick(event, index)}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        )
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <ConfirmStatusChangeDialog
                open={dialogOpen}
                onCancel={() => setSelectedIndex(originIndex)}
                onSubmit={(id) => onChange && onChange(id)}
                onClose={() => setDialogOpen(false)}
                originIndex={originIndex}
                selectedIndex={selectedIndex}
                options={options}
            />
        </React.Fragment>
    );
}
