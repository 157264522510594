import { useState, useMemo, useEffect } from 'react';
import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import PropTypes from 'prop-types';
import { styled as styledMui } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { ReactComponent as DisabledDeleteIcon } from '../../assets/delete-icon-disabled.svg';
import constants from '../../constants';
import {
    useQuery,
} from '@tanstack/react-query'
// import { listTAndC } from '../apis/termsAndConditions'
import { request, axios } from '../../apis'
import _ from 'lodash'
import Typography from './Typography'
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components'

export const UnderlineText = styledMui(Typography)(
    ({ theme, disabled }) => `
    && {
        text-decoration-line: underline;
        cursor: pointer;
        color: ${theme.palette.primary.main};
        ${disabled && `pointer-events: none; color: ${theme.palette.secondary.main};`}
    }
  `,
);

export const DeleteActionIcon = (props) => {
    return (
        <IconButton {...props} disabled={props?.hide || props?.disabled}>
            {
                props.hide ? 
                    <Box height={15} width={16} /> : 
                    props.disabled ? <DisabledDeleteIcon /> : <DeleteIcon />
            }
        </IconButton>
    )
}


function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            page={page + 1}
            count={pageCount}
            defaultPage={1}
            siblingCount={0}
            sx={[
                {
                    ".MuiPaginationItem-root.Mui-selected": {
                        backgroundColor: 'primary.main',
                        color: '#fff',
                        ":hover": {
                            backgroundColor: 'primary.main',
                            color: '#fff',
                        }
                    }
                },
                {
                    ".MuiPaginationItem-text": {
                        color: "#CBD5E0"
                    },
                },
                {
                    ".MuiPaginationItem-previousNext": {
                        color: "#4A5568"
                    },
                },
            ]}
            renderItem={(props2) => (
                <PaginationItem
                    {...props2}
                    disableRipple
                />
            )}
            onChange={(event, value) =>
                apiRef.current.setPage(value - 1)
            }
        />
    );
}

export default function Table({ style, columns, data, api, apiParams, onSuccess, onError, checkboxSelection, ...props }) {

    const [page, setPage] = useState({
        pageNum: 0,
        pageSize: 30
    });
    const [sorting, setSorting] = useState(null);

    let params = _.pickBy(apiParams, v => v !== undefined && v !== null && v !== "")
    params = new URLSearchParams(params)
    params.append("page", page.pageNum + 1)
    params.append("limit", page.pageSize)
    if (sorting) params.append("sort", sorting)

    const { data: response, error, isError, isLoading } = useQuery({
        queryKey: [api, { page: page.pageNum, params: apiParams, sorting }],
        enabled: api ? true : false,
        queryFn: () => axios.get(`${constants.API_ENDPOINT}${api}`, {
            params: params
        }).then((res) => res.data),
        retry: false,
        onSuccess: (data) => {
            onSuccess && onSuccess(data)
        },
        onError: (error) => {
            onError && onError(error)
        }
    })

    return (
        <>
            <div style={{ height: '100%', display: 'flex', ...style }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        //api
                        // rows={data || dummy_rows}
                        rows={data || response?.data || []}
                        columns={columns}
                        hideFooterSelectedRowCount
                        disableColumnMenu                                             
                        pageSize={page.pageSize}
                        // rowsPerPageOptions={[5]}
                        paginationMode="server"
                        onPageChange={(pageValue) => setPage({ ...page, pageNum: pageValue })}
                        getRowId={(row) => row?._id || row.createdAt}
                        rowCount={response?.totalCount || 0}
                        rowSelection={false}
                        isRowSelectable={() => checkboxSelection}
                        onSortModelChange={(model, details) => {
                            if (model.length > 0) {
                                const s = model[0]
                                setSorting(s.sort === 'asc' ? s.field : `-${s.field}`)
                            } else {
                                setSorting(null)
                            }
                        }}
                        sx={{
                            '.MuiDataGrid-columnSeparator': {
                                display: 'none',
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: 'mainColor.light',
                                borderBottom: 'none',
                                borderBottomLeftRadius: 4,
                                borderBottomRightRadius: 4,
                                color: 'info.dark',
                                "& :focus": {
                                    outline: "none !important",
                                }
                            },
                            '& .MuiDataGrid-footerContainer': {
                                borderTop: 'none'
                            },
                            '& .MuiDataGrid-cell': {
                                borderBottom: `1px dashed #C9C9C9`,
                                cursor: !!props.onRowClick || !!props.onCellClick? 'pointer' : 'default'
                                // marginRight: 0.3
                            },
                            "& .MuiDataGrid-cell:focus-within": {
                                outline: "none !important",
                            },
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: 'transparent'
                            },
                        }}
                        components={{
                            Pagination: CustomPagination,
                        }}
                        style={{
                            border: 'none'
                        }}
                        loading={api && isLoading}
                        checkboxSelection={checkboxSelection}
                        {...props}
                    />
                </div>
            </div>
        </>
    );
}

Table.propTypes = {
    rows: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    api: PropTypes.string,
};
