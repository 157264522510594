import { Grid, Box, List, ListItem } from '@mui/material';
import Typography from '../Typography'
import { Controller } from "react-hook-form";
import RadioGroup from '../RadioGroup';
import Button from '../Button'

const opts = [
    {
        label: 'Disable',
        value: 'disable'
    },
    {
        label: 'Enable',
        value: 'enable'
    },
    {
        label: 'Enable With Mandatory',
        value: 'enableWithMandatory'
    }
];

function SectionList({ data, control, name, readOnly }) {
    return (
        <List>
            {
                data.map((item, position) => {
                    if (item.id === 'header') {
                        return (
                            <ListItem key={`${name}-${item.name}-${position}`}>
                                <Typography variant="header" >{item.name}</Typography>
                            </ListItem>
                        )
                    }
                    if (item.id === 'mobileNoPortabilityDownloadForm') {
                        return (
                            <ListItem key={`${name}-${item.name}-${position}`} style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Controller
                                    name={`control.${item.id}`}
                                    control={control}
                                    render={({ field }) => (
                                        <RadioGroup
                                            title={item.name}
                                            options={opts}
                                            row
                                            fullWidth={false}
                                            disabled={readOnly}
                                            direction='vertical'
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name={`control.downloadForm`}
                                    control={control}
                                    render={({ field }) => (
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            style={{ margin: `8px 10px` }}
                                        // onClick={() => openDialog({ onChange: field.onChange })}
                                        >
                                            + Upload
                                        </Button>
                                    )}
                                />
                            </ListItem>
                        )
                    }
                    return (
                        <ListItem key={`${name}-${item.id}-${position}`}>
                            <Controller
                                name={`control.${item.id}`}
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup
                                        title={item.name}
                                        options={opts}
                                        row
                                        fullWidth={false}
                                        disabled={readOnly}
                                        direction='vertical'
                                        {...field}
                                    />
                                )}
                            />
                        </ListItem>
                    )
                })
            }
        </List>
    )
}

export default SectionList