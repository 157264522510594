import { useState, useContext, useCallback, useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
    GridActionsCellItem,
} from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import { Routes, Route, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash'
import {
    Typography,
    Button,
    Tab,
    Searchbar,
    Checkbox,
    UploadComponent,
    PageWrapper
} from '../../components/share';
import Table, { DeleteActionIcon } from '../../components/share/Table';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import AlertDialogProvider, { alertDialogContext } from '../../context/AlertDialogProvider';
import { tncContext } from '../../context/TncProvider';
import { userContext } from '../../context/UserProvider';
import { listCategory, deleteTnc } from '../../apis/termsAndConditions';
import constants from '../../constants'
import dayjs from 'dayjs'
import { getPermission, toDisplayName } from '../../utils'

const margin = 10

//listTNC: sort

function TermsAndConditions(props) {
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [dialog, setDialog] = useContext(alertDialogContext);
    const [user, setUser] = useContext(userContext);
    const [tncList, setTncList] = useContext(tncContext)

    const { canView, canModify } = getPermission(user?.permissions?.tnc)

    const [filter, setFilter] = useState({
        keyword: ""
    })
    const [selectMode, setSelectMode] = useState(false);

    let [searchParams, setSearchParams] = useSearchParams();

    const queryClient = useQueryClient()

    const { data, error, isError, isLoading } = useQuery({
        queryKey: ["category"],
        queryFn: listCategory,
        onSuccess: (data) => {
            if (data && data.length > 0) {
                if (!searchParams.has('tab')) {
                    setSearchParams({ tab: data[0] })
                    setTabIndex(0)
                } else {
                    const tabIndex = _.indexOf(data, categoryName)
                    setTabIndex(tabIndex)
                }
            }
        }
    });

    const categoryList = useMemo(() => {
        if (data) {
            return _.map(data, (item) => ({ name: item.replace("_", " ").toLowerCase() }))
        }
        return []
    }, [data])

    const mutation = useMutation({
        mutationFn: (id) => deleteTnc(id),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['/tnc'] })
            queryClient.invalidateQueries({ queryKey: ['allTnc'] })
        },
        onError: (error) => {
            console.log("error", error)
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        },
    })

    const onDeleteTnc = (params) => {
        setDialog({
            open: true,
            title:  "Are you sure to delete this T&C?",
            handleOnClickOK: () => mutation.mutate(params.id)
        })
    }

    const columns = useMemo(() => {
        return [
            { field: '_id', headerName: 'ID', width: 250, sortable: false, },
            { field: 'title.en', headerName: 'T&C Name (en)', flex: 1, valueGetter: (params) => params.row?.title?.en || "", },
            { field: 'title.zh', headerName: 'T&C Name (chi)', flex: 1, valueGetter: (params) => params.row?.title?.zh || "", },
            { field: 'updatedAt', headerName: 'Last Updated Date', width: 140, sortable: false, valueGetter: (params) => params.value ? dayjs(params.value).format(constants.DATE_FORMAT) : "" },
            // { field: 'status', headerName: 'Status', width: 100, sortable: false, },
            {
                field: 'actions',
                headerName: 'Action',
                type: 'actions',
                width: 80,
                headerAlign: 'center',
                getActions: (params) => [
                    <DeleteActionIcon disabled={!canModify} onClick={() => onDeleteTnc(params)} />
                ],
            }
        ]
    }, [data, canModify])

    const categoryName = searchParams.get('tab');

    const onClickTab = (value) => {
        setSearchParams({ tab: data[value] })
        setTabIndex(value)
    };

    const onApiError = (error) => {
        setSnackbar({
            type: 'error',
            message: `${error?.response?.data?.result?.errorMessage?.en || error?.message}`
        })
    }

    const onRowClick = (params) => {
        navigate(`/terms-and-conditions/${params.id}`)
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`T&C > ${toDisplayName(categoryName)}`}</Typography>
                {/* <UploadComponent
                    renderComponent={({ selectFile }) => (
                        <Button
                            color="blue"
                            style={{ marginRight: margin }}
                            onClick={selectFile}
                        >
                            Import
                        </Button>
                    )}
                /> */}
                {/* <Button color="green" style={{ marginRight: margin }}>Filter</Button> */}
            </>
        )
    }

    const CardHeader = () => {
        return (
            <Tab
                tabs={categoryList}
                selected={tabIndex}
                onChange={onClickTab}
                hideScrollbar
                renderButton={() => {
                    return (
                        <Button
                            style={{
                                alignSelf: 'flex-end',
                                margin: `0px 20px 14px 20px`,
                            }}
                            disabled={!canModify}
                            onClick={() => navigate(`/terms-and-conditions/${categoryName}/add`)}
                            type="add"
                        >
                            Add New
                        </Button>
                    )
                }}
            />
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderCustomCardHeader={CardHeader}
        >

            <Grid container direction='row' alignItems='center' style={{ paddingRight: 10 }}>
                <Searchbar value={filter.keyword} onChange={(e) => setFilter({ ...filter, keyword: e.target.value })} />
                {/* <Checkbox label="Select" fullWidth={false} checked={selectMode} onChange={(event) => setSelectMode(event.target.checked)} /> */}
            </Grid>
            <Table
                style={{ margin: `0px 20px 20px 20px` }}
                color="grey"
                columns={columns}
                api={isLoading ? undefined : "/tnc"}
                apiParams={{
                    ...filter,
                    category: data?.[tabIndex]
                }}
                sortingMode="server"
                onError={onApiError}
                setSnackbar={setSnackbar}
                onRowClick={onRowClick}
                checkboxSelection={selectMode}
            />
        </PageWrapper>
    )
}

export default TermsAndConditions