import Button from '@mui/material/Button';
import styled from 'styled-components';
// import { theme } from '../../App';
import { ReactComponent as BackIcon } from '../../assets/button-back-left-arrow.svg';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { forwardRef } from 'react'
import { styled as styledMui, createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#333333',
            contrastText: "#fff"
        },
        secondary: {
            main: "#9B9B9B",
            light: "#f6f6f6",
            dark: "#E2E2E2"
        },
        green: {
            main: "#309E9E",
            contrastText: "#fff"
        },
        blue: {
            main: "#306097",
            contrastText: "#fff"
        },
        orange: {
            main: "#C06902",
            contrastText: "#fff"
        },
        neon: {
            main: "#F2A000"
        }
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableFocusRipple: true,
                disableRipple: true,
                disableElevation: true,
            },
            variants: [
                {
                    props: { variant: 'underline', color: 'neon' },
                    style: {
                        textDecoration: "underline",
                        fontSize: 14,
                        fontWeight: 500,
                        color: "#F2A000",
                        "&:hover": {
                            backgroundColor: "transparent",
                            textDecoration: "underline",
                        }
                    },
                },
            ],
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    "&:disabled": {
                        color: "secondary.main",
                        backgroundColor: "secondary.dark",
                        cursor: "not-allowed"
                    },
                },
            },
        },
    },
});

const StyledButton = styledMui(Button)(
    ({ theme }) => `
    && {
        height: 30px;
        white-space: nowrap;
    }
  `,
);

const BackButton = styledMui(Button)(
    ({ theme }) => `
    && {
        width: 62px;
        height: 30px;
        background-color: #fff;
    }
  `,
);

const AppButton = forwardRef(({ type = "filled", loading, ...props }, ref) => {

    if (loading) {
        return (
            <ThemeProvider theme={theme}>
                <StyledButton {...props} ref={ref} disabled={true}>
                    <CircularProgress size={16} sx={{ color: 'secondary.main' }} />
                </StyledButton>
            </ThemeProvider>
        )
    }

    if (type === 'back') {
        return (
            <ThemeProvider theme={theme}>
                <BackButton variant="outlined" startIcon={<BackIcon />} {...props} ref={ref}>
                    Back
                </BackButton>
            </ThemeProvider>
        )
    }

    if (type === 'add') {
        return (
            <ThemeProvider theme={theme}>
                <StyledButton variant="contained" startIcon={<AddOutlinedIcon />} {...props} ref={ref}>
                    {props.children}
                </StyledButton>
            </ThemeProvider>
        )
    }

    if (type === 'outlined') {
        return (
            <ThemeProvider theme={theme}>
                <StyledButton variant="outlined" {...props} ref={ref} />
            </ThemeProvider>
        )
    }

    return (
        <ThemeProvider theme={theme}>
            <StyledButton variant="contained" {...props} ref={ref} />
        </ThemeProvider>
    )
})

export default AppButton;
