import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { snackbarContext } from '../../context/SnackbarProvider';
import { useContext, forwardRef } from 'react';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomSnackbar() {
  const [snackbar, setSnackbar] = useContext(snackbarContext);

  const handleSnackbarClose = () => {
    setSnackbar(null);
  }
  return (
    <Snackbar open={snackbar != null} autoHideDuration={6000} onClose={handleSnackbarClose}>
      <Alert onClose={handleSnackbarClose} severity={(snackbar && snackbar.type) || 'success'}>
        {snackbar && snackbar.message}
      </Alert>
    </Snackbar>
  )
}
