import UploadComponent from "../UploadComponent"
import { ImgContainer } from "./ImageV2"
import Typography from "../Typography"
import { CircularProgress } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';

const UploadImgBox = ({ imgSize, onImageUploaded }) => {
    return (
        <UploadComponent
            renderComponent={({ isLoading, selectFile }) => {
                return (
                    <ImgContainer
                        height={imgSize}
                        width={imgSize}
                        onClick={selectFile}
                        style={{ flexDirection: 'column', flexWrap: 'nowrap', cursor: 'pointer' }}
                    >
                        {
                            isLoading ? <CircularProgress /> :
                                <>
                                    <AddIcon/>
                                    <Typography fontSize={12}>Upload Image</Typography>
                                </>
                        }
                    </ImgContainer>
                )
            }}
            accept="image/*"
            onChange={(event, response) => {
                onImageUploaded && onImageUploaded(response)
            }}
        />
    )
}

export default UploadImgBox;