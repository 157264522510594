import { useState, useContext, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import {
    useQuery,
    useMutation,
    useQueryClient
} from '@tanstack/react-query'
import _ from 'lodash'
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import {
    PageWrapper,
    Typography,
    Button,
    Form
} from '../../components/share';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import { listCategory, createTnc } from '../../apis/termsAndConditions';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import { FIELDS } from './formFields';
import { getPermission, toDisplayName } from '../../utils'
import { userContext } from '../../context/UserProvider';
import constants from '../../constants'

const SaveButton = styled(Button)`
    && {
        align-self: end; 
        width: 80px;
        margin: 10px 20px 14px 20px;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
    height: 100%;
    overflow: auto;
`

const margin = 10;

export const schema = Joi.object({
    code: Joi.string().required(),
    title: Joi.object({
        en: Joi.string().required(),
        zh: Joi.string().required(),
    }),
    shortDescription: Joi.object({
        en: Joi.string().empty(""),
        zh: Joi.string().empty(""),
    }),
    description: Joi.object({
        en: Joi.string().required(),
        zh: Joi.string().required(),
    }),
    // category: Joi.string().required(),
}).messages({
    "any.required": "Required",
    "object.base": "Required"
});

function AddTermsAndConditions(props) {

    let { categoryId } = useParams();
    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [user, setUser] = useContext(userContext);

    const { canView, canModify } = getPermission(user?.permissions?.tnc)

    const { data, error, isError, isLoading } = useQuery({
        queryKey: ["category", canModify],
        queryFn: listCategory,
        enabled: canModify
    });

    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (newTnc) => createTnc(newTnc),
        onSuccess: (data) => {
            navigate(`/terms-and-conditions?tab=${data.category}`);
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
            queryClient.invalidateQueries({ queryKey: ['allTnc'] })
        },
    })

    const { control, handleSubmit, watch, formState: { errors }, getValues } = useForm({
        resolver: joiResolver(schema),
    });

    useEffect(() => {
        if (!_.isEmpty(errors)) {
            setSnackbar({
                type: "error",
                message: constants.INVALID_INPUT_ERR_MSG
            })
        }
    }, [errors])

    const categoryList = useMemo(() => {
        if (data) {
            return _.map(data, (item) => ({ name: item }))
        }
        return []
    }, [data])

    const handleOnSubmit = (formValue) => {
        const payload = {
            ...formValue,
            category: categoryId
        };
        mutation.mutate(payload)
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`T&C > ${toDisplayName(categoryId)} > Add New`}</Typography>
            </>
        )
    }

    const CardHeader = () => {
        return (
            <SaveButton 
                onClick={() => {
                    // console.log("getValues", getValues())
                    handleSubmit(handleOnSubmit)();
                }}
                disabled={!canModify}
            >
                Save
            </SaveButton>
        )
    }

    return (
        <PageWrapper
            loading={isLoading || mutation.isLoading}
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            renderCardHeader={CardHeader}
        >
            <Content>
                <Typography variant="header3">Fields marked * are required</Typography>
                <Form fields={FIELDS({ control, categoryList: categoryList, errors })} p={`14px 0px`} />
            </Content>
        </PageWrapper>
    )
}

export default AddTermsAndConditions