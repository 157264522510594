import { useState, useContext, useCallback, useImperativeHandle, useEffect, forwardRef } from 'react';
import styled from 'styled-components';
import _ from 'lodash'
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Form } from '../../components/share';
import {
    DeliveryFields,
    PlanFields,
} from './formFields';
import { getDirtyValues } from '../../utils'
import { CircularProgress, Box } from '@mui/material';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

const schema = Joi.object({
    mnpActivationHolidays: Joi.array().items(Joi.string()),
    mnpNumberActivationMinDay: Joi.number(),
    mnpNumberActivationMaxDay: Joi.number(),
    newNumberActivationMinDay: Joi.number(),
    newNumberActivationMaxDay: Joi.number(),
}).messages({
    "any.required": "Required",
    "number.base": "Must be a number",
});


const getResetData = ({ data }) => {
    return {
        mnpActivationHolidays: _.find(data, { keyPath: "plan.mnpActivationHolidays" })?.value,
        mnpNumberActivationMinDay: _.find(data, { keyPath: "plan.mnpNumberActivationMinDay" })?.value,
        mnpNumberActivationMaxDay: _.find(data, { keyPath: "plan.mnpNumberActivationMaxDay" })?.value,
        newNumberActivationMinDay: _.find(data, { keyPath: "plan.newNumberActivationMinDay" })?.value,
        newNumberActivationMaxDay: _.find(data, { keyPath: "plan.newNumberActivationMaxDay" })?.value,
    }
}

const PlanConfiguration = forwardRef((props, ref) => {
    const { data, isLoading, setSnackbar, permission, onSubmit } = props;

    const { control, handleSubmit, watch, formState: { errors, dirtyFields }, reset, setValue } = useForm({
        resolver: joiResolver(schema),
    });

    useEffect(() => {
        if (data) {
            reset(getResetData({ data: data }))
        }
    }, [data])

    const handleOnSubmit = (formValue) => {
        if (_.isEmpty(dirtyFields)) {
            return setSnackbar({
                type: "success",
                message: "Success"
            })
        }
        const dirty = getDirtyValues(dirtyFields, formValue);
        onSubmit && onSubmit(dirty);
    }

    useImperativeHandle(ref, () => ({
        onSubmit: () => handleSubmit(handleOnSubmit)()
    }));

    if (isLoading) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" height="100％"></Box>
        )
    }

    return (
        <Form fields={PlanFields({ control, errors: errors, readOnly: permission.canModify ? false : true })} p={`14px 0px`} />
    )
})

export default PlanConfiguration