import { useState, useEffect, createContext } from "react";
import pkceChallenge from 'pkce-challenge';

export const userContext = createContext();
function UserProvider({ children }){
    const [user, setUser] = useState(null);

    // useEffect(() => {
    //     const code = pkceChallenge();
    //     console.log("code", code)
    //     setUser(code)
    // }, [])

    return (
        <userContext.Provider value={[user, setUser]}>
            {children}
        </userContext.Provider>
    )
}

export default UserProvider