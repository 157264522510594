import {
    useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const SortableItem = ({
    id,
    item,
    renderFunction,
    isDragged = false,
    position
}) => {

    const {
        setNodeRef,
        attributes,
        listeners,
        transform,
        transition,
        activeIndex,
        index
    } = useSortable({ id });

    return renderFunction({
        item,
        ref: setNodeRef,
        props: {
            ...attributes,
            style: {
                transform: CSS.Transform.toString(transform),
                transition
            }
        },
        handleProps: listeners,
        isActive: index >= 0 && activeIndex === index,
        isDragged,
        index,
        position
    });
};

export default SortableItem