import { request } from './index';
import constants from '../constants';

const fetchPlan = (id) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "get",
            url: `${constants.API_ENDPOINT}/plan/${id}`
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const createPlan = (payload) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "post",
            url: `${constants.API_ENDPOINT}/plan`,
            data: payload
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const updatePlan = (id, payload) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "post",
            url: `${constants.API_ENDPOINT}/plan/${id}`,
            data: payload
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const deletePlan = (id) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "delete",
            url: `${constants.API_ENDPOINT}/plan/${id}`,
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const disableNumber = (id) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "put",
            url: `${constants.API_ENDPOINT}/numberPool/${id}/updateUnavailable`,
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result || error)
            })
    })
}

const importNumber = (file) => {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('file', file);

        return request({
            method: "post",
            url: `${constants.API_ENDPOINT}/numberPool/import/csv`,
            data: formData
        })
            .then(res => {
                resolve(res?.data)
            })
            .catch((error) => {
                reject(error?.result || error)
            })
    })
}

const fetchSortingPlan = ({ company=undefined }) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "get",
            url: `${constants.API_ENDPOINT}/plan/reorder/list`,
            params: {
                company
            }
        })
            .then(res => {
                resolve(res)    // TODO: api wrapped by res.data?
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const updateSortingPlan = ({ payload }) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "post",
            url: `${constants.API_ENDPOINT}/plan/reorder/list`,
            data: payload
        })
            .then(res => {
                resolve(res)    // TODO: api wrapped by res.data?
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

export {
    fetchPlan,
    createPlan,
    updatePlan,
    deletePlan,
    disableNumber,
    importNumber,
    fetchSortingPlan,
    updateSortingPlan,
}
