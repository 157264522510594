import dayjs, { Dayjs } from 'dayjs';
import Badge from '@mui/material/Badge';
import TextField from './TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { CalendarPickerSkeleton } from '@mui/x-date-pickers/CalendarPickerSkeleton';
import Typography from './Typography';
import styled from 'styled-components';
import ComponentContainer from './ComponentContainer';


const Container = styled.div`
    display: flex;
    flex-direction: column;
`

function TimePickerComponent({ title, fullWidth, onChange, value = dayjs(), ...props}) {
    return (
        <ComponentContainer direction='vertical' title={title} {...props}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth={fullWidth}
                        />
                    )}
                    {...props}
                    value={value}
                    onChange={(newValue) => {
                        if (!newValue || !dayjs(newValue).isValid()) {
                            const tmp = undefined
                            // const tmp = value ? dayjs().set('hour', 0).set('minute', 0).set('second', 0) : null
                            onChange && onChange(tmp)
                            return 
                        }

                        const newDate = dayjs(newValue)
                        const originDate = value ? dayjs(value) : dayjs().set('hour', 0).set('minute', 0).set('second', 0)
                        const tmp = originDate.set('hour', newDate.get('hour')).set('minute', newDate.get('minute')).set('second', newDate.get('second'))
                        onChange && onChange(tmp.toISOString())
                    }}
                />
            </LocalizationProvider>
        </ComponentContainer>

    )
}

export default TimePickerComponent;