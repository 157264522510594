import { request } from './index';
import constants from '../constants';

const listCompany = (setting={}) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "get",
            url: `${constants.API_ENDPOINT}/company`,
            ...setting,
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const fetchCompany = (id) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "get",
            url: `${constants.API_ENDPOINT}/company/${id}`
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const createCompany = (payload) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "post",
            url: `${constants.API_ENDPOINT}/company`,
            data: payload
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const updateCompany = (id, payload) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "put",
            url: `${constants.API_ENDPOINT}/company/${id}`,
            data: payload
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const deleteCompany = (id) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "delete",
            url: `${constants.API_ENDPOINT}/company/${id}`,
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const listCompanyBannerIndex = (id) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "get",
            url: `${constants.API_ENDPOINT}/company/${id}/banners`,
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const updateBannerIndex = (id, payload) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "put",
            url: `${constants.API_ENDPOINT}/company/${id}/banners`,
            data: payload
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

export {
    listCompany,
    fetchCompany,
    createCompany,
    updateCompany,
    deleteCompany,
    listCompanyBannerIndex,
    updateBannerIndex
}
