import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Grid, List } from '@mui/material';
import _ from 'lodash';
import { useContext, useMemo, useState, forwardRef, useImperativeHandle } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Logo } from '../assets/eShopLogo-small.svg';
import { ReactComponent as LogoutIcon } from '../assets/Logout.svg';
import { userContext } from "../context/UserProvider";
import Typography from './share/Typography';
import constants from '../constants'
import { styled as styledMui } from '@mui/material/styles';
import { isDev } from '../utils';

const Container = styledMui('div')(
    ({ theme }) => `
    width: 250px;
    display: flex;
    flex-direction: column;
    background-color: #cdcdcd;
    height: 100%;
  `,
);

const LogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 75px;

    background-color: #fff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

const Footer = styled.div`
    height: 60px;
    background-color: #15012E;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

const StyledList = styled(List)`
    && {
        width: 100%; 
        height: 100%;
        overflow: auto;
    }
`

const StyledListItem = styled.div`
    flex-direction: row;

    display: flex;
    align-items: center;
    height: 60px;
    ${props => props.isChild && `
        height: 40px;
        background-color: #d9d9d9;
        padding-left: 20px;
    `}
`

const SelectedBar = styled.div`
    background: #333;
    border-radius: 105px;
    height: 48px;
    width: 5px;
    ${props => !props.selected && `opacity: 0;`}
`

const StyledLink = styled(Link)`
    && {
        text-decoration-line: none; 
        display: flex; 
        flex-direction: row; 
        align-items: center;
        cursor: pointer;
    }
`

export const ProductCategoryOptions = [
    {
        name: "Apple",
        id: 'apple',
        _id: "apple",
        query: "?type=apple"
    },
    {
        name: "Android Corner",
        id: 'android',
        _id: "android",
        query: "?type=android"
    },
    {
        name: "Accessories & Gaming (Abondoned)",
        id: 'accessoriesGaming',
        _id: "accessoriesGaming",
        query: "?type=accessoriesGaming",
        disabled: true,
    },
    {
        name: "HomeLifeStyle",
        id: 'homeLifestyle',
        _id: "homeLifestyle",
        query: "?type=homeLifestyle"
    },
]

export const drawerItems = [
    {
        name: "Admin Management",
        path: "/admin",
        _id: ["adminUser", "adminAccountCreation"]
    },
    {
        name: "Mobile",
        path: "/mobile",
        query: "?tab=mobile-service-plans"
    },
    {
        name: "Product",
        path: "/product",
        child: ProductCategoryOptions,
    },
    {
        name: "Stock Management",
        path: "/stock",
        query: "?tab=all",
        _id: "stock"
    },
    // {
    //     name: "Text Configuration",
    //     path: "/text-configuration",
    // },
    {
        name: "Company Information",
        path: "/company",
        id: "company",
        _id: "company",
    },
    // {
    //     name: "User Management",
    //     path: "/user"
    // },
    // {
    //     name: "Campaign",
    //     path: "/campaign"
    // },
    // {
    //     name: "Bundle",
    //     path: "/bundle"
    // },
    {
        name: "Promotion Code",
        path: "/promotion-code"
    },
    {
        name: "Public Banner",
        path: "/public-banner",
        _id: "banner",
        query: "?tab=desktop"
    },
    {
        name: "VAS",
        path: "/vas",
        _id: "vas",
    },
    {
        name: "T&C",
        path: "/terms-and-conditions",
        _id: "tnc"
    },
    {
        name: "Order",
        path: "/order",
        child: [
            {
                name: "All Orders",
                id: 'orders',
                _id: "order",
                query: "?type=orders"
            },
            {
                name: "All Order Items",
                id: 'orderItems',
                _id: "orderItem",
                query: "?type=orderItems",
            }
        ]
    },
    {
        name: "Media",
        path: "/media",
        query: "?tab=all"
    },
    {
        name: "Shopping Tutorial",
        path: "/shopping-tutorial",
    },
    {
        name: "Global Settings",
        path: "/globel-settings",
        _id: "globalSettings"
    },
];


const ListItem = ({ to, selected, children, isChild, EndIcon, onToggle, onClick }) => {
    return (
        <StyledListItem isChild={isChild} >
            <StyledLink to={to} onClick={onClick}>
                {!isChild && <SelectedBar selected={selected} />}
                <Typography
                    fontSize={16}
                    sx={{
                        color: selected ? 'info.dark' : "#717171",
                        ml: 2
                    }}
                    bold={selected}
                >
                    {children}
                </Typography>
            </StyledLink>
            {
                EndIcon &&
                <EndIcon
                    sx={{ marginLeft: 2, color: selected ? 'info.main' : "#717171", cursor: 'pointer' }}
                    onClick={onToggle}
                />
            }
        </StyledListItem>
    )
}

const Drawer = forwardRef(({ onLogout }, ref) => {

    const [isCollapsed, setIsCollapsed] = useState('')
    const [user, setUser] = useContext(userContext);

    let location = useLocation();

    const getDrawerItems = useMemo(() => {
        if (!user?.permissions) return []
        const permissionKeys = Object.keys(user?.permissions)
        let items = [];
        _.forEach(drawerItems, (item) => {
            if (item.name === "Product") {
                if (!permissionKeys.includes('product') || user?.permissions?.product.length > 0) {
                    items.push(item)
                }
            } else if (item.name === "Order") {
                let child = _.filter(item.child, (o) => !permissionKeys.includes(o._id) || user.permissions[o._id].length > 0)
                if (child.length > 0) {
                    items.push({ ...item, child })
                }
            } else if (item.name === "Admin Management") {
                const hasAdminUserPermission = !permissionKeys.includes(item._id[0]) || user.permissions[item._id[0]].length > 0
                const hasAdminCreationPermission = !permissionKeys.includes(item._id[1]) || user.permissions[item._id[1]].length > 0
                if (hasAdminUserPermission || hasAdminCreationPermission) {
                    items.push(item)
                }
            } else if (!permissionKeys.includes(item._id) || user.permissions[item._id].length > 0) {
                items.push(item)
            }
        })
        return items
    }, [user && user.permissions])


    const handleToggle = (pathId) => {
        const isSame = pathId === isCollapsed
        setIsCollapsed(isSame ? '' : pathId)
    };

    useImperativeHandle(ref, () => ({
        getDrawerItems: getDrawerItems
    }));

    const fullPath = `${location.pathname}${location.search}`

    return (
        <Container>
            {isDev() &&
                <div style={{ height: 50, width: 150, backgroundColor: 'red', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    ***IS DEV***
                </div>}
            <LogoWrapper>
                <Logo style={{ margin: "0 10px" }} />
            </LogoWrapper>
            <StyledList>
                {
                    getDrawerItems.map((item, index) => {
                        const selected = location.pathname.includes(item.path);
                        if (item.child) {
                            return (
                                <Grid container direction='column' key={item.name}>
                                    <ListItem
                                        to={`${item.path}${item?.child?.[0]?.query || ""}`}
                                        selected={selected}
                                        EndIcon={isCollapsed ? KeyboardArrowDownIcon : KeyboardArrowUpIcon}
                                        onToggle={() => handleToggle(item.path)}
                                        onClick={() => {
                                            // console.log("hello")
                                            // setIsCollapsed(false)
                                            if (item.path === "/product" || item.path === "/order") {
                                                setIsCollapsed(item.path)
                                            } else {
                                                setIsCollapsed(false)
                                            }
                                        }}
                                    >
                                        {item.name}
                                    </ListItem>
                                    {isCollapsed === item.path &&
                                        <>
                                            {
                                                item.child.map((childItem) => {
                                                    const childSelected = fullPath.includes(item.path+childItem.query);
                                                    return (
                                                        <ListItem
                                                            to={`${item.path}${childItem.query}`}
                                                            selected={childSelected}
                                                            isChild
                                                        >
                                                            {childItem.name}
                                                        </ListItem>
                                                    )
                                                })
                                            }
                                        </>
                                    }
                                </Grid>
                            )
                        }
                        return (
                            <ListItem
                                to={`${item.path}${item?.query || ""}`}
                                selected={selected}
                            >
                                {item.name}
                            </ListItem>
                        )
                    })
                }
            </StyledList>
            <Footer onClick={onLogout}>
                <LogoutIcon />
                <Typography color="#fff" style={{ marginLeft: 4 }}>Logout</Typography>
            </Footer>
        </Container>
    )
})

export default Drawer
