import { useState, forwardRef, useImperativeHandle } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from "../share";
import EncryptedPDFViewer from "../share/EncryptedPDFViewer";
import ImageViewer from "../share/ImageViewer";
import useContextMenu from "../../context/ContextMenuProvider";

const ViewUploadFileDialog = forwardRef((props, ref) => {

    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);

    const { toggleMenu } = useContextMenu();

    const createBlobURL = ({data, contentType}) => {
        const blob = new Blob([data], { type: contentType });
        const url = URL.createObjectURL(blob);
        return url;
    }

    const handleOpen = ({data, fileType}) => {
        setOpen(true);
        if (data) {
            const contentType = fileType === "pdf" ? 'application/pdf' : `image/${fileType}`;
            const value = { data: createBlobURL({data, contentType}), contentType }
            setData(value);
            toggleMenu(false);
        }
    }

    const handleClose = () => {
        setOpen(false);
        setData(null);
        toggleMenu(true);
    }

    useImperativeHandle(ref, () => ({
        open: handleOpen,
        close: handleClose,
    }));

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="lg"
            fullWidth
            PaperProps={{
                sx: {
                    minHeight: '80%',
                }
            }}
        >
            <DialogTitle style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography fontSize={18} bold>Preview File</Typography>
                <CloseIcon fontSize={"large"} onClick={handleClose} style={{ cursor: "pointer" }} />
            </DialogTitle>
            <DialogContent style={{ display: "flex" }}>
                {
                    data && data?.contentType === 'application/pdf'
                        ? <EncryptedPDFViewer data={data?.data} /> 
                            : <ImageViewer data={data?.data} />
                }
            </DialogContent>
        </Dialog>
    )
})

export default ViewUploadFileDialog;