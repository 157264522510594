import { useState, useContext, useCallback, useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
    GridActionsCellItem,
} from '@mui/x-data-grid';
import { Grid, Divider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import { Routes, Route, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash'
import Typography from '../../components/share/Typography';
import Button from '../../components/share/Button';
import PageWrapper from '../../components/share/PageWrapper';
import { listMedia, updateMedia } from '../../apis/medias';
import UploadImgItem from '../../components/media/UploadMediaItem';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { fetchMedia } from '../../apis/medias';
import dayjs from 'dayjs';
import constants from '../../constants';
import UploadMediaForm from '../../components/media/UploadMediaForm';
import { snackbarContext } from '../../context/SnackbarProvider';

const AddButton = styled(Typography)`
    && {
        text-decoration-line: underline;
        cursor: pointer;
        padding-left: 30px;
        padding-bottom: 20px;
    }
`

const SaveButton = styled(Button)`
    && {
        align-self: end; 
        width: 80px;
        margin: 10px 20px 14px 20px;
    }
`



function MediaDetailPage() {
    const navigate = useNavigate();
    let { mediaId } = useParams();
    const formRef = useRef();
    const [snackbar, setSnackbar] = useContext(snackbarContext);

    const { data, error, isError, isLoading } = useQuery({
        queryKey: ["mediaDetail", { mediaId }],
        queryFn: async() => await fetchMedia(mediaId)
    });

    const queryClient = useQueryClient();

    const updateMutation = useMutation({
        mutationFn: (data) => updateMedia(mediaId, data),
        onSuccess: (data) => {
            setSnackbar({
                type: 'success',
                message: 'Media updated successfully'
            })
            queryClient.invalidateQueries('mediaDetail')
        },
        onError: (err) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const handleOnSubmit = (formValue) => {
        console.log("formValue", formValue)
        const payload = {
            newImageKey: formValue.images[0].image.fileKey,
            title: formValue.images[0].title,
            description: formValue.images[0].description,
            category: formValue.images[0].category,
        }
        updateMutation.mutate(payload)
    }

    const Header = () => {
        return (
            <Typography bold style={{ flex: 1 }}>{`Media > ${mediaId}`}</Typography>
        )
    }

    const CardHeader = () => {
        return (
            <SaveButton onClick={() => formRef.current.submitForm(handleOnSubmit)()}>Save</SaveButton>
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            renderCardHeader={CardHeader}
            loading={isLoading}
        >
            <UploadMediaForm
                ref={formRef}
                type={"edit"}
                data={data}
            />

        </PageWrapper>
    )
}

export default MediaDetailPage