import { Grid } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    PageWrapper,
    Searchbar,
    Table,
    Typography,
    TableStatusFilter,
    FilterButton,
    Checkbox
} from '../../components/share';
import { useMutation } from '@tanstack/react-query';
import { alertDialogContext } from '../../context/AlertDialogProvider';
import { snackbarContext } from '../../context/SnackbarProvider';
import { userContext } from '../../context/UserProvider';
import { exportCSV, getPermission } from '../../utils'
import { STATUS } from './index'
import dayjs from 'dayjs';
import TableDateFilter from '../../components/share/TableDateFilter';
import { fetchRSPReport } from '../../apis/report';

const margin = 10

function OrderListPage({ columns, orderPermission = { canView: false, canModify: false }, reportPermission = { canView: false, canModify: false } }) {
    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [dialog, setDialog] = useContext(alertDialogContext);
    // const [selectMode, setSelectMode] = useState(false);

    const { canView: canViewOrders, canModify: canModifyOrders } = orderPermission;
    const { canView: canViewReport, canModify: canModifyReport } = reportPermission;

    const [filter, setFilter] = useState({
        keyword: "",
        // status: null
    });

    const onApiError = (error) => {
        setSnackbar({
            type: 'error',
            message: `${error?.response?.data?.result?.errorMessage?.en || error?.message}`
        })
    }

    const rspReportMutation = useMutation({
        mutationFn: (payload) => fetchRSPReport(payload),
        onSuccess: (data) => {
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
            // TODO: confirm download file name
            exportCSV(data, `RSP_Report_${dayjs().format('DD-MM-YYYY')}`);
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const handleOnClickExport = async () => {
        // console.log('==onClick Export RSP Report with filter', filter);

        const payload = Object.keys(filter).reduce((acc, key) => {
            if (filter?.[key] && filter?.[key] !== "") { acc[key] = filter[key] }
            return acc
        }, {});

        // If no start date or end date, return error
        if (!payload?.startDateTime || !payload?.endDateTime) {
            return onApiError({ message: `INVALID_INPUT - Start Date and End Date are required` })
        }

        // Remove keyword key from payload
        if (payload?.keyword) { delete payload.keyword }

        rspReportMutation.mutate(payload);
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Orders`}</Typography>
                {/* {
                    canView &&
                    <FilterButton
                        style={{ marginRight: margin }}
                        filters={[
                            {
                                type: 'date-range',
                                startDataKey: 'startDate',
                                endDataKey: 'endDate',
                            },
                            {
                                type: 'select',
                                dataKey: 'status',
                                options: STATUS
                            }
                        ]}
                        disabled={!canView}
                        value={filter}
                        onChange={(newFilterValue) => setFilter({ ...filter, ...newFilterValue })}
                    />
                } */}
                { canViewReport && <Button color="orange" style={{ marginRight: margin }} onClick={handleOnClickExport}>Export</Button> }
                {/* <Button color="green" style={{ marginRight: margin }} onClick={handleOnOpenFilter}>Filter</Button> */}
            </>
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderCardHeader={() => (<div />)}
        >
            <Grid container direction='row' alignItems='center' style={{ paddingRight: 10 }}>
                <TableDateFilter type="date-range" filter={filter} setFilter={setFilter} fromId={"startDateTime"} toId={"endDateTime"} />
                <TableStatusFilter width={200} options={STATUS} value={filter.status} disabled={!canViewOrders} onChange={(newValue) => setFilter({ ...filter, status: newValue })} />
                <Searchbar value={filter.keyword} onChange={(e) => setFilter({ ...filter, keyword: e.target.value })} />
                {/* <Checkbox label="Select" fullWidth={false} typographyProps={{ color: "primary" }} checked={selectMode} onChange={(event) => setSelectMode(event.target.checked)} /> */}
            </Grid>
            <Table
                style={{ margin: `0px 20px 20px 20px` }}
                columns={columns}
                api={"/order"}
                apiParams={{
                    ...filter
                }}
                sortingMode="server"
                onError={onApiError}
                setSnackbar={setSnackbar}
                // onRowClick={(params) => {
                //     navigate(`/terms-and-conditions/${params.id}`)
                // }}
                // checkboxSelection={selectMode}
                // onSelectionModelChange={(item) => {
                //     console.log('==onSelectionModelChange', item);
                // }}
            />
        </PageWrapper>
    )
}

export default OrderListPage