import { useState, useContext, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import {
    useQuery,
    useMutation,
    useQueryClient
} from '@tanstack/react-query'
import _ from 'lodash'
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import PageWrapper from '../../components/share/PageWrapper';
import Typography from '../../components/share/Typography';
import Button from '../../components/share/Button';
import Select from '../../components/share/Select';
import GreyDivider from '../../components/GreyDivider';
import TextField from '../../components/share/TextField';
import Form from '../../components/share/form/Form';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import { userContext } from '../../context/UserProvider';
import { updateCompany, fetchCompany, updateBannerIndex, listCompanyBannerIndex } from '../../apis/company';
// import { listBanner } from '../../apis/banner';
import { Routes, Route, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Fields } from './formFields'
import Tab from '../../components/share/Tab';
import BannerSortableList from '../../components/company/BannerSortableList';
import { getPermission } from '../../utils'
import { schema, vipSchema } from './add'
import constants from '../../constants'

const TABS = [
    {
        name: 'Information',
        id: "info"
    },
    {
        name: 'Banner',
        id: "banner"
    }
]

const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
    height: 100%;
    overflow: auto;
`


const getResetInfo = ({ data, bannerData }) => {
    if (data?.companyType === 'vip') {
        return {
            name: data?.name,
            emailDomains: data?.emailDomains ? _.map(data?.emailDomains, (item) => ({ value: item })) : [],
            // urlPath: data?.urlPath,
            banner: bannerData || [],
            notificationEmails: data?.notificationEmails && data?.notificationEmails?.length ? _.map(data?.notificationEmails, (item) => ({ value: item })) : [{ value: "" }],
            // image: {
            //     fileKey: data?.image,
            //     previewUrl: data?.imageUrl
            // }
        }
    } else {
        return {
            name: data?.name,
            emailDomains: data?.emailDomains ? _.map(data?.emailDomains, (item) => ({ value: item })) : [],
            banner: bannerData || [],
            // notificationEmails: data?.notificationEmails && data?.notificationEmails?.length ? _.map(data?.notificationEmails, (item) => ({ value: item })) : [{ value: "" }],
        }
    }

}

function CompanyDetailPage(props) {

    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [user, setUser] = useContext(userContext);

    const { canView, canModify } = getPermission(user?.permissions?.company)
    let { companyId } = useParams();

    const [tabIndex, setTabIndex] = useState(0)

    const { data, error, isError, isLoading } = useQuery({
        queryKey: ["companyDetail", { companyId }],
        queryFn: async () => await fetchCompany(companyId),
    });

    const { data: bannerData, error: bannerError, isError: isBannerError, isLoading: isBannerLoading } = useQuery({
        queryKey: ["listBanner"],
        queryFn: async () => await listCompanyBannerIndex(companyId)
    });

    const companyType = data?.companyType

    const { control, handleSubmit, watch, formState: { errors, dirtyFields }, reset, setValue, getValues } = useForm({
        resolver: tabIndex === 0 ? joiResolver(companyType === 'vip' ? vipSchema : schema) : null,
    });

    useEffect(() => {
        if (data && bannerData) {
            reset(getResetInfo({ data, bannerData }))
        }
    }, [data, bannerData, companyId])

    useEffect(() => {
        if ((isError && error) || (isBannerError && bannerError)) {
            const tmpErr = error || bannerError
            setSnackbar({
                type: 'error',
                message: `${tmpErr.code} ${tmpErr.err ? `- ${tmpErr.err}` : ""}`
            })
        }
    }, [error, isError, bannerError, isBannerError])

    useEffect(() => {
        if (!_.isEmpty(errors)) {
            setSnackbar({
                type: "error",
                message: constants.INVALID_INPUT_ERR_MSG
            })
        }
    }, [errors])

    const onChangeTab = (tabIndex) => {
        setTabIndex(tabIndex)
    }

    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (payload) => updateCompany(companyId, payload),
        onSuccess: (data) => {
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
            queryClient.invalidateQueries({ queryKey: ['allCompany'] })
            queryClient.invalidateQueries({ queryKey: ['vipCompany'] })
            // reset(getResetInfo({ data, bannerData: getValues("banner") }))
            queryClient.invalidateQueries({ queryKey: ["companyDetail", { companyId }] })
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const bannerIndexMutation = useMutation({
        mutationFn: (payload) => updateBannerIndex(companyId, payload),
        onSuccess: (data) => {
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
            queryClient.invalidateQueries({ queryKey: ["listBanner"] })
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const handleOnSubmit = (formValue) => {
        if (_.isEmpty(dirtyFields)) {
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
            return
        }
        if (tabIndex === 0) {
            let payload = {}
            _.forEach(dirtyFields, (item, key) => {
                if (key === "emailDomains" || key === "notificationEmails") {
                    payload[key] = _.map(formValue[key], (item) => item.value).filter((item) => item && item !== "")
                } else if (key === "image") {
                    payload[key] = formValue[key].fileKey
                } else {
                    payload[key] = formValue[key]
                }
            })
            if (payload.banner) delete payload.banner
            mutation.mutate(payload)
        } else if (tabIndex === 1) {
            let payload = [...formValue.banner]
            payload = _.map(payload, (item, index) => ({
                banner: item.banner._id,
                index
            }))
            console.log("payload", payload)
            bannerIndexMutation.mutate(payload)
        }
    }

    console.log("errors", errors)
    // console.log("getValues2", getValues())

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Company Information > ${data?.name || ""}`}</Typography>
            </>
        )
    }

    const CardHeader = () => {
        return (
            <Tab
                tabs={TABS}
                selected={tabIndex}
                onChange={onChangeTab}
                renderButton={() => {
                    return (
                        <Button
                            style={{
                                alignSelf: 'flex-end',
                                margin: `0px 20px 14px 20px`,
                            }}
                            disabled={!canModify || isLoading || isError || isBannerLoading || isBannerError}
                            onClick={() => handleSubmit(handleOnSubmit)()}
                        >
                            Save
                        </Button>
                    )
                }}
            />
        )
    }

    return (
        <PageWrapper
            loading={mutation.isLoading || isLoading || bannerIndexMutation.isLoading || isBannerLoading}
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            renderCustomCardHeader={CardHeader}
        >
            <Content>
                {
                    tabIndex === 0 ?
                        <Form fields={Fields({ control, errors, companyType, readOnly: !canModify })} p={`14px 0px`} /> :
                        <BannerSortableList
                            // data={bannerData}
                            control={control}
                        />
                }
            </Content>
        </PageWrapper>
    )
}

export default CompanyDetailPage