import { useState, useEffect, createContext } from "react";
import { listTnc } from '../apis/termsAndConditions'
import {
    useQuery,
} from '@tanstack/react-query'

export const tncContext = createContext();
function TncProvider({ children }){
    const [tncList, setTncList] = useState(null);
    const token = localStorage.getItem('access_token');

    const { data, isLoading } = useQuery({
        queryKey: ["allTnc"],
        queryFn: async () => await listTnc({ limit: 1000 }),
        enabled: token ? true : false,
        // retry: false,
        onSuccess: (data) => {
            setTncList(data)   
        }
    });

    return (
        <tncContext.Provider value={[tncList, setTncList]}>
            {children}
        </tncContext.Provider>
    )
}

export default TncProvider