import { Controller } from "react-hook-form";
import TextField from '../../components/share/TextField';
import HtmlEditor from '../../components/share/HtmlEditor'

const FIELDS = ({ control, errors = {}, readOnly }) => [
    {
        id: "code",
        component: (
            <Controller
                name="code"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="T&C Code/ID*"
                        direction="vertical"
                        fullWidth
                        error={errors.code}
                        helperText={errors?.code?.message}
                        readOnly={readOnly}
                    />
                )}
            />
        )
    }, { id: 'spacing' },
    {
        id: "title_en",
        component: (
            <Controller
                name="title.en"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="T&C Title (English)*"
                        direction="vertical"
                        fullWidth
                        error={errors.title?.en}
                        helperText={errors?.title?.en?.message}
                        readOnly={readOnly}
                    />
                )}
            />
        )
    }, { id: 'spacing' },
    {
        id: "short_description_en",
        component: (
            <Controller
                name="shortDescription.en"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Short Description (English)*"
                        direction="vertical"
                        multiline
                        fullWidth
                        error={errors.shortDescription?.en}
                        helperText={errors?.shortDescription?.en?.message}
                        readOnly={readOnly}
                    />
                )}
            />
        )
    }, { id: 'spacing' },
    {
        id: "desc_en",
        xs: 2,
        component: (
            <Controller
                name="description.en"
                control={control}
                render={({ field }) => (
                    <HtmlEditor
                        title="T&C Description (English)*"
                        value={field.value}
                        onChange={field.onChange}
                        height={300}
                        error={errors.description?.en}
                        helperText={errors?.description?.en?.message}
                        readOnly={readOnly}
                    />
                )}
            />
        )
    },
    {
        id: "title_zh",
        component: (
            <Controller
                name="title.zh"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="T&C Title (Chinese)*"
                        direction="vertical"
                        fullWidth
                        error={errors.title?.zh}
                        helperText={errors?.title?.zh?.message}
                        readOnly={readOnly}
                    />
                )}
            />
        )
    }, { id: 'spacing' },
    {
        id: "short_description_zh",
        component: (
            <Controller
                name="shortDescription.zh"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Short Description (Chinese)*"
                        direction="vertical"
                        multiline
                        fullWidth
                        error={errors.shortDescription?.zh}
                        helperText={errors?.shortDescription?.zh?.message}
                        readOnly={readOnly}
                    />
                )}
            />
        )
    }, { id: 'spacing' },
    {
        id: "desc_en",
        xs: 2,
        component: (
            <Controller
                name="description.zh"
                control={control}
                render={({ field }) => (
                    <HtmlEditor
                        title="T&C Description (Chinese)*"
                        value={field.value}
                        onChange={field.onChange}
                        height={300}
                        error={errors.description?.zh}
                        helperText={errors?.description?.zh?.message}
                        readOnly={readOnly}
                    />
                )}
            />
        )
    },
]

export { FIELDS }