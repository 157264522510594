import React, { useCallback, useMemo, useState } from "react";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragOverlay,
    DragStartEvent,
    KeyboardSensor,
    PointerSensor,
    UniqueIdentifier,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";
import {
    arrayMove,
    horizontalListSortingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Image, { ImgContainer } from "./ImageV2";
import { CircularProgress, Grid } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import UploadImgBox from "./UploadImgBox";
import { SelectImageDialog, Typography } from "..";


const SortableItem = (props) => {
    const { item, imgSize, readOnly=false, onRemove, onClickImg, position, selected } = props;

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id, disabled: readOnly });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const isSelected = selected?.id === item.id;

    const url = item?.url || item?.previewUrl;

    return (
        <Grid item key={item.id} ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <Image
                height={imgSize}
                width={imgSize}
                url={url}
                readOnly={readOnly}
                imgStyle={{ border: `${isSelected ? 3 : 1}px solid ${isSelected ? '#607FF7' : '#9B9B9B'}`, borderRadius: 5 }}
                onClick={() => {
                    if (onClickImg) {
                        onClickImg(item)
                    } else {
                        window.open(url)
                    }
                }}
                onRemove={() => {
                    onRemove && onRemove({ position })
                }}
            />
        </Grid>
    );
}

const SortableImgGrid = (props) => {
    const { data, onSort, renderRow, selected, onImageUploaded, imgSize, spacing = 2, readOnly=false, onRemove, style, onClickImg } = props;

    const itemIds = useMemo(() => data.map((item) => item.id), [data]);
    const [activeId, setActiveId] = useState();

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: { distance: 5 },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    const handleDragStart = useCallback((event) => {
        if (readOnly || !onSort) return;
        setActiveId(event.active.id);
    }, [readOnly]);

    const handleDragEnd = useCallback((event) => {
        if (readOnly || !onSort) return;
        const { active, over } = event;
        if (over && active.id !== over.id) {
            const oldIndex = itemIds.indexOf(active.id);
            const newIndex = itemIds.indexOf(over.id);
            if (onSort) onSort(oldIndex, newIndex);
        }
        setActiveId(undefined);
    }, [itemIds, onSort, readOnly]);

    const handleDragCancel = () => {
        if (readOnly || !onSort) return;
        setActiveId(undefined);
    };

    return (
        <Grid container spacing={spacing} justifyContent={"flex-start"} style={style}>
            <DndContext
                sensors={sensors}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
                collisionDetection={closestCenter}
                modifiers={[restrictToFirstScrollableAncestor]}
            >
                <SortableContext
                    items={itemIds}
                    strategy={horizontalListSortingStrategy}
                >
                    {itemIds.map((id, i) => {
                        const item = data[i];

                        if (item.id === 'upload') return (
                            <Grid item key={item.id} style={{ marginBottom: 10 }}>
                                <SelectImageDialog
                                    renderComponent={({ openDialog }) => (
                                        <ImgContainer
                                            height={imgSize}
                                            width={imgSize}
                                            onClick={openDialog}
                                            style={{ flexDirection: 'column', flexWrap: 'nowrap', cursor: 'pointer' }}
                                        >
                                            <AddIcon/>
                                            <Typography fontSize={12}>Add Images</Typography>

                                        </ImgContainer>
                                    )}
                                    accept="image/*"
                                    onChange={(selectedImage) => {
                                        onImageUploaded && onImageUploaded(selectedImage)
                                    }}
                                />
                            </Grid>
                        )

                        return (
                            <SortableItem
                                key={i}
                                id={id}
                                item={item}
                                renderFunction={renderRow}
                                position={i}
                                imgSize={imgSize}
                                readOnly={readOnly}
                                onRemove={onRemove}
                                onClickImg={onClickImg}
                                selected={selected}
                            />
                        )
                    })}
                </SortableContext>

                {/* Drag overlay */}
                <DragOverlay adjustScale={false} dropAnimation={null}>
                    {activeId ? (
                        <SortableItem
                            id={activeId}
                            item={data[itemIds.indexOf(activeId)]}
                            renderFunction={renderRow}
                            isDragged
                            imgSize={imgSize}
                            readOnly={readOnly}
                            onRemove={onRemove}
                            onClickImg={onClickImg}
                        />
                    ) : (
                        <></>
                    )}
                </DragOverlay>
            </DndContext>
        </Grid>
    )
}

export default SortableImgGrid;