import { useState, useContext, useCallback, useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
    GridActionsCellItem,
} from '@mui/x-data-grid';
import { Grid, Divider } from '@mui/material';
import { Routes, Route, useParams, useNavigate, useSearchParams, createSearchParams, Link } from 'react-router-dom';
import _ from 'lodash'
import Typography from '../../components/share/Typography';
import Button from '../../components/share/Button';
import Table, { UnderlineText, DeleteActionIcon } from '../../components/share/Table';
import PageWrapper from '../../components/share/PageWrapper';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import AlertDialogProvider, { alertDialogContext } from '../../context/AlertDialogProvider';
import { userContext } from '../../context/UserProvider';
import { companiesContext } from '../../context/CompaniesProvider';
import Searchbar from '../../components/share/Search';
import withTabSearchParams from '../../hoc/withTabSearchParams'
import Tab from '../../components/share/Tab';
import constants from '../../constants';
import dayjs from 'dayjs';
import StatusButton from '../../components/share/StatusButtonV2'
import { deleteCompany } from '../../apis/company';
import {
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import { getPermission } from '../../utils'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditNoteIcon from '@mui/icons-material/EditNote';



const TABS = [
    {
        name: 'Generic',
        id: "generic",
        _id: "normal"
    },
    {
        name: 'VIP',
        id: "vip",
        _id: "vip"
    }
]

function CompanyPage({ updateTab, tabIndex }) {
    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [alert, setAlert] = useContext(alertDialogContext);
    const [user, setUser] = useContext(userContext);
    const [companies, setCompanies] = useContext(companiesContext)
    const [filter, setFilter] = useState({
        keyword: "",
    })

    const { canView, canModify } = getPermission(user?.permissions?.company)

    const onClickTab = (index) => {
        updateTab(TABS[index], index)
        setFilter({})
    }

    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (id) => deleteCompany(id),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['/company'] })
            queryClient.invalidateQueries({ queryKey: ['allCompany'] })
            queryClient.invalidateQueries({ queryKey: ['vipCompany'] })
        },
    })

    const columns = useMemo(() => {
        return [
            { field: 'name', headerName: 'Company Name', width: 180 },
            { field: 'emailDomains', headerName: 'Domain Name', flex: 1, sortable: false },
            { field: 'createdAt', headerName: 'Import Date', width: 120, sortable: false, valueGetter: (params) => dayjs(params.value).format(constants.DATE_FORMAT) },
            { field: 'updatedAt', headerName: 'Last Update Date', width: 140, sortable: false, valueGetter: (params) => dayjs(params.value).format(constants.DATE_FORMAT) },
            {
                field: 'status',
                headerName: 'Status',
                width: 130,
                sortable: false,
                renderCell: (params) => {
                    const status = params.isDeleted ? 'suspended' : 'active';
                    return (
                        <StatusButton 
                            value={status} 
                            readOnly
                            options={[
                                {
                                    name: "Active",
                                    id: "active",
                                    // id: "published",
                                    color: "#306097",
                                    canEdit: true,
                                    Icon: (<CheckCircleOutlineIcon />)
                                },
                                {
                                    name: "Suspended",
                                    id: "suspended",
                                    color: "#f6f6f6",
                                    textColor: "#9B9B9B",
                                    canSelect: true,
                                    Icon: (<RemoveCircleOutlineIcon />)
                                }
                            ]}
                        />
                    )
                }
            },
            {
                field: 'actions',
                headerName: 'Action',
                type: 'actions',
                width: 110,
                headerAlign: 'flex-start',
                getActions: (params) => [
                    <UnderlineText disabled={!canView} onClick={() => navigate(`/company/${params.row._id}`)}>View</UnderlineText>,
                    <DeleteActionIcon
                        disabled={!canModify}
                        onClick={
                            () => setAlert({
                                open: true,
                                title: "Are you sure to delete this company?",
                                handleOnClickOK: () => mutation.mutate(params.row._id)
                            })
                        } />,
                ],
            }
        ]
    }, [canModify]);

    const onApiError = (error) => {
        setSnackbar({
            type: 'error',
            message: `${error?.response?.data?.result?.errorMessage?.en || error?.message}`
        })
    }


    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Company Information`}</Typography>
            </>

        )
    }

    const CardHeader = () => {

        return (
            <Tab
                tabs={TABS}
                selected={tabIndex}
                onChange={onClickTab}
                renderButton={() => {
                    return (
                        <Button
                            style={{
                                alignSelf: 'flex-end',
                                margin: `0px 20px 14px 20px`,
                            }}
                            disabled={!canModify}
                            type="add"
                            onClick={() => navigate(`/company/${TABS?.[tabIndex]?.id || TABS[0].id}/add`)}
                        >
                            Add New
                        </Button>
                    )
                }}
            />
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderCustomCardHeader={CardHeader}
        >
            <Grid container direction='row' alignItems='center' style={{ paddingRight: 10 }}>
                <Searchbar value={filter.keyword} onChange={(e) => setFilter({ ...filter, keyword: e.target.value })} />
            </Grid>
            <Table
                style={{ margin: `0px 20px 20px 20px` }}
                color="grey"
                columns={columns}
                api={"/company"}
                apiParams={{
                    companyType: TABS[tabIndex]._id,
                    keyword: filter.keyword
                }}
                sortingMode="server"
                onError={onApiError}
                setSnackbar={setSnackbar}
            // onRowClick={(params) => {
            //     navigate(`/company/${params.id}`)
            // }}
            />
        </PageWrapper>
    )
}

export default withTabSearchParams(CompanyPage, TABS)