import { useEffect } from 'react';
import { Image, Typography, TextField, Select, Button, ComponentContainer, UploadImgBox } from '../share';
import { Divider, Grid } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import styled from 'styled-components'
import _, { isEmpty } from 'lodash';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';

const FormContainer = styled(Grid)`
    && {
        > * {
            margin-bottom: 20px;
        }
    }
`

const CategoryList = [
    // {
    //     name: 'All',
    //     _id: 'all'
    // },
    {
        name: 'Banner',
        _id: 'banner',
    },
    {
        name: 'Others',
        _id: 'others'
    }
]

const UploadMediaItem = (props) => {
    const { control, isEdit=false, onRemove, position, errors } = props;

    return (
        <Grid container direction='column' gap={3}>
            <Grid item container direction='row'>
                <Grid item xs container flexDirection={"column"} gap={3}>
                    { !isEdit && (<Typography variant="header" >{`Image ${position+1}`}</Typography>) }
                    <Typography variant="header3">PNG, JPG and GIF files are allowed</Typography>
                </Grid>
                {position > 0 && 
                <Grid item>
                    <DeleteIcon style={{ cursor: 'pointer' }} onClick={onRemove} />
                </Grid>}
            </Grid>
            
            <Grid item xs container direction='row' gap={3}>
                <Grid item xs>
                    <Controller
                        name={`images.${position}.image`}
                        control={control}
                        render={({ field }) => {
                            const imgSize = 300;

                            if (!field.value || isEmpty(field.value.fileKey)) return (
                                <ComponentContainer
                                    style={{ justifyContent: "start" }}
                                    error={errors?.images?.[position]?.image?.fileKey}
                                    helperText={errors?.images?.[position]?.image?.fileKey?.message}
                                >
                                    <UploadImgBox
                                        imgSize={imgSize}
                                        onImageUploaded={(img) => {
                                            field.onChange({
                                                fileKey: img.fileKey,
                                                previewUrl: img.previewUrl
                                            })
                                        }}
                                    />
                                </ComponentContainer>
                            )
                            
                            return (
                                <Image
                                    height={imgSize}
                                    width={imgSize}
                                    url={field.value?.previewUrl}
                                    onRemove={() => {
                                        field.onChange(null)
                                    }}
                                />
                            )
                        }}
                    />
                </Grid>
                <FormContainer item xs container direction='column'>
                    <Grid item>
                        <Controller
                            name={`images.${position}.title`}
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    title="Title*"
                                    direction='vertical'
                                    fullWidth
                                    error={errors?.images?.[position]?.title}
                                    helperText={errors?.images?.[position]?.title?.message}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item>
                        <Controller
                            name={`images.${position}.description`}
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    title="Description*"
                                    direction='vertical'
                                    multiline
                                    fullWidth
                                    error={errors?.images?.[position]?.description}
                                    helperText={errors?.images?.[position]?.description?.message}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                    { isEdit && (
                        <Grid item>
                            <Controller
                                name={`images.${position}.image.previewUrl`}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        title="URL"
                                        direction='vertical'
                                        fullWidth
                                        disabled
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    )}
                    <Grid item>
                        <Controller
                            name={`images.${position}.category`}
                            control={control}
                            render={({ field }) => (
                                <Select
                                    title="Category*"
                                    direction='vertical'
                                    fullWidth
                                    options={CategoryList}
                                    {...field}
                                    value={field.value || null}
                                    onChange={(value) => {
                                        field.onChange && field.onChange(value)
                                    }}
                                    error={errors?.images?.[position]?.category}
                                    helperText={errors?.images?.[position]?.category?.message}
                                />
                            )}
                        />
                    </Grid>
                    { isEdit && (
                        <>
                        <Divider />
                        <Grid item>
                            <Controller
                                name={`images.${position}.imageType`}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        title="File Type"
                                        direction='vertical'
                                        fullWidth
                                        readOnly
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <Controller
                                name={`images.${position}.uploadAt`}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        title="Upload Date"
                                        direction='vertical'
                                        fullWidth
                                        readOnly
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        </>
                    )}
                </FormContainer>
            </Grid>
            { !isEdit && (<Divider />) }
        </Grid>
    )
}

export default UploadMediaItem;