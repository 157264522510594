import { useState, useContext, useCallback, useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
    useQueryClient,
} from '@tanstack/react-query'
import { Grid } from '@mui/material';
import { Routes, Route, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash'
import { snackbarContext } from '../../context/SnackbarProvider';
import { userContext } from '../../context/UserProvider';
import { alertDialogContext } from '../../context/AlertDialogProvider';
import constants from '../../constants'
import {
    Searchbar,
    UploadComponent,
    PageWrapper,
    Tab,
    Button,
    Typography
} from '../../components/share';
import Table, { UnderlineText } from '../../components/share/Table';
import withTabSearchParams from '../../hoc/withTabSearchParams';
import { getPermission } from '../../utils'

const margin = 10

export const TABS = [
    {
        name: "All",
        id: undefined
    },
    {
        name: "Apple",
        id: 'apple'
    },
    {
        name: "Android",
        id: 'android'
    },
    {
        name: "Accessories & Gaming",
        id: 'accessoriesGaming'
    },
    {
        name: "Home & lifestyle",
        id: 'homeLifestyle'
    },
]

function StockPage({ updateTab, tabIndex }) {
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [user, setUser] = useContext(userContext);
    const [dialog, setDialog] = useContext(alertDialogContext);
    const [filter, setFilter] = useState({
        keyword: "",
    })

    const queryClient = useQueryClient();

    const { canView, canModify } = getPermission(user?.permissions?.stock)

    const columns = useMemo(() => {
        return [
            { field: 'internalCode', headerName: 'Internal Item Code', width: 160, sortable: false, },
            { field: 'productDescription', headerName: 'Product Description', flex: 1, sortable: false, },
            { field: 'totalQuantity', headerName: 'Total Quantity', width: 120, sortable: false, },
            { field: 'inStockQuantity', headerName: 'Available Qty', width: 120, sortable: false, },
            { field: 'holdingQuantity', headerName: 'On-Hold Qty', width: 120, sortable: false, },
            { field: 'soldQuantity', headerName: 'Sales Qty', width: 120, sortable: false, },
            {
                field: 'actions',
                headerName: 'Action',
                type: 'actions',
                width: 80,
                headerAlign: 'center',
                getActions: (params) => {
                    return [
                        <UnderlineText
                            disabled={!canView}
                            onClick={() => navigate(`/stock/${params.id}`)}
                        >
                            Edit
                        </UnderlineText>,
                    ]
                }
            }
        ]
    }, []);

    const onApiError = (error) => {
        setSnackbar({
            type: 'error',
            message: `${error?.response?.data?.result?.errorMessage?.en || error?.message}`
        })
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Stock Management > ${TABS[tabIndex]?.name}`}</Typography>
                {/* <UploadComponent
                    renderComponent={({ selectFile }) => (
                        <Button
                        color="blue"
                            style={{ marginRight: margin }}
                            onClick={selectFile}
                        >
                            Import
                        </Button>
                    )}
                /> */}
                {/* <Button color="green" style={{ marginRight: margin }}>Filter</Button> */}
            </>

        )
    }

    const onClickTab = (index) => {
        updateTab(TABS[index], index);
        setFilter({})
    };

    const CardHeader = () => {
        return (
            <Tab
                tabs={TABS}
                selected={tabIndex}
                onChange={onClickTab}
                renderButton={() => {
                    return (
                        <Button
                            style={{
                                alignSelf: 'flex-end',
                                margin: `0px 20px 14px 20px`,
                            }}
                            type="add"
                            onClick={() => navigate(tabIndex ? `/stock/add?type=${TABS[tabIndex]?.id}` : `/stock/add`)}
                            disabled={!canModify}
                        >
                            Add New
                        </Button>
                    )
                }}
            />
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderCustomCardHeader={CardHeader}
        >
            <Grid container direction='row' alignItems='center' style={{ paddingRight: 10 }}>
                <Searchbar value={filter.keyword} onChange={(e) => setFilter({ ...filter, keyword: e.target.value })} />
            </Grid>
            <Table
                style={{ margin: `0px 20px 20px 20px` }}
                columns={columns}
                api={"/stock"}
                apiParams={{
                    category: TABS[tabIndex]?.id,
                    ...filter
                }}
                sortingMode="server"
                onError={onApiError}
                setSnackbar={setSnackbar}
            // onRowClick={(params) => {
            //     navigate(`/stock/${params.id}`)
            // }}
            />
        </PageWrapper>
    )
}
export default withTabSearchParams(StockPage, TABS)