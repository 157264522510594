import { useRef, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import TextField from '../TextField';
import PropTypes from 'prop-types';
import Typography from '../Typography'
import { InputAdornment, Grid, Box } from "@mui/material";
import ComponentContainer from '../ComponentContainer';
// import { ReactComponent as UploadIcon } from '../assets/upload-background.svg';
import { ReactComponent as RemoveIcon } from '../../../assets/delete-icon.svg';
import _ from 'lodash';
import Button from '../Button'
import HideImageIcon from '@mui/icons-material/HideImage';
import CircularProgress from '@mui/material/CircularProgress'

//selected img
//no selected img
//dragged ?

export const ImgContainer = styled.div`
    height: ${props => props.height}px;
    width: ${props => props.width}px;
    background: #F4F4F4;
    border: 2px dashed #9B9B9B;
    color: #9B9B9B;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${props => props.showPointerCursor && `cursor: pointer;`}
    position: relative;
`
const Img = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
`

function Image({ readOnly, height = 120, width = 120, url, Icon, onClick, onChange, imgStyle, onRemove, dragable, hideRemove, disableClick, isLoading, ...props }) {

    const [selectedImg, setSelectedImg] = useState({});

    useEffect(() => {
        if (url) {
            setSelectedImg({
                preview: url
            })
        }
    }, [url])

    const handleClickImg = (event) => {
        event.stopPropagation()
        if (onClick) onClick(selectedImg);
    }

    const handleRemoveImg = (event) => {
        event.stopPropagation()
        if (onRemove) {
            onRemove()
            // setSelectedImg({})
        } else {
            setSelectedImg({})
            onChange && onChange(null);
        }
    }

    const renderContent = () => {
        if (isLoading) {
            return (<CircularProgress />)
        } else if (readOnly && !url) {
            return (
                <HideImageIcon />
            )
        } else {
            if (Icon && !selectedImg?.preview) {
                return <Icon style={{ margin: 6, cursor: onClick ? 'pointer' : 'default' }} onClick={handleClickImg} />
            } else {
                return (
                    <>
                        <Img
                            src={selectedImg?.preview}
                            onLoad={() => { URL.revokeObjectURL(selectedImg.preview) }}
                            onClick={() => {
                                if (!selectedImg.preview || disableClick) return
                                if (onClick) onClick(selectedImg);
                            }}
                        />
                        {!hideRemove && !readOnly &&
                            <RemoveIcon style={{ position: 'absolute', top: 10, right: 10, zIndex: 10 }} onClick={handleRemoveImg} />}
                    </>
                )
            }
        }
    }

    return (
        <ComponentContainer direction='vertical' {...props}>
            <ImgContainer height={height} width={width} style={imgStyle} showPointerCursor={!selectedImg?.preview ? false : !disableClick}>
                {renderContent()}
            </ImgContainer>
        </ComponentContainer>
    )
}

export default Image