import Typography from '../share/Typography';
import RadioGroup from '../share/RadioGroup';
import { Grid, Box, List, ListItem } from '@mui/material';
import styled from 'styled-components';
import { Controller } from "react-hook-form";
import GreyDivider from '../GreyDivider';
import Button from '../share/Button'
import _ from 'lodash';
import { useState } from 'react';
import SectionList from '../share/control/SectionList'
import CheckAllSection from '../share/control/CheckAllComponent'

const CompanyInformation = [
    {
        name: "Business Registration No. (New Name : XXXXX)",
        id: 'businessRegistrationNo'
    },
    {
        name: "Company Name (English) (New Name : XXXXX)",
        id: 'companyNameEn'
    },
    {
        name: "Company Name (Chinese) (New Name : XXXXX)",
        id: 'companyNameZh'
    },
    {
        name: "Industry",
        id: 'industry'
    },
    {
        name: "Branch Name (English)",
        id: 'branchNameEn'
    },
    {
        name: "Branch Name (Chinese)",
        id: 'branchNameZh'
    },
    {
        name: "Business Registration Expiry Date",
        id: 'businessRegistrationExpiryDate'
    },
    {
        name: "Company Registered Address",
        id: 'header'
    },
    {
        name: "Address",
        id: 'address'
    },
    {
        name: "Distract",
        id: 'distract'
    },
    {
        name: "Company Registered Address",
        id: 'header'
    },
    {
        name: "Company Registered Address",
        id: 'companyRegisteredAddress'
    },
]

const AdminProfile = [
    {
        name: "Title",
        id: 'title'
    },
    {
        name: "Full Name (English)",
        id: 'fullNameEn'
    },
    {
        name: "Full Name (Chinese)",
        id: 'fullNameZh'
    },
    {
        name: "Birthday Date",
        id: 'birthdayDate'
    },
    {
        name: "Email",
        id: 'email'
    },
    {
        name: "Contact",
        id: 'contact'
    },
    {
        name: "Certificate Type",
        id: 'certificateType'
    },
    {
        name: "HK Identity Card No.",
        id: 'hkIdentity'
    },
]

const DeliveryMethod = [
    {
        name: "Delivery Method",
        id: 'deliveryMethod'
    }
]

const UploadDoc = [
    {
        name: "Business Registration (BR)",
        id: 'businessRegistration'
    },
    {
        name: "HKID Card",
        id: 'hkIdCard'
    },
    {
        name: "Address Proof",
        id: 'addressProof'
    },
    {
        name: "Mobile No. Portability (MNP)",
        id: 'mobileNoPortability'
    },
    {
        name: "Mobile No. Portability (MNP) Download Form",
        id: 'mobileNoPortabilityDownloadForm',
    },
    {
        name: "Real Name Registration (RNR)",
        id: 'realNameRegistration'
    }
]

function ControlComponent({ control, reset, getValues, readOnly }) {

    const [companyInfoStatus, setCompanyInfoStatus] = useState(null)
    const [adminProfileStatus, setAdminProfileStatus] = useState(null)
    const [deliveryMethodStatus, setDeliveryMethodStatus] = useState(null)
    const [uploadDocStatus, setUploadDocStatus] = useState(null)

    const handleOnCheckAll = (value, id) => {
        const values = getValues();
        console.log("handleOnCheckAll-values", values)
        let controlPayload = {
            ...values.control
        };
        let data = [];
        if (id === 'company-info') {
            data = CompanyInformation
            setCompanyInfoStatus(value)
        } else if (id === 'admin-profile') {
            data = AdminProfile
            setAdminProfileStatus(value)
        } else if (id === 'delivery-method') {
            data = DeliveryMethod
            setDeliveryMethodStatus(value)
        } else if (id === 'upload-doc') {
            data = UploadDoc
            setUploadDocStatus(value)
        }
        _.forEach(data, (item) => {
            controlPayload[item.id] = value
        })
        console.log("handleOnCheckAll", controlPayload)
        reset && reset({
            ...values,
            control: controlPayload
        })
    }

    return (
        <Grid container direction='column'>
            <CheckAllSection title="Company Information" value={companyInfoStatus} onChange={(v) => handleOnCheckAll(v, 'company-info')} readOnly={readOnly} />
            <SectionList data={CompanyInformation} control={control} name="company-info" readOnly={readOnly} />
            <GreyDivider />
            <div style={{ marginTop: 20 }}>
                <CheckAllSection title="Admin Profile" value={adminProfileStatus} onChange={(v) => handleOnCheckAll(v, 'admin-profile')} readOnly={readOnly} />
                <SectionList data={AdminProfile} control={control} name="admin-profile" readOnly={readOnly} />
            </div>
            <GreyDivider />
            <div style={{ marginTop: 20 }}>
                <CheckAllSection title="Delivery Method" value={deliveryMethodStatus} onChange={(v) => handleOnCheckAll(v, 'delivery-method')} readOnly={readOnly} />
                <SectionList data={DeliveryMethod} control={control} name="delivery-method" readOnly={readOnly} />
            </div>
            <GreyDivider />
            <div style={{ marginTop: 20 }}>
                <CheckAllSection title="Upload Document" value={uploadDocStatus} onChange={(v) => handleOnCheckAll(v, 'upload-doc')} readOnly={readOnly} />
                <SectionList data={UploadDoc} control={control} name="upload-doc" readOnly={readOnly} />
            </div>
        </Grid>
    )
}

export default ControlComponent